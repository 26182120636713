import React from 'react'
import { Card, CardContent } from '@mui/material'
import { FilesystemNavigation } from './FilesystemNavigation'
import { FilesystemActions } from './FilesystemActions'
import { FilesystemFileview } from './FilesystemFileview'
import { RenderTree } from '../utils/types'

type Props = {
  files: RenderTree[]
  currentPath: string
  currentDepth: number
  history: any
  tickedFiles: RenderTree[]
  onFileClick: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, file: any) => void
  onHistoryForwardClick: () => void
  onHistoryBackClick: () => void
  updateTickedStatus: (file: RenderTree) => void
  getTickedStatus: (file: RenderTree) => number
  onTickAllFilesClick: () => void
  onUntickAllFilesClick: () => void
  onReloadClick: () => void
  onCreateFolderClick: () => void
  onRenameFileClick?: () => void
  onDownloadFileClick: () => void
  onDeleteFileClick: () => void
  allowCreateFolder: boolean
  allowRename: boolean
  allowDownload: boolean
  allowDelete: boolean
  sx?: React.CSSProperties
}
/**
 * Filesystem consisting of:
 *  - FileUpload
 *  - CADUpload
 *  - Filesystem navigation
 *  - Filesystem actions
 *  - Filesystem fileview
 */
export function Filesystem(props: Props) {
  const {
    files,
    currentPath,
    currentDepth,
    history,
    tickedFiles,
    onFileClick,
    onHistoryForwardClick,
    onHistoryBackClick,
    updateTickedStatus,
    getTickedStatus,
    onTickAllFilesClick,
    onUntickAllFilesClick,
    onReloadClick,
    onCreateFolderClick,
    onRenameFileClick,
    onDownloadFileClick,
    onDeleteFileClick,
    allowCreateFolder,
    allowRename,
    allowDownload,
    allowDelete,
    sx,
  } = props

  return (
    <div style={{ ...sx, marginBottom: '50px' }}>
      <Card>
        <CardContent>
          <FilesystemNavigation
            history={history}
            currentDepth={currentDepth}
            currentPath={currentPath}
            onHistoryBackClick={onHistoryBackClick}
            onHistoryForwardClick={onHistoryForwardClick}
          />
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <FilesystemActions
              spacing={1}
              wrapAlign="right"
              onReloadClick={onReloadClick}
              onCreateFolderClick={onCreateFolderClick}
              allowCreateFolder={allowCreateFolder}
              /*onRenameFileClick={onRenameFileClick}*/
              onDownloadFileClick={onDownloadFileClick}
              onDeleteFileClick={onDeleteFileClick}
              allowRename={allowRename}
              allowDownload={allowDownload}
              allowDelete={allowDelete}
              sx={{ marginTop: '8px', marginBottom: '4px' }}
            />
          </div>
          <FilesystemFileview
            files={files}
            tickedFiles={tickedFiles}
            onFileClick={onFileClick}
            updateTickedStatus={updateTickedStatus}
            getTickedStatus={getTickedStatus}
            onTickAllFilesClick={onTickAllFilesClick}
            onUntickAllFilesClick={onUntickAllFilesClick}
          />
        </CardContent>
      </Card>
    </div>
  )
}
