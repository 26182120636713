import React from 'react'
import { Button, Card, CardActions, CardContent, CardHeader, Grid, Modal } from '@mui/material'
import { useTranslation } from 'react-i18next'

enum ModalType {
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
}

type Props = {
  name: string
  type: ModalType
  open: boolean
  setOpen: (open: boolean) => void
  onConfirm: () => void
}
export default function AreYouSureModal(props: Props) {
  const { name, type, open, setOpen, onConfirm } = props
  const { t } = useTranslation()

  const body = (
    <Grid
      container
      spacing={0}
      alignItems={'center'}
      justifyContent={'center'}
      style={{ height: '100%', padding: '10px' }}
    >
      <Grid item>
        <Card style={{ padding: '20px' }}>
          <CardHeader title={t('areYouSure')} />
          {type === 'ACCEPT' && <CardContent>{t('acceptRequestText', { name: name })}</CardContent>}
          {type === 'REJECT' && <CardContent>{t('rejectRequestText', { name: name })}</CardContent>}
          <CardActions>
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs={6}>
                <Button
                  style={{ float: 'left' }}
                  color="primary"
                  variant="outlined"
                  onClick={() => props.setOpen(false)}
                >
                  {t('cancel')}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  style={{ float: 'right' }}
                  color="primary"
                  variant="contained"
                  onClick={onConfirm}
                >
                  {type === 'ACCEPT' && t('accept')}
                  {type === 'REJECT' && t('reject')}
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  )

  return (
    <div>
      <Modal open={open} onClose={() => setOpen(false)}>
        {body}
      </Modal>
    </div>
  )
}
