import { Address } from '../../../graphql/graphql'

/**
 * Check if address has all minimal required information
 * @param address
 * @returns if address is valid
 */
export const validAddress = (address: Omit<Address, 'id'> | Address): boolean => {
  return !!address?.city && !!address?.zip_code && !!address?.country && !!address?.address_1
}
export const stringNotEmpty = (s: string) => s !== ''
