import React from 'react'
import { useTranslation } from 'react-i18next'
import useMobileService from '../../hooks/useMobileService'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

type Props<T> = {
  name: string
  value: T
  options: { value: T; label?: string; disabled?: boolean }[]
  disabled?: boolean
  labelWidth?: number
  onChange: (value: T) => void
}

export default function SelectInput<T extends string | number | boolean>(props: Props<T>) {
  const { name, value, options, disabled, labelWidth = 100, onChange } = props
  const { t } = useTranslation(['common', 'profile'])

  const isMobile = useMobileService()
  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel htmlFor={name}>{name}</InputLabel>
      <Select
        native={isMobile}
        label={name}
        id={name}
        value={value ? value : ''}
        disabled={disabled}
        onChange={e => onChange(e.target.value as T)}
      >
        {options.map(option =>
          isMobile ? (
            <option
              value={option.value as string}
              disabled={option.disabled}
              key={option.value as string}
            >
              {option.label ? t(option.label) : option.value}
            </option>
          ) : (
            <MenuItem
              value={option.value as string}
              disabled={option.disabled}
              key={option.value as string}
            >
              {option.label ? t(option.label) : option.value}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  )
}
