/**
 * Dynamically read a property from an object based on the path
 * Values inside Arrays can be accessed by using the index as key e.g. `array_property.2.property`
 * @param obj the object
 * @param path the path (e.g. `nested.property.0`)
 * @returns the property or undefined
 */
export const getPropertyByString = (obj: any, path: string) =>
  path.split('.').reduce((acc, part) => acc && acc[part], obj)

/**
 * Dynamically sets a property from an object to a new value based on the path
 * Values inside Arrays can be accessed by using the index as key e.g. `array_property.2.property`
 * @param obj the object
 * @param path the path (e.g. `nested.property.0`)
 * @param value the new value
 * @returns the updated object
 */
export const setPropertyByString = (obj: any, path: string, value: any): any => {
  const props = path.split('.')
  const prop = props.shift()

  const updateArray = (index: number, value: any) => {
    obj.splice(index, 1, value)
    return [...obj]
  }

  if (!prop) {
    return obj
  }

  if (props.length === 0) {
    return obj instanceof Array ? updateArray(+prop, value) : { ...obj, [prop]: value }
  } else {
    if (obj instanceof Array) {
      const index = +prop
      return updateArray(index, setPropertyByString(obj[index], props.join('.'), value))
    }
    return { ...obj, [prop]: setPropertyByString(obj[prop] ?? {}, props.join('.'), value) }
  }
}

/**
 * Read manufacturing_process_data for a product object
 * @param obj the object (in this case always a product)
 * @param path the path to the required field
 * @returns manProcessData from path or undefined
 * */
export const getManProcessData = (obj: any, path: string) => {
  path = path.toString().split('.')[1]
  if (obj) {
    if (Object.keys(obj).includes('manufacturing_process_data')) {
      const manProcessData = JSON.parse(getPropertyByString(obj, 'manufacturing_process_data'))
      if (manProcessData && Object.keys(manProcessData).includes(path)) {
        return manProcessData[path]
      } else {
        return undefined
      }
    }
  }
}

/**
 * Special case for setPropertyByString for manipulating the manufacturing_process_data JSON field
 * of a Product
 * @param obj the object (in this case always a product)
 * @param path the path (e.g. `manufacturing_process_data.` + field in JSON object)
 * @param value the new value (for example 'GTIN' for barcodeType)
 * @returns the object with an updated manufacturing_process_data JSON string
 * */
export const setManProcessData = (obj: any, path: string, value: any) => {
  path = path.toString().split('.')[1]
  if (obj && Object.keys(obj).includes('manufacturing_process_data')) {
    const manProcessData = JSON.parse(getPropertyByString(obj, 'manufacturing_process_data'))
    const newManProcessData = JSON.stringify(setPropertyByString(manProcessData, path, value))
    return { ...obj, manufacturing_process_data: newManProcessData }
  } else {
    const manProcessData = { [path]: value }
    const newManProcessData = JSON.stringify(manProcessData)
    return { ...obj, manufacturing_process_data: newManProcessData }
  }
}
