import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Address } from '../../../graphql/graphql'
import { getAddress, listAddresses } from '../../../graphql/queries'
import useMobileService from '../../../hooks/useMobileService'
import { handlePromise, notFalsy } from '../../../utils/functions'
import { getAddressText } from '../utils/functions'
import Create from './Create'
import { API, graphqlOperation } from 'aws-amplify'

interface Props {
  name: string
  addressId: string
  onChange: (address: Address) => void
  editing?: boolean
  createable?: boolean
}

/**
 * Select Input that lets you select already created adresses
 */
export function AddressSelect(props: Props) {
  const { name, addressId, editing, createable = true } = props
  const [addressList, setAddressList] = useState<Address[]>()
  const [address, setAddress] = useState<Address>()
  const { t } = useTranslation(['profile'])
  const isMobile = useMobileService()

  useEffect(() => {
    fetchAddresses()
    fetchAddress()
  }, [])

  async function fetchAddresses() {
    const [res, err] = await handlePromise(
      'listAddresses',
      API.graphql(graphqlOperation(listAddresses))
    )
    res
      ? setAddressList(res.data.listAddresses.items)
      : console.log('Error on fetching addresses', err)
  }

  async function fetchAddress() {
    const [res, err] = await handlePromise(
      'getAddress',
      API.graphql(graphqlOperation(getAddress, { input: { id: addressId } }))
    )
    res ? setAddress(res.data.getAddress) : console.log('Error while fetching Address', err)
  }

  const onChange = (id: string) => {
    const selected = addressList?.filter(notFalsy).find(item => item.id === id)
    if (selected) {
      props.onChange(selected as Address)
    }
  }

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel htmlFor="address_select">{t(name)}</InputLabel>
      <Select
        native={isMobile}
        label={t(name)}
        value={addressId}
        disabled={!editing}
        onChange={e => onChange(e.target.value as string)}
      >
        {addressList?.filter(notFalsy).map((item: Address, index: number) =>
          isMobile ? (
            <option value={item.id} disabled={item.id === address?.id} key={index}>
              {getAddressText(item as Address)}
            </option>
          ) : (
            <MenuItem value={item.id} disabled={item.id === address?.id} key={index}>
              {getAddressText(item as Address)}
            </MenuItem>
          )
        )}
      </Select>
      {createable && editing && (
        <FormHelperText>
          <Create
            type="text"
            onSuccess={address => props.onChange(address)}
            fetchAddresses={fetchAddresses}
          />
        </FormHelperText>
      )}
    </FormControl>
  )
}
