import React, { useEffect, useState } from 'react'
import Page from '../components/layout/Page'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import { Form, FormFieldType } from '../features/Form'
import { Address, Profile } from '../graphql/graphql'
import PaperCard from '../components/widgets/PaperCard'
import FAB from '../components/widgets/FAB'
import { useNotifications } from '../features/Feedback'
import { useTranslation } from 'react-i18next'
import { listAddresses } from '../graphql/queries'
import { AddressList } from '../features/Address'
import { Button, Card } from '@mui/material'
import { handlePromise } from '../utils/functions'
import { parseUserAttr } from '../features/Address/utils/functions'
import DeleteModal from '../components/widgets/DeleteModal'

const formFields: FormFieldType[] = [
  {
    type: 'text',
    key: 'custom:company_name',
    label: 'profile:company',
    xs: 12,
    sm: 6,
    /*validation: (value: string) => value.length > 6,*/
    errorMessage: 'Länger als 6',
    labelWidth: 120,
  },
  { type: 'text', key: 'custom:contact_person', label: 'profile:contactPerson', xs: 12, sm: 6 },
  { type: 'email', key: 'email', label: 'profile:email', xs: 12, sm: 6 },
  { type: 'text', key: 'custom:phone_number', label: 'profile:phone', xs: 12, sm: 6 },
  { type: 'text', key: 'custom:mobile_number', label: 'profile:mobile', xs: 12, sm: 6 },
  { type: 'text', key: 'custom:vat_number', label: 'profile:vat', xs: 12, sm: 6 },
  {
    type: 'select',
    key: 'custom:contact_way',
    label: 'profile:preferredContactWay',
    xs: 12,
    sm: 6,
    options: [
      { value: 'email', label: 'email' },
      { value: 'phone', label: 'profile:phone' },
      { value: 'mobile', label: 'profile:mobile' },
    ],
  },
]

export default function ProfilePage() {
  const [editing, setEditing] = useState(false)
  const [profile, setProfile] = useState<Profile>()
  const [open, setOpen] = useState<boolean>(false)
  const [addresses, setAddresses] = useState<Address[]>([])
  const [error, setError] = useState(false)
  const { addNotification } = useNotifications()
  const { t } = useTranslation()

  useEffect(() => {
    getUserProfile()
  }, [])

  async function fetchAddresses() {
    const [data, error] = await handlePromise(
      'listAddresses',
      API.graphql(graphqlOperation(listAddresses))
    )
    error ? console.log('Error on fetching addresses') : setAddresses(data.data.listAddresses.items)
  }

  async function getUserProfile() {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true })
    setProfile(user.attributes)
    fetchAddresses()
  }

  async function updateUserAttributes() {
    const user = await Auth.currentAuthenticatedUser()
    if (profile) {
      const userAttr = parseUserAttr(profile)
      Auth.updateUserAttributes(user, { 'custom:mobile_number': userAttr.mobile_number }).catch(
        () => setError(true)
      )
      Auth.updateUserAttributes(user, { 'custom:contact_person': userAttr.contact_person }).catch(
        () => setError(true)
      )
      Auth.updateUserAttributes(user, { 'custom:company_name': userAttr.company_name }).catch(() =>
        setError(true)
      )
      Auth.updateUserAttributes(user, { 'custom:vat_number': userAttr.vat_number }).catch(() =>
        setError(true)
      )
      Auth.updateUserAttributes(user, {
        'custom:contact_way': userAttr.preferred_contact_way,
      }).catch(() => setError(true))
      Auth.updateUserAttributes(user, { 'custom:phone_number': userAttr.phone_number }).catch(() =>
        setError(true)
      )
    }
    if (error) {
      addNotification({ message: t('error.errorOccurred'), color: 'error' })
    } else {
      addNotification({ message: t('success.changesSavedSuccessfully'), color: 'success' })
    }
    onEditCancel()
  }

  const onEditCancel = () => {
    setEditing(false)
  }

  async function deleteUser() {
    try {
      const result = await Auth.deleteUser()
      console.log(result)
    } catch (error) {
      console.log('Error deleting user', error)
    }
  }

  async function signOut() {
    try {
      await Auth.signOut()
      window.location.reload()
    } catch (error) {
      console.log('error signing out: ', error)
    }
  }

  return (
    <Page marginBottom="200px">
      <PaperCard
        title={t('pages.profile')}
        editing={editing}
        onEditCancel={onEditCancel}
        onSave={updateUserAttributes}
      >
        {profile && (
          <Form
            editing={editing}
            formObject={profile}
            onChange={value => setProfile(value as Profile)}
            formFields={formFields}
          />
        )}
        <Button
          color="error"
          variant="contained"
          sx={{ margin: '15px' }}
          onClick={() => setOpen(!open)}
        >
          {t('deleteProfile')}
        </Button>
        <DeleteModal
          name={t('theProfile')}
          open={open}
          setOpen={() => setOpen(!open)}
          onDelete={() => {
            deleteUser()
              .then(r => signOut().then(r => console.log('Deleted profile and signed out')))
              .catch(err => console.log('Error deleting profile', err))
          }}
        />
      </PaperCard>
      <Card>
        <AddressList
          addresses={addresses.map(x => x) || []}
          editing={editing}
          fetchAddresses={fetchAddresses}
        />
      </Card>
      <FAB type="EDIT" onClick={() => setEditing(true)} hidden={editing} />
    </Page>
  )
}
