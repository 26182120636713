import React from 'react'
import useMobileService from '../../hooks/useMobileService'
import ErrorBoundary from './ErrorBoundary'
import { Box, Breadcrumbs, Grid, Link, Typography } from '@mui/material'

type Props = {
  children: React.ReactNode
  title?: React.ReactNode
  showTitleOnMobile?: boolean
  breadcrumps?: { name: string; href?: string }[]
  className?: string
  marginBottom?: string
}

export default function Page(props: Props) {
  const isMobile = useMobileService()
  const { children, title, breadcrumps, className, showTitleOnMobile, marginBottom } = props

  return (
    <Box
      flex={1}
      sx={{
        width: isMobile ? null : '100%',
        padding: '10px',
        marginBottom: marginBottom,
      }}
    >
      <Grid container className={className || 'main'}>
        <Grid container className="header">
          <Grid item>
            {title &&
              (showTitleOnMobile || !isMobile) &&
              (typeof title === 'string' ? <h1 className="title">{title}</h1> : title)}
          </Grid>
          <Grid item>
            {breadcrumps && (
              <Breadcrumbs maxItems={2} aria-label="breadcrumb">
                {breadcrumps.map(({ name, href }, index) =>
                  index < breadcrumps.length - 1 ? (
                    <Link key={index} color="inherit" href={href}>
                      {name}
                    </Link>
                  ) : (
                    <Typography key={index} color="textPrimary">{name}</Typography>
                  )
                )}
              </Breadcrumbs>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ErrorBoundary>{children}</ErrorBoundary>
        </Grid>
      </Grid>
    </Box>
  )
}
