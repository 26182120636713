import { ColorPicker, ColorValue } from 'mui-color'
import React from 'react'
import useMobileService from '../../hooks/useMobileService'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
  value: ColorValue
  disabled?: boolean
  labelWidth?: number
  onChange: (value: ColorValue) => void
}

export default function ColorInput(props: Props) {
  const { value, disabled, labelWidth, onChange } = props
  const { t } = useTranslation()

  const isMobile = useMobileService()
  return (
    <div>
      <Typography>{t('selectColor')}</Typography>
      <ColorPicker value={value} onChange={(color: ColorValue) => onChange(color)} />
    </div>
  )
}
