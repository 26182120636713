import React from 'react'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
  onForward: () => void
  disabled?: boolean
  text: string
}

export default function ForwardButton(props: Props) {
  const { t } = useTranslation()
  return (
    <Button
      disabled={props.disabled}
      color="primary"
      variant="contained"
      style={{ float: 'right', marginTop: '5px' }}
      onClick={props.onForward}
    >
      {t(props.text)}
    </Button>
  )
}
