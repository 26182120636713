import React, {useEffect, useMemo, useState} from 'react'
import { useDropzone } from 'react-dropzone'
import { Card, CardContent, Typography } from '@mui/material'
import { useNotifications } from '../../features/Feedback'
import { useTranslation } from 'react-i18next'
import LinearProgressWithLabel from '../widgets/LinearProgressWithLabel'
import UnauthFileList from '../widgets/UnauthFileList'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column' as const,
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const focusedStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

type File = {
  name: string
  size: number
  type: string
  lastModified: number
  path: string
  webkitRelativePath: string
}

type Props = {
  fileList: File[]
  setFileList: (fileList: File[]) => void
}

export default function UnauthS3FileUpload(props: Props) {
  const { fileList, setFileList } = props
  const [loading, setLoading] = useState(false) // loading status for uploading files
  const [errorMsg, setErrorMsg] = useState<string>('') // error status for uploading files
  const { addNotification } = useNotifications()
  const { t } = useTranslation(['common'])
  const maxFileSize = 52428800 // 50MB
  const maxFiles = 10 // 10 files

  useEffect(() => {
    if (errorMsg) {
      addNotification({
        message: t(errorMsg),
        color: 'error',
      })
    }
  }, [errorMsg])

  // hook for upload that uses drag and drop
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, acceptedFiles } =
    useDropzone({
      multiple: true,
      accept: ".stl, .stp, .step, .iges, .igs, .3mf, .e57, .example, .gltf-binary, .JT, .mesh, .mtl, .obj, .prc, .u3d, .vnd, vrml, .dxf, .dwg, .sat, .sab, .pdf, .xlsx, .doc, .docx, .ppt, .pptx, .xls, .jpg, .jpeg, .png, .gif, .bmp, .tiff, .tif, .svg, .mp3, .mp4, .wav, .avi, .mov, .wmv, .flv, .mkv, .webm, .mpg, .mpeg, .m4v",
      onDrop: (acceptedFiles: any[], fileRejections) => {
        addFiles(acceptedFiles)
        if (fileRejections.length > 0) {
          fileRejections.forEach(file => {
            if (file.errors.length > 0) {
              file.errors.forEach(error => {
                if (error.code === 'file-too-large') {
                  setErrorMsg('error.fileTooLargeText')
                } else if (error.code === 'file-invalid-type') {
                  setErrorMsg('error.fileInvalidTypeText')
                } else if (error.code === 'too-many-files') {
                  setErrorMsg('error.tooManyFilesText')
                } else {
                  setErrorMsg('error.fileInvalidText')
                }
              })
            }
          })
        }
      },
      maxFiles: maxFiles, // 10 files
      maxSize: maxFileSize, // 50MB
    })

  const addFiles = (files: File[]) => {
    setFileList([...fileList, ...files])
  }

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  )

  return (
    <Card>
      <CardContent>
        <Typography>{t('uploadAdditionalFiles')}</Typography>
      </CardContent>
      <Card>
        <div className="container" style={{ margin: '5px' }}>
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <p>Drag &apos;n&apos; drop some files here, or click to select files <br/>
              Upload up to {maxFiles} files, each file must be less than 50MB <br/>
                Supported files: cad (STEP Files recommended), pdf, excel, word, powerpoint, image, audio, video
            </p>
          </div>
          {loading && <LinearProgressWithLabel value={100} />}
          {fileList.length > 0 && <UnauthFileList fileList={fileList} setFileList={setFileList} />}
        </div>
      </Card>
    </Card>
  )
}
