/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getManufacturingRequirement = /* GraphQL */ `
  query GetManufacturingRequirement($id: ID!) {
    getManufacturingRequirement(id: $id) {
      id
      type
      label
      value
      description
      options
      manufacturingProcessID
      ManufacturingProcess {
        id
        category
        process
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listManufacturingRequirements = /* GraphQL */ `
  query ListManufacturingRequirements(
    $filter: ModelManufacturingRequirementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listManufacturingRequirements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        label
        value
        description
        options
        manufacturingProcessID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getManufacturingProcess = /* GraphQL */ `
  query GetManufacturingProcess($id: ID!) {
    getManufacturingProcess(id: $id) {
      id
      category
      process
      Requirements {
        nextToken
      }
      Components {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listManufacturingProcesses = /* GraphQL */ `
  query ListManufacturingProcesses(
    $filter: ModelManufacturingProcessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listManufacturingProcesses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        category
        process
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContractStatus = /* GraphQL */ `
  query GetContractStatus($id: ID!) {
    getContractStatus(id: $id) {
      id
      date
      createdAt
      contractID
      Contract {
        id
        billingAddressID
        certificates
        comment_customer
        comment_internal
        currency
        deadline
        description
        price
        priority
        quantity
        read_only
        remarks
        shippingAddressID
        version
        wish_date
        productID
        createdAt
        updatedAt
        owner
      }
      Status
      owner
      updatedAt
    }
  }
`;
export const listContractStatuses = /* GraphQL */ `
  query ListContractStatuses(
    $filter: ModelContractStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContractStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        createdAt
        contractID
        Status
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const contractStatusByContract = /* GraphQL */ `
  query ContractStatusByContract(
    $contractID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContractStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contractStatusByContract(
      contractID: $contractID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        createdAt
        contractID
        Status
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContractRequest = /* GraphQL */ `
  query GetContractRequest($id: ID!) {
    getContractRequest(id: $id) {
      id
      date
      createdAt
      contractID
      Contract {
        id
        billingAddressID
        certificates
        comment_customer
        comment_internal
        currency
        deadline
        description
        price
        priority
        quantity
        read_only
        remarks
        shippingAddressID
        version
        wish_date
        productID
        createdAt
        updatedAt
        owner
      }
      accepted
      updatedAt
      owner
    }
  }
`;
export const listContractRequests = /* GraphQL */ `
  query ListContractRequests(
    $filter: ModelContractRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContractRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        createdAt
        contractID
        accepted
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const contractRequestByContract = /* GraphQL */ `
  query ContractRequestByContract(
    $contractID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContractRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contractRequestByContract(
      contractID: $contractID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        createdAt
        contractID
        accepted
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getContract = /* GraphQL */ `
  query GetContract($id: ID!) {
    getContract(id: $id) {
      id
      billingAddressID
      certificates
      comment_customer
      comment_internal
      currency
      deadline
      description
      price
      priority
      quantity
      read_only
      remarks
      shippingAddressID
      version
      wish_date
      productID
      Product {
        id
        name
        description
        article_number
        barcode_type
        barcode_content
        comments
        extra_data
        owner
        createdAt
        updatedAt
      }
      ShippingAddress {
        id
        name
        address_1
        address_2
        zip_code
        city
        country
        type
        createdAt
        updatedAt
        owner
      }
      BillingAddress {
        id
        name
        address_1
        address_2
        zip_code
        city
        country
        type
        createdAt
        updatedAt
        owner
      }
      Status {
        nextToken
      }
      Request {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listContracts = /* GraphQL */ `
  query ListContracts(
    $filter: ModelContractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContracts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        billingAddressID
        certificates
        comment_customer
        comment_internal
        currency
        deadline
        description
        price
        priority
        quantity
        read_only
        remarks
        shippingAddressID
        version
        wish_date
        productID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const contractByProduct = /* GraphQL */ `
  query ContractByProduct(
    $productID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelContractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contractByProduct(
      productID: $productID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        billingAddressID
        certificates
        comment_customer
        comment_internal
        currency
        deadline
        description
        price
        priority
        quantity
        read_only
        remarks
        shippingAddressID
        version
        wish_date
        productID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      name
      description
      article_number
      barcode_type
      barcode_content
      comments
      extra_data
      owner
      Components {
        nextToken
      }
      Contracts {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        article_number
        barcode_type
        barcode_content
        comments
        extra_data
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getComponent = /* GraphQL */ `
  query GetComponent($id: ID!) {
    getComponent(id: $id) {
      id
      name
      root
      description
      dimensions {
        bounding_box_x
        bounding_box_y
        bounding_box_z
        volume
      }
      article_number
      barcode_type
      barcode_content
      tolerances
      weight
      thread_count
      masking
      certificates
      comment
      comment_internal
      costs
      manufacturing_data
      productID
      Product {
        id
        name
        description
        article_number
        barcode_type
        barcode_content
        comments
        extra_data
        owner
        createdAt
        updatedAt
      }
      manufacturingprocessID
      ManufacturingProcess {
        id
        category
        process
        createdAt
        updatedAt
      }
      Parts {
        nextToken
      }
      Materials {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listComponents = /* GraphQL */ `
  query ListComponents(
    $filter: ModelComponentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComponents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        root
        description
        article_number
        barcode_type
        barcode_content
        tolerances
        weight
        thread_count
        masking
        certificates
        comment
        comment_internal
        costs
        manufacturing_data
        productID
        manufacturingprocessID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const componentByProduct = /* GraphQL */ `
  query ComponentByProduct(
    $productID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelComponentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    componentByProduct(
      productID: $productID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        root
        description
        article_number
        barcode_type
        barcode_content
        tolerances
        weight
        thread_count
        masking
        certificates
        comment
        comment_internal
        costs
        manufacturing_data
        productID
        manufacturingprocessID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getBOM = /* GraphQL */ `
  query GetBOM($id: ID!) {
    getBOM(id: $id) {
      id
      quantity
      parentID
      childID
      Child {
        id
        name
        root
        description
        article_number
        barcode_type
        barcode_content
        tolerances
        weight
        thread_count
        masking
        certificates
        comment
        comment_internal
        costs
        manufacturing_data
        productID
        manufacturingprocessID
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listBOMS = /* GraphQL */ `
  query ListBOMS(
    $filter: ModelBOMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBOMS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        quantity
        parentID
        childID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getMaterial = /* GraphQL */ `
  query GetMaterial($id: ID!) {
    getMaterial(id: $id) {
      id
      name
      type
      available
      description
      chemical_name
      numerical_name
      number
      Components {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMaterials = /* GraphQL */ `
  query ListMaterials(
    $filter: ModelMaterialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMaterials(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        available
        description
        chemical_name
        numerical_name
        number
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      company_name
      contact_person
      mobile_number
      vat_number
      preferred_contact_way
      preferred_incoterms
      phone_number
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        company_name
        contact_person
        mobile_number
        vat_number
        preferred_contact_way
        preferred_incoterms
        phone_number
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getAddress = /* GraphQL */ `
  query GetAddress($id: ID!) {
    getAddress(id: $id) {
      id
      name
      address_1
      address_2
      zip_code
      city
      country
      type
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listAddresses = /* GraphQL */ `
  query ListAddresses(
    $filter: ModelAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAddresses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        address_1
        address_2
        zip_code
        city
        country
        type
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getChannel = /* GraphQL */ `
  query GetChannel($id: ID!) {
    getChannel(id: $id) {
      id
      address_from
      address_to
      reference
      visitedAt
      isNew
      product
      Messages {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listChannels = /* GraphQL */ `
  query ListChannels(
    $filter: ModelChannelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChannels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        address_from
        address_to
        reference
        visitedAt
        isNew
        product
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      body
      author
      channelID
      createdAt
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        body
        author
        channelID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const messageByChannel = /* GraphQL */ `
  query MessageByChannel(
    $channelID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messageByChannel(
      channelID: $channelID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        body
        author
        channelID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUnauthRequest = /* GraphQL */ `
  query GetUnauthRequest($id: ID!) {
    getUnauthRequest(id: $id) {
      id
      email
      name
      company_name
      address
      zip_code
      city
      country
      phone_number
      project_description
      status
      createdAt
      updatedAt
    }
  }
`;
export const listUnauthRequests = /* GraphQL */ `
  query ListUnauthRequests(
    $filter: ModelUnauthRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUnauthRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        name
        company_name
        address
        zip_code
        city
        country
        phone_number
        project_description
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEmailResult = /* GraphQL */ `
  query GetEmailResult($id: ID!) {
    getEmailResult(id: $id) {
      statusCode
      body
      id
      createdAt
      updatedAt
    }
  }
`;
export const listEmailResults = /* GraphQL */ `
  query ListEmailResults(
    $filter: ModelEmailResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmailResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        statusCode
        body
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getComponentMaterials = /* GraphQL */ `
  query GetComponentMaterials($id: ID!) {
    getComponentMaterials(id: $id) {
      id
      componentID
      materialID
      component {
        id
        name
        root
        description
        article_number
        barcode_type
        barcode_content
        tolerances
        weight
        thread_count
        masking
        certificates
        comment
        comment_internal
        costs
        manufacturing_data
        productID
        manufacturingprocessID
        createdAt
        updatedAt
        owner
      }
      material {
        id
        name
        type
        available
        description
        chemical_name
        numerical_name
        number
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listComponentMaterials = /* GraphQL */ `
  query ListComponentMaterials(
    $filter: ModelComponentMaterialsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComponentMaterials(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        componentID
        materialID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
