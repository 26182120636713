import React, { useEffect, useState } from 'react'
import { Storage } from 'aws-amplify'
import { IconButton, Table, TableCell, TableHead, TableRow } from '@mui/material'
import { downloadBlob, formatBytes, handlePromise } from '../../utils/functions'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import DeleteIcon from '@mui/icons-material/Delete'
import { useTranslation } from 'react-i18next'
import { useConfirm, useNotifications } from '../../features/Feedback'
import { useLocation } from 'react-router-dom'
type Props = {
  type: string
  fileKeys: string[]
  filePath: string
}

/**
 * Filelist for fileUploads outside of the FileSystem
 * Shows you recently uploaded files, which then can be deleted or downloaded
 */
export default function FileList(props: Props) {
  const [fileList, setFileList] = useState<any[]>(props.fileKeys)
  const { t } = useTranslation(['profile'])
  const { confirmDialog } = useConfirm()
  const { addNotification } = useNotifications()
  const location = useLocation()

  useEffect(() => {
    props.filePath !== '' ? listFiles() : null
  }, [props.fileKeys])

  const listFiles = async () => {
    const files = await Storage.list(props.filePath, { level: 'private' })
    const fileList = files.filter(x => x.key !== '')
    let filteredFileList: any[] = []
    Object.values(fileList).map((value: any) => {
      const key: string = value.key.split('/').splice(-1).join()
      const fileType: string = key.substring(key.indexOf('.') + 1)
      if (props.type === 'CAD') {
        fileType === 'stp' || fileType === 'stl' ? filteredFileList.push(value) : null
      } else if (props.type === 'DOCUMENT') {
        fileType !== 'stp' && fileType !== 'stl' ? filteredFileList.push(value) : null
      } else {
        filteredFileList = fileList
      }
    })
    setFileList(filteredFileList)
  }

  const downloadFile = async (key: string) => {
    const result = await Storage.get(key, { download: true, level: 'private' })
    downloadBlob(result.Body, key)
  }

  async function removeFile(key: string) {
    const [result, error] = await handlePromise(
      'removeFile',
      Storage.remove(key, { level: 'private' })
    )
    await listFiles()
    result
      ? addNotification({ message: t('common:success.changesSavedSuccessfully'), color: 'success' })
      : addNotification({ message: t('common:error.errorOccurred'), color: 'error' })
  }

  const onClick = (key: string) =>
    confirmDialog(t('confirmDeleteFile'), () => removeFile(key), 'error')

  const files = fileList.map((file: any, index: number) => (
    <TableRow key={index}>
      <TableCell>{file.key.split('/').pop()}</TableCell>
      <TableCell>{formatBytes(file.size)}</TableCell>
      <TableCell>
        <IconButton onClick={() => downloadFile(file.key)}>
          <FileDownloadIcon />
        </IconButton>
        <IconButton onClick={() => onClick(file.key)}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  ))
  return (
    <div>
      {location.pathname !== '/files' && (
        <Table>
          {files.length >= 1 && (
            <TableHead>
              <TableCell>Name</TableCell>
              <TableCell>Size</TableCell>
              <TableCell>Actions</TableCell>
            </TableHead>
          )}
          {files}
        </Table>
      )}
    </div>
  )
}
