import {
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Address } from '../../../graphql/graphql'
import Create from './Create'
import Delete from './Delete'
import Update from './Update'

interface Props {
  editing?: boolean
  addresses?: (Address | null)[] | null
  fetchAddresses: () => void
}

// TODO: Jede Zelle bekommt aktuell einen eigenen Dialog!
/**
 * Lists all available adresses in a table
 */
export function AddressList(props: Props) {
  const { editing, addresses, fetchAddresses } = props
  const { t } = useTranslation()

  return (
    <Card>
      <Typography variant="h6" style={{ margin: '5px' }}>
        {t('profile:addressList.title')}
      </Typography>
      <Create fetchAddresses={fetchAddresses} />
      {addresses && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>{t('profile:addressList.address1')}</TableCell>
                <TableCell>{t('profile:addressList.city')}</TableCell>
                <TableCell>{t('profile:addressList.zipCode')}</TableCell>
                <TableCell>{t('profile:addressList.contactPerson')}</TableCell>
                {editing && <TableCell />}
              </TableRow>
            </TableHead>
            <TableBody>
              {addresses.map((x, i) => (
                <TableRow key={x?.id}>
                  <TableCell>{i + 1}</TableCell>
                  <TableCell>{x?.address_1}</TableCell>
                  <TableCell>{x?.city}</TableCell>
                  <TableCell>{x?.zip_code}</TableCell>
                  <TableCell>{x?.name}</TableCell>
                  {editing && (
                    <TableCell align="right" style={{ flexDirection: 'row' }}>
                      <div style={{ display: 'inline-block' }}>
                        <Update address={x as Address} fetchAddresses={fetchAddresses} />
                      </div>
                      <div style={{ display: 'inline-block' }}>
                        {x?.id && <Delete id={x.id} fetchAddresses={fetchAddresses} />}
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              ))}
              {editing && (
                <TableRow>
                  <TableCell colSpan={7} align="right">
                    <Create fetchAddresses={fetchAddresses} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Card>
  )
}
