/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createManufacturingRequirement = /* GraphQL */ `
  mutation CreateManufacturingRequirement(
    $input: CreateManufacturingRequirementInput!
    $condition: ModelManufacturingRequirementConditionInput
  ) {
    createManufacturingRequirement(input: $input, condition: $condition) {
      id
      type
      label
      value
      description
      options
      manufacturingProcessID
      ManufacturingProcess {
        id
        category
        process
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateManufacturingRequirement = /* GraphQL */ `
  mutation UpdateManufacturingRequirement(
    $input: UpdateManufacturingRequirementInput!
    $condition: ModelManufacturingRequirementConditionInput
  ) {
    updateManufacturingRequirement(input: $input, condition: $condition) {
      id
      type
      label
      value
      description
      options
      manufacturingProcessID
      ManufacturingProcess {
        id
        category
        process
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteManufacturingRequirement = /* GraphQL */ `
  mutation DeleteManufacturingRequirement(
    $input: DeleteManufacturingRequirementInput!
    $condition: ModelManufacturingRequirementConditionInput
  ) {
    deleteManufacturingRequirement(input: $input, condition: $condition) {
      id
      type
      label
      value
      description
      options
      manufacturingProcessID
      ManufacturingProcess {
        id
        category
        process
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createManufacturingProcess = /* GraphQL */ `
  mutation CreateManufacturingProcess(
    $input: CreateManufacturingProcessInput!
    $condition: ModelManufacturingProcessConditionInput
  ) {
    createManufacturingProcess(input: $input, condition: $condition) {
      id
      category
      process
      Requirements {
        nextToken
      }
      Components {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateManufacturingProcess = /* GraphQL */ `
  mutation UpdateManufacturingProcess(
    $input: UpdateManufacturingProcessInput!
    $condition: ModelManufacturingProcessConditionInput
  ) {
    updateManufacturingProcess(input: $input, condition: $condition) {
      id
      category
      process
      Requirements {
        nextToken
      }
      Components {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteManufacturingProcess = /* GraphQL */ `
  mutation DeleteManufacturingProcess(
    $input: DeleteManufacturingProcessInput!
    $condition: ModelManufacturingProcessConditionInput
  ) {
    deleteManufacturingProcess(input: $input, condition: $condition) {
      id
      category
      process
      Requirements {
        nextToken
      }
      Components {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createContractStatus = /* GraphQL */ `
  mutation CreateContractStatus(
    $input: CreateContractStatusInput!
    $condition: ModelContractStatusConditionInput
  ) {
    createContractStatus(input: $input, condition: $condition) {
      id
      date
      createdAt
      contractID
      Contract {
        id
        billingAddressID
        certificates
        comment_customer
        comment_internal
        currency
        deadline
        description
        price
        priority
        quantity
        read_only
        remarks
        shippingAddressID
        version
        wish_date
        productID
        createdAt
        updatedAt
        owner
      }
      Status
      owner
      updatedAt
    }
  }
`;
export const updateContractStatus = /* GraphQL */ `
  mutation UpdateContractStatus(
    $input: UpdateContractStatusInput!
    $condition: ModelContractStatusConditionInput
  ) {
    updateContractStatus(input: $input, condition: $condition) {
      id
      date
      createdAt
      contractID
      Contract {
        id
        billingAddressID
        certificates
        comment_customer
        comment_internal
        currency
        deadline
        description
        price
        priority
        quantity
        read_only
        remarks
        shippingAddressID
        version
        wish_date
        productID
        createdAt
        updatedAt
        owner
      }
      Status
      owner
      updatedAt
    }
  }
`;
export const deleteContractStatus = /* GraphQL */ `
  mutation DeleteContractStatus(
    $input: DeleteContractStatusInput!
    $condition: ModelContractStatusConditionInput
  ) {
    deleteContractStatus(input: $input, condition: $condition) {
      id
      date
      createdAt
      contractID
      Contract {
        id
        billingAddressID
        certificates
        comment_customer
        comment_internal
        currency
        deadline
        description
        price
        priority
        quantity
        read_only
        remarks
        shippingAddressID
        version
        wish_date
        productID
        createdAt
        updatedAt
        owner
      }
      Status
      owner
      updatedAt
    }
  }
`;
export const createContractRequest = /* GraphQL */ `
  mutation CreateContractRequest(
    $input: CreateContractRequestInput!
    $condition: ModelContractRequestConditionInput
  ) {
    createContractRequest(input: $input, condition: $condition) {
      id
      date
      createdAt
      contractID
      Contract {
        id
        billingAddressID
        certificates
        comment_customer
        comment_internal
        currency
        deadline
        description
        price
        priority
        quantity
        read_only
        remarks
        shippingAddressID
        version
        wish_date
        productID
        createdAt
        updatedAt
        owner
      }
      accepted
      updatedAt
      owner
    }
  }
`;
export const updateContractRequest = /* GraphQL */ `
  mutation UpdateContractRequest(
    $input: UpdateContractRequestInput!
    $condition: ModelContractRequestConditionInput
  ) {
    updateContractRequest(input: $input, condition: $condition) {
      id
      date
      createdAt
      contractID
      Contract {
        id
        billingAddressID
        certificates
        comment_customer
        comment_internal
        currency
        deadline
        description
        price
        priority
        quantity
        read_only
        remarks
        shippingAddressID
        version
        wish_date
        productID
        createdAt
        updatedAt
        owner
      }
      accepted
      updatedAt
      owner
    }
  }
`;
export const deleteContractRequest = /* GraphQL */ `
  mutation DeleteContractRequest(
    $input: DeleteContractRequestInput!
    $condition: ModelContractRequestConditionInput
  ) {
    deleteContractRequest(input: $input, condition: $condition) {
      id
      date
      createdAt
      contractID
      Contract {
        id
        billingAddressID
        certificates
        comment_customer
        comment_internal
        currency
        deadline
        description
        price
        priority
        quantity
        read_only
        remarks
        shippingAddressID
        version
        wish_date
        productID
        createdAt
        updatedAt
        owner
      }
      accepted
      updatedAt
      owner
    }
  }
`;
export const createContract = /* GraphQL */ `
  mutation CreateContract(
    $input: CreateContractInput!
    $condition: ModelContractConditionInput
  ) {
    createContract(input: $input, condition: $condition) {
      id
      billingAddressID
      certificates
      comment_customer
      comment_internal
      currency
      deadline
      description
      price
      priority
      quantity
      read_only
      remarks
      shippingAddressID
      version
      wish_date
      productID
      Product {
        id
        name
        description
        article_number
        barcode_type
        barcode_content
        comments
        extra_data
        owner
        createdAt
        updatedAt
      }
      ShippingAddress {
        id
        name
        address_1
        address_2
        zip_code
        city
        country
        type
        createdAt
        updatedAt
        owner
      }
      BillingAddress {
        id
        name
        address_1
        address_2
        zip_code
        city
        country
        type
        createdAt
        updatedAt
        owner
      }
      Status {
        nextToken
      }
      Request {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateContract = /* GraphQL */ `
  mutation UpdateContract(
    $input: UpdateContractInput!
    $condition: ModelContractConditionInput
  ) {
    updateContract(input: $input, condition: $condition) {
      id
      billingAddressID
      certificates
      comment_customer
      comment_internal
      currency
      deadline
      description
      price
      priority
      quantity
      read_only
      remarks
      shippingAddressID
      version
      wish_date
      productID
      Product {
        id
        name
        description
        article_number
        barcode_type
        barcode_content
        comments
        extra_data
        owner
        createdAt
        updatedAt
      }
      ShippingAddress {
        id
        name
        address_1
        address_2
        zip_code
        city
        country
        type
        createdAt
        updatedAt
        owner
      }
      BillingAddress {
        id
        name
        address_1
        address_2
        zip_code
        city
        country
        type
        createdAt
        updatedAt
        owner
      }
      Status {
        nextToken
      }
      Request {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteContract = /* GraphQL */ `
  mutation DeleteContract(
    $input: DeleteContractInput!
    $condition: ModelContractConditionInput
  ) {
    deleteContract(input: $input, condition: $condition) {
      id
      billingAddressID
      certificates
      comment_customer
      comment_internal
      currency
      deadline
      description
      price
      priority
      quantity
      read_only
      remarks
      shippingAddressID
      version
      wish_date
      productID
      Product {
        id
        name
        description
        article_number
        barcode_type
        barcode_content
        comments
        extra_data
        owner
        createdAt
        updatedAt
      }
      ShippingAddress {
        id
        name
        address_1
        address_2
        zip_code
        city
        country
        type
        createdAt
        updatedAt
        owner
      }
      BillingAddress {
        id
        name
        address_1
        address_2
        zip_code
        city
        country
        type
        createdAt
        updatedAt
        owner
      }
      Status {
        nextToken
      }
      Request {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      name
      description
      article_number
      barcode_type
      barcode_content
      comments
      extra_data
      owner
      Components {
        nextToken
      }
      Contracts {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      name
      description
      article_number
      barcode_type
      barcode_content
      comments
      extra_data
      owner
      Components {
        nextToken
      }
      Contracts {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      name
      description
      article_number
      barcode_type
      barcode_content
      comments
      extra_data
      owner
      Components {
        nextToken
      }
      Contracts {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createComponent = /* GraphQL */ `
  mutation CreateComponent(
    $input: CreateComponentInput!
    $condition: ModelComponentConditionInput
  ) {
    createComponent(input: $input, condition: $condition) {
      id
      name
      root
      description
      dimensions {
        bounding_box_x
        bounding_box_y
        bounding_box_z
        volume
      }
      article_number
      barcode_type
      barcode_content
      tolerances
      weight
      thread_count
      masking
      certificates
      comment
      comment_internal
      costs
      manufacturing_data
      productID
      Product {
        id
        name
        description
        article_number
        barcode_type
        barcode_content
        comments
        extra_data
        owner
        createdAt
        updatedAt
      }
      manufacturingprocessID
      ManufacturingProcess {
        id
        category
        process
        createdAt
        updatedAt
      }
      Parts {
        nextToken
      }
      Materials {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateComponent = /* GraphQL */ `
  mutation UpdateComponent(
    $input: UpdateComponentInput!
    $condition: ModelComponentConditionInput
  ) {
    updateComponent(input: $input, condition: $condition) {
      id
      name
      root
      description
      dimensions {
        bounding_box_x
        bounding_box_y
        bounding_box_z
        volume
      }
      article_number
      barcode_type
      barcode_content
      tolerances
      weight
      thread_count
      masking
      certificates
      comment
      comment_internal
      costs
      manufacturing_data
      productID
      Product {
        id
        name
        description
        article_number
        barcode_type
        barcode_content
        comments
        extra_data
        owner
        createdAt
        updatedAt
      }
      manufacturingprocessID
      ManufacturingProcess {
        id
        category
        process
        createdAt
        updatedAt
      }
      Parts {
        nextToken
      }
      Materials {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteComponent = /* GraphQL */ `
  mutation DeleteComponent(
    $input: DeleteComponentInput!
    $condition: ModelComponentConditionInput
  ) {
    deleteComponent(input: $input, condition: $condition) {
      id
      name
      root
      description
      dimensions {
        bounding_box_x
        bounding_box_y
        bounding_box_z
        volume
      }
      article_number
      barcode_type
      barcode_content
      tolerances
      weight
      thread_count
      masking
      certificates
      comment
      comment_internal
      costs
      manufacturing_data
      productID
      Product {
        id
        name
        description
        article_number
        barcode_type
        barcode_content
        comments
        extra_data
        owner
        createdAt
        updatedAt
      }
      manufacturingprocessID
      ManufacturingProcess {
        id
        category
        process
        createdAt
        updatedAt
      }
      Parts {
        nextToken
      }
      Materials {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createBOM = /* GraphQL */ `
  mutation CreateBOM(
    $input: CreateBOMInput!
    $condition: ModelBOMConditionInput
  ) {
    createBOM(input: $input, condition: $condition) {
      id
      quantity
      parentID
      childID
      Child {
        id
        name
        root
        description
        article_number
        barcode_type
        barcode_content
        tolerances
        weight
        thread_count
        masking
        certificates
        comment
        comment_internal
        costs
        manufacturing_data
        productID
        manufacturingprocessID
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateBOM = /* GraphQL */ `
  mutation UpdateBOM(
    $input: UpdateBOMInput!
    $condition: ModelBOMConditionInput
  ) {
    updateBOM(input: $input, condition: $condition) {
      id
      quantity
      parentID
      childID
      Child {
        id
        name
        root
        description
        article_number
        barcode_type
        barcode_content
        tolerances
        weight
        thread_count
        masking
        certificates
        comment
        comment_internal
        costs
        manufacturing_data
        productID
        manufacturingprocessID
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteBOM = /* GraphQL */ `
  mutation DeleteBOM(
    $input: DeleteBOMInput!
    $condition: ModelBOMConditionInput
  ) {
    deleteBOM(input: $input, condition: $condition) {
      id
      quantity
      parentID
      childID
      Child {
        id
        name
        root
        description
        article_number
        barcode_type
        barcode_content
        tolerances
        weight
        thread_count
        masking
        certificates
        comment
        comment_internal
        costs
        manufacturing_data
        productID
        manufacturingprocessID
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createMaterial = /* GraphQL */ `
  mutation CreateMaterial(
    $input: CreateMaterialInput!
    $condition: ModelMaterialConditionInput
  ) {
    createMaterial(input: $input, condition: $condition) {
      id
      name
      type
      available
      description
      chemical_name
      numerical_name
      number
      Components {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMaterial = /* GraphQL */ `
  mutation UpdateMaterial(
    $input: UpdateMaterialInput!
    $condition: ModelMaterialConditionInput
  ) {
    updateMaterial(input: $input, condition: $condition) {
      id
      name
      type
      available
      description
      chemical_name
      numerical_name
      number
      Components {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMaterial = /* GraphQL */ `
  mutation DeleteMaterial(
    $input: DeleteMaterialInput!
    $condition: ModelMaterialConditionInput
  ) {
    deleteMaterial(input: $input, condition: $condition) {
      id
      name
      type
      available
      description
      chemical_name
      numerical_name
      number
      Components {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createProfile = /* GraphQL */ `
  mutation CreateProfile(
    $input: CreateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    createProfile(input: $input, condition: $condition) {
      id
      company_name
      contact_person
      mobile_number
      vat_number
      preferred_contact_way
      preferred_incoterms
      phone_number
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      id
      company_name
      contact_person
      mobile_number
      vat_number
      preferred_contact_way
      preferred_incoterms
      phone_number
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      id
      company_name
      contact_person
      mobile_number
      vat_number
      preferred_contact_way
      preferred_incoterms
      phone_number
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createAddress = /* GraphQL */ `
  mutation CreateAddress(
    $input: CreateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    createAddress(input: $input, condition: $condition) {
      id
      name
      address_1
      address_2
      zip_code
      city
      country
      type
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateAddress = /* GraphQL */ `
  mutation UpdateAddress(
    $input: UpdateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    updateAddress(input: $input, condition: $condition) {
      id
      name
      address_1
      address_2
      zip_code
      city
      country
      type
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteAddress = /* GraphQL */ `
  mutation DeleteAddress(
    $input: DeleteAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    deleteAddress(input: $input, condition: $condition) {
      id
      name
      address_1
      address_2
      zip_code
      city
      country
      type
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createChannel = /* GraphQL */ `
  mutation CreateChannel(
    $input: CreateChannelInput!
    $condition: ModelChannelConditionInput
  ) {
    createChannel(input: $input, condition: $condition) {
      id
      address_from
      address_to
      reference
      visitedAt
      isNew
      product
      Messages {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateChannel = /* GraphQL */ `
  mutation UpdateChannel(
    $input: UpdateChannelInput!
    $condition: ModelChannelConditionInput
  ) {
    updateChannel(input: $input, condition: $condition) {
      id
      address_from
      address_to
      reference
      visitedAt
      isNew
      product
      Messages {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteChannel = /* GraphQL */ `
  mutation DeleteChannel(
    $input: DeleteChannelInput!
    $condition: ModelChannelConditionInput
  ) {
    deleteChannel(input: $input, condition: $condition) {
      id
      address_from
      address_to
      reference
      visitedAt
      isNew
      product
      Messages {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      body
      author
      channelID
      createdAt
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      body
      author
      channelID
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      body
      author
      channelID
      createdAt
      updatedAt
    }
  }
`;
export const createUnauthRequest = /* GraphQL */ `
  mutation CreateUnauthRequest(
    $input: CreateUnauthRequestInput!
    $condition: ModelUnauthRequestConditionInput
  ) {
    createUnauthRequest(input: $input, condition: $condition) {
      id
      email
      name
      company_name
      address
      zip_code
      city
      country
      phone_number
      project_description
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateUnauthRequest = /* GraphQL */ `
  mutation UpdateUnauthRequest(
    $input: UpdateUnauthRequestInput!
    $condition: ModelUnauthRequestConditionInput
  ) {
    updateUnauthRequest(input: $input, condition: $condition) {
      id
      email
      name
      company_name
      address
      zip_code
      city
      country
      phone_number
      project_description
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteUnauthRequest = /* GraphQL */ `
  mutation DeleteUnauthRequest(
    $input: DeleteUnauthRequestInput!
    $condition: ModelUnauthRequestConditionInput
  ) {
    deleteUnauthRequest(input: $input, condition: $condition) {
      id
      email
      name
      company_name
      address
      zip_code
      city
      country
      phone_number
      project_description
      status
      createdAt
      updatedAt
    }
  }
`;
export const createEmailResult = /* GraphQL */ `
  mutation CreateEmailResult(
    $input: CreateEmailResultInput!
    $condition: ModelEmailResultConditionInput
  ) {
    createEmailResult(input: $input, condition: $condition) {
      statusCode
      body
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateEmailResult = /* GraphQL */ `
  mutation UpdateEmailResult(
    $input: UpdateEmailResultInput!
    $condition: ModelEmailResultConditionInput
  ) {
    updateEmailResult(input: $input, condition: $condition) {
      statusCode
      body
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteEmailResult = /* GraphQL */ `
  mutation DeleteEmailResult(
    $input: DeleteEmailResultInput!
    $condition: ModelEmailResultConditionInput
  ) {
    deleteEmailResult(input: $input, condition: $condition) {
      statusCode
      body
      id
      createdAt
      updatedAt
    }
  }
`;
export const createComponentMaterials = /* GraphQL */ `
  mutation CreateComponentMaterials(
    $input: CreateComponentMaterialsInput!
    $condition: ModelComponentMaterialsConditionInput
  ) {
    createComponentMaterials(input: $input, condition: $condition) {
      id
      componentID
      materialID
      component {
        id
        name
        root
        description
        article_number
        barcode_type
        barcode_content
        tolerances
        weight
        thread_count
        masking
        certificates
        comment
        comment_internal
        costs
        manufacturing_data
        productID
        manufacturingprocessID
        createdAt
        updatedAt
        owner
      }
      material {
        id
        name
        type
        available
        description
        chemical_name
        numerical_name
        number
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateComponentMaterials = /* GraphQL */ `
  mutation UpdateComponentMaterials(
    $input: UpdateComponentMaterialsInput!
    $condition: ModelComponentMaterialsConditionInput
  ) {
    updateComponentMaterials(input: $input, condition: $condition) {
      id
      componentID
      materialID
      component {
        id
        name
        root
        description
        article_number
        barcode_type
        barcode_content
        tolerances
        weight
        thread_count
        masking
        certificates
        comment
        comment_internal
        costs
        manufacturing_data
        productID
        manufacturingprocessID
        createdAt
        updatedAt
        owner
      }
      material {
        id
        name
        type
        available
        description
        chemical_name
        numerical_name
        number
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteComponentMaterials = /* GraphQL */ `
  mutation DeleteComponentMaterials(
    $input: DeleteComponentMaterialsInput!
    $condition: ModelComponentMaterialsConditionInput
  ) {
    deleteComponentMaterials(input: $input, condition: $condition) {
      id
      componentID
      materialID
      component {
        id
        name
        root
        description
        article_number
        barcode_type
        barcode_content
        tolerances
        weight
        thread_count
        masking
        certificates
        comment
        comment_internal
        costs
        manufacturing_data
        productID
        manufacturingprocessID
        createdAt
        updatedAt
        owner
      }
      material {
        id
        name
        type
        available
        description
        chemical_name
        numerical_name
        number
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const convertCADtoSTL = /* GraphQL */ `
  mutation ConvertCADtoSTL($key: String!) {
    convertCADtoSTL(key: $key) {
      file_key
      format
      data
    }
  }
`;
export const copyProduct = /* GraphQL */ `
  mutation CopyProduct($id: ID!) {
    copyProduct(id: $id) {
      id
      name
      description
      article_number
      barcode_type
      barcode_content
      comments
      extra_data
      owner
      Components {
        nextToken
      }
      Contracts {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const sendEmail = /* GraphQL */ `
  mutation SendEmail($to: String!, $subject: EmailSubject!, $body: String!) {
    sendEmail(to: $to, subject: $subject, body: $body) {
      statusCode
      body
      id
      createdAt
      updatedAt
    }
  }
`;
export const accessS3 = /* GraphQL */ `
  mutation AccessS3($key: String!, $action: S3Action) {
    accessS3(key: $key, action: $action)
  }
`;
