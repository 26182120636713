import { createTheme as createMUITheme } from '@mui/material'
import { grey } from '@mui/material/colors'

const createTheme = (prefersDarkMode: boolean) =>
  createMUITheme({
    palette: {
      mode: prefersDarkMode ? 'dark' : 'light',
      primary: {
        main: '#0088ff',
      },
      secondary: {
        main: grey[800],
      },
    },
  })

export default createTheme
