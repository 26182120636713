import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from '@mui/material'
import { API, graphqlOperation } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SelectInput from '../../../components/inputs/SelectInput'
import { customListProducts } from '../../../graphql/custom-queries'
import { Product } from '../../../graphql/graphql'
import useMobileService from '../../../hooks/useMobileService'
import { handlePromise } from '../../../utils/functions'

type Props = {
  open: boolean
  onClose: () => void
  onConfirm: (value: Product) => void
}

export default function ContractOptionsModal(props: Props) {
  const { open, onClose, onConfirm } = props
  const [productData, setProductData] = useState<any>({ data: null, error: false, loading: true })
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>(undefined)
  const { t } = useTranslation()
  const isMobile = useMobileService()

  useEffect(() => {
    setTimeout(fetchProducts, 100)
  }, [])

  async function fetchProducts() {
    const [res, err] = await handlePromise(
      'listProducts',
      API.graphql(graphqlOperation(customListProducts))
    )
    res
      ? setProductData({ data: res.data.listProducts.items, error: false, loading: false })
      : setProductData({ data: null, error: true, loading: false })
  }

  const handleConfirm = () => {
    onClose()
    selectedProduct && onConfirm(selectedProduct)
  }

  const handleCancel = () => {
    onClose()
    setSelectedProduct(undefined)
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Card
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 'max-content',
          height: 'max-content',
          maxWidth: isMobile ? '95%' : '50%',
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            paddingBottom: '16px',
          }}
        >
          <Typography variant="h6" sx={{ float: 'left', marginBottom: '1em' }}>
            {t('contract:details.placeNewRequest')}
          </Typography>
          <FormControl>
            <InputLabel>{t('product')}</InputLabel>
            <Select
              value={selectedProduct ? selectedProduct.id : ''}
              label={t('product')}
              onChange={e =>
                setSelectedProduct(
                  productData.data.find((product: Product) => product.id === e.target.value)
                )
              }
            >
              {productData?.data?.map((product: Product) => (
                <MenuItem value={product.id} key={product.id}>
                  <Typography
                    sx={{
                      width: 'inherit',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {product.name}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={{ marginTop: '1em' }}>
            <Button variant="outlined" onClick={handleCancel} sx={{ float: 'left' }}>
              {t('cancel')}
            </Button>
            <Button
              variant="contained"
              disabled={selectedProduct ? false : true}
              onClick={handleConfirm}
              sx={{ float: 'right', marginLeft: '40px' }}
            >
              {t('product:overview.requestNow')}
            </Button>
          </div>
        </CardContent>
      </Card>
    </Modal>
  )
}
