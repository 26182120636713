import React, { useEffect } from 'react'
import { useState } from 'react'
import {
  TreeItem,
  TreeItemProps,
  useTreeItem,
  TreeItemContentProps,
} from '@mui/x-tree-view/TreeItem';
import clsx from 'clsx'
import Typography from '@mui/material/Typography'
import { Component } from '../../../../graphql/graphql'
import { handlePromise } from '../../../../utils/functions'
import { API, graphqlOperation } from 'aws-amplify'
import { getBOM, getComponent, listBOMS } from '../../../../graphql/queries'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { IconButton } from '@mui/material'
import { Add } from '@mui/icons-material'
import { customGetComponent } from '../../../../graphql/custom-queries'
import useMobileService from '../../../../hooks/useMobileService'

type ComponentTreeItemProps = {
  updatedComponent?: Component
  isEditMode?: boolean
  handleTick?: (id: string, name: string) => void
  getTickedStatus?: (id: string) => boolean
  setOpen: (open: boolean) => void
  setComponent: (component: Component) => void
  setNew: (isNew: boolean) => void
  setActiveStep: (step: any) => void
  setParentComponent: (comp: Component) => void
  setParentBOM: (BOM: any) => void
  setQuantity: (quantity: number) => void
  childPos: number
} & TreeItemProps

/**
 * Item in Tree
 * Edit by clicking on label
 * Expand by clicking on expand arrow
 * Mark by clicking checkbox in marking mode
 */
export default function ComponentTreeItem(props: ComponentTreeItemProps) {
  const {
    updatedComponent,
    setOpen,
    setComponent,
    setNew,
    isEditMode = false,
    handleTick = () => {},
    getTickedStatus = () => false,
    setActiveStep,
    setParentComponent,
    setParentBOM,
    setQuantity,
    childPos,
    ...treeItemProps //treeItemProps.nodeId === id of the item
  } = props
  const [itemComponent, setItemComponent] = useState<Component>()
  const isMobile = useMobileService()

  useEffect(() => {
    !itemComponent && fetchComponent(treeItemProps.nodeId)
  })

  useEffect(() => {
    if (itemComponent?.id === updatedComponent?.id) {
      setItemComponent(updatedComponent)
    }
  }, [updatedComponent])

  async function fetchComponent(id: string) {
    const [res, err] = await handlePromise(
      'getComponent',
      API.graphql(graphqlOperation(customGetComponent, { id: id }))
    )
    res ? setItemComponent(res.data.getComponent) : console.log('Error on fetching component', err)
  }

  const CustomContent = React.forwardRef(function CustomContent(
    props: TreeItemContentProps,
    ref: React.ForwardedRef<unknown>
  ) {
    const { classes, className, label, nodeId, icon: iconProp, expansionIcon, displayIcon } = props

    const {
      disabled,
      expanded,
      selected,
      focused,
      handleExpansion,
      //handleSelection,
      preventSelection,
    } = useTreeItem(nodeId)

    const icon = iconProp || expansionIcon || displayIcon

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      preventSelection(event)
    }

    const handleExpansionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      handleExpansion(event)
    }

    async function findParentBOM(childID: string) {
      const [res, err] = await handlePromise(
        'getBOM',
        API.graphql(graphqlOperation(listBOMS, { filter: { childID: { eq: `${childID}` } } }))
      )
      if (res) {
        setParentBOM({
          id: res.data.listBOMS.items[0]?.id,
          quantity: res.data.listBOMS.items[0]?.quantity,
          //version: res.data.listBOMS.items[0]?._version,
        })
        setQuantity(res.data.listBOMS.items[childPos]?.quantity)
      } else {
        console.log('Error on finding parent', err)
      }
    }

    const handleSelectionClick = () => {
      setComponent(
        updatedComponent && updatedComponent.id === itemComponent?.id
          ? updatedComponent
          : (itemComponent as Component)
      )
      findParentBOM(itemComponent?.id as string)
      setActiveStep(0)
      setNew(false)
      setOpen(true)
    }

    return (
      <div>
        <div
          className={clsx(className, classes.root, {
            [classes.expanded]: expanded,
            [classes.selected]: selected,
            [classes.focused]: focused,
            [classes.disabled]: disabled,
          })}
          onMouseDown={handleMouseDown}
          ref={ref as React.Ref<HTMLDivElement>}
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            paddingRight: 0,
            borderBottom: '1px solid gray',
          }}
        >
          {/* TreeItem start */}
          <div style={{ display: 'flex', alignContent: 'center', flexGrow: 1 }}>
            <div
              onClick={handleExpansionClick}
              className={classes.iconContainer}
              style={{ alignSelf: 'center' }}
            >
              {icon}
            </div>
            <Typography onClick={handleSelectionClick} component="div" className={classes.label}>
              {label}
            </Typography>
          </div>
          {/* TreeItem end */}
          {/* table content start */}
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
            }}
          >
            {!isMobile && (
              <span
                style={{
                  width: '180px',
                  textAlign: 'center',
                  overflowWrap: 'break-word' /* making sure long articlenumbers do not overflow */,
                  wordWrap:
                    'break-word' /* overflow protection (second time for browser compatibility) */,
                  paddingRight: '5px',
                  paddingLeft: '5px',
                  margin: 'auto',
                }}
              >
                {itemComponent?.article_number as string}
              </span>
            )}
            <span
              style={{
                width: '120px',
                textAlign: 'center',
                margin: 'auto',
              }}
            >
              {itemComponent?.ManufacturingProcess?.process as string}
            </span>
            <div style={{ width: '50px', display: 'flex', justifyContent: 'flex-end' }}>
              {isEditMode && (
                <div
                  className={classes.iconContainer}
                  style={{ alignSelf: 'center' }}
                  onClick={
                    updatedComponent
                      ? () => handleTick(updatedComponent.id, updatedComponent.name)
                      : () => handleTick(itemComponent?.id as string, itemComponent?.name as string)
                  }
                >
                  {getTickedStatus(treeItemProps.nodeId) ? (
                    <CheckBoxIcon color="primary" />
                  ) : (
                    <CheckBoxOutlineBlankIcon />
                  )}
                </div>
              )}
            </div>
          </div>
          {/* table content end */}
        </div>
        {/* add child part start */}
        {expanded && (
          <div style={{ width: '100%', overflowX: 'hidden' }}>
            {/* hides overflow (gray line) on the right of child-div */}
            <div
              style={{
                width: '100%',
                alignSelf: 'center',
                marginLeft: '17px',
                borderBottom: '1px solid gray',
              }}
            >
              <IconButton
                color="primary"
                size="small"
                onClick={() => {
                  setParentComponent(itemComponent as Component)
                  setOpen(true)
                  setNew(true)
                  setComponent({} as Component)
                  setActiveStep(0)
                  setQuantity(1)
                }}
              >
                <Add sx={{ fontSize: 'large' }} />
              </IconButton>
            </div>
          </div>
        )}
        {/* add child part end */}
      </div>
    )
  })

  return <TreeItem ContentComponent={CustomContent} {...treeItemProps} />
}
