import React from 'react'
import { Card, CardContent, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ChannelList from './ChannelList'
import ChatView from '../Message/ChatView'
import useMobileService from '../../../../hooks/useMobileService'
import { Channel, Message } from '../../../../graphql/graphql'

type Props = {
  messages: Message[]
  setMessages: (messages: Message[]) => void
  newMessages: Message[]
  setNewMessages: (messages: Message[]) => void
  channels: Channel[]
  activeChannel: Channel
  visitedChannel: Channel
  createChannelMode: boolean
  user: any
  userGroups: string[] | null
  handleClose: () => void
  handleCreateChannelClick: () => void
  handleMobileCloseChatView: () => void
  handleChannelClick: (channel: Channel | undefined) => void
  handleChannelDelete: (channels: any[]) => void
}

/**
 * Container to overview the message system
 * Consists of:
 *  - ChannelList
 *  - ChatView
 */
export default function ChannelOverview(props: Props) {
  const {
    messages,
    setMessages,
    newMessages,
    setNewMessages,
    channels,
    activeChannel,
    visitedChannel,
    createChannelMode,
    handleCreateChannelClick,
    handleMobileCloseChatView,
    handleChannelClick,
    handleChannelDelete,
    user,
    userGroups,
    handleClose,
  } = props
  const headerMarginBottom = '20px' // makes later calculations a little bit easier to read
  const TabbarHeight = '58px'
  const ChatTextInputHeight = '75px'
  const { t } = useTranslation()
  const isMobile = useMobileService()

  if (!isMobile) {
    return (
      <div>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <ChannelList
                  user={user}
                  userGroups={userGroups}
                  newMessages={newMessages}
                  channels={channels}
                  handleCreateChannelClick={handleCreateChannelClick}
                  handleChannelClick={handleChannelClick}
                  handleChannelDelete={handleChannelDelete}
                  sx={{
                    width: '100%',
                    height: '1000px',
                    flexShrink: 0,
                    padding: '10px',
                    borderRight: '1px solid #9e9e9e',
                  }}
                />
              </Grid>
              <Grid item xs={7}>
                <ChatView
                  messages={messages}
                  setMessages={setMessages}
                  newMessages={newMessages}
                  setNewMessages={setNewMessages}
                  user={user}
                  userGroups={userGroups}
                  channel={activeChannel}
                  visitedChannel={visitedChannel}
                  handleChannelClick={handleChannelClick}
                  sx={{
                    flexGrow: 1,
                    paddingLeft: '10px',
                    height: '1000px',
                    width: '100%',
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    )
  } else {
    return (
      <div>
        {!activeChannel && !createChannelMode ? (
          <ChannelList
            user={user}
            newMessages={newMessages}
            channels={channels}
            userGroups={userGroups}
            handleCreateChannelClick={handleCreateChannelClick}
            handleChannelClick={handleChannelClick}
            handleChannelDelete={handleChannelDelete}
          />
        ) : (
          <ChatView
            messages={messages}
            setMessages={setMessages}
            newMessages={newMessages}
            setNewMessages={setNewMessages}
            user={user}
            userGroups={userGroups}
            channel={activeChannel}
            handleMobileCloseChatView={handleMobileCloseChatView}
            visitedChannel={visitedChannel}
            handleChannelClick={handleChannelClick}
            sx={{
              flexGrow: 1,
              height: `calc(100vh - ${TabbarHeight} - ${ChatTextInputHeight})`,
            }}
          />
        )}
      </div>
    )
  }
}
