import React, { useEffect, useState } from 'react'
import {
  Component,
  ManufacturingProcess,
  ManufacturingRequirement,
} from '../../../../graphql/graphql'
import { requirementsToFields } from '../../utils/functions'
import { Form, FormFieldType } from '../../../Form'
import { handlePromise } from '../../../../utils/functions'
import { API, graphqlOperation } from 'aws-amplify'
import { listManufacturingRequirements } from '../../../../graphql/queries'

type Props = {
  component: Component
  manufacturingProcess: ManufacturingProcess
  manufacturingData: ManufacturingRequirement[]
  setManufacturingData: (manufacturingData: ManufacturingRequirement[]) => void
}

/**
 * Process inputs are loaded from the requirements stored in the backend
 */
export default function ProcessInputs(props: Props) {
  const { component, manufacturingProcess, setManufacturingData, manufacturingData } = props
  const [originalProcess, setOriginalProcess] = useState<ManufacturingProcess>(manufacturingProcess)
  const [requirements, setRequirements] = useState<ManufacturingRequirement[]>([])
  const [requirementsObject, setReqObj] = useState<any>({})
  const [requirementsData, setReqData] = useState<FormFieldType[]>(
    requirementsToFields(requirements)
  )

  useEffect(() => {
    fetchRequirements()
  }, [manufacturingProcess])

  useEffect(() => {
    // parsing components manufacturing data --> requirements for dynamic data
    if (component.manufacturing_data) {
      setDynamicData(JSON.parse(component.manufacturing_data))
    }
  }, [])

  useEffect(() => {
    // process changes --> requirements change --> get the right requirements
    // for right process. Original process is the process that is already selected
    if (originalProcess.process !== manufacturingProcess.process) {
      setDynamicData(requirements)
    } else {
      setDynamicData(requirements)
      if (component.manufacturing_data) {
        setDynamicData(requirements)
        if (JSON.parse(component.manufacturing_data as string).length === 0) {
          setDynamicData(requirements)
        } else {
          setDynamicData(JSON.parse(component.manufacturing_data))
        }
      }
    }
  }, [requirements])

  const requirementsFilter = {
    manufacturingProcessID: {
      eq: `${manufacturingProcess?.id}`,
    },
  }

  /**
   * Sets the dynamic data in the right format to display
   * @param requirements Needed for manufacturing process
   */
  function setDynamicData(requirements: ManufacturingRequirement[]) {
    setReqObj(createRequirementsObject(requirements))
    setReqData(requirementsToFields(requirements))
    setManufacturingData(requirements)
  }

  async function fetchRequirements() {
    const [res, err] = await handlePromise(
      'listManufacturingRequirements',
      API.graphql(
        graphqlOperation(listManufacturingRequirements, { filter: requirementsFilter, limit: 200 })
      )
    )
    if (res) {
      setRequirements(res.data.listManufacturingRequirements.items)
    } else {
      console.log('Error fetching requirements', err)
    }
  }

  /**
   * Creates object with requirements
   * Example: {color: "#f0f0f0", infill: 30%, ...}
   * @param requirements
   * @returns Object with requirements
   */
  function createRequirementsObject(requirements: ManufacturingRequirement[]) {
    const reqObj: any = {}
    requirements.map(req => (reqObj[`${req.label}`] = req.value))
    return reqObj
  }

  const handleChange = (value: any) => {
    setReqObj(value)
    const objectEntries = Object.entries(value)
    objectEntries.map(([label, value]) => {
      const indexOfNewReq: number = manufacturingData.findIndex(req => req.label === label)
      const newManData: ManufacturingRequirement[] = [...manufacturingData]
      newManData[indexOfNewReq].value = value as string
      setManufacturingData(newManData)
    })
  }

  return (
    <div>
      <Form
        formObject={requirementsObject}
        formFields={requirementsData}
        onChange={value => handleChange(value)}
        editing={true}
      />
    </div>
  )
}
