import React from 'react'
import Page from '../components/layout/Page'
import { FilesystemLogic } from '../features/Filesystem'

export default function FilesystemPage() {
  return (
    <Page marginBottom="200px">
      <FilesystemLogic />
    </Page>
  )
}
