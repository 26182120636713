import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormFieldType } from '../../../Form'
import { Product } from '../../../../graphql/graphql'
import ComponentLogic from '../Component/ComponentLogic'
import { stringNotEmpty } from '../../utils/validation'

type Props = {
  product: Product
  setProduct: (product: Product) => void
  editing: boolean
  isNew: boolean
  productIdInOverview?: string
}

/**
 * Form to show and edit Product details
 */
export default function ProductDetails(props: Props) {
  const { t } = useTranslation()

  const productData: FormFieldType[] = [
    {
      type: 'text',
      key: 'name',
      label: 'name',
      xs: 12,
      sm: 6,
      validation: stringNotEmpty(props.product?.name),
    },
    { type: 'text', key: 'description', label: 'description', xs: 12, sm: 6 },
    { type: 'number', key: 'article_number', label: 'articleNumber', xs: 12, sm: 6 },
    {
      type: 'select',
      key: 'barcode_type',
      label: 'barcodeType',
      xs: 12,
      sm: 6,
      options: [
        { value: 'EAN', label: 'EAN' },
        { value: 'GTIN', label: 'GTIN' },
        { value: 'UPC', label: 'UPC' },
        { value: 'custom', label: 'custom' },
      ],
    },
    {
      type: 'text',
      key: 'barcode_content',
      label: 'barcodeContent',
      xs: 12,
      sm: 6,
    },
    { type: 'text', key: 'comments', label: 'additionalComments', xs: 12, sm: 6 },
  ]

  return (
    <div>
      {!props.productIdInOverview && (
        <Form
          formObject={props.product as Product}
          formFields={productData}
          onChange={value => props.setProduct(value as Product)}
          editing={props.editing}
        />
      )}
      {!props.isNew && <ComponentLogic productIdInOverview={props.productIdInOverview} />}
    </div>
  )
}
