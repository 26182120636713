export const customUpdateComponent = /* GraphQL */ `
  mutation customUpdateComponent(
    $input: UpdateComponentInput!
    $condition: ModelComponentConditionInput
  ) {
    updateComponent(input: $input, condition: $condition) {
      id
      name
      root
      description
      dimensions {
        bounding_box_x
        bounding_box_y
        bounding_box_z
        volume
      }
      article_number
      barcode_type
      barcode_content
      tolerances
      weight
      thread_count
      masking
      certificates
      comment
      comment_internal
      costs
      manufacturing_data
      productID
      Product {
        id
        name
        description
        article_number
        barcode_type
        barcode_content
        comments
        extra_data
        createdAt
        updatedAt
        owner
      }
      manufacturingprocessID
      ManufacturingProcess {
        id
        category
        process
        createdAt
        updatedAt
      }
      Parts {
        items {
          id
          childID
          parentID
          quantity
        }
        nextToken
      }
      Materials {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`
export const customUpdateChannel = /* GraphQL */ `
  mutation customUpdateChannel(
    $input: UpdateChannelInput!
    $condition: ModelChannelConditionInput
  ) {
    updateChannel(input: $input, condition: $condition) {
      id
      address_from
      address_to
      reference
      visitedAt
      isNew
      product
      Messages {
        nextToken
        items {
          id
          body
          createdAt
        }
      }
      createdAt
      updatedAt
    }
  }
`

export const customCreateProduct = /* GraphQL */ `
  mutation customCreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      name
      description
      article_number
      barcode_type
      barcode_content
      comments
      extra_data
      Components {
        items {
          Parts {
            items {
              childID
              parentID
              quantity
            }
          }
          name
          id
          description
          weight
          tolerances
          thread_count
          root
          productID
          masking
          manufacturingprocessID
          manufacturing_data
          dimensions {
            bounding_box_x
            bounding_box_y
            bounding_box_z
            volume
          }
          costs
          comment_internal
          comment
          certificates
          barcode_type
          barcode_content
          article_number
        }
      }
      createdAt
      updatedAt
      owner
    }
  }
`
export const customCreateContractStatus = /* GraphQL */ `
  mutation customCreateContractStatus(
    $input: CreateContractStatusInput!
    $condition: ModelContractStatusConditionInput
  ) {
    createContractStatus(input: $input, condition: $condition) {
      id
      date
      createdAt
      contractID
      Contract {
        id
        billingAddressID
        certificates
        comment_customer
        comment_internal
        currency
        deadline
        description
        price
        priority
        quantity
        read_only
        remarks
        shippingAddressID
        version
        wish_date
        productID
        createdAt
        updatedAt
        owner
      }
      owner
      Status
      updatedAt
    }
  }
`
