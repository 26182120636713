import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Autocomplete, Chip, TextField } from '@mui/material'

type Props<T> = {
  name: string
  value: T[]
  options: { value: T; label?: string; disabled?: boolean }[]
  disabled?: boolean
  labelWidth?: number
  onChange: (value: T[]) => void
}

export default function SelectMultipleWithTextInput<T extends string | number | boolean>(
  props: Props<T>
) {
  const { t } = useTranslation(['common', 'profile'])
  const { name, value, options, disabled, labelWidth = 100, onChange } = props
  const [inputValue, setInputValue] = useState<string>('')
  const translatedOptions = () =>
    options.map((value: any) => {
      return t(value.label)
    })
  const [newOptions, setNewOptions] = useState<any[]>(translatedOptions())

  return (
    <Autocomplete
      options={newOptions}
      noOptionsText={t('EnterForNewOption')}
      isOptionEqualToValue={(option: T, value: T) => option == value} // from strict equal(default) to non-strict equal
      onInputChange={(e: any, newValue) => {
        setInputValue(newValue)
      }}
      value={Array.isArray(value[0]) ? value[0] : []}
      onChange={(event, newValue) => onChange(newValue)}
      disabled={disabled}
      multiple
      renderTags={(value: T[]) =>
        value.map((option: T, index: number) => (
          <Chip key={index} variant="outlined" label={option} sx={{ maxWidth: '200px' }} />
        ))
      }
      renderInput={params => (
        <TextField
          {...params}
          label={name}
          variant="outlined"
          onKeyDown={(e: any) => {
            if (
              e.key === 'Enter' &&
              newOptions.findIndex((o: any) => o.value === inputValue) === -1
            ) {
              setNewOptions([...newOptions, inputValue])
            }
          }}
        />
      )}
    />
  )
}
