import { IconButton, Tooltip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { deleteAddress } from '../../../graphql/mutations'
import { useConfirm, useNotifications } from '../../Feedback'
import { API, graphqlOperation } from 'aws-amplify'
import { feedbackForGraphQL, handlePromise } from '../../../utils/functions'

interface Props {
  id: string
  fetchAddresses?: () => void
}

/**
 * Logic to delete a selected address
 */
export default function Delete(props: Props) {
  const { confirmDialog } = useConfirm()
  const { addNotification } = useNotifications()
  const { t } = useTranslation(['profile'])

  async function onConfirm() {
    const [res, error] = await handlePromise(
      'deleteAddress',
      API.graphql(graphqlOperation(deleteAddress, { input: { id: props.id } }))
    )
    props.fetchAddresses ? props.fetchAddresses() : null
    feedbackForGraphQL(res, addNotification, t)
  }

  const onClick = () => confirmDialog(t('confirmDeleteAddress'), onConfirm, 'error')

  return (
    <Tooltip
      title={t('deleteAddress') as string}
      aria-label="delete"
      style={{ opacity: 0.7, marginLeft: 5, marginRight: 5 }}
      leaveDelay={200}
    >
      <IconButton
        className="icon-button"
        size="small"
        color="default"
        aria-label="delete"
        onClick={onClick}
      >
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  )
}
