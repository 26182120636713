import React, { useState } from 'react'
import { Checkbox, FormControl, FormControlLabel, FormHelperText, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
  value: string
  label: string
  labelWidth?: number
  onChange: (value: string) => void
  validation?: (value: string) => boolean
  errorMessage?: string
  disabled?: boolean
}

export default function CheckedTextInput(props: Props) {
  const {
    label,
    value,
    onChange,
    labelWidth = 100,
    disabled = false,
    errorMessage = '',
    validation,
  } = props
  const error = value !== '' && !disabled && validation && !validation(value)
  const [open, setOpen] = useState<boolean>(!!value)
  const { t } = useTranslation()

  return (
    <FormControl fullWidth variant="outlined">
      <FormControlLabel
        control={<Checkbox defaultChecked={open} onClick={() => setOpen(!open)} />}
        label={label}
      />
      {open && (
        <TextField
          id={label}
          variant="outlined"
          label={t('custom')}
          value={value || ''}
          onChange={e => onChange(e.currentTarget.value)}
          autoComplete="text"
          disabled={disabled}
          error={error}
          aria-describedby="text-input"
          inputProps={{
            'aria-label': 'weight',
          }}
        />
      )}
      {error && (
        <FormHelperText error variant="outlined">
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  )
}
