import React, { useState } from 'react'
import { FormControl, IconButton, InputAdornment, OutlinedInput } from '@mui/material'
import { SendRounded } from '@mui/icons-material'
import useMobileService from '../../../../hooks/useMobileService'

type Props = {
  sendMessage: (message: string) => void
  sx?: React.CSSProperties
}

/**
 * TextInput to write new chat messages in
 * "Ctrl + enter " --> makes a newline in the textbox
 * "enter" --> sends message
 */
export default function ChatTextInput(props: Props) {
  const { sendMessage, sx = {} } = props
  const [messageText, setMessageText] = useState<string>('')
  const isMobile = useMobileService()

  async function handleSubmit(event: any) {
    if (messageText.trim().length !== 0) {
      event.preventDefault()
      event.stopPropagation()
      sendMessage(messageText)
      setMessageText('')
    }
  }

  async function handleKeyDown(event: any) {
    if (event.keyCode === 13 && !event.ctrlKey && messageText.trim().length !== 0) {
      event.preventDefault()
      event.stopPropagation()
      sendMessage(messageText)
      setMessageText('')
    }
  }

  function handleChange(event: any) {
    setMessageText(event.currentTarget.value)
  }

  return isMobile ? (
    <div style={{ ...sx, display: 'flex', flexShrink: 0 }}>
      <FormControl
        fullWidth
        sx={{
          height: 'max-content',
          margin: '5px',
        }}
      >
        <OutlinedInput
          id="chat-input"
          placeholder="Enter a message"
          multiline
          minRows={1}
          maxRows={4}
          value={messageText}
          onChange={e => handleChange(e)}
          onKeyDown={event => handleKeyDown(event)}
          endAdornment={
            <InputAdornment position="end" sx={{ marginBottom: '0px' }}>
              <IconButton
                onClick={handleSubmit}
                /* check if string is empty or only contains spaces */
                disabled={messageText.length === 0 || messageText.trim().length === 0}
                size="large"
                edge="end"
                color="primary"
                sx={{ float: 'right' }}
              >
                <SendRounded color="primary" />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </div>
  ) : (
    <div style={{ ...sx, display: 'flex', flexShrink: 0 }}>
      <FormControl
        fullWidth
        sx={{
          height: 'max-content',
          margin: '5px',
        }}
      >
        <OutlinedInput
          id="chat-input"
          placeholder="Enter a message"
          multiline
          minRows={1}
          maxRows={4}
          value={messageText}
          onChange={e => setMessageText(e.currentTarget.value)}
          onKeyDown={event => handleKeyDown(event)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={handleSubmit}
                /* check if string is empty or only contains spaces */
                disabled={messageText.length === 0 || messageText.trim().length === 0}
                size="large"
                edge="end"
                color="primary"
                sx={{ float: 'right' }}
              >
                <SendRounded color="primary" />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </div>
  )
}
