import React, { useEffect, useState } from 'react'
import Page from '../components/layout/Page'
import { Form, FormFieldType } from '../features/Form'
import { useParams } from 'react-router-dom'
import { formatBytes, handlePromise } from '../utils/functions'
import { API, graphqlOperation } from 'aws-amplify'
import { getUnauthRequest } from '../graphql/queries'
import { UnauthRequest } from '../graphql/graphql'
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { accessS3 } from '../graphql/mutations'
import { Delete, Download } from '@mui/icons-material'
import DeleteModal from '../components/widgets/DeleteModal'
import LoadingSkeleton from '../components/widgets/LoadingSkeleton'
import { useTranslation } from 'react-i18next'
import { useNotifications } from '../features/Feedback'
import useMobileService from '../hooks/useMobileService'
import MobileListItem from '../components/widgets/MobileListItem'

export default function RequestAdminPage() {
  const { id } = useParams<{ id: string }>()
  const [unauthRequest, setUnauthRequest] = useState<UnauthRequest>()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)
  const [files, setFiles] = useState<any[]>([])
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [fileToDelete, setFileToDelete] = useState<string>('')
  const { addNotification } = useNotifications()
  const { t } = useTranslation(['common'])
  const isMobile = useMobileService()

  const unauthorizedRequestFields: FormFieldType[] = [
    {
      type: 'text',
      key: 'name',
      label: t('name'),
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
      xl: 6,
    },
    {
      type: 'email',
      key: 'email',
      label: t('email'),
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
      xl: 6,
    },
    {
      type: 'text',
      key: 'company_name',
      label: t('requestForm.companyName'),
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
    },
    {
      type: 'text',
      key: 'address',
      label: t('requestForm.address'),
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
    },
    {
      type: 'number',
      key: 'zip_code',
      label: t('requestForm.zipCode'),
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
      xl: 6,
    },
    {
      type: 'text',
      key: 'city',
      label: t('requestForm.city'),
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
      xl: 6,
    },
    {
      type: 'text',
      key: 'country',
      label: t('requestForm.country'),
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
    },
    {
      type: 'text',
      key: 'phone_number',
      label: t('requestForm.phoneNumber'),
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
    },
    {
      type: 'multiline',
      key: 'project_description',
      label: t('requestForm.projectDescription'),
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
    },
  ]

  useEffect(() => {
    fetchRequest()
  }, [])

  useEffect(() => {
    unauthRequest ? listObjects() : null
  }, [unauthRequest])

  async function fetchRequest() {
    const [res, err] = await handlePromise(
      'getUnauthRequest',
      API.graphql(graphqlOperation(getUnauthRequest, { id }))
    )
    if (!err) {
      setUnauthRequest(res.data.getUnauthRequest)
    } else {
      setError(true)
    }
  }

  async function listObjects() {
    if (unauthRequest && unauthRequest.email) {
      console.log('/' + unauthRequest.email)
      const [res, err] = await handlePromise(
        'accessS3',
        API.graphql(graphqlOperation(accessS3, { key: unauthRequest.email + '/', action: 'LIST' }))
      )
      res ? setFiles(JSON.parse(res.data.accessS3)) : console.log(err)
      setLoading(false)
    }
  }

  async function downloadObject(fileKey: string) {
    const [res, err] = await handlePromise(
      'accessS3',
      API.graphql(graphqlOperation(accessS3, { key: fileKey, action: 'GET' }))
    )
    res ? window.open(res.data.accessS3) : console.log(err)
  }

  async function deleteObject(fileKey: string) {
    const [res, err] = await handlePromise(
      'accessS3',
      API.graphql(graphqlOperation(accessS3, { key: fileKey, action: 'DELETE' }))
    )
    if (res) {
      setOpenDeleteModal(false)
      setFileToDelete('')
      listObjects()
      addNotification({
        color: 'success',
        message: t('success.deleted'),
      })
    } else {
      console.log(err)
    }
  }

  if (loading) return <LoadingSkeleton />
  if (error)
    return (
      <Page>
        <h1>Error</h1>
      </Page>
    )

  // show files in a Table
  const filesTable = files.map((file, index) => {
    return (
      <TableRow key={index} hover>
        <TableCell>{file.Key.split('/')[1]}</TableCell>
        <TableCell>{formatBytes(file.Size)}</TableCell>
        <TableCell>
          <IconButton onClick={() => downloadObject(file.Key)}>
            <Download />
          </IconButton>
          <IconButton
            onClick={() => {
              setOpenDeleteModal(true)
              setFileToDelete(file.Key)
            }}
          >
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
    )
  })

  // show files in a Grid for mobile devices
  const filesGrid = files.map((file, index) => {
    return (
      <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
        <MobileListItem primaryText={file.Key.split('/')[1]}>
          <Grid container spacing={1} sx={{ mt: 1 }}>
            <Grid item xs={8}>
              <Typography variant="body2" sx={{ mt: 1 }}>
                {formatBytes(file.Size)}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <IconButton onClick={() => downloadObject(file.Key)}>
                <Download />
              </IconButton>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                onClick={() => {
                  setOpenDeleteModal(true)
                  setFileToDelete(file.Key)
                }}
              >
                <Delete />
              </IconButton>
            </Grid>
          </Grid>
        </MobileListItem>
      </Grid>
    )
  })

  return (
    <Page marginBottom="200px">
      <Card>
        <CardHeader title={t('requestDetails')} />
        <CardContent>
          <Typography variant="h6" component="h2" sx={{ marginBottom: '20px' }}>
            {`${t('status')}: ${t(`requestStatus.${unauthRequest?.status}`)} `}
          </Typography>
          <Form
            formObject={unauthRequest as UnauthRequest}
            formFields={unauthorizedRequestFields}
            onChange={value => setUnauthRequest(value as UnauthRequest)}
            editing={false}
          />
          <Typography variant="h6" component="h2" sx={{ mt: 2 }}>
            {t('attachments')}
          </Typography>
          {isMobile ? (
            <Grid container spacing={2}>
              {filesGrid}
            </Grid>
          ) : (
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>{t('name')}</TableCell>
                  <TableCell>{t('size')}</TableCell>
                  <TableCell>{t('actions')}</TableCell>
                </TableRow>
              </TableHead>
              {filesTable}
            </Table>
          )}
        </CardContent>
      </Card>
      <DeleteModal
        name={fileToDelete.split('/')[1]}
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        onDelete={() => {
          deleteObject(fileToDelete)
          setOpenDeleteModal(false)
        }}
      />
    </Page>
  )
}
