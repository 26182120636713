import React from 'react'
import { useHistory } from 'react-router-dom'
import useMobileService from '../../hooks/useMobileService'
import Footer from './Footer'
import Header from './Header'
import TabBar from './TabBar'
import { Box, Container } from '@mui/material'

type Props = {
  children: React.ReactNode
  title?: string
  showBack?: boolean
  showSettings?: boolean
  onBackClick?: () => void
  groups?: any
}

export function MainLayout(props: Props) {
  const isMobile = useMobileService()
  const { children, title, showBack, showSettings, onBackClick, groups } = props
  const history = useHistory()

  console.log(showSettings)
  if (isMobile) {
    return (
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          flexShrink: 0,
          flexGrow: 1,
        }}
      >
        <Header
          title={title}
          centerTitle
          showBack={showBack}
          showSettings={showSettings}
          onBackClick={onBackClick || history.goBack}
        />
        <Box
          sx={{
            flex: '1 1 auto',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
        >
          {children}
        </Box>
        <TabBar groups={groups} />
      </Box>
    )
  }

  return (
    <Box minHeight="100vh" display="flex" flexDirection="column">
      <Header title={title} isDekstop groups={groups} />
      <Box flex={1} overflow="auto">
        <Container maxWidth="lg">
          <React.Fragment>{children}</React.Fragment>
        </Container>
      </Box>
      <Footer />
    </Box>
  )
}

export const AuthLayout = ({ children }: Partial<Props>) => (
  <Box height="100vh" display="flex" justifyContent="center" alignItems="center">
    {children}
  </Box>
)

export const EmptyLayout = ({ children }: Partial<Props>) => (
  <Box height="100vh" display="flex" flexDirection="column">
    {children}
  </Box>
)
