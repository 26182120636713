import React, { useEffect, useState } from 'react'
import ChannelOverview from './ChannelOverview'
import { handlePromise } from '../../../../utils/functions'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import { customListChannels } from '../../../../graphql/custom-queries'
import { customUpdateChannel } from '../../../../graphql/custom-mutations'
import { deleteChannel } from '../../../../graphql/mutations'
import { Channel, Message } from '../../../../graphql/graphql'
import { Observable } from 'zen-observable-ts'
import { onCreateChannel, onDeleteChannel } from '../../../../graphql/subscriptions'
import { customOnCreateMessage } from '../../../../graphql/custom-subscriptions'
import useMobileService from '../../../../hooks/useMobileService'
import { useNotifications } from '../../../Feedback'
import { useTranslation } from 'react-i18next'
import { sortChannels } from '../../utils/functions'
import { useLocation } from 'react-router-dom'

type Props = {
  setBadgeStatus_countMessages?: (count: number) => void
  setBadgeStatus_unreadConversations?: (unread: boolean) => void
}

/**
 * Logic for channel operation (delete/create) and subscriptions
 */
export default function ChannelLogic(props: Props) {
  const { setBadgeStatus_countMessages, setBadgeStatus_unreadConversations } = props
  const [channels, setChannels] = useState<Channel[]>() // current channels
  // channel that is active and used for operations
  const [activeChannel, setActiveChannel] = useState<Channel>()
  const [createChannelMode, setCreateChannelMode] = useState<boolean>(false) // necessary for mobile
  // channel that is visited, helps to differentiate seen and unseen messages
  const [visitedChannel, setVisitedChannel] = useState<Channel | undefined>()
  // channelIds with number of new Messages
  const [messages, setMessages] = useState<Message[]>([]) // messages inside the active channel
  // new messages that have been received
  const [newMessages, setNewMessages] = useState<Message[]>([])
  const [initNewMessages, setInitNewMessages] = useState<Message[]>([])
  const [countMessages, setCountMessages] = useState<number>(0) // number of new received messages
  const [user, setUser] = useState<any | null>(null)
  // user groups to know if a user is admin or not (user inside group admin?)
  const [userGroups, setUserGroups] = useState<string[] | null>([])
  const isMobile = useMobileService()
  const location = useLocation()
  const { addNotification } = useNotifications()
  const { t } = useTranslation()

  /* ----------------------------------Effects start---------------------------------------- */
  useEffect(() => {
    fetchUser()
  }, [])

  useEffect(() => {
    if (user) {
      fetchChannels(!isMobile)
      const userGroups: string[] | null =
        user?.signInUserSession?.accessToken?.payload['cognito:groups']
      setUserGroups(userGroups)
    }
  }, [user])

  useEffect(() => {
    if (channels) {
      const initNewMessages: Message[] = []
      channels.map(channel => {
        if (channel.Messages && channel.Messages.items) {
          const messages: Message[] = channel.Messages.items as Message[]
          const sortedMessages: Message[] = messages.sort(function (a: Message, b: Message) {
            const dateA = new Date(a.updatedAt)
            const dateB = new Date(b.updatedAt)
            return dateA < dateB ? 1 : -1
          })
          for (const message of sortedMessages) {
            if (
              new Date(message.updatedAt) > new Date(channel.visitedAt as string) &&
              user.username !== message?.author
            ) {
              initNewMessages.push(message)
            } else {
              break
            }
          }
        }
      })
      setInitNewMessages(initNewMessages)
    }
  }, [channels])

  useEffect(() => {
    if (location.pathname !== '/messages') {
      handleClose()
    } else if (location.pathname === '/messages') {
      setCountMessages(0)
      setBadgeStatus_countMessages ? setBadgeStatus_countMessages(0) : null
    }
  }, [location])

  useEffect(() => {
    if (newMessages.length > 0) {
      fetchChannels(false)
    }
  }, [newMessages])

  /* -----------------------------------Effects end----------------------------------------- */
  /* -------------------------------Subscriptions start------------------------------------- */

  useEffect(() => {
    // increment message counter if new Message is created with a subscription
    const client = API.graphql(graphqlOperation(customOnCreateMessage)) as Observable<any>
    const subscription = client.subscribe({
      next: event => {
        if (
          channels &&
          channels.filter(channel => channel.id === event.value.data.onCreateMessage.channelID)
            .length > 0
        ) {
          if (
            event.value.data.onCreateMessage.author !== user.username &&
            location.pathname !== '/messages'
          ) {
            setCountMessages(countMessages + 1)
            setBadgeStatus_countMessages ? setBadgeStatus_countMessages(countMessages + 1) : null
          }
        }
      },
      error: error => console.warn(error),
    })

    return () => {
      subscription.unsubscribe()
    }
  })

  useEffect(() => {
    // update current channels if a new channel is created
    const client = API.graphql(graphqlOperation(onCreateChannel)) as Observable<any>
    const subscription = client.subscribe({
      next: event => {
        const channelData = event.value.data.onCreateChannel
        if (
          channelData.address_to === user.attributes.email ||
          channelData.address_from === user.attributes.email
        ) {
          setChannels([...(channels as Channel[]), event.value.data.onCreateChannel])
          updateChannelVisitedAt(event.value.data.onCreateChannel)
          setBadgeStatus_unreadConversations ? setBadgeStatus_unreadConversations(true) : null
        }
      },
      error: error => console.warn(error),
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [channels])

  useEffect(() => {
    // update current channels when a channel is deleted
    const client = API.graphql(graphqlOperation(onDeleteChannel)) as Observable<any>
    const subscription = client.subscribe({
      next: event => {
        fetchChannels(true)
      },
      error: error => console.warn(error),
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [channels])

  /* -------------------------------Subscriptions end--------------------------------------- */
  /* ---------------------------------Queries start----------------------------------------- */
  async function fetchUser() {
    const [res, error] = await handlePromise(
      'getCurrentAuthenticatedUser',
      Auth.currentAuthenticatedUser()
    )
    res ? setUser(res) : console.log('Error on fetching current authenticated user')
  }

  async function fetchChannels(resetActiveChannel: boolean) {
    const filterCurrentUser = {
      or: [
        { address_to: { eq: user.attributes.email } },
        { address_from: { eq: user.attributes.email } },
      ],
    }
    const [res, err] = await handlePromise(
      'listChannels',
      API.graphql(graphqlOperation(customListChannels, { filter: filterCurrentUser }))
    )
    if (res) {
      const channels: Channel[] = res.data.listChannels.items
      setChannels(sortChannels(channels, newMessages))
      resetActiveChannel ? setActiveChannel(channels[0]) : null
    } else {
      console.log('Error on fetching Channels', err)
    }
  }

  /* ----------------------------------Queries end------------------------------------------ */
  /* --------------------------------Mutations start---------------------------------------- */

  async function updateChannelVisitedAt(channel: Channel | undefined) {
    if (channel) {
      const currentTime = new Date().toISOString()
      const [res, err] = await handlePromise(
        'updateChannelVisitedAt',
        API.graphql(
          graphqlOperation(customUpdateChannel, {
            input: { id: channel.id, visitedAt: currentTime },
          })
        )
      )
      if (res) {
        /* fetchChannels(false) */
        setVisitedChannel(res.data.updateChannel)
      } else {
        console.log('Error on updating visitedAt field of channel')
      }
    }
  }

  async function handleChannelDelete(channels: any[]) {
    Promise.all(channels.map(channel => channelDelete(channel.id)))
      .then(value => {
        value.includes(false)
          ? addNotification({ message: t('error.errorOccurred'), color: 'error' })
          : addNotification({ message: t('success.changesSavedSuccessfully'), color: 'success' })
        fetchChannels(true)
      })
      .catch(err => {
        addNotification({ message: t('error.errorOccurred'), color: 'error' })
        console.log('Error on deleting components', err)
      })
  }

  async function channelDelete(id: string) {
    const [res, err] = await handlePromise(
      'deleteChannel',
      API.graphql(graphqlOperation(deleteChannel, { input: { id: id } }))
    )
    return !err
  }

  /* ---------------------------------Mutations end----------------------------------------- */
  /* ----------------------------------Events start----------------------------------------- */

  const handleClose = () => {
    setActiveChannel(undefined)
    setBadgeStatus_unreadConversations ? setBadgeStatus_unreadConversations(false) : null
    setCountMessages(0)
    setBadgeStatus_countMessages ? setBadgeStatus_countMessages(0) : null
  }

  //allows chatview to be closed on mobile
  const handleMobileCloseChatView = () => {
    setActiveChannel(undefined)
    setCreateChannelMode(false)
  }

  const handleCreateChannelClick = () => {
    setActiveChannel(undefined)
    setCreateChannelMode(true)
  }

  const handleChannelClick = (channel: Channel | undefined) => {
    setActiveChannel(channel)
    updateChannelVisitedAt(channel)
  }

  /* -----------------------------------Events end------------------------------------------ */

  return (
    <div>
      {channels &&
        channels?.length >= 0 &&
        !setBadgeStatus_countMessages &&
        !setBadgeStatus_unreadConversations && (
          <ChannelOverview
            user={user}
            userGroups={userGroups}
            channels={
              initNewMessages.length > 0 ? sortChannels(channels, initNewMessages) : channels
            }
            messages={messages}
            setMessages={setMessages}
            newMessages={initNewMessages.length > 0 ? initNewMessages : newMessages}
            setNewMessages={setNewMessages}
            visitedChannel={visitedChannel as Channel}
            activeChannel={activeChannel as Channel}
            createChannelMode={createChannelMode}
            handleMobileCloseChatView={handleMobileCloseChatView}
            handleCreateChannelClick={handleCreateChannelClick}
            handleChannelClick={handleChannelClick}
            handleChannelDelete={handleChannelDelete}
            handleClose={handleClose}
          />
        )}
    </div>
  )
}
