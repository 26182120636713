import React from 'react'
import { useTranslation } from 'react-i18next'
import CheckboxInput from '../../../components/inputs/CheckboxInput'
import ColorInput from '../../../components/inputs/ColorInput'
import DateInput from '../../../components/inputs/DateInput'
import EmailInput from '../../../components/inputs/EmailInput'
import MultiSelectInput from '../../../components/inputs/MultiSelectInput'
import NumberInput from '../../../components/inputs/NumberInput'
import S3FileUpload from '../../../components/inputs/S3FileUpload'
import SearchSelectInput from '../../../components/inputs/SearchSelectInput'
import SelectInput from '../../../components/inputs/SelectInput'
import TextInput from '../../../components/inputs/TextInput'
import { FieldObject } from '../utils/types'
import CheckedTextInput from '../../../components/inputs/CheckedTextInput'
import SelectWithTextInput from '../../../components/inputs/SelectWithTextInput'
import CADFileUpload from '../../../components/inputs/CADFileUpload'
import SelectMultipleWithTextInput from '../../../components/inputs/SelectMultipleWithTextInput'

interface Props {
  field: FieldObject
  disabled?: boolean
  onChange: (value: any) => void
  value: any
}

export function FormField({ field, onChange, value, disabled }: Props) {
  const { t } = useTranslation()
  switch (field.type) {
    case 'custom':
      return <div>{field.CustomComponent}</div>
    case 'email':
      return <EmailInput email={value as string} setEmail={onChange} disabled={disabled} />
    case 'select':
      return (
        <SelectInput
          name={t(field.label)}
          onChange={onChange}
          options={field.options}
          disabled={disabled}
          value={value}
        />
      )
    case 'selectWithText':
      return (
        <SelectWithTextInput
          name={t(field.label)}
          value={value}
          options={field.options}
          onChange={onChange}
          disabled={disabled}
        />
      )
    case 'selectMultipleWithText':
      return (
        <SelectMultipleWithTextInput
          name={t(field.label)}
          value={[value]}
          options={field.options as any[]}
          onChange={onChange}
          disabled={disabled}
        />
      )
    case 'select-multi':
      return (
        <MultiSelectInput
          name={t(field.label)}
          onChange={onChange}
          options={field.options}
          value={value}
          disabled={disabled}
          labelWidth={field.labelWidth}
        />
      )
    case 'select-search':
      return (
        <SearchSelectInput
          name={t(field.label)}
          onChange={onChange}
          options={field.options}
          value={value}
          disabled={disabled}
          errorMessage={field.errorMessage}
          validation={field.validation}
        />
      )
    case 'text':
      return (
        <TextInput
          label={t(field.label)}
          onChange={onChange}
          value={value}
          disabled={disabled}
          errorMessage={field.errorMessage}
          labelWidth={field.labelWidth}
          validation={field.validation}
        />
      )
    case 'multiline':
      return (
        <TextInput
          label={t(field.label)}
          onChange={onChange}
          value={value}
          disabled={disabled}
          errorMessage={field.errorMessage}
          labelWidth={field.labelWidth}
          validation={field.validation}
          multiline={true}
        />
      )
    case 'number':
      return (
        <NumberInput
          label={t(field.label)}
          value={value}
          onChange={onChange}
          disabled={disabled}
          errorMessage={field.errorMessage}
          labelWidth={field.labelWidth}
          validation={field.validation}
        />
      )
    case 'date':
      return (
        <DateInput
          value={value}
          label={t(field.label)}
          onChange={onChange}
          disabled={disabled}
          errorMessage={field.errorMessage}
          labelWidth={field.labelWidth}
          validation={field.validation}
        />
      )
    case 'checkbox':
      return (
        <CheckboxInput
          value={value}
          label={t(field.label)}
          onChange={onChange}
          disabled={disabled}
        />
      )
    case 'checked':
      return (
        <CheckedTextInput
          value={value}
          label={t(field.label)}
          onChange={onChange}
          disabled={disabled}
        />
      )
    case 'color':
      return <ColorInput onChange={onChange} disabled={disabled} value={value} />
    case 'file_upload':
      return <S3FileUpload component={field.component} />
    case 'cad_upload':
      return <CADFileUpload component={field.component} />
    default:
      return null
  }
}
