import { API, Auth, graphqlOperation } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import useMobileService from '../hooks/useMobileService'
import Page from '../components/layout/Page'
import { Observable } from 'zen-observable-ts'
import { Contract, ContractRequest, Product } from '../graphql/graphql'
import { Card, CardContent, Table, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import FAB from '../components/widgets/FAB'
import MobileListItem from '../components/widgets/MobileListItem'
import { customListContracts } from '../graphql/custom-queries'
import LoadingSkeleton from '../components/widgets/LoadingSkeleton'
import { getUserEmail, groupBy, handlePromise } from '../utils/functions'
import { listContractRequests } from '../graphql/queries'
import ContractOptionsModal from '../features/Contract/components/ContractOptionsModal'
import { createContract } from '../graphql/mutations'
import { onCreateContractRequest } from '../graphql/subscriptions'

/**
 * Displays contract tables divided by user so the Admin can work on
 * contracts requested by the users
 */
function ContractOverviewAdminPage() {
  const { t } = useTranslation(['common', 'contract'])
  const [contractData, setContractData] = useState<any>({ data: null, error: false, loading: true })
  const [user, setUser] = useState<any>()
  const [userEmails, setUserEmails] = useState<string[]>([])
  const [openOptionsModal, setOpenOptionsModal] = useState<boolean>(false)
  const [contractRequests, setContractRequests] = useState<ContractRequest[]>([])
  const history = useHistory()
  const isMobile = useMobileService()

  useEffect(() => {
    fetchUser()
    setTimeout(fetchContracts, 100)
    fetchContractRequests()
  }, [])

  useEffect(() => {
    if (contractData && contractData.data) {
      const email = Promise.all(
        Object.keys(contractData.data).map(async value => await getUserEmail(value, user, t))
      )
      email.then(value => setUserEmails(value))
    }
  }, [contractData])

  useEffect(() => {
    // update contractRequests when new requests are received
    const client = API.graphql(graphqlOperation(onCreateContractRequest)) as Observable<any>
    const subscription = client.subscribe({
      next: event => {
        console.log(event.value.data.onCreateContractStatus)
        setContractRequests([
          ...(contractRequests as ContractRequest[]),
          event.value.data.onCreateContractRequest,
        ])
      },
      error: error => console.warn(error),
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [contractRequests])

  console.log('ContractRequests: ', contractRequests)

  async function fetchUser() {
    const [res, error] = await handlePromise(
      'getCurrentAuthenticatedUser',
      Auth.currentAuthenticatedUser()
    )
    res ? setUser(res) : console.log('Error on fetching current authenticated user')
  }

  async function fetchContracts() {
    const [res, err] = await handlePromise(
      'listContracts',
      API.graphql(graphqlOperation(customListContracts))
    )
    res
      ? setContractData({
          data: groupBy(res.data.listContracts.items, 'owner'),
          error: false,
          loading: false,
        })
      : setContractData({ data: null, error: true, loading: false })
  }

  async function orderProduct(product: Product) {
    const [res, err] = await handlePromise(
      'createContract',
      API.graphql(graphqlOperation(createContract, { input: { productID: product?.id } }))
    )
    res ? console.log(res.data.createContract) : console.log('Error on creating Contract', err)

    const id = res.data.createContract.id

    history.push('/contracts/' + id)
  }

  async function fetchContractRequests() {
    const [res, err] = await handlePromise(
      'listContractRequests',
      API.graphql(graphqlOperation(listContractRequests))
    )
    res
      ? setContractRequests(res.data.listContractRequests.items)
      : console.log('Error on fetching ContractRequests', err)
  }

  const columnWidths = {
    name: {
      width: 200,
      maxWidth: 200,
    },
    description: {
      width: 120,
      maxWidth: 120,
    },
    articleNumber: {
      width: 100,
      maxWidth: 100,
    },
  }

  if (contractData.error) {
    return <div>{t('product:error.fetchingProducts')}</div>
  }

  if (contractData.loading) {
    return <LoadingSkeleton />
  }

  if (isMobile) {
    return (
      <Page marginBottom="200px">
        <h2>{t('contract:overview.yourContracts')}</h2>
        {Object.entries(contractData.data).map((value: any, index) => {
          const contracts: any[] = value[1]
          return (
            <div key={index}>
              <Typography>{userEmails[index]}</Typography>
              {contracts.length === 0 ? (
                //Show Empty-Page-Info when there are no contracts yet
                <Typography align="center" color="text.secondary" sx={{ fontSize: 13 }}>
                  {t('contract:error.noContractsFound')}
                </Typography>
              ) : (
                //Show Contracts
                contracts.map((contract: Contract, index) => {
                  return (
                    <MobileListItem
                      key={index}
                      primaryText={contract.Product?.name}
                      onClick={() => history.push('/contracts/' + contract?.id)}
                      buttonWhenClosed={t('mobile.showDetails')}
                      buttonWhenOpened={t('mobile.hideDetails')}
                      index={index}
                    >
                      <br />
                      {`${t('contract:details.wishDate')}: ${
                        contract?.wish_date ? contract.wish_date : ''
                      }`}
                      <br />
                      {`${t('contract:details.deadline')}: ${
                        contract?.deadline ? contract.deadline : ''
                      }`}
                      <br />
                      {`${t('quantity')}: ${contract?.quantity ? contract.quantity : ''}`}
                      <br />
                      {`${t('price')}: ${contract?.price ? contract.price : ''}`}
                      <br />
                    </MobileListItem>
                  )
                })
              )}
            </div>
          )
        })}
        <FAB type={'ADD'} onClick={() => history.push('/contracts/new')} />
      </Page>
    )
  }

  return (
    <Page>
      {Object.entries(contractData.data).length === 0 ? (
        <Typography align="center" color="text.secondary" sx={{ fontSize: 13 }}>
          {t('contract:error.noContractsFound')}
        </Typography>
      ) : (
        Object.entries(contractData.data).map((value: any, index) => {
          const contracts: any[] = value[1]
          return (
            <Card key={index}>
              <CardContent>
                <Typography variant="h5">{userEmails[index]}</Typography>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">#</TableCell>
                      <TableCell align="center">{t('name')}</TableCell>
                      <TableCell align="center">{t('articleNumber')}</TableCell>
                      <TableCell align="center">{t('description')}</TableCell>
                      <TableCell align="center">{t('quantity')}</TableCell>
                      <TableCell align="center">{t('price')}</TableCell>
                      <TableCell align="center">{t('status')}</TableCell>
                    </TableRow>
                  </TableHead>
                  {contracts.map((contract: Contract, index) => {
                    return (
                      <React.Fragment key={index}>
                        <TableRow
                          hover={true}
                          onClick={() => history.push('/contracts/' + contract?.id)}
                        >
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              width: columnWidths.description.width,
                              maxWidth: columnWidths.description.maxWidth,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {contract?.Product?.name}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: columnWidths.description.width,
                              maxWidth: columnWidths.description.maxWidth,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {contract?.Product?.article_number}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: columnWidths.description.width,
                              maxWidth: columnWidths.description.maxWidth,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {contract?.description}
                          </TableCell>
                          <TableCell align="center">{contract?.quantity}</TableCell>
                          <TableCell align="center">{contract?.price}</TableCell>
                          {contractRequests.filter(x => x.contractID === contract.id).length > 0 &&
                          contract?.Status?.items.length === 0 ? (
                            <TableCell align="center">REQUEST</TableCell>
                          ) : (
                            <TableCell align="center">
                              {
                                contract?.Status?.items.sort(
                                  (a, b) =>
                                    Date.parse(b?.createdAt as string) -
                                    Date.parse(a?.createdAt as string)
                                )[0]?.Status
                              }
                            </TableCell>
                          )}
                        </TableRow>
                      </React.Fragment>
                    )
                  })}
                </Table>
              </CardContent>
            </Card>
          )
        })
      )}
      <FAB type="ADD" onClick={() => setOpenOptionsModal(!openOptionsModal)} />
      <ContractOptionsModal
        open={openOptionsModal}
        onClose={() => setOpenOptionsModal(!openOptionsModal)}
        onConfirm={(product: Product) => orderProduct(product)}
      />
    </Page>
  )
}
export default ContractOverviewAdminPage
