import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
  Typography,
} from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { Close } from '@mui/icons-material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Color, useConfirm } from '../hooks/useConfirm'

type StyleProps = { color: Color }
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: (props: StyleProps) => theme.palette[props.color].main,
      color: (props: StyleProps) => theme.palette[props.color].contrastText,
    },
  })
)

export function ConfirmDialog() {
  const { message, color, onSubmit, close } = useConfirm()
  const classes = useStyles({ color })
  const { t } = useTranslation()

  return (
    <Dialog
      open={Boolean(onSubmit)}
      onClose={close}
      maxWidth="sm"
      fullWidth
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle>{t('dialog.areYouSure')}</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={close} size="large">
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography>{t(message)}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="inherit" variant="contained">
          {t('cancel')}
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          onClick={() => {
            if (onSubmit) {
              onSubmit()
            }
            close()
          }}
        >
          {t('continue')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
