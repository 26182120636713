import React, { useEffect, useState } from 'react'
import ContractDetails from '../features/Contract/components/ContractDetails'
import Page from '../components/layout/Page'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import ForwardButton from '../components/widgets/ForwardButton'
import FAB from '../components/widgets/FAB'
import ContractLogic from '../features/Contract/components/ContractLogic'
import { Contract, ContractRequest, ContractStatus, Product } from '../graphql/graphql'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import { Alert, Button, CardContent, Popover, Tooltip } from '@mui/material'
import { customGetContract, getStatuses } from '../graphql/custom-queries'
import LoadingSkeleton from '../components/widgets/LoadingSkeleton'
import useQuery from '../hooks/useAPI'
import { useTranslation } from 'react-i18next'
import { feedbackForGraphQL, getUserEmail, handlePromise, sendInfoEmail } from '../utils/functions'
import {
  createContractRequest,
  deleteContract,
  updateContract,
  updateContractRequest,
} from '../graphql/mutations'
import DeleteModal from '../components/widgets/DeleteModal'
import { useNotifications } from '../features/Feedback'
import { contractRequestByContract, contractStatusByContract } from '../graphql/queries'
import { customCreateContractStatus } from '../graphql/custom-mutations'

type urlParams = {
  id: string
}
/**
 * Contract page consisting of contract logic and details
 */
export default function ContractPage() {
  const { t } = useTranslation(['common', 'contract'])
  const { addNotification } = useNotifications()
  const location = useLocation()
  const history = useHistory()
  const { id } = useParams<urlParams>()
  const [contract, setContract] = useState<Contract>()
  const [deleteModal, openDeleteModal] = useState(false)
  const [status, setStatus] = useState(false)
  const isNew = location.pathname === '/contracts/new'
  const [editing, setEditing] = useState(isNew)
  const [open, setOpen] = React.useState(false)
  const [user, setUser] = useState<any>()
  const [groups, setGroups] = useState<any>()
  const [contractStatuses, setContractStatuses] = useState<ContractStatus[]>()
  const [statuses, setStatuses] = useState<string[]>([])
  const [request, setRequest] = useState<any>()
  const [adminStatus, setAdminStatus] = useState<string>('')
  const [statusDate, setStatusDate] = useState<string>('')

  const { data, error, loading } = useQuery(
    'fetchContract',
    API.graphql(graphqlOperation(customGetContract, { id: id }))
  )

  useEffect(() => {
    data ? setContract(data.data.getContract) : null
  }, [data])

  useEffect(() => {
    fetchUser()
    fetchStatuses()
  }, [])

  useEffect(() => {
    if (contract) {
      fetchContractRequest(contract.id)
      fetchContractStatuses(contract.id)
    }
  }, [contract])

  useEffect(() => {
    const userGroups: string[] | null =
      user?.signInUserSession?.accessToken?.payload['cognito:groups']
    setGroups(userGroups)
  }, [user])

  async function fetchUser() {
    const [res, error] = await handlePromise(
      'getCurrentAuthenticatedUser',
      Auth.currentAuthenticatedUser()
    )
    res ? setUser(res) : console.log('Error on fetching current authenticated user')
  }

  async function fetchStatuses() {
    const statuses: string[] = []
    const [res, err] = await handlePromise('getStatuses', API.graphql({ query: getStatuses }))
    res
      ? res.data.__type.enumValues.map((value: any) => statuses.push(value.name))
      : console.log('Error on getting Statuses')
    setStatuses(statuses)
  }

  async function createStatus() {
    acceptRequest(request)
    const [res, err] = await handlePromise(
      'customCreateContractStatus',
      API.graphql(
        graphqlOperation(customCreateContractStatus, {
          input: {
            date: statusDate,
            Status: adminStatus,
            contractID: contract?.id,
            owner: request.owner,
          },
        })
      )
    )
    const userEmail: string = await getUserEmail(res.data.createContractStatus.owner, user, t)
    feedbackForGraphQL(res, addNotification, t)
    res && userEmail ? sendInfoEmail(userEmail, 'NEW_CONTRACT_STATUS', adminStatus) : null
    contract ? fetchContractStatuses(contract.id) : null
  }
  async function fetchContractStatuses(contractID: string) {
    const [res, err] = await handlePromise(
      'contractStatusByContract',
      API.graphql(graphqlOperation(contractStatusByContract, { contractID: contractID }))
    )
    res
      ? setContractStatuses(res.data.contractStatusByContract.items)
      : console.log('Error on fetching contract statuses', err)
  }

  async function fetchContractRequest(contractID: string) {
    const [res, err] = await handlePromise(
      'contractRequestByContract',
      API.graphql(
        graphqlOperation(contractRequestByContract, {
          contractID: contractID,
        })
      )
    )
    res
      ? setRequest(res.data.contractRequestByContract.items[0])
      : console.log('Error on fetching ContractRequests', err)
  }

  async function acceptRequest(request: ContractRequest) {
    const [res, err] = await handlePromise(
      'updateContractRequest',
      API.graphql(
        graphqlOperation(updateContractRequest, { input: { id: request.id, accepted: true } })
      )
    )
    res
      ? console.log(res.data.updateContractRequest)
      : console.log('Error on accepting request', err)
  }

  const handleClose = () => {
    setOpen(false)
  }

  if (error && !isNew) {
    return <div>{t('contract:error.fetchingContracts')}</div>
  }

  if (loading) {
    return <LoadingSkeleton />
  }

  async function createRequest() {
    const {
      createdAt,
      updatedAt,
      owner,
      productID,
      Status,
      Product,
      ShippingAddress,
      BillingAddress,
      Request,
      ...contractInput
    } = contract as Contract
    const [res, error] = await handlePromise(
      'updateContract',
      API.graphql(graphqlOperation(updateContract, { input: contractInput }))
    )
    feedbackForGraphQL(res, addNotification, t)
    const todayDate: string = new Date().toISOString().slice(0, 10)
    if (res) {
      const [result, err] = await handlePromise(
        'createContractRequest',
        API.graphql(
          graphqlOperation(createContractRequest, {
            input: {
              contractID: contract?.id,
              accepted: false,
              date: todayDate,
            },
          })
        )
      )
      feedbackForGraphQL(result, addNotification, t)
      result
        ? sendInfoEmail('Admin', 'NEW_CONTRACT_REQUEST', contract?.Product?.name as string)
        : null
    }
  }

  async function deleteContractwithStatus() {
    const [res, err] = await handlePromise(
      'deleteContract',
      API.graphql(graphqlOperation(deleteContract, { input: { id: id } }))
    )
    feedbackForGraphQL(res, addNotification, t)

    history.push('/contracts')
  }

  function checkContractStatus() {
    if (
      (contract?.Status?.items[0]?.Status == null &&
        contract?.Request?.items[0]?.accepted == null) ||
      groups.includes('AdminS3')
    ) {
      // contract can be deleted
      setStatus(false)
      openDeleteModal(true)
    } else {
      // contract cant be deleted
      setStatus(true)
      setOpen(true)
    }
  }

  return (
    <Page marginBottom="100px">
      <ContractLogic
        setContract={setContract}
        contract={contract as Contract}
        editing={editing}
        setEditing={setEditing}
        /*data={data}*/
        isNew={isNew}
      >
        <CardContent>
          <ContractDetails
            product={location.state as Product}
            contract={contract as Contract}
            setContract={setContract}
            editing={editing}
            setEditing={setEditing}
            isNew={isNew}
            request={request}
            groups={groups}
            contractStatuses={contractStatuses}
            statuses={statuses}
            setStatusDate={setStatusDate}
            setAdminStatus={setAdminStatus}
            createStatus={createStatus}
            statusDate={statusDate}
            adminStatus={adminStatus}
          />
          <ForwardButton
            disabled={contract?.Request?.items && contract.Request.items.length > 0}
            onForward={() => {
              createRequest()
              history.push('/contracts')
            }}
            text={'request'}
          />
          <Tooltip title={t('contract:tooltips.deleteContract') as string}>
            <Button
              color="primary"
              variant="contained"
              style={{ float: 'right', marginTop: '5px', marginRight: '10px' }}
              onClick={() => checkContractStatus()}
            >
              {t('delete')}
            </Button>
          </Tooltip>
          <Popover
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            open={open}
            onClose={handleClose}
            anchorReference={'none'}
          >
            <Alert severity="warning" sx={{ fontSize: '20px', textAlign: 'center' }}>
              {' '}
              {t('contract:error.canNotDelete')}
            </Alert>
          </Popover>
          <DeleteModal
            name={t('thisContract')}
            open={deleteModal}
            setOpen={openDeleteModal}
            onDelete={() => {
              deleteContractwithStatus()
              openDeleteModal(false)
              history.push('/contracts')
            }}
          />

          {!(location.pathname === '/contracts/new') && (
            <FAB type="EDIT" onClick={() => setEditing(!editing)} />
          )}
        </CardContent>
      </ContractLogic>
    </Page>
  )
}
