import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import useMobileService from '../hooks/useMobileService'
import Page from '../components/layout/Page'
import {
  Button,
  Card,
  CardContent,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import FAB from '../components/widgets/FAB'
import { API, graphqlOperation } from 'aws-amplify'
import { Product } from '../graphql/graphql'
import MobileListItem from '../components/widgets/MobileListItem'
import { customListProducts } from '../graphql/custom-queries'
import LoadingSkeleton from '../components/widgets/LoadingSkeleton'
import { copyProduct, createContract } from '../graphql/mutations'
import { handlePromise } from '../utils/functions'
import ProductPage from './ProductPage'
import { useNotifications } from '../features/Feedback'
import ProductOptionsModal from '../features/Product/components/Product/ProductOptionsModal'

/**
 * Shows all contracts in a table
 */
export default function ProductOverviewPage() {
  const { t } = useTranslation(['common', 'product'])
  const history = useHistory()
  const [open, setOpen] = useState(-1)
  const [openOptionsModal, setOpenOptionsModal] = useState(false)
  const isMobile = useMobileService()
  const [productData, setProductData] = useState<any>({ data: null, error: false, loading: true })
  const [openProduct, setOpenProduct] = useState<Product>()
  const [copyOldProduct, setCopyOldProduct] = useState<boolean>(false)
  const { addNotification } = useNotifications()

  useEffect(() => {
    setTimeout(fetchProducts, 100)
  }, [])

  async function fetchProducts() {
    const [res, err] = await handlePromise(
      'listProducts',
      API.graphql(graphqlOperation(customListProducts))
    )
    res
      ? setProductData({ data: res.data.listProducts.items, error: false, loading: false })
      : setProductData({ data: null, error: true, loading: false })
  }

  async function orderProduct(product: Product) {
    const [res, err] = await handlePromise(
      'createContract',
      API.graphql(graphqlOperation(createContract, { input: { productID: product?.id } }))
    )
    if (res) {
      const id = res.data.createContract.id
      history.push({ pathname: '/contracts/' + id, state: true })
    } else {
      console.log('Error on creating Contract', err)
    }
  }

  async function copySelectedProduct(productID: string) {
    setCopyOldProduct(false)
    if (productID) {
      setProductData({ ...productData, loading: true })
      const productCopy = await API.graphql(graphqlOperation(copyProduct, { id: productID }))
      console.log(productCopy)
      fetchProducts()
      setProductData({ ...productData, loading: false })
    }
  }

  if (productData.error) {
    return <div>{t('product:error.fetchingProducts')}</div>
  }

  if (productData.loading) {
    return <LoadingSkeleton />
  }

  const columnWidths = {
    arrowDown: {
      width: 10,
      maxWidth: 10,
    },
    name: {
      width: 160,
      maxWidth: 160,
    },
    category: {
      width: 80,
      maxWidth: 80,
    },
    articleNumber: {
      width: 60,
      maxWidth: 60,
    },
    requestNow: {
      width: 60,
      maxWidth: 60,
    },
  }

  return (
    <Page marginBottom="200px">
      {isMobile ? (
        <div>
          <h1>{t('product:overview.yourProducts')}</h1>
          {productData.data.length === 0 ? (
            //Show Empty-Page-Info when there are no products yet
            <Typography align="center" color="text.secondary" sx={{ fontSize: 13 }}>
              {t('product:error.noProductsFound')}
            </Typography>
          ) : (
            //Show Products
            productData.data.map((product: Product, index: number) => {
              const rootComponents: any = product?.Components?.items.filter(
                (x: any) => x.root === true
              )
              const sortedRootComponents: any[] = rootComponents.sort((a: any, b: any) => {
                const dateA: any = new Date(a.createdAt)
                const dateB: any = new Date(b.createdAt)
                return dateA - dateB
              })
              const category = sortedRootComponents[0]?.ManufacturingProcess?.category
              return (
                <MobileListItem
                  key={index}
                  primaryText={product.name}
                  index={index}
                  onClick={() => history.push('/products/' + product?.id)}
                  buttonWhenClosed={t('mobile.showDetails')}
                  buttonWhenOpened={t('mobile.hideDetails')}
                  enableCopyAction={copyOldProduct}
                >
                  <br />
                  {`${t('product:overview.category')}: ${category ?? t('notSetYet')}`}
                  <br />
                  {`${t('product:overview.articleNumber')}: ${
                    product.article_number ? product.article_number : t('notSetYet')
                  }`}
                </MobileListItem>
              )
            })
          )}
          <FAB type={'ADD'} onClick={() => setOpenOptionsModal(!openOptionsModal)} />
        </div>
      ) : (
        <div>
          <Card>
            <CardContent>
              <Typography variant="h5">{t('product:overview.productList')}</Typography>
              <TableContainer sx={{ width: '100%' }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right" />
                      <TableCell align="right">{t('name')}</TableCell>
                      <TableCell align="right">{t('product:overview.category')}</TableCell>
                      <TableCell align="right">{t('articleNumber')}</TableCell>
                      <TableCell align="right" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {productData.data.map((product: Product, index: number) => {
                      const rootComponents: any = product?.Components?.items.filter(
                        (x: any) => x.root === true
                      )
                      const sortedRootComponents: any[] = rootComponents.sort((a: any, b: any) => {
                        const dateA: any = new Date(a.createdAt)
                        const dateB: any = new Date(b.createdAt)
                        return dateA - dateB
                      })
                      const category = sortedRootComponents[0]?.ManufacturingProcess?.category
                      return (
                        <React.Fragment key={index}>
                          <TableRow
                            hover={true}
                            onClick={() =>
                              copyOldProduct ? null : history.push('/products/' + product?.id)
                            }
                          >
                            <TableCell
                              sx={{
                                width: columnWidths.arrowDown.width,
                                maxWidth: columnWidths.arrowDown.maxWidth,
                              }}
                            >
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={e => {
                                  e.stopPropagation()
                                  setOpen(open === index ? -1 : index)
                                  setOpenProduct(product)
                                }}
                              >
                                {open === index ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                width: columnWidths.name.width,
                                maxWidth: columnWidths.name.maxWidth,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {product.name}
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                width: columnWidths.category.width,
                                maxWidth: columnWidths.category.maxWidth,
                              }}
                            >
                              {category ?? ''}
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                width: columnWidths.articleNumber.width,
                                maxWidth: columnWidths.articleNumber.maxWidth,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {product.article_number}
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                width: columnWidths.requestNow.width,
                                maxWidth: columnWidths.requestNow.maxWidth,
                              }}
                            >
                              {' '}
                              <Button
                                style={{ textTransform: 'none' }}
                                variant="contained"
                                onClick={() =>
                                  copyOldProduct
                                    ? copySelectedProduct(product.id)
                                    : orderProduct(product)
                                }
                              >
                                {copyOldProduct ? t('copy') : t('requestNow')}
                              </Button>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                              <Collapse in={open === index} timeout="auto" unmountOnExit>
                                <ProductPage productIdInOverview={openProduct?.id} />
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <FAB type="ADD" onClick={() => setOpenOptionsModal(!openOptionsModal)} />
            </CardContent>
          </Card>
        </div>
      )}
      <ProductOptionsModal
        open={openOptionsModal}
        onClose={() => setOpenOptionsModal(!openOptionsModal)}
        onCreate={() => history.push('/products/new')}
        onCopy={() => setCopyOldProduct(true)}
      />
    </Page>
  )
}
