import moment from 'moment'
import i18n from '../../../utils/i18n'
import { RenderTree } from './types'

/**
 * Processes list of s3 file objects to have the format of the RenderTree to represent
 * files in file structure
 * @param pathObjects s3 object with key that represents filepath of file in the s3 bucket
 * @returns List of files as RenderTree
 */
export function processStorageList(pathObjects: any[]) {
  const result: RenderTree[] = []
  const level = { result }
  pathObjects.map((value: any, index) => {
    let last: any = {}
    const { key, ...rest } = value
    key.split('/').reduce((r: any, name: string, i: number, { length }: any) => {
      if (!r[name]) {
        r[name] = { result: [] }
        last = {
          name,
          path: key
            .split('/')
            .slice(0, i + 1)
            .join('/'),
          children: r[name].result,
          size: rest.size,
          lastModified: rest.lastModified,
        }
        r.result.push(last)
      }

      return r[name]
    }, level)
    Object.assign(last, rest)
  })
  return result
}

/**
 * Walks recursively through file structure and gets files at a certain depth of the file tree
 * @param depth depth at which files are returned
 * @param path filepath that is currently visited
 * @param fileStructure file structure that is traversed recursively
 * @param level current level of recursion
 * @param result files at depth
 * @returns children/files at a given depth
 */
export function getChildrenAtDepth(
  depth: number,
  path: string,
  fileStructure: any[],
  level = 0,
  result: RenderTree[]
) {
  fileStructure.map((value: any) => {
    if (depth === level && value.path.includes(path.slice(1))) {
      result.push(value)
    } else {
      getChildrenAtDepth(depth, path, value.children, level + 1, result)
    }
  })
  return result
}

/**
 * Formats date returned from s3 bucket file to a string that can be shown on fileview
 * @param date AWS Time field for lastModified field
 * @returns date formatted to string
 */
export const formatDateString = (date: any) => {
  const lastModified = moment(date, 'ddd MMM DD YYYY HH:mm:ss ZZ').locale(i18n.language)
  return lastModified.format('LLLL')
}

/**
 * Sort a given filestructure to first show all folders than all files
 * @param data Filestructure that is being sorted
 * @returns List with structure [folder1, folder2, ..., file1, file2, ...]
 */
export const groupedFilesAndFolders = (data: any[]) => {
  const folders: any[] = []
  const files: any[] = []
  data.map(value => {
    value.children.length > 0 ? folders.push(value) : files.push(value)
  })
  return folders.concat(files)
}

/**
 * Converts list of s3 objects to the Type RenderTree
 * @param resultObject object thats returned from Amplify Storage list function
 */
export function convertToRenderTree(resultObject: any) {
  const renderTreeObject: RenderTree = {
    name: resultObject.key.split('/').pop(),
    path: resultObject.key,
    size: resultObject.size,
    lastModified: resultObject.lastModified,
  }
  return renderTreeObject
}
