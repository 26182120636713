import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import useMobileService from '../hooks/useMobileService'
import Page from '../components/layout/Page'
import {
  Button,
  Card,
  CardContent,
  Collapse,
  IconButton,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import FAB from '../components/widgets/FAB'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import { Product } from '../graphql/graphql'
import MobileListItem from '../components/widgets/MobileListItem'
import { customListProducts } from '../graphql/custom-queries'
import LoadingSkeleton from '../components/widgets/LoadingSkeleton'
import { copyProduct, createContract } from '../graphql/mutations'
import { getUserEmail, groupBy, handlePromise } from '../utils/functions'
import ProductPage from './ProductPage'
import { useNotifications } from '../features/Feedback'
import ProductOptionsModal from '../features/Product/components/Product/ProductOptionsModal'

/**
 * Displays product tables divided by user for the admin to see
 */
export default function ProductOverviewAdminPage() {
  const { t } = useTranslation(['common', 'product'])
  const history = useHistory()
  const [open, setOpen] = useState<number[]>([]) // open state for all the tables e.g. open at position 0 holds state for table 1
  // -1 --> closed, any other number --> item at that position is opened
  const isMobile = useMobileService()
  const [productData, setProductData] = useState<any>({ data: null, error: false, loading: true })
  const [openProduct, setOpenProduct] = useState<Product>()
  const [openOptionsModal, setOpenOptionsModal] = useState(false)
  const [user, setUser] = useState<any>()
  const [userEmails, setUserEmails] = useState<string[]>([])
  const [copyOldProduct, setCopyOldProduct] = useState<boolean>(false)
  const { addNotification } = useNotifications()

  useEffect(() => {
    fetchUser()
    setTimeout(fetchProducts, 100)
  }, [])

  useEffect(() => {
    if (productData && productData.data) {
      const numberOfTables: number = Object.values(productData.data).length
      if (numberOfTables !== open.length) {
        for (let i = 0; i < numberOfTables; i++) {
          open.push(-1) // initialize open array with -1 for closed
        }
      }
      const email = Promise.all(
        Object.keys(productData.data).map(async value => await getUserEmail(value, user, t))
      )
      email.then(value => setUserEmails(value))
    }
  }, [productData])

  async function fetchUser() {
    const [res, error] = await handlePromise(
      'getCurrentAuthenticatedUser',
      Auth.currentAuthenticatedUser()
    )
    res ? setUser(res) : console.log('Error on fetching current authenticated user')
  }

  async function fetchProducts() {
    const [res, err] = await handlePromise(
      'listProducts',
      API.graphql(graphqlOperation(customListProducts))
    )
    res
      ? setProductData({
          data: groupBy(res.data.listProducts.items, 'owner'),
          error: false,
          loading: false,
        })
      : setProductData({ data: null, error: true, loading: false })
  }

  async function orderProduct(product: Product) {
    const [res, err] = await handlePromise(
      'createContract',
      API.graphql(graphqlOperation(createContract, { input: { productID: product?.id } }))
    )
    if (res) {
      const id = res.data.createContract.id
      history.push({ pathname: '/contracts/' + id, state: true })
    } else {
      console.log('Error on creating Contract', err)
    }
  }

  /**
   * Uses graphql api that uses a custom lambda function copyProduct
   * @param productID id of product thats being copied
   */
  async function copySelectedProduct(productID: string) {
    setCopyOldProduct(false)
    if (productID) {
      setProductData({ ...productData, loading: true })
      const productCopy = await API.graphql(graphqlOperation(copyProduct, { id: productID }))
      fetchProducts()
      setProductData({ ...productData, loading: false })
    }
  }

  function handleOpenItem(index: number, table: number) {
    const newOpen = [...open]
    if (newOpen[table] === index) {
      newOpen[table] = -1
      setOpen(newOpen)
    } else {
      newOpen[table] = index
      setOpen(newOpen)
    }
  }

  const columnWidths = {
    arrowDown: {
      width: 10,
      maxWidth: 10,
    },
    name: {
      width: 160,
      maxWidth: 160,
    },
    category: {
      width: 80,
      maxWidth: 80,
    },
    articleNumber: {
      width: 60,
      maxWidth: 60,
    },
    requestNow: {
      width: 60,
      maxWidth: 60,
    },
  }

  if (productData.error) {
    return <div>{t('product:error.fetchingProducts')}</div>
  }

  if (productData.loading) {
    return <LoadingSkeleton />
  }

  if (isMobile) {
    return (
      <Page marginBottom="200px">
        <h1>{t('product:overview.yourProducts')}</h1>
        {Object.entries(productData.data).map((value: any, index) => {
          const products: any[] = value[1]
          return (
            <div key={index}>
              <Typography>{userEmails[index]}</Typography>
              {products.length === 0 ? (
                //Show Empty-Page-Info when there are no products yet
                <Typography align="center" color="text.secondary" sx={{ fontSize: 13 }}>
                  {t('product:error.noProductsFound')}
                </Typography>
              ) : (
                //Show Products
                products.map((product: Product, index: number) => {
                  const rootComponents: any = product?.Components?.items.filter(
                    (x: any) => x.root === true
                  )
                  const sortedRootComponents: any[] = rootComponents.sort((a: any, b: any) => {
                    const dateA: any = new Date(a.createdAt)
                    const dateB: any = new Date(b.createdAt)
                    return dateA - dateB
                  })
                  const category = sortedRootComponents[0]?.ManufacturingProcess?.category
                  return (
                    <MobileListItem
                      key={index}
                      primaryText={product.name}
                      index={index}
                      onClick={() => history.push('/products/' + product?.id)}
                      buttonWhenClosed={t('mobile.showDetails')}
                      buttonWhenOpened={t('mobile.hideDetails')}
                    >
                      <br />
                      {`${t('product:overview.category')}: ${category ?? ''}`}
                      <br />
                      {`${t('product:overview.articleNumber')}: ${
                        product.article_number ? product.article_number : ''
                      }`}
                    </MobileListItem>
                  )
                })
              )}
            </div>
          )
        })}
        <FAB type={'ADD'} onClick={() => history.push('/products/new')} />
      </Page>
    )
  }
  return (
    <Page>
      {Object.entries(productData.data).length === 0 ? (
        <Typography align="center" color="text.secondary" sx={{ fontSize: 13 }}>
          {t('product:error.noProductsFound')}
        </Typography>
      ) : (
        Object.entries(productData.data).map((value: any, idx: number) => {
          const products: any[] = value[1]
          return (
            <div key={idx}>
              <Typography variant="h5">{t('product:overview.productList')}</Typography>
              <Card>
                <CardContent>
                  <Typography variant="h5">{userEmails[idx]}</Typography>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="right" />
                        <TableCell align="right">{t('name')}</TableCell>
                        <TableCell align="right">{t('product:overview.category')}</TableCell>
                        <TableCell align="right">{t('articleNumber')}</TableCell>
                        <TableCell align="right"> </TableCell>
                      </TableRow>
                    </TableHead>
                    {products.map((product: Product, index: number) => {
                      const rootComponents: any = product?.Components?.items.filter(
                        (x: any) => x.root === true
                      )
                      const sortedRootComponents: any[] = rootComponents.sort((a: any, b: any) => {
                        const dateA: any = new Date(a.createdAt)
                        const dateB: any = new Date(b.createdAt)
                        return dateA - dateB
                      })
                      const category = sortedRootComponents[0]?.ManufacturingProcess?.category

                      console.log()
                      return (
                        <React.Fragment key={index}>
                          <TableRow
                            hover={true}
                            onClick={() =>
                              copyOldProduct ? null : history.push('/products/' + product?.id)
                            }
                          >
                            <TableCell
                              sx={{
                                width: columnWidths.arrowDown.width,
                                maxWidth: columnWidths.arrowDown.maxWidth,
                              }}
                            >
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={e => {
                                  e.stopPropagation()
                                  handleOpenItem(index, idx)
                                  setOpenProduct(product)
                                }}
                              >
                                {open[idx] === index ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                width: columnWidths.name.width,
                                maxWidth: columnWidths.name.maxWidth,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {product.name}
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                width: columnWidths.category.width,
                                maxWidth: columnWidths.category.maxWidth,
                              }}
                            >
                              {category ?? ''}
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                width: columnWidths.articleNumber.width,
                                maxWidth: columnWidths.articleNumber.maxWidth,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {product.article_number}
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                width: columnWidths.requestNow.width,
                                maxWidth: columnWidths.requestNow.maxWidth,
                              }}
                            >
                              {' '}
                              <Button
                                style={{ textTransform: 'none' }}
                                variant="contained"
                                onClick={() =>
                                  copyOldProduct
                                    ? copySelectedProduct(product.id)
                                    : orderProduct(product)
                                }
                              >
                                {copyOldProduct ? t('copy') : t('requestNow')}
                              </Button>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                              <Collapse in={open[idx] === index} timeout="auto" unmountOnExit>
                                <ProductPage productIdInOverview={openProduct?.id} />
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      )
                    })}
                  </Table>
                </CardContent>
              </Card>
            </div>
          )
        })
      )}
      <FAB type="ADD" onClick={() => setOpenOptionsModal(!openOptionsModal)} />
      <ProductOptionsModal
        open={openOptionsModal}
        onClose={() => setOpenOptionsModal(!openOptionsModal)}
        onCreate={() => history.push('/products/new')}
        onCopy={() => setCopyOldProduct(true)}
      />
    </Page>
  )
}
