import React from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import { Fab, Grow, Theme, Tooltip } from '@mui/material'
import { Add } from '@mui/icons-material'
import { Edit } from '@mui/icons-material'
import { useTranslate } from 'mui-color'
import { useTranslation } from 'react-i18next'

type Props = {
  type: 'ADD' | 'EDIT'
  onClick: () => void
  hidden?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: 'fixed',
      bottom: 70,
      right: theme.spacing(2),
    },
  })
)

export default function FAB(props: Props) {
  const classes = useStyles()
  const { type, hidden, onClick } = props
  const { t } = useTranslation()

  if (hidden) {
    return null
  }

  return (
    <Grow in={!hidden}>
      <Tooltip
        title={type === 'ADD' ? (t('addElement') as string) : (t('editElement') as string)}
        placement="left"
      >
        <Fab hidden={true} className={classes.fab} color="primary" onClick={() => onClick()}>
          {type === 'ADD' ? <Add /> : <Edit />}
        </Fab>
      </Tooltip>
    </Grow>
  )
}
