import { useState, useEffect } from 'react'

const useQuery = (queryName: string, promise: any) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<any>(null)
  const [error, setError] = useState(false)

  useEffect(() => {
    handlePromise(queryName, promise)
  }, [])

  async function handlePromise(gqlOperation: string | undefined, promise: any) {
    try {
      const data = await promise
      setLoading(false)
      setData(data)
    } catch (error) {
      setLoading(false)
      setError(true)
      console.log('Error on Query: ' + gqlOperation)
    }
  }

  return { data, error, loading }
}

export default useQuery
