// write basic functional component
import React from 'react'
import { IconButton, Table, TableCell, TableHead, TableRow } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { formatBytes } from '../../utils/functions'
import { useTranslation } from 'react-i18next'

type File = {
  name: string
  size: number
  path: string
  type: string
  lastModified: number
  webkitRelativePath: string
}

type Props = {
  fileList: File[]
  setFileList: (fileList: File[]) => void
}

export default function UnauthFileList(props: Props) {
  const { fileList, setFileList } = props
  const { t } = useTranslation()

  const deleteFile = (name: string) => {
    // delete file from fileList
    const key = fileList.findIndex(file => file.path === name)
    fileList.splice(key, 1)
    setFileList([...fileList])
  }

  const files = fileList.map((file, index) => {
    // return files in a table row
    console.log(file)
    return (
      <TableRow key={index}>
        <TableCell>{file.name}</TableCell>
        <TableCell>{formatBytes(file.size)}</TableCell>
        <TableCell>
          <IconButton onClick={() => deleteFile(file.path)}>
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
    )
  })

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('name')}</TableCell>
          <TableCell>{t('size')}</TableCell>
          <TableCell>{t('delete')}</TableCell>
        </TableRow>
      </TableHead>
      {files}
    </Table>
  )
}
