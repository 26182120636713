import { Auth } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import S3FileUpload from '../../../components/inputs/S3FileUpload'
import TextfieldModal from '../../../components/widgets/NewFolderDialog'
import { downloadBlob, handlePromise } from '../../../utils/functions'
import AccessFilesModal from '../../../components/widgets/AccessFilesModal'
import { useTranslation } from 'react-i18next'
import { Storage } from '@aws-amplify/storage'
import { Filesystem } from './Filesystem'
import { RenderTree } from '../utils/types'
import { convertToRenderTree, getChildrenAtDepth, processStorageList } from '../utils/functions'
import { useNotifications } from '../../Feedback'
import CADFileUpload from '../../../components/inputs/CADFileUpload'
import {
  S3Client,
  ListObjectsCommand,
  DeleteObjectsCommand,
  ListObjectsV2Command,
  GetObjectCommand,
  PutObjectCommand,
} from '@aws-sdk/client-s3'
import { getSignedUrl } from '@aws-sdk/s3-request-presigner'

/**
 * Logic needed to make whole Filesystem work
 * Handles:
 *  - uploads
 *  - Navigation paths and history
 *  - deleting/downloading files
 *  - creating new folders
 */
export function FilesystemLogic() {
  const [user, setUser] = useState<any | null>(null) // cognito user
  // cognito user credentials used for accessing s3 bucket
  const [credentials, setCredentials] = useState<any | null>(null)
  // identityIds of users stored in a email:IdentityId pair
  // to help get right paths for admin uploads
  const [identityIds, setIdentityIds] = useState<any>({})
  // userGroups to detect if user is admin or not
  const [groups, setUserGroups] = useState<any | null>(null)
  // files with RenderTree file structure
  const [files, setFiles] = useState<RenderTree[]>([])
  // depth in file structure to determine current position
  const [depth, setDepth] = useState<number>(1)
  // path that is currently used to show files on path
  const [path, setPath] = useState<string>('')
  // history saved for navigating backward and forward
  const [history, setHistory] = useState<any>({})
  // current files shown at the current depth and path
  const [currentFiles, setCurrentFiles] = useState<RenderTree[]>([])
  // reload files after uploading or deleting
  const [reloaded, reloadFiles] = useState<boolean>(false)
  // selected files to delete/download
  const [tickedFiles, setTickedFiles] = useState<RenderTree[]>([])
  const [openNewFolderDialog, setNewFolderDialog] = useState<boolean>(false)
  const [openDeleteDialog, setDeleteDialog] = useState<boolean>(false)
  const [newFolderName, setNewFolderName] = useState<string>('')
  const { addNotification } = useNotifications()
  const { t } = useTranslation()

  // Set the AWS Region.
  const REGION = process.env.REACT_APP_REGION
  const bucketParams = { Bucket: process.env.REACT_APP_BUCKET_NAME }
  // Create an Amazon S3 service client object.
  const s3Client = new S3Client({ region: REGION, credentials: credentials })

  useEffect(() => {
    fetchUser()
    fetchUserCredentials()
  }, [])

  useEffect(() => {
    // load files as admin or user after reloading
    if (credentials && user && user.attributes && user.attributes.email) {
      const userGroups: string[] | null = user.signInUserSession
        ? user?.signInUserSession?.accessToken?.payload['cognito:groups']
        : null
      if (userGroups && userGroups.includes('AdminS3')) {
        setUserGroups(userGroups)
        fetchFilesAdmin()
      } else {
        fetchFiles(user.attributes.email)
      }
    }
  }, [reloaded])

  useEffect(() => {
    // load files as admin or user
    if (credentials && user && user.attributes && user.attributes.email) {
      const userGroups: string[] | null =
        user?.signInUserSession?.accessToken?.payload['cognito:groups']
      if (userGroups && userGroups.includes('AdminS3')) {
        setUserGroups(userGroups)
        fetchFilesAdmin()
      } else {
        fetchFiles(user.attributes.email)
        setPath(user.attributes.email)
      }
    }
  }, [user, credentials])

  useEffect(() => {
    // show contents of folder that was clicked at
    setCurrentFiles(getChildrenAtDepth(depth, path, files, 0, []))
  }, [depth])

  useEffect(() => {
    // create new folder as admin or user
    if (typeof newFolderName !== 'undefined' && newFolderName.length !== 0) {
      groups && groups.includes('AdminS3')
        ? createFolderAdmin(newFolderName)
        : createFolder(newFolderName)
      setNewFolderName('')
    }
  }, [newFolderName])

  async function fetchUser() {
    const [res, error] = await handlePromise(
      'getCurrentAuthenticatedUser',
      Auth.currentAuthenticatedUser()
    )
    res ? setUser(res) : console.log('Error on fetching current authenticated user')
  }

  async function fetchUserCredentials() {
    const [res, error] = await handlePromise('getCurrentCredentials', Auth.currentCredentials())
    res ? setCredentials(res) : console.log('Error on fetching current user credentials')
  }

  /* ------------------------------------ Begin of user actions---------------------------------- */
  /**
   * Fetch files with amplify storage api
   * @param filePath
   */
  async function fetchFiles(filePath: string) {
    const [res, err] = await handlePromise(
      'listFiles',
      Storage.list(filePath, { level: 'private' })
    )
    if (res) {
      // TODO: Write function that returns added sizes of folders children
      /*console.log(getSizeAtDepth(depth, path, processStorageList(res)))*/
      setFiles(processStorageList(res)) // set files state with the RenderTree structure
      // set currentFiles with files/folders at the right depth
      setCurrentFiles(getChildrenAtDepth(depth, path, processStorageList(res), 0, []))
    } else {
      console.log('Error fetching files for logged in user')
    }
  }

  /**
   * Create a new folder by creating a empty file with a
   * filepath that ends with "/"
   * @param folderName
   */
  async function createFolder(folderName: string) {
    if (user && user.attributes && user.attributes.email) {
      const [data, error] = await handlePromise(
        'uploadFile',
        Storage.put(
          path.slice(-1) === '/' ? path + folderName + '/' : path + '/' + folderName + '/',
          '',
          {
            level: 'private',
            progressCallback(progress: any) {
              console.log(`Uploaded: ${progress.loaded}/${progress.total}`)
            },
          }
        )
      )
      data
        ? addNotification({
            message: t('common:success.uploadedFileSuccessfully'),
            color: 'success',
          })
        : addNotification({ message: t('common:error.errorOccurred'), color: 'error' })
      if (user && user.attributes && user.attributes.email) {
        fetchFiles(user.attributes.email)
      }
    }
  }

  /**
   * Download one or more multiple files in parallel
   * @param files
   */
  async function downloadFiles(files: RenderTree[]) {
    const results = await Promise.all(
      files.map(file => {
        return downloadFile(file)
      })
    )
    results.includes(false)
      ? addNotification({ message: t('common:error.errorOccurred'), color: 'error' })
      : addNotification({ message: t('common:success.changesSavedSuccessfully'), color: 'success' })
    setTickedFiles([])
  }

  /**
   * Download file using a blob thats returned from amplify storage api
   * @param file
   * @returns status of download
   */
  async function downloadFile(file: RenderTree) {
    try {
      const result = await Storage.get(file.path, { download: true, level: 'private' })
      downloadBlob(result.Body, file.path)
      return true
    } catch (err) {
      console.log('Error on downloading file', err)
      return false
    }
  }

  /**
   * Delete multiple files in parallel using amplify storage api
   * @param files
   */
  async function deleteFiles(files: RenderTree[]) {
    const results = await Promise.all(
      files.map((value: RenderTree) => {
        if (value.children && value.children.length > 0) {
          return deleteFolder(value)
        } else {
          return deleteFile(value)
        }
      })
    )
    if (user && user.attributes && user.attributes.email) {
      fetchFiles(user.attributes.email)
    }
    results.includes(false)
      ? addNotification({ message: t('common:error.errorOccurred'), color: 'error' })
      : addNotification({ message: t('common:success.changesSavedSuccessfully'), color: 'success' })
    setTickedFiles([])
    setHistory({})
  }

  /**
   * Delete file with amplify storage api
   * @param file
   * @returns status of deletion
   */
  const deleteFile = async (file: RenderTree) => {
    console.log(file)
    const [result, error] = await handlePromise(
      'removeFile',
      Storage.remove(file.children && file.children?.length > 0 ? file.path + '/' : file.path, {
        level: 'private',
      })
    )
    console.log('Deleting File Result: ', result)
    return !!result
  }

  const deleteFolder = async (folder: RenderTree) => {
    const [result, error] = await handlePromise(
      'listFiles',
      Storage.list(folder.path, { level: 'private' })
    )
    if (result) {
      const convertedResult: RenderTree[] = result.map((value: any) => {
        return convertToRenderTree(value)
      })
      deleteFiles(convertedResult)
    } else {
      console.log('Error on listing files on folder ', error)
      return false
    }
  }

  /* ------------------------------------ End of user actions---------------------------------- */
  /* ------------------------------------ Begin of admin actions------------------------------- */
  function getIdentityIds(result: any[]) {
    const identityIdObject: any = {}
    result.map((value: any) => {
      const key: string = value.key
      const identityId = key.split('/')[0]
      const email = key.split('/')[1]
      identityIdObject[email] = identityId
    })
    return identityIdObject
  }
  /**
   * fetch files as admin with aws s3-sdk, that gets all contents of the s3-bucket
   * @returns data for unit tests
   */
  const fetchFilesAdmin = async () => {
    try {
      const data = await s3Client.send(new ListObjectsCommand(bucketParams))
      const res: any[] = data.Contents as any[]
      // make higher case attributes to lowercase to use processStorageList function
      res.forEach(obj => {
        obj.lastModified = obj.LastModified
        obj.size = obj.Size
        obj.key = obj.Key
        delete obj.Key
        delete obj.LastModified
        delete obj.Size
      })
      const result = res.map(value => {
        {
          return {
            ...value,
            key: value.key.replace('private/', ''),
          }
        }
      })
      setIdentityIds(getIdentityIds(result))
      setFiles(processStorageList(result))
      setCurrentFiles(getChildrenAtDepth(depth, path, processStorageList(result), 0, []))
      return data // For unit tests.
    } catch (err) {
      console.log('Error', err)
    }
  }

  /**
   * Create a folder as admin, which is possible only inside users folders
   * Cant create public folders, which would be unwanted
   * @param folderName
   */
  async function createFolderAdmin(folderName: string) {
    console.log('private' + '/' + identityIds[path.split('/')[1]] + path + folderName + '/')
    try {
      const result = await s3Client.send(
        new PutObjectCommand({
          Bucket: process.env.REACT_APP_BUCKET_NAME,
          Key:
            path.slice(-1) === '/'
              ? 'private' + '/' + identityIds[path.split('/')[1]] + path + folderName + '/'
              : 'private' + '/' + identityIds[path.split('/')[1]] + path + '/' + folderName + '/',
          Body: '',
        })
      )
      console.log('Success: ', result)
      fetchFilesAdmin()
    } catch (err) {
      console.log('Error: ', err)
    }
  }

  /**
   * Download file as admin using blob returned by aws s3-sdk
   * @param file
   * @returns status of download
   */
  const downloadFileAdmin = async (file: RenderTree) => {
    try {
      const command = new GetObjectCommand({
        Key: file.path,
        Bucket: process.env.REACT_APP_BUCKET_NAME,
      })
      const signedURL = await getSignedUrl(s3Client, command, {
        expiresIn: 3600,
      })
      const response = await fetch(signedURL)
      const blob = await response.blob()
      downloadBlob(blob, file.path)
      return true
    } catch (err) {
      console.log('Error', err)
      return false
    }
  }

  /**
   * Download multiple files in parallel using aws s3-sdk
   * @param files
   */
  const downloadFilesAdmin = async (files: RenderTree[]) => {
    const results = await Promise.all(
      files.map(file => {
        return downloadFileAdmin(file)
      })
    )
    results.includes(false)
      ? addNotification({ message: t('common:error.errorOccurred'), color: 'error' })
      : addNotification({ message: t('common:success.changesSavedSuccessfully'), color: 'success' })
    setTickedFiles([])
  }

  const deleteFilesAndFoldersAdmin = async (filesAndFolders: RenderTree[]) => {
    const filesArray: RenderTree[] = []
    const foldersArray: RenderTree[] = []
    filesAndFolders.map((value: RenderTree) => {
      if (value.children && value.children.length > 0) {
        foldersArray.push(value)
      } else {
        filesArray.push(value)
      }
    })
    if (filesArray.length > 0) {
      deleteFilesAdmin(filesArray)
    }
    if (foldersArray.length > 0) {
      deleteFolders(foldersArray)
    }
  }

  const deleteFolders = async (folders: RenderTree[]) => {
    Promise.all(
      folders.map(async folder => {
        const list = await listObjectsRecursive('private/' + folder.path)
        const folderKeys = list.map(folder => folder.Key)
        const deletedFolders = await deleteKeys(folderKeys)
      })
    )
    fetchFilesAdmin()
    setTickedFiles([])
    setHistory({})
  }

  /**
   * Delete multiple files as admin with aws s3-sdk
   * @param files
   * @returns status of deletion
   */
  const deleteFilesAdmin = async (files: RenderTree[]) => {
    const filesArray: string[] = files.map(file => {
      const keyObj: any = {
        Key: 'private/' + file.path,
      }
      return keyObj
    })
    if (filesArray.length > 0) {
      const deleteObject: any = { Objects: filesArray }
      try {
        const result = await s3Client.send(
          new DeleteObjectsCommand({
            Bucket: process.env.REACT_APP_BUCKET_NAME,
            Delete: deleteObject,
          })
        )
        fetchFilesAdmin()
        setTickedFiles([])
        setHistory({})
        return true
      } catch (err) {
        console.log('Error: ', err)
        setTickedFiles([])
        setHistory({})
        return false
      }
    }
  }

  /**
   * Get all keys recurively
   * @param Prefix
   * @returns
   */
  async function listObjectsRecursive(Prefix: string, ContinuationToken?: string): Promise<any[]> {
    // Get objects for current prefix
    const listObjects = await s3Client.send(
      new ListObjectsV2Command({
        Delimiter: '/',
        Bucket: process.env.REACT_APP_BUCKET_NAME,
        Prefix,
        ContinuationToken,
      })
    )

    let deepFiles, nextFiles

    // Recurive call to get sub prefixes
    if (listObjects.CommonPrefixes) {
      const deepFilesPromises = listObjects.CommonPrefixes.flatMap(({ Prefix }) => {
        return listObjectsRecursive(Prefix as string)
      })

      deepFiles = (await Promise.all(deepFilesPromises)).flatMap(t => t)
    }

    // If we must paginate
    if (listObjects.IsTruncated) {
      nextFiles = await listObjectsRecursive(Prefix, listObjects.NextContinuationToken)
    }

    return [...(listObjects.Contents || []), ...(deepFiles || []), ...(nextFiles || [])]
  }

  async function deleteKeys(keys: string[]): Promise<any[]> {
    function spliceIntoChunks(arr: any[], chunkSize: number) {
      const res = []
      while (arr.length > 0) {
        const chunk = arr.splice(0, chunkSize)
        res.push(chunk)
      }
      return res
    }
    const allKeysToRemovePromises = keys.map(k => listObjectsRecursive(k))
    const allKeysToRemove = (await Promise.all(allKeysToRemovePromises)).flatMap(k => k)
    const allKeysToRemoveGroups = spliceIntoChunks(allKeysToRemove, 3)
    const deletePromises = allKeysToRemoveGroups.map(group => {
      return s3Client.send(
        new DeleteObjectsCommand({
          Bucket: process.env.REACT_APP_BUCKET_NAME,
          Delete: {
            Objects: group.map(({ Key }) => {
              return {
                Key,
              }
            }),
          },
        })
      )
    })
    const results = await Promise.all(deletePromises)
    return results.flatMap(({ $metadata, Deleted }) => {
      return Deleted?.map(({ Key }) => {
        return {
          status: $metadata.httpStatusCode,
          key: Key,
        }
      })
    })
  }

  /* ------------------------------------ End of admin actions------------------------------- */

  const onFileClick = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    file: RenderTree
  ) => {
    if (file.children && file.children.length > 0) {
      // update history, path and depth for navigation
      setHistory({
        path: path.slice(-1) === '/' ? path + file.name + '/' : path + '/' + file.name + '/',
        depth: depth + 1,
      })
      setPath(path.slice(-1) === '/' ? path + file.name + '/' : path + '/' + file.name + '/')
      setDepth(depth + 1)
      setTickedFiles([])
    } else {
      event.stopPropagation()
      updateTickedStatus(file)
    }
  }

  const onDownloadFileClick = () => {
    groups && groups.includes('AdminS3')
      ? downloadFilesAdmin(tickedFiles)
      : downloadFiles(tickedFiles)
  }

  const onDeleteFileClick = () => {
    setDeleteDialog(true)
  }

  const onHistoryBackClick = () => {
    setHistory({ ...history, depth: depth })
    let newPath: string = path.slice(0, -1)
    newPath = newPath.substring(0, newPath.lastIndexOf('/'))
    setPath(newPath)
    setDepth(depth - 1)
    setTickedFiles([])
  }

  const onHistoryForwardClick = () => {
    const newPath: string[] = history.path.split('/').slice(0, history.depth + 1)
    setPath(newPath.join('/'))
    const historyEndReached: boolean = history.path.split('/').length - 2 === path.split('/').length
    setHistory({ ...history, depth: historyEndReached ? history.depth : history.depth + 1 })
    setDepth(history.depth)
    setTickedFiles([])
  }

  const onReloadClick = () => {
    groups && groups.includes('AdminS3') ? fetchFilesAdmin() : fetchFiles(user.attributes.email)
  }

  const onCreateFolderClick = () => {
    setNewFolderDialog(true)
  }

  const updateTickedStatus = (file: RenderTree) => {
    setTickedFiles(
      tickedFiles.includes(file)
        ? tickedFiles.filter(x => x.path !== file.path)
        : [...tickedFiles, file]
    )
  }

  const getTickedStatus = (file: RenderTree) => {
    //if file is ticked return index of file in tickedFiles, else return -1
    return tickedFiles.findIndex(tickedFile => tickedFile.path === file.path)
  }

  const onTickAllFilesClick = () => {
    const update = [...tickedFiles]
    currentFiles.forEach(file => {
      if (getTickedStatus(file) === -1) {
        update.push(file)
      }
      setTickedFiles(update)
    })
  }

  const onUntickAllFilesClick = () => {
    setTickedFiles([])
  }

  return (
    <div>
      <S3FileUpload
        uploadPath={path}
        reloaded={reloaded}
        reloadFiles={reloadFiles}
        isAdmin={groups && groups.includes('AdminS3')}
        credentials={credentials}
        identityIds={identityIds}
      />
      <CADFileUpload
        uploadPath={path}
        reloaded={reloaded}
        reloadFiles={reloadFiles}
        isAdmin={groups && groups.includes('AdminS3')}
        credentials={credentials}
        identityIds={identityIds}
      />
      <Filesystem
        files={currentFiles}
        currentPath={path}
        currentDepth={depth}
        history={history}
        tickedFiles={tickedFiles}
        onFileClick={onFileClick}
        onHistoryForwardClick={onHistoryForwardClick}
        onHistoryBackClick={onHistoryBackClick}
        updateTickedStatus={updateTickedStatus}
        getTickedStatus={getTickedStatus}
        onTickAllFilesClick={onTickAllFilesClick}
        onUntickAllFilesClick={onUntickAllFilesClick}
        onReloadClick={onReloadClick}
        onCreateFolderClick={onCreateFolderClick}
        /*onRenameFileClick={onRenameFileClick}*/
        onDownloadFileClick={onDownloadFileClick}
        onDeleteFileClick={onDeleteFileClick}
        allowCreateFolder={
          (path.split('/').length > 2 && groups && groups.includes('AdminS3')) || !groups
        }
        allowRename={tickedFiles.length === 1}
        allowDownload={tickedFiles.length > 0}
        allowDelete={tickedFiles.length > 0}
      />
      <TextfieldModal
        title={t('dialog.createFolder')}
        textfieldLabel={t('dialog.folderName')}
        fallbackValue={t('dialog.createFolderFallbackValue')}
        open={openNewFolderDialog}
        setOpen={setNewFolderDialog}
        fetchValueBy={setNewFolderName}
      />
      {/*<AccessFilesModal
        open={openDownloadDialog}
        setOpen={setDownloadDialog}
        title={t('dialog.downloadFiles')}
        description={t('dialog.areYouSureDownload')}
        files={tickedFiles.map(tickedFile => {
          return currentFiles.find(file => file.path === tickedFile.path) as RenderTree
        })}
        onConfirm={() => downloadFiles(tickedFiles)}
      />*/}
      <AccessFilesModal
        open={openDeleteDialog}
        setOpen={setDeleteDialog}
        title={t('dialog.deleteFiles')}
        description={t('dialog.areYouSureDeleteFiles')}
        files={tickedFiles.map(tickedFile => {
          return currentFiles.find(file => file.path === tickedFile.path) as RenderTree
        })}
        onConfirm={() =>
          groups && groups.includes('AdminS3')
            ? deleteFilesAndFoldersAdmin(tickedFiles)
            : deleteFiles(tickedFiles)
        }
      />
    </div>
  )
}
