import React from 'react'
import DetailsToolbar from './DetailsToolbar'
import { Grid, Paper } from '@mui/material'
import { Product } from '../../graphql/graphql'

type Props = {
  product?: Product
  title?: string
  editing?: boolean
  showMenu?: boolean
  justify?: any
  alignItems?: any
  hideCancel?: boolean
  onEditCancel?: () => void
  invalid?: boolean
  onSave?: () => void
  onDelete?: () => void
  children?: React.ReactNode
  CustomActions?: React.ReactNode
  TitleButton?: React.ReactNode
  hideTitleButton?: boolean
  archived?: boolean
}

/**
 * Container for different forms which can be in show or edit mode
 * Switch modes with edit/abort/save buttons to support workflow
 */
export default function PaperCard(props: Props) {
  const {
    product,
    title,
    editing,
    showMenu,
    hideCancel,
    onEditCancel = () => {},
    invalid,
    onSave = () => {},
    onDelete = () => {},
    children,
    CustomActions,
    TitleButton,
    hideTitleButton,
    justify,
    alignItems,
    archived,
  } = props

  return (
    <Paper elevation={3} style={{ marginTop: 0, marginBottom: 16, flex: 1 }}>
      {title && (
        <DetailsToolbar
          product={product}
          title={title}
          editing={editing}
          showMenu={showMenu}
          invalid={invalid}
          hideCancel={hideCancel}
          onEditCancel={onEditCancel}
          onSave={onSave}
          onDelete={onDelete}
          CustomActions={CustomActions}
          TitleButton={TitleButton}
          hideTitleButton={hideTitleButton}
          archived={archived}
        />
      )}
      <Grid
        container
        style={{ paddingLeft: 16, paddingRight: 16, paddingBottom: 16 }}
        spacing={2}
        alignItems={alignItems}
        justifyContent={justify}
      >
        {children && children}
      </Grid>
    </Paper>
  )
}
