import { Button, Grid, Tooltip } from '@mui/material'
import React from 'react'
import CreateNewFolderRoundedIcon from '@mui/icons-material/CreateNewFolderRounded'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useTranslation } from 'react-i18next'

type Props = {
  spacing?: number
  wrapAlign?: 'left' | 'right'
  onReloadClick: () => void
  onCreateFolderClick: () => void
  onRenameFileClick?: () => void
  onDownloadFileClick: () => void
  onDeleteFileClick: () => void
  allowCreateFolder: boolean
  allowRename: boolean
  allowDownload: boolean
  allowDelete: boolean
  sx?: React.CSSProperties
}

/**
 * Actions that change the Filesystem
 * Actions:
 *  - reload --> After uploading stp files it sometimes dont show the /converted folder
 *               reloading lets the user get all folders
 *  - new --> opens dialog to create a new folder
 *  - download --> download all selected files
 *  - delete --> delete all selected files
 */
export function FilesystemActions(props: Props) {
  const {
    spacing,
    wrapAlign = 'left',
    onReloadClick,
    onCreateFolderClick,
    onRenameFileClick,
    onDownloadFileClick,
    onDeleteFileClick,
    allowCreateFolder,
    allowRename,
    allowDownload,
    allowDelete,
    sx,
  } = props
  const { t } = useTranslation()
  let actionButtons = [
    {
      title: t('reload'),
      tooltip: t('product:tooltip.reload'),
      onClick: onReloadClick,
      disabled: false,
      icon: <RefreshIcon />,
    },
    {
      title: t('new'),
      tooltip: t('product:tooltip.newFolder'),
      onClick: onCreateFolderClick,
      disabled: !allowCreateFolder,
      icon: <CreateNewFolderRoundedIcon />,
    },
    {
      title: t('download'),
      tooltip: t('product:tooltip.download'),
      onClick: onDownloadFileClick,
      disabled: !allowDownload,
      icon: <DownloadRoundedIcon />,
    },
    /*{
      title: t('rename'),
      onClick: onRenameFileClick,
      disabled: !allowRename,
      icon: <EditRoundedIcon />,
    },*/
    {
      title: t('delete'),
      tooltip: t('product:tooltip.delete'),
      onClick: onDeleteFileClick,
      disabled: !allowDelete,
      icon: <DeleteForeverRoundedIcon />,
    },
  ]
  const direction = wrapAlign === 'right' ? 'rtl' : 'ltr'
  if (wrapAlign === 'right') {
    actionButtons = actionButtons.reverse()
  }

  return (
    <div style={{ ...sx, direction: direction }}>
      <Grid container spacing={spacing}>
        {actionButtons.map((actionButton, index) => {
          return (
            <Grid item key={index}>
              {actionButton.tooltip ? (
                <Tooltip title={actionButton.tooltip as string}>
                  <Button
                    onClick={actionButton.onClick}
                    disabled={actionButton.disabled}
                    variant="contained"
                    endIcon={actionButton.icon}
                    sx={{ direction: 'ltr' }}
                  >
                    {actionButton.title}
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  onClick={actionButton.onClick}
                  disabled={actionButton.disabled}
                  variant="contained"
                  endIcon={actionButton.icon}
                  sx={{ direction: 'ltr' }}
                >
                  {actionButton.title}
                </Button>
              )}
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}
