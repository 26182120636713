import { Channel, Message } from '../../../graphql/graphql'

interface MessageDate {
  year: number
  month: number
  day: number
  hour: number
  minute: number
  second: number
  millisecond: number
}

export function parseMessageDate(dateString: string): MessageDate {
  const date = dateString.split(/-|T|:|\.|Z/)
  return {
    year: parseInt(date[0]),
    month: parseInt(date[1]),
    day: parseInt(date[2]),
    hour: parseInt(date[3]),
    minute: parseInt(date[4]),
    second: parseInt(date[5]),
    millisecond: parseInt(date[6]),
  }
}

export function sortChannels(channels: Channel[], newMessages: Message[]) {
  if (newMessages.length > 0) {
    const newMessagesChannelIds: (string | null | undefined)[] = newMessages.map(
      message => message.channelID
    )
    const channelsWithNewMessages: Channel[] = channels.filter(x =>
      newMessagesChannelIds.includes(x.id)
    )
    const channelsWithoutNewMessages: Channel[] = channels.filter(
      x => !newMessagesChannelIds.includes(x.id)
    )
    return channelsWithNewMessages.concat(channelsWithoutNewMessages)
  } else {
    // sort channels after createdAt time to have the order newest to oldest
    channels.sort(function (a: Channel, b: Channel) {
      const dateA = new Date(a.createdAt).getTime()
      const dateB = new Date(b.createdAt).getTime()
      return dateA < dateB ? 1 : -1
    })
    return channels
  }
}
