/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { Autocomplete } from '@mui/lab'
import { TextField, TextFieldProps } from '@mui/material'

type Props = {
  name: string
  value: string
  errorMessage?: string
  options: { value: string; label?: string; disabled?: boolean }[]
  disabled?: boolean
  onChange: (value: string | null) => void
  validation?: boolean
}

export default function SearchSelectInput<T>(props: Props) {
  const { name, value, options, disabled, errorMessage, validation, onChange } = props

  const getOptionLabel = (value: string) =>
    options.find(option => option.value === value)?.label || value

  const isOptionSelected = (option: string, value: string) => option === value

  const [error, setError] = useState<boolean>(validation !== undefined && value === '')

  return (
    <Autocomplete
      id="name"
      disabled={disabled}
      options={options.map(x => x.value)}
      value={value as any}
      /*getOptionSelected={isOptionSelected}*/
      getOptionLabel={getOptionLabel}
      onChange={(_: any, option: any) => {
        onChange(option)
        option !== '' ? setError(false) : setError(true)
      }}
      renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
        <TextField
          {...params}
          error={error}
          helperText={error && errorMessage}
          label={name}
          variant="outlined"
        />
      )}
    />
  )
}
