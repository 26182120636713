import React, { useEffect, useState } from 'react'
import { FormControl, FormHelperText, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
  value: string
  label: string
  labelWidth?: number
  onChange: (value: string) => void
  validation?: boolean
  errorMessage?: string
  disabled?: boolean
  multiline?: boolean
}

export default function TextInput(props: Props) {
  const {
    label,
    value = '',
    onChange,
    labelWidth = 100,
    disabled = false,
    errorMessage = '',
    validation,
    multiline,
  } = props

  const { t } = useTranslation()
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    validation !== undefined && value === '' ? setError(true) : setError(false)
  })

  return (
    <FormControl fullWidth variant="outlined">
      <TextField
        id={label}
        variant="outlined"
        multiline={multiline}
        rows={multiline ? 4 : 1}
        label={label}
        value={value || ''}
        onChange={e => {
          e.currentTarget.value !== '' || validation === undefined
            ? setError(false)
            : setError(true)
          onChange(e.currentTarget.value)
        }}
        autoComplete="text"
        disabled={disabled}
        error={error}
        aria-describedby="text-input"
        inputProps={{
          'aria-label': 'weight',
        }}
      />
      {error && (
        <FormHelperText error variant="outlined">
          {t(errorMessage)}
        </FormHelperText>
      )}
    </FormControl>
  )
}
