import { Address } from '../../../graphql/graphql'

/*export const emptyAddress = (): Omit<Address, 'id'> => {
  return {
    name: '',
    city: '',
    zip_code: '',
    address_1: '',
    address_2: '',
    country: '',
    main: false,
    type: 'TODO',
  }
}*/

export const getAddressText = (address: Address) =>
  `${address.name}, ${address.address_1}, ${address.zip_code}`

/**
 * Parses custom User attributes from "custom:attribute" to "attribute" so that its consistent with the Profile type
 * @param attr Attributes that are going to be parsed
 * */
export function parseUserAttr(attr: any) {
  const userAttr: any = {}
  Object.entries(attr).map((value, index) => {
    if (value[0] === 'custom:contact_way') {
      value[0] = value[0].replace('custom:contact_way', 'preferred_contact_way')
      userAttr[value[0]] = value[1]
    } else if (value[0].includes('custom:')) {
      value[0] = value[0].replace('custom:', '')
      userAttr[value[0]] = value[1]
    }
  })
  return userAttr
}
