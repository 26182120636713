/* eslint-disable import/prefer-default-export */

import { Readable } from 'stream'
import { saveAs } from 'file-saver'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import { sendEmail } from '../graphql/mutations'

/**
 * Check the current navigation item is active
 * @param targetPath the target path
 * @param exact whether only the exact link should be equal
 * @param currentPath the current path of the brwoser
 * @returns true if the current path equals the targeted path
 */
export const isNavActive = (targetPath: string, exact: boolean, currentPath: string) =>
  exact ? currentPath === targetPath : currentPath.startsWith(targetPath)

/**
 * Filtering utility function with correct typing
 * Returns true if the value is not falsy e.g. not null or undefined
 * @param value to be checked
 * @returns boolean whether the value is not falsy
 */
export const notFalsy = <T>(value: T): value is NonNullable<typeof value> => !!value

/**
 * Standardize error handling for try catch Blocks to have less redundant code
 * @param gqlOperation string to on what operation error happened
 * @param promise Operation that gets handled
 * */
export async function handlePromise(gqlOperation: string | undefined, promise: any) {
  try {
    const data = await promise
    return [data, null]
  } catch (error) {
    gqlOperation !== undefined
      ? console.log('Error on ' + gqlOperation)
      : console.log('Error handling promise')
    console.log(error)
    return [null, error]
  }
}

/**
 * Filters all Items of queries which have already been deleted
 * @param list of items returned by query
 * */
/*export function notDeleted(list: any) {
  return list.filter((value: any) => value._deleted === null)
}*/

/**
 * Filters all Items that are owned by a specific user
 * @param owner of that item
 * @param list of items returned by query
 * */
export function ownedBy(owner: string, list: any) {
  return list.filter((value: any) => value.owner === owner)
}

/**
 * Formats given bytes into human-readable sizes
 * @param bytes number of bytes
 * @param decimals number of decimals displayed
 * */
export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

/**
 * Downloads file from a given Blob Object and a filename
 * @param blob object to download
 * @param filename filename to save as
 * */
export function downloadBlob(
  blob: Blob | Readable | ReadableStream<any> | undefined,
  filename: string
) {
  const url = URL.createObjectURL(blob as Blob)
  const a = document.createElement('a')
  a.href = url
  a.download = filename || 'download'
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url)
      a.removeEventListener('click', clickHandler)
    }, 150)
  }
  a.addEventListener('click', clickHandler, false)
  a.click()
  return a
}

/**
 * Download multiple blobs
 * @param blobs download objects
 */
export function downloadBlobs(
  blobs: Blob[] | Readable[] | ReadableStream<any>[] | undefined /*, filenames: string[]*/
) {
  blobs?.map((blob, index) => {
    const url: any = URL.createObjectURL(blob as Blob)
    fetch(url.download)
      .then(res => res.blob())
      .then(blob => {
        saveAs(blob, url.filename)
      })
  })
}

/**
 * Feedback for operations with graphql like fetch, delete, update, create
 * @param res Result of gql operation
 * @param addNotification hook function for notification widget
 * @param t hook function for translation
 * */
export function feedbackForGraphQL(res: any, addNotification: any, t: any) {
  res
    ? addNotification({ message: t('success.changesSavedSuccessfully'), color: 'success' })
    : addNotification({ message: t('error.errorOccurred'), color: 'error' })
}

/**
 * Group List of objects by a property
 * @param xs source object
 * @param prop property to group by
 * @returns object that is grouped by a property e.g. products grouped by owner
 * */
export function groupBy(xs: any, prop: string) {
  const grouped: any = {}
  for (let i = 0; i < xs.length; i++) {
    const p = xs[i][prop]
    if (!grouped[p]) {
      grouped[p] = []
    }
    grouped[p].push(xs[i])
  }
  return grouped
}

export async function sendInfoEmail(userEmail: string, subject: string, body: string) {
  console.log(typeof userEmail)
  const [res, err] = await handlePromise(
    'sendEmail',
    API.graphql(
      graphqlOperation(sendEmail, {
        to: userEmail,
        subject: subject,
        body: body,
      })
    )
  )
  res ? console.log(res) : console.log('Error on sending email: ', err)
}

/**
 * Uses admin queries api to fetch user emails with their user id
 * @param username alphanumerical id of that user
 * @param user current user
 * @param t hook function for translation
 */
export async function getUserEmail(username: string, user: any, t: any) {
  if (username === 'null') {
    return t('product:overview.yourProducts')
  } else {
    const apiName = 'AdminQueries'
    const path = '/getUser'
    const myInit = {
      queryStringParameters: {
        UserPoolId: user.pool?.userPoolId,
        username: username,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    }
    try {
      const result = await API.get(apiName, path, myInit)
      const userEmail: string = Object.keys(result.UserAttributes)
        .filter(key => result.UserAttributes[key]['Name'] === 'email')
        .map(key => result.UserAttributes[key]['Value'])[0]
      return userEmail
    } catch (err) {
      console.log('Error on getting user email', err)
    }
  }
}
