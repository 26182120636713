import React from 'react'
import { isValidEmail } from '../../utils/validation'
import { useTranslation } from 'react-i18next'
import { FormControl, FormHelperText, InputLabel, OutlinedInput } from '@mui/material'

type Props = {
  email: string
  setEmail: (email: string) => void
  autoFocus?: boolean
  disabled?: boolean
}

export default function EmailInput(props: Props) {
  const { email, setEmail, disabled, autoFocus = false } = props
  const { t } = useTranslation()

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel htmlFor="email">{t('auth.emailAddress')}</InputLabel>
      <OutlinedInput
        id="E-Mail"
        value={email || ''}
        onChange={e => setEmail(e.currentTarget.value)}
        autoComplete="email"
        disabled={true}
        autoFocus={autoFocus}
        error={email !== '' && !isValidEmail(email)}
        aria-describedby="email-input"
        inputProps={{
          'aria-label': 'weight',
        }}
        label={t('auth.emailAddress')}
      />
      {email !== '' && !disabled && !isValidEmail(email) && (
        <FormHelperText error variant="outlined">
          E-Mail-Adresse ist ungültig
        </FormHelperText>
      )}
    </FormControl>
  )
}
