/* eslint-disable @typescript-eslint/no-var-requires */
export const Printing3DIcon =
  require('../assets/manufacturingIcons/Icon_AdditiveFertigung.png').default
export const SheetMetalIcon = require('../assets/manufacturingIcons/Icon_Blech.png').default
export const CNCMachineIcon = require('../assets/manufacturingIcons/Icon_CNC.png').default
export const InjectionMouldingIcon = require('../assets/manufacturingIcons/Icon_Guss.png').default
export const FuegenIcon = require('../assets/manufacturingIcons/Icon_Fuegen.png').default
export const AssemblyIcon = require('../assets/manufacturingIcons/Icon_Montage.png').default
export const PostprocessingIcon =
  require('../assets/manufacturingIcons/Icon_Nachbearbeitung.png').default
export const QCIcon = require('../assets/manufacturingIcons/Icon_QC.png').default
export const SourcedPartIcon = require('../assets/manufacturingIcons/Icon_Sourcing.png').default
export const customProcessIcon =
  require('../assets/manufacturingIcons/Icon_Benutzerdefiniert.png').default
export const customPartIcon =
  require('../assets/manufacturingIcons/Icon_eigeneBauteile.png').default
export const rawMaterialIcon = require('../assets/manufacturingIcons/Icon_Rohmaterial.png').default
export const standardPartIcon = require('../assets/manufacturingIcons/Icon_Standard.png').default
export const purchasedPartIcon =
  require('../assets/manufacturingIcons/Icon_Zukaufteile.png').default
export const Logo = require('../assets/logo.png').default
export const smallLogo = require('../assets/small_logo.png').default
