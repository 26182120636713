/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type UpdateComponentInput = {
  id: string,
  name?: string | null,
  root?: boolean | null,
  description?: string | null,
  dimensions?: DimensionsInput | null,
  article_number?: string | null,
  barcode_type?: string | null,
  barcode_content?: string | null,
  tolerances?: string | null,
  weight?: string | null,
  thread_count?: number | null,
  masking?: string | null,
  certificates?: Array< string | null > | null,
  comment?: string | null,
  comment_internal?: string | null,
  costs?: string | null,
  manufacturing_data?: string | null,
  productID?: string | null,
  manufacturingprocessID?: string | null,
};

export type DimensionsInput = {
  bounding_box_x?: string | null,
  bounding_box_y?: string | null,
  bounding_box_z?: string | null,
  volume?: string | null,
};

export type ModelComponentConditionInput = {
  name?: ModelStringInput | null,
  root?: ModelBooleanInput | null,
  description?: ModelStringInput | null,
  article_number?: ModelStringInput | null,
  barcode_type?: ModelStringInput | null,
  barcode_content?: ModelStringInput | null,
  tolerances?: ModelStringInput | null,
  weight?: ModelStringInput | null,
  thread_count?: ModelIntInput | null,
  masking?: ModelStringInput | null,
  certificates?: ModelStringInput | null,
  comment?: ModelStringInput | null,
  comment_internal?: ModelStringInput | null,
  costs?: ModelStringInput | null,
  manufacturing_data?: ModelStringInput | null,
  productID?: ModelIDInput | null,
  manufacturingprocessID?: ModelIDInput | null,
  and?: Array< ModelComponentConditionInput | null > | null,
  or?: Array< ModelComponentConditionInput | null > | null,
  not?: ModelComponentConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type Component = {
  __typename: "Component",
  id: string,
  name: string,
  root?: boolean | null,
  description?: string | null,
  dimensions?: Dimensions | null,
  article_number?: string | null,
  barcode_type?: string | null,
  barcode_content?: string | null,
  tolerances?: string | null,
  weight?: string | null,
  thread_count?: number | null,
  masking?: string | null,
  certificates?: Array< string | null > | null,
  comment?: string | null,
  comment_internal?: string | null,
  costs?: string | null,
  manufacturing_data?: string | null,
  productID?: string | null,
  Product?: Product | null,
  manufacturingprocessID: string,
  ManufacturingProcess?: ManufacturingProcess | null,
  Parts?: ModelBOMConnection | null,
  Materials?: ModelComponentMaterialsConnection | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type Dimensions = {
  __typename: "Dimensions",
  bounding_box_x?: string | null,
  bounding_box_y?: string | null,
  bounding_box_z?: string | null,
  volume?: string | null,
};

export type Product = {
  __typename: "Product",
  id: string,
  name: string,
  description?: string | null,
  article_number?: string | null,
  barcode_type?: string | null,
  barcode_content?: string | null,
  comments?: string | null,
  extra_data?: Array< string | null > | null,
  owner?: string | null,
  Components?: ModelComponentConnection | null,
  Contracts?: ModelContractConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelComponentConnection = {
  __typename: "ModelComponentConnection",
  items:  Array<Component | null >,
  nextToken?: string | null,
};

export type ModelContractConnection = {
  __typename: "ModelContractConnection",
  items:  Array<Contract | null >,
  nextToken?: string | null,
};

export type Contract = {
  __typename: "Contract",
  id: string,
  billingAddressID?: string | null,
  certificates?: Array< string | null > | null,
  comment_customer?: string | null,
  comment_internal?: string | null,
  currency?: string | null,
  deadline?: string | null,
  description?: string | null,
  price?: number | null,
  priority?: boolean | null,
  quantity?: number | null,
  read_only?: boolean | null,
  remarks?: string | null,
  shippingAddressID?: string | null,
  version?: number | null,
  wish_date?: string | null,
  productID: string,
  Product?: Product | null,
  ShippingAddress?: Address | null,
  BillingAddress?: Address | null,
  Status?: ModelContractStatusConnection | null,
  Request?: ModelContractRequestConnection | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type Address = {
  __typename: "Address",
  id: string,
  name?: string | null,
  address_1: string,
  address_2?: string | null,
  zip_code: string,
  city: string,
  country?: string | null,
  type?: AddressType | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export enum AddressType {
  SHIPPING = "SHIPPING",
  BILLING = "BILLING",
}


export type ModelContractStatusConnection = {
  __typename: "ModelContractStatusConnection",
  items:  Array<ContractStatus | null >,
  nextToken?: string | null,
};

export type ContractStatus = {
  __typename: "ContractStatus",
  id: string,
  date: string,
  createdAt?: string | null,
  contractID: string,
  Contract?: Contract | null,
  Status: Status,
  owner?: string | null,
  updatedAt: string,
};

export enum Status {
  DRAFT = "DRAFT",
  INFOREQUEST = "INFOREQUEST",
  OPEN = "OPEN",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  PAYMENTREQUESTED = "PAYMENTREQUESTED",
  PAYMENTDONE = "PAYMENTDONE",
  PAYMENTCOMPLETED = "PAYMENTCOMPLETED",
  SCHEDULED = "SCHEDULED",
  PRODUCTION = "PRODUCTION",
  SHIPMENT = "SHIPMENT",
  COMPLETED = "COMPLETED",
  CHECKED = "CHECKED",
  ARCHIVED = "ARCHIVED",
  PARKED = "PARKED",
  INTERRUPTED = "INTERRUPTED",
  ERROR = "ERROR",
}


export type ModelContractRequestConnection = {
  __typename: "ModelContractRequestConnection",
  items:  Array<ContractRequest | null >,
  nextToken?: string | null,
};

export type ContractRequest = {
  __typename: "ContractRequest",
  id: string,
  date: string,
  createdAt?: string | null,
  contractID: string,
  Contract?: Contract | null,
  accepted: boolean,
  updatedAt: string,
  owner?: string | null,
};

export type ManufacturingProcess = {
  __typename: "ManufacturingProcess",
  id: string,
  category: ProcessCategory,
  process: string,
  Requirements?: ModelManufacturingRequirementConnection | null,
  Components?: ModelComponentConnection | null,
  createdAt: string,
  updatedAt: string,
};

export enum ProcessCategory {
  ASSEMBLY = "ASSEMBLY",
  JOINING = "JOINING",
  CNCMACHINING = "CNCMACHINING",
  CASTING = "CASTING",
  POSTPROCESSING = "POSTPROCESSING",
  SHEETMETAL = "SHEETMETAL",
  ADDITIVEMANUFACTURING = "ADDITIVEMANUFACTURING",
  PURCHASEDPART = "PURCHASEDPART",
  STANDARDCOMPONENTS = "STANDARDCOMPONENTS",
  RAWMATERIAL = "RAWMATERIAL",
  QUALITYCONTROL = "QUALITYCONTROL",
  CUSTOMPROCESS = "CUSTOMPROCESS",
}


export type ModelManufacturingRequirementConnection = {
  __typename: "ModelManufacturingRequirementConnection",
  items:  Array<ManufacturingRequirement | null >,
  nextToken?: string | null,
};

export type ManufacturingRequirement = {
  __typename: "ManufacturingRequirement",
  id: string,
  type: string,
  label: string,
  value?: string | null,
  description?: string | null,
  options?: Array< string | null > | null,
  manufacturingProcessID: string,
  ManufacturingProcess?: ManufacturingProcess | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelBOMConnection = {
  __typename: "ModelBOMConnection",
  items:  Array<BOM | null >,
  nextToken?: string | null,
};

export type BOM = {
  __typename: "BOM",
  id: string,
  quantity?: number | null,
  parentID: string,
  childID: string,
  Child?: Component | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type ModelComponentMaterialsConnection = {
  __typename: "ModelComponentMaterialsConnection",
  items:  Array<ComponentMaterials | null >,
  nextToken?: string | null,
};

export type ComponentMaterials = {
  __typename: "ComponentMaterials",
  id: string,
  componentID: string,
  materialID: string,
  component: Component,
  material: Material,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type Material = {
  __typename: "Material",
  id: string,
  name: string,
  type?: string | null,
  available?: boolean | null,
  description?: string | null,
  chemical_name?: string | null,
  numerical_name?: string | null,
  number?: string | null,
  Components?: ModelComponentMaterialsConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateChannelInput = {
  id: string,
  address_from?: string | null,
  address_to?: string | null,
  reference?: string | null,
  visitedAt?: string | null,
  isNew?: boolean | null,
  product?: string | null,
};

export type ModelChannelConditionInput = {
  address_from?: ModelStringInput | null,
  address_to?: ModelStringInput | null,
  reference?: ModelStringInput | null,
  visitedAt?: ModelStringInput | null,
  isNew?: ModelBooleanInput | null,
  product?: ModelStringInput | null,
  and?: Array< ModelChannelConditionInput | null > | null,
  or?: Array< ModelChannelConditionInput | null > | null,
  not?: ModelChannelConditionInput | null,
};

export type Channel = {
  __typename: "Channel",
  id: string,
  address_from: string,
  address_to: string,
  reference?: string | null,
  visitedAt?: string | null,
  isNew?: boolean | null,
  product?: string | null,
  Messages?: ModelMessageConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelMessageConnection = {
  __typename: "ModelMessageConnection",
  items:  Array<Message | null >,
  nextToken?: string | null,
};

export type Message = {
  __typename: "Message",
  id: string,
  body: string,
  author: string,
  channelID?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type CreateProductInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  article_number?: string | null,
  barcode_type?: string | null,
  barcode_content?: string | null,
  comments?: string | null,
  extra_data?: Array< string | null > | null,
  owner?: string | null,
};

export type ModelProductConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  article_number?: ModelStringInput | null,
  barcode_type?: ModelStringInput | null,
  barcode_content?: ModelStringInput | null,
  comments?: ModelStringInput | null,
  extra_data?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelProductConditionInput | null > | null,
  or?: Array< ModelProductConditionInput | null > | null,
  not?: ModelProductConditionInput | null,
};

export type CreateContractStatusInput = {
  id?: string | null,
  date: string,
  createdAt?: string | null,
  contractID: string,
  Status: Status,
  owner?: string | null,
};

export type ModelContractStatusConditionInput = {
  date?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  contractID?: ModelIDInput | null,
  Status?: ModelStatusInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelContractStatusConditionInput | null > | null,
  or?: Array< ModelContractStatusConditionInput | null > | null,
  not?: ModelContractStatusConditionInput | null,
};

export type ModelStatusInput = {
  eq?: Status | null,
  ne?: Status | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelComponentFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  root?: ModelBooleanInput | null,
  description?: ModelStringInput | null,
  article_number?: ModelStringInput | null,
  barcode_type?: ModelStringInput | null,
  barcode_content?: ModelStringInput | null,
  tolerances?: ModelStringInput | null,
  weight?: ModelStringInput | null,
  thread_count?: ModelIntInput | null,
  masking?: ModelStringInput | null,
  certificates?: ModelStringInput | null,
  comment?: ModelStringInput | null,
  comment_internal?: ModelStringInput | null,
  costs?: ModelStringInput | null,
  manufacturing_data?: ModelStringInput | null,
  productID?: ModelIDInput | null,
  manufacturingprocessID?: ModelIDInput | null,
  and?: Array< ModelComponentFilterInput | null > | null,
  or?: Array< ModelComponentFilterInput | null > | null,
  not?: ModelComponentFilterInput | null,
};

export type ModelProductFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  article_number?: ModelStringInput | null,
  barcode_type?: ModelStringInput | null,
  barcode_content?: ModelStringInput | null,
  comments?: ModelStringInput | null,
  extra_data?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelProductFilterInput | null > | null,
  or?: Array< ModelProductFilterInput | null > | null,
  not?: ModelProductFilterInput | null,
};

export type ModelProductConnection = {
  __typename: "ModelProductConnection",
  items:  Array<Product | null >,
  nextToken?: string | null,
};

export type ModelContractFilterInput = {
  id?: ModelIDInput | null,
  billingAddressID?: ModelIDInput | null,
  certificates?: ModelStringInput | null,
  comment_customer?: ModelStringInput | null,
  comment_internal?: ModelStringInput | null,
  currency?: ModelStringInput | null,
  deadline?: ModelStringInput | null,
  description?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  priority?: ModelBooleanInput | null,
  quantity?: ModelIntInput | null,
  read_only?: ModelBooleanInput | null,
  remarks?: ModelStringInput | null,
  shippingAddressID?: ModelIDInput | null,
  version?: ModelIntInput | null,
  wish_date?: ModelStringInput | null,
  productID?: ModelIDInput | null,
  and?: Array< ModelContractFilterInput | null > | null,
  or?: Array< ModelContractFilterInput | null > | null,
  not?: ModelContractFilterInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelChannelFilterInput = {
  id?: ModelIDInput | null,
  address_from?: ModelStringInput | null,
  address_to?: ModelStringInput | null,
  reference?: ModelStringInput | null,
  visitedAt?: ModelStringInput | null,
  isNew?: ModelBooleanInput | null,
  product?: ModelStringInput | null,
  and?: Array< ModelChannelFilterInput | null > | null,
  or?: Array< ModelChannelFilterInput | null > | null,
  not?: ModelChannelFilterInput | null,
};

export type ModelChannelConnection = {
  __typename: "ModelChannelConnection",
  items:  Array<Channel | null >,
  nextToken?: string | null,
};

export type __Type = {
  __typename: "__Type",
  kind: __TypeKind,
  name?: string | null,
  description?: string | null,
  specifiedByUrl?: string | null,
  fields?:  Array<__Field > | null,
  interfaces?:  Array<__Type > | null,
  possibleTypes?:  Array<__Type > | null,
  enumValues?:  Array<__EnumValue > | null,
  inputFields?:  Array<__InputValue > | null,
  ofType?: __Type | null,
};

// An enum describing what kind of type a given `__Type` is.
export enum __TypeKind {
  SCALAR = "SCALAR", // Indicates this type is a scalar.
  OBJECT = "OBJECT", // Indicates this type is an object. `fields` and `interfaces` are valid fields.
  INTERFACE = "INTERFACE", // Indicates this type is an interface. `fields`, `interfaces`, and `possibleTypes` are valid fields.
  UNION = "UNION", // Indicates this type is a union. `possibleTypes` is a valid field.
  ENUM = "ENUM", // Indicates this type is an enum. `enumValues` is a valid field.
  INPUT_OBJECT = "INPUT_OBJECT", // Indicates this type is an input object. `inputFields` is a valid field.
  LIST = "LIST", // Indicates this type is a list. `ofType` is a valid field.
  NON_NULL = "NON_NULL", // Indicates this type is a non-null. `ofType` is a valid field.
}


export type __Field = {
  __typename: "__Field",
  name: string,
  description?: string | null,
  args:  Array<__InputValue >,
  type: __Type,
  isDeprecated: boolean,
  deprecationReason?: string | null,
};

export type __InputValue = {
  __typename: "__InputValue",
  name: string,
  description?: string | null,
  type: __Type,
  // A GraphQL-formatted string representing the default value for this input value.
  defaultValue?: string | null,
  isDeprecated: boolean,
  deprecationReason?: string | null,
};

export type __EnumValue = {
  __typename: "__EnumValue",
  name: string,
  description?: string | null,
  isDeprecated: boolean,
  deprecationReason?: string | null,
};

export type CreateManufacturingRequirementInput = {
  id?: string | null,
  type: string,
  label: string,
  value?: string | null,
  description?: string | null,
  options?: Array< string | null > | null,
  manufacturingProcessID: string,
};

export type ModelManufacturingRequirementConditionInput = {
  type?: ModelStringInput | null,
  label?: ModelStringInput | null,
  value?: ModelStringInput | null,
  description?: ModelStringInput | null,
  options?: ModelStringInput | null,
  manufacturingProcessID?: ModelIDInput | null,
  and?: Array< ModelManufacturingRequirementConditionInput | null > | null,
  or?: Array< ModelManufacturingRequirementConditionInput | null > | null,
  not?: ModelManufacturingRequirementConditionInput | null,
};

export type UpdateManufacturingRequirementInput = {
  id: string,
  type?: string | null,
  label?: string | null,
  value?: string | null,
  description?: string | null,
  options?: Array< string | null > | null,
  manufacturingProcessID?: string | null,
};

export type DeleteManufacturingRequirementInput = {
  id: string,
};

export type CreateManufacturingProcessInput = {
  id?: string | null,
  category: ProcessCategory,
  process: string,
};

export type ModelManufacturingProcessConditionInput = {
  category?: ModelProcessCategoryInput | null,
  process?: ModelStringInput | null,
  and?: Array< ModelManufacturingProcessConditionInput | null > | null,
  or?: Array< ModelManufacturingProcessConditionInput | null > | null,
  not?: ModelManufacturingProcessConditionInput | null,
};

export type ModelProcessCategoryInput = {
  eq?: ProcessCategory | null,
  ne?: ProcessCategory | null,
};

export type UpdateManufacturingProcessInput = {
  id: string,
  category?: ProcessCategory | null,
  process?: string | null,
};

export type DeleteManufacturingProcessInput = {
  id: string,
};

export type UpdateContractStatusInput = {
  id: string,
  date?: string | null,
  createdAt?: string | null,
  contractID?: string | null,
  Status?: Status | null,
  owner?: string | null,
};

export type DeleteContractStatusInput = {
  id: string,
};

export type CreateContractRequestInput = {
  id?: string | null,
  date: string,
  createdAt?: string | null,
  contractID: string,
  accepted: boolean,
};

export type ModelContractRequestConditionInput = {
  date?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  contractID?: ModelIDInput | null,
  accepted?: ModelBooleanInput | null,
  and?: Array< ModelContractRequestConditionInput | null > | null,
  or?: Array< ModelContractRequestConditionInput | null > | null,
  not?: ModelContractRequestConditionInput | null,
};

export type UpdateContractRequestInput = {
  id: string,
  date?: string | null,
  createdAt?: string | null,
  contractID?: string | null,
  accepted?: boolean | null,
};

export type DeleteContractRequestInput = {
  id: string,
};

export type CreateContractInput = {
  id?: string | null,
  billingAddressID?: string | null,
  certificates?: Array< string | null > | null,
  comment_customer?: string | null,
  comment_internal?: string | null,
  currency?: string | null,
  deadline?: string | null,
  description?: string | null,
  price?: number | null,
  priority?: boolean | null,
  quantity?: number | null,
  read_only?: boolean | null,
  remarks?: string | null,
  shippingAddressID?: string | null,
  version?: number | null,
  wish_date?: string | null,
  productID: string,
};

export type ModelContractConditionInput = {
  billingAddressID?: ModelIDInput | null,
  certificates?: ModelStringInput | null,
  comment_customer?: ModelStringInput | null,
  comment_internal?: ModelStringInput | null,
  currency?: ModelStringInput | null,
  deadline?: ModelStringInput | null,
  description?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  priority?: ModelBooleanInput | null,
  quantity?: ModelIntInput | null,
  read_only?: ModelBooleanInput | null,
  remarks?: ModelStringInput | null,
  shippingAddressID?: ModelIDInput | null,
  version?: ModelIntInput | null,
  wish_date?: ModelStringInput | null,
  productID?: ModelIDInput | null,
  and?: Array< ModelContractConditionInput | null > | null,
  or?: Array< ModelContractConditionInput | null > | null,
  not?: ModelContractConditionInput | null,
};

export type UpdateContractInput = {
  id: string,
  billingAddressID?: string | null,
  certificates?: Array< string | null > | null,
  comment_customer?: string | null,
  comment_internal?: string | null,
  currency?: string | null,
  deadline?: string | null,
  description?: string | null,
  price?: number | null,
  priority?: boolean | null,
  quantity?: number | null,
  read_only?: boolean | null,
  remarks?: string | null,
  shippingAddressID?: string | null,
  version?: number | null,
  wish_date?: string | null,
  productID?: string | null,
};

export type DeleteContractInput = {
  id: string,
};

export type UpdateProductInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  article_number?: string | null,
  barcode_type?: string | null,
  barcode_content?: string | null,
  comments?: string | null,
  extra_data?: Array< string | null > | null,
  owner?: string | null,
};

export type DeleteProductInput = {
  id: string,
};

export type CreateComponentInput = {
  id?: string | null,
  name: string,
  root?: boolean | null,
  description?: string | null,
  dimensions?: DimensionsInput | null,
  article_number?: string | null,
  barcode_type?: string | null,
  barcode_content?: string | null,
  tolerances?: string | null,
  weight?: string | null,
  thread_count?: number | null,
  masking?: string | null,
  certificates?: Array< string | null > | null,
  comment?: string | null,
  comment_internal?: string | null,
  costs?: string | null,
  manufacturing_data?: string | null,
  productID?: string | null,
  manufacturingprocessID: string,
};

export type DeleteComponentInput = {
  id: string,
};

export type CreateBOMInput = {
  id?: string | null,
  quantity?: number | null,
  parentID: string,
  childID: string,
};

export type ModelBOMConditionInput = {
  quantity?: ModelIntInput | null,
  parentID?: ModelIDInput | null,
  childID?: ModelIDInput | null,
  and?: Array< ModelBOMConditionInput | null > | null,
  or?: Array< ModelBOMConditionInput | null > | null,
  not?: ModelBOMConditionInput | null,
};

export type UpdateBOMInput = {
  id: string,
  quantity?: number | null,
  parentID?: string | null,
  childID?: string | null,
};

export type DeleteBOMInput = {
  id: string,
};

export type CreateMaterialInput = {
  id?: string | null,
  name: string,
  type?: string | null,
  available?: boolean | null,
  description?: string | null,
  chemical_name?: string | null,
  numerical_name?: string | null,
  number?: string | null,
};

export type ModelMaterialConditionInput = {
  name?: ModelStringInput | null,
  type?: ModelStringInput | null,
  available?: ModelBooleanInput | null,
  description?: ModelStringInput | null,
  chemical_name?: ModelStringInput | null,
  numerical_name?: ModelStringInput | null,
  number?: ModelStringInput | null,
  and?: Array< ModelMaterialConditionInput | null > | null,
  or?: Array< ModelMaterialConditionInput | null > | null,
  not?: ModelMaterialConditionInput | null,
};

export type UpdateMaterialInput = {
  id: string,
  name?: string | null,
  type?: string | null,
  available?: boolean | null,
  description?: string | null,
  chemical_name?: string | null,
  numerical_name?: string | null,
  number?: string | null,
};

export type DeleteMaterialInput = {
  id: string,
};

export type CreateProfileInput = {
  id?: string | null,
  company_name?: string | null,
  contact_person?: string | null,
  mobile_number?: string | null,
  vat_number?: string | null,
  preferred_contact_way?: string | null,
  preferred_incoterms?: string | null,
  phone_number?: string | null,
};

export type ModelProfileConditionInput = {
  company_name?: ModelStringInput | null,
  contact_person?: ModelStringInput | null,
  mobile_number?: ModelStringInput | null,
  vat_number?: ModelStringInput | null,
  preferred_contact_way?: ModelStringInput | null,
  preferred_incoterms?: ModelStringInput | null,
  phone_number?: ModelStringInput | null,
  and?: Array< ModelProfileConditionInput | null > | null,
  or?: Array< ModelProfileConditionInput | null > | null,
  not?: ModelProfileConditionInput | null,
};

export type Profile = {
  __typename: "Profile",
  id: string,
  company_name?: string | null,
  contact_person?: string | null,
  mobile_number?: string | null,
  vat_number?: string | null,
  preferred_contact_way?: string | null,
  preferred_incoterms?: string | null,
  phone_number?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateProfileInput = {
  id: string,
  company_name?: string | null,
  contact_person?: string | null,
  mobile_number?: string | null,
  vat_number?: string | null,
  preferred_contact_way?: string | null,
  preferred_incoterms?: string | null,
  phone_number?: string | null,
};

export type DeleteProfileInput = {
  id: string,
};

export type CreateAddressInput = {
  id?: string | null,
  name?: string | null,
  address_1: string,
  address_2?: string | null,
  zip_code: string,
  city: string,
  country?: string | null,
  type?: AddressType | null,
};

export type ModelAddressConditionInput = {
  name?: ModelStringInput | null,
  address_1?: ModelStringInput | null,
  address_2?: ModelStringInput | null,
  zip_code?: ModelStringInput | null,
  city?: ModelStringInput | null,
  country?: ModelStringInput | null,
  type?: ModelAddressTypeInput | null,
  and?: Array< ModelAddressConditionInput | null > | null,
  or?: Array< ModelAddressConditionInput | null > | null,
  not?: ModelAddressConditionInput | null,
};

export type ModelAddressTypeInput = {
  eq?: AddressType | null,
  ne?: AddressType | null,
};

export type UpdateAddressInput = {
  id: string,
  name?: string | null,
  address_1?: string | null,
  address_2?: string | null,
  zip_code?: string | null,
  city?: string | null,
  country?: string | null,
  type?: AddressType | null,
};

export type DeleteAddressInput = {
  id: string,
};

export type CreateChannelInput = {
  id?: string | null,
  address_from: string,
  address_to: string,
  reference?: string | null,
  visitedAt?: string | null,
  isNew?: boolean | null,
  product?: string | null,
};

export type DeleteChannelInput = {
  id: string,
};

export type CreateMessageInput = {
  id?: string | null,
  body: string,
  author: string,
  channelID?: string | null,
};

export type ModelMessageConditionInput = {
  body?: ModelStringInput | null,
  author?: ModelStringInput | null,
  channelID?: ModelIDInput | null,
  and?: Array< ModelMessageConditionInput | null > | null,
  or?: Array< ModelMessageConditionInput | null > | null,
  not?: ModelMessageConditionInput | null,
};

export type UpdateMessageInput = {
  id: string,
  body?: string | null,
  author?: string | null,
  channelID?: string | null,
};

export type DeleteMessageInput = {
  id: string,
};

export type CreateUnauthRequestInput = {
  id?: string | null,
  email: string,
  name: string,
  company_name: string,
  address: string,
  zip_code: string,
  city: string,
  country: string,
  phone_number: string,
  project_description: string,
  status: UnauthRequestStatus,
};

export enum UnauthRequestStatus {
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  PENDING = "PENDING",
}


export type ModelUnauthRequestConditionInput = {
  email?: ModelStringInput | null,
  name?: ModelStringInput | null,
  company_name?: ModelStringInput | null,
  address?: ModelStringInput | null,
  zip_code?: ModelStringInput | null,
  city?: ModelStringInput | null,
  country?: ModelStringInput | null,
  phone_number?: ModelStringInput | null,
  project_description?: ModelStringInput | null,
  status?: ModelUnauthRequestStatusInput | null,
  and?: Array< ModelUnauthRequestConditionInput | null > | null,
  or?: Array< ModelUnauthRequestConditionInput | null > | null,
  not?: ModelUnauthRequestConditionInput | null,
};

export type ModelUnauthRequestStatusInput = {
  eq?: UnauthRequestStatus | null,
  ne?: UnauthRequestStatus | null,
};

export type UnauthRequest = {
  __typename: "UnauthRequest",
  id: string,
  email: string,
  name: string,
  company_name: string,
  address: string,
  zip_code: string,
  city: string,
  country: string,
  phone_number: string,
  project_description: string,
  status: UnauthRequestStatus,
  createdAt: string,
  updatedAt: string,
};

export type UpdateUnauthRequestInput = {
  id: string,
  email?: string | null,
  name?: string | null,
  company_name?: string | null,
  address?: string | null,
  zip_code?: string | null,
  city?: string | null,
  country?: string | null,
  phone_number?: string | null,
  project_description?: string | null,
  status?: UnauthRequestStatus | null,
};

export type DeleteUnauthRequestInput = {
  id: string,
};

export type CreateEmailResultInput = {
  statusCode?: number | null,
  body?: string | null,
  id?: string | null,
};

export type ModelEmailResultConditionInput = {
  statusCode?: ModelIntInput | null,
  body?: ModelStringInput | null,
  and?: Array< ModelEmailResultConditionInput | null > | null,
  or?: Array< ModelEmailResultConditionInput | null > | null,
  not?: ModelEmailResultConditionInput | null,
};

export type EmailResult = {
  __typename: "EmailResult",
  statusCode?: number | null,
  body?: string | null,
  id: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateEmailResultInput = {
  statusCode?: number | null,
  body?: string | null,
  id: string,
};

export type DeleteEmailResultInput = {
  id: string,
};

export type CreateComponentMaterialsInput = {
  id?: string | null,
  componentID: string,
  materialID: string,
};

export type ModelComponentMaterialsConditionInput = {
  componentID?: ModelIDInput | null,
  materialID?: ModelIDInput | null,
  and?: Array< ModelComponentMaterialsConditionInput | null > | null,
  or?: Array< ModelComponentMaterialsConditionInput | null > | null,
  not?: ModelComponentMaterialsConditionInput | null,
};

export type UpdateComponentMaterialsInput = {
  id: string,
  componentID?: string | null,
  materialID?: string | null,
};

export type DeleteComponentMaterialsInput = {
  id: string,
};

export type ConversionResult = {
  __typename: "ConversionResult",
  file_key?: string | null,
  format?: string | null,
  data?: string | null,
};

export enum EmailSubject {
  NEW_CONTRACT_REQUEST = "NEW_CONTRACT_REQUEST",
  NEW_UNAUTH_REQUEST = "NEW_UNAUTH_REQUEST",
  NEW_CONTRACT_STATUS = "NEW_CONTRACT_STATUS",
  NEW_CONVERSATION = "NEW_CONVERSATION",
  NEW_MESSAGES = "NEW_MESSAGES",
  UNAUTH_REQUEST_ACCEPTED = "UNAUTH_REQUEST_ACCEPTED",
  UNAUTH_REQUEST_REJECTED = "UNAUTH_REQUEST_REJECTED",
}


export enum S3Action {
  GET = "GET",
  LIST = "LIST",
  DELETE = "DELETE",
}


export type ModelManufacturingRequirementFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  label?: ModelStringInput | null,
  value?: ModelStringInput | null,
  description?: ModelStringInput | null,
  options?: ModelStringInput | null,
  manufacturingProcessID?: ModelIDInput | null,
  and?: Array< ModelManufacturingRequirementFilterInput | null > | null,
  or?: Array< ModelManufacturingRequirementFilterInput | null > | null,
  not?: ModelManufacturingRequirementFilterInput | null,
};

export type ModelManufacturingProcessFilterInput = {
  id?: ModelIDInput | null,
  category?: ModelProcessCategoryInput | null,
  process?: ModelStringInput | null,
  and?: Array< ModelManufacturingProcessFilterInput | null > | null,
  or?: Array< ModelManufacturingProcessFilterInput | null > | null,
  not?: ModelManufacturingProcessFilterInput | null,
};

export type ModelManufacturingProcessConnection = {
  __typename: "ModelManufacturingProcessConnection",
  items:  Array<ManufacturingProcess | null >,
  nextToken?: string | null,
};

export type ModelContractStatusFilterInput = {
  id?: ModelIDInput | null,
  date?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  contractID?: ModelIDInput | null,
  Status?: ModelStatusInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelContractStatusFilterInput | null > | null,
  or?: Array< ModelContractStatusFilterInput | null > | null,
  not?: ModelContractStatusFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelContractRequestFilterInput = {
  id?: ModelIDInput | null,
  date?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  contractID?: ModelIDInput | null,
  accepted?: ModelBooleanInput | null,
  and?: Array< ModelContractRequestFilterInput | null > | null,
  or?: Array< ModelContractRequestFilterInput | null > | null,
  not?: ModelContractRequestFilterInput | null,
};

export type ModelBOMFilterInput = {
  id?: ModelIDInput | null,
  quantity?: ModelIntInput | null,
  parentID?: ModelIDInput | null,
  childID?: ModelIDInput | null,
  and?: Array< ModelBOMFilterInput | null > | null,
  or?: Array< ModelBOMFilterInput | null > | null,
  not?: ModelBOMFilterInput | null,
};

export type ModelMaterialFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  type?: ModelStringInput | null,
  available?: ModelBooleanInput | null,
  description?: ModelStringInput | null,
  chemical_name?: ModelStringInput | null,
  numerical_name?: ModelStringInput | null,
  number?: ModelStringInput | null,
  and?: Array< ModelMaterialFilterInput | null > | null,
  or?: Array< ModelMaterialFilterInput | null > | null,
  not?: ModelMaterialFilterInput | null,
};

export type ModelMaterialConnection = {
  __typename: "ModelMaterialConnection",
  items:  Array<Material | null >,
  nextToken?: string | null,
};

export type ModelProfileFilterInput = {
  id?: ModelIDInput | null,
  company_name?: ModelStringInput | null,
  contact_person?: ModelStringInput | null,
  mobile_number?: ModelStringInput | null,
  vat_number?: ModelStringInput | null,
  preferred_contact_way?: ModelStringInput | null,
  preferred_incoterms?: ModelStringInput | null,
  phone_number?: ModelStringInput | null,
  and?: Array< ModelProfileFilterInput | null > | null,
  or?: Array< ModelProfileFilterInput | null > | null,
  not?: ModelProfileFilterInput | null,
};

export type ModelProfileConnection = {
  __typename: "ModelProfileConnection",
  items:  Array<Profile | null >,
  nextToken?: string | null,
};

export type ModelAddressFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  address_1?: ModelStringInput | null,
  address_2?: ModelStringInput | null,
  zip_code?: ModelStringInput | null,
  city?: ModelStringInput | null,
  country?: ModelStringInput | null,
  type?: ModelAddressTypeInput | null,
  and?: Array< ModelAddressFilterInput | null > | null,
  or?: Array< ModelAddressFilterInput | null > | null,
  not?: ModelAddressFilterInput | null,
};

export type ModelAddressConnection = {
  __typename: "ModelAddressConnection",
  items:  Array<Address | null >,
  nextToken?: string | null,
};

export type ModelMessageFilterInput = {
  id?: ModelIDInput | null,
  body?: ModelStringInput | null,
  author?: ModelStringInput | null,
  channelID?: ModelIDInput | null,
  and?: Array< ModelMessageFilterInput | null > | null,
  or?: Array< ModelMessageFilterInput | null > | null,
  not?: ModelMessageFilterInput | null,
};

export type ModelUnauthRequestFilterInput = {
  id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  name?: ModelStringInput | null,
  company_name?: ModelStringInput | null,
  address?: ModelStringInput | null,
  zip_code?: ModelStringInput | null,
  city?: ModelStringInput | null,
  country?: ModelStringInput | null,
  phone_number?: ModelStringInput | null,
  project_description?: ModelStringInput | null,
  status?: ModelUnauthRequestStatusInput | null,
  and?: Array< ModelUnauthRequestFilterInput | null > | null,
  or?: Array< ModelUnauthRequestFilterInput | null > | null,
  not?: ModelUnauthRequestFilterInput | null,
};

export type ModelUnauthRequestConnection = {
  __typename: "ModelUnauthRequestConnection",
  items:  Array<UnauthRequest | null >,
  nextToken?: string | null,
};

export type ModelEmailResultFilterInput = {
  statusCode?: ModelIntInput | null,
  body?: ModelStringInput | null,
  and?: Array< ModelEmailResultFilterInput | null > | null,
  or?: Array< ModelEmailResultFilterInput | null > | null,
  not?: ModelEmailResultFilterInput | null,
};

export type ModelEmailResultConnection = {
  __typename: "ModelEmailResultConnection",
  items:  Array<EmailResult | null >,
  nextToken?: string | null,
};

export type ModelComponentMaterialsFilterInput = {
  id?: ModelIDInput | null,
  componentID?: ModelIDInput | null,
  materialID?: ModelIDInput | null,
  and?: Array< ModelComponentMaterialsFilterInput | null > | null,
  or?: Array< ModelComponentMaterialsFilterInput | null > | null,
  not?: ModelComponentMaterialsFilterInput | null,
};

export type ModelSubscriptionManufacturingRequirementFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  label?: ModelSubscriptionStringInput | null,
  value?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  options?: ModelSubscriptionStringInput | null,
  manufacturingProcessID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionManufacturingRequirementFilterInput | null > | null,
  or?: Array< ModelSubscriptionManufacturingRequirementFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionManufacturingProcessFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  category?: ModelSubscriptionStringInput | null,
  process?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionManufacturingProcessFilterInput | null > | null,
  or?: Array< ModelSubscriptionManufacturingProcessFilterInput | null > | null,
};

export type ModelSubscriptionContractStatusFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  date?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  contractID?: ModelSubscriptionIDInput | null,
  Status?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionContractStatusFilterInput | null > | null,
  or?: Array< ModelSubscriptionContractStatusFilterInput | null > | null,
};

export type ModelSubscriptionContractRequestFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  date?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  contractID?: ModelSubscriptionIDInput | null,
  accepted?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionContractRequestFilterInput | null > | null,
  or?: Array< ModelSubscriptionContractRequestFilterInput | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionContractFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  billingAddressID?: ModelSubscriptionIDInput | null,
  certificates?: ModelSubscriptionStringInput | null,
  comment_customer?: ModelSubscriptionStringInput | null,
  comment_internal?: ModelSubscriptionStringInput | null,
  currency?: ModelSubscriptionStringInput | null,
  deadline?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  price?: ModelSubscriptionFloatInput | null,
  priority?: ModelSubscriptionBooleanInput | null,
  quantity?: ModelSubscriptionIntInput | null,
  read_only?: ModelSubscriptionBooleanInput | null,
  remarks?: ModelSubscriptionStringInput | null,
  shippingAddressID?: ModelSubscriptionIDInput | null,
  version?: ModelSubscriptionIntInput | null,
  wish_date?: ModelSubscriptionStringInput | null,
  productID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionContractFilterInput | null > | null,
  or?: Array< ModelSubscriptionContractFilterInput | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionProductFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  article_number?: ModelSubscriptionStringInput | null,
  barcode_type?: ModelSubscriptionStringInput | null,
  barcode_content?: ModelSubscriptionStringInput | null,
  comments?: ModelSubscriptionStringInput | null,
  extra_data?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProductFilterInput | null > | null,
  or?: Array< ModelSubscriptionProductFilterInput | null > | null,
};

export type ModelSubscriptionComponentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  root?: ModelSubscriptionBooleanInput | null,
  description?: ModelSubscriptionStringInput | null,
  article_number?: ModelSubscriptionStringInput | null,
  barcode_type?: ModelSubscriptionStringInput | null,
  barcode_content?: ModelSubscriptionStringInput | null,
  tolerances?: ModelSubscriptionStringInput | null,
  weight?: ModelSubscriptionStringInput | null,
  thread_count?: ModelSubscriptionIntInput | null,
  masking?: ModelSubscriptionStringInput | null,
  certificates?: ModelSubscriptionStringInput | null,
  comment?: ModelSubscriptionStringInput | null,
  comment_internal?: ModelSubscriptionStringInput | null,
  costs?: ModelSubscriptionStringInput | null,
  manufacturing_data?: ModelSubscriptionStringInput | null,
  productID?: ModelSubscriptionIDInput | null,
  manufacturingprocessID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionComponentFilterInput | null > | null,
  or?: Array< ModelSubscriptionComponentFilterInput | null > | null,
};

export type ModelSubscriptionBOMFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  quantity?: ModelSubscriptionIntInput | null,
  parentID?: ModelSubscriptionIDInput | null,
  childID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionBOMFilterInput | null > | null,
  or?: Array< ModelSubscriptionBOMFilterInput | null > | null,
};

export type ModelSubscriptionMaterialFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  available?: ModelSubscriptionBooleanInput | null,
  description?: ModelSubscriptionStringInput | null,
  chemical_name?: ModelSubscriptionStringInput | null,
  numerical_name?: ModelSubscriptionStringInput | null,
  number?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMaterialFilterInput | null > | null,
  or?: Array< ModelSubscriptionMaterialFilterInput | null > | null,
};

export type ModelSubscriptionProfileFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  company_name?: ModelSubscriptionStringInput | null,
  contact_person?: ModelSubscriptionStringInput | null,
  mobile_number?: ModelSubscriptionStringInput | null,
  vat_number?: ModelSubscriptionStringInput | null,
  preferred_contact_way?: ModelSubscriptionStringInput | null,
  preferred_incoterms?: ModelSubscriptionStringInput | null,
  phone_number?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProfileFilterInput | null > | null,
  or?: Array< ModelSubscriptionProfileFilterInput | null > | null,
};

export type ModelSubscriptionAddressFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  address_1?: ModelSubscriptionStringInput | null,
  address_2?: ModelSubscriptionStringInput | null,
  zip_code?: ModelSubscriptionStringInput | null,
  city?: ModelSubscriptionStringInput | null,
  country?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAddressFilterInput | null > | null,
  or?: Array< ModelSubscriptionAddressFilterInput | null > | null,
};

export type ModelSubscriptionChannelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  address_from?: ModelSubscriptionStringInput | null,
  address_to?: ModelSubscriptionStringInput | null,
  reference?: ModelSubscriptionStringInput | null,
  visitedAt?: ModelSubscriptionStringInput | null,
  isNew?: ModelSubscriptionBooleanInput | null,
  product?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionChannelFilterInput | null > | null,
  or?: Array< ModelSubscriptionChannelFilterInput | null > | null,
};

export type ModelSubscriptionMessageFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  body?: ModelSubscriptionStringInput | null,
  author?: ModelSubscriptionStringInput | null,
  channelID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionMessageFilterInput | null > | null,
  or?: Array< ModelSubscriptionMessageFilterInput | null > | null,
};

export type ModelSubscriptionUnauthRequestFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  email?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  company_name?: ModelSubscriptionStringInput | null,
  address?: ModelSubscriptionStringInput | null,
  zip_code?: ModelSubscriptionStringInput | null,
  city?: ModelSubscriptionStringInput | null,
  country?: ModelSubscriptionStringInput | null,
  phone_number?: ModelSubscriptionStringInput | null,
  project_description?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUnauthRequestFilterInput | null > | null,
  or?: Array< ModelSubscriptionUnauthRequestFilterInput | null > | null,
};

export type ModelSubscriptionEmailResultFilterInput = {
  statusCode?: ModelSubscriptionIntInput | null,
  body?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionEmailResultFilterInput | null > | null,
  or?: Array< ModelSubscriptionEmailResultFilterInput | null > | null,
};

export type ModelSubscriptionComponentMaterialsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  componentID?: ModelSubscriptionIDInput | null,
  materialID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionComponentMaterialsFilterInput | null > | null,
  or?: Array< ModelSubscriptionComponentMaterialsFilterInput | null > | null,
};

export type customUpdateComponentMutationVariables = {
  input: UpdateComponentInput,
  condition?: ModelComponentConditionInput | null,
};

export type customUpdateComponentMutation = {
  updateComponent?:  {
    __typename: "Component",
    id: string,
    name: string,
    root?: boolean | null,
    description?: string | null,
    dimensions?:  {
      __typename: "Dimensions",
      bounding_box_x?: string | null,
      bounding_box_y?: string | null,
      bounding_box_z?: string | null,
      volume?: string | null,
    } | null,
    article_number?: string | null,
    barcode_type?: string | null,
    barcode_content?: string | null,
    tolerances?: string | null,
    weight?: string | null,
    thread_count?: number | null,
    masking?: string | null,
    certificates?: Array< string | null > | null,
    comment?: string | null,
    comment_internal?: string | null,
    costs?: string | null,
    manufacturing_data?: string | null,
    productID?: string | null,
    Product?:  {
      __typename: "Product",
      id: string,
      name: string,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      comments?: string | null,
      extra_data?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    manufacturingprocessID: string,
    ManufacturingProcess?:  {
      __typename: "ManufacturingProcess",
      id: string,
      category: ProcessCategory,
      process: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    Parts?:  {
      __typename: "ModelBOMConnection",
      items:  Array< {
        __typename: "BOM",
        id: string,
        childID: string,
        parentID: string,
        quantity?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    Materials?:  {
      __typename: "ModelComponentMaterialsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type customUpdateChannelMutationVariables = {
  input: UpdateChannelInput,
  condition?: ModelChannelConditionInput | null,
};

export type customUpdateChannelMutation = {
  updateChannel?:  {
    __typename: "Channel",
    id: string,
    address_from: string,
    address_to: string,
    reference?: string | null,
    visitedAt?: string | null,
    isNew?: boolean | null,
    product?: string | null,
    Messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
      items:  Array< {
        __typename: "Message",
        id: string,
        body: string,
        createdAt: string,
      } | null >,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type customCreateProductMutationVariables = {
  input: CreateProductInput,
  condition?: ModelProductConditionInput | null,
};

export type customCreateProductMutation = {
  createProduct?:  {
    __typename: "Product",
    id: string,
    name: string,
    description?: string | null,
    article_number?: string | null,
    barcode_type?: string | null,
    barcode_content?: string | null,
    comments?: string | null,
    extra_data?: Array< string | null > | null,
    Components?:  {
      __typename: "ModelComponentConnection",
      items:  Array< {
        __typename: "Component",
        Parts?:  {
          __typename: "ModelBOMConnection",
          items:  Array< {
            __typename: "BOM",
            childID: string,
            parentID: string,
            quantity?: number | null,
          } | null >,
        } | null,
        name: string,
        id: string,
        description?: string | null,
        weight?: string | null,
        tolerances?: string | null,
        thread_count?: number | null,
        root?: boolean | null,
        productID?: string | null,
        masking?: string | null,
        manufacturingprocessID: string,
        manufacturing_data?: string | null,
        dimensions?:  {
          __typename: "Dimensions",
          bounding_box_x?: string | null,
          bounding_box_y?: string | null,
          bounding_box_z?: string | null,
          volume?: string | null,
        } | null,
        costs?: string | null,
        comment_internal?: string | null,
        comment?: string | null,
        certificates?: Array< string | null > | null,
        barcode_type?: string | null,
        barcode_content?: string | null,
        article_number?: string | null,
      } | null >,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type customCreateContractStatusMutationVariables = {
  input: CreateContractStatusInput,
  condition?: ModelContractStatusConditionInput | null,
};

export type customCreateContractStatusMutation = {
  createContractStatus?:  {
    __typename: "ContractStatus",
    id: string,
    date: string,
    createdAt?: string | null,
    contractID: string,
    Contract?:  {
      __typename: "Contract",
      id: string,
      billingAddressID?: string | null,
      certificates?: Array< string | null > | null,
      comment_customer?: string | null,
      comment_internal?: string | null,
      currency?: string | null,
      deadline?: string | null,
      description?: string | null,
      price?: number | null,
      priority?: boolean | null,
      quantity?: number | null,
      read_only?: boolean | null,
      remarks?: string | null,
      shippingAddressID?: string | null,
      version?: number | null,
      wish_date?: string | null,
      productID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    owner?: string | null,
    Status: Status,
    updatedAt: string,
  } | null,
};

export type CustomGetComponentQueryVariables = {
  id: string,
};

export type CustomGetComponentQuery = {
  getComponent?:  {
    __typename: "Component",
    id: string,
    name: string,
    root?: boolean | null,
    description?: string | null,
    dimensions?:  {
      __typename: "Dimensions",
      bounding_box_x?: string | null,
      bounding_box_y?: string | null,
      bounding_box_z?: string | null,
      volume?: string | null,
    } | null,
    article_number?: string | null,
    barcode_type?: string | null,
    barcode_content?: string | null,
    tolerances?: string | null,
    weight?: string | null,
    thread_count?: number | null,
    masking?: string | null,
    certificates?: Array< string | null > | null,
    comment?: string | null,
    comment_internal?: string | null,
    costs?: string | null,
    manufacturing_data?: string | null,
    productID?: string | null,
    Product?:  {
      __typename: "Product",
      id: string,
      name: string,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      comments?: string | null,
      extra_data?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    manufacturingprocessID: string,
    ManufacturingProcess?:  {
      __typename: "ManufacturingProcess",
      id: string,
      category: ProcessCategory,
      process: string,
      Requirements?:  {
        __typename: "ModelManufacturingRequirementConnection",
        items:  Array< {
          __typename: "ManufacturingRequirement",
          id: string,
          label: string,
          options?: Array< string | null > | null,
          type: string,
          value?: string | null,
        } | null >,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    Parts?:  {
      __typename: "ModelBOMConnection",
      items:  Array< {
        __typename: "BOM",
        id: string,
        parentID: string,
        quantity?: number | null,
        Child?:  {
          __typename: "Component",
          id: string,
          name: string,
          Parts?:  {
            __typename: "ModelBOMConnection",
            items:  Array< {
              __typename: "BOM",
              id: string,
              Child?:  {
                __typename: "Component",
                id: string,
                name: string,
              } | null,
            } | null >,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    Materials?:  {
      __typename: "ModelComponentMaterialsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CustomComponentByProductQueryVariables = {
  productID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelComponentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomComponentByProductQuery = {
  componentByProduct?:  {
    __typename: "ModelComponentConnection",
    items:  Array< {
      __typename: "Component",
      id: string,
      name: string,
      root?: boolean | null,
      Parts?:  {
        __typename: "ModelBOMConnection",
        items:  Array< {
          __typename: "BOM",
          id: string,
          parentID: string,
          childID: string,
          Child?:  {
            __typename: "Component",
            id: string,
            name: string,
            Parts?:  {
              __typename: "ModelBOMConnection",
              items:  Array< {
                __typename: "BOM",
                id: string,
                Child?:  {
                  __typename: "Component",
                  id: string,
                  name: string,
                } | null,
              } | null >,
            } | null,
          } | null,
        } | null >,
      } | null,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      tolerances?: string | null,
      weight?: string | null,
      thread_count?: number | null,
      masking?: string | null,
      certificates?: Array< string | null > | null,
      comment?: string | null,
      comment_internal?: string | null,
      costs?: string | null,
      manufacturing_data?: string | null,
      productID?: string | null,
      ManufacturingProcess?:  {
        __typename: "ManufacturingProcess",
        id: string,
        process: string,
        Requirements?:  {
          __typename: "ModelManufacturingRequirementConnection",
          items:  Array< {
            __typename: "ManufacturingRequirement",
            id: string,
            label: string,
            options?: Array< string | null > | null,
            type: string,
            value?: string | null,
          } | null >,
        } | null,
      } | null,
      manufacturingprocessID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type customListProductsQueryVariables = {
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type customListProductsQuery = {
  listProducts?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      name: string,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      comments?: string | null,
      extra_data?: Array< string | null > | null,
      Components?:  {
        __typename: "ModelComponentConnection",
        items:  Array< {
          __typename: "Component",
          id: string,
          name: string,
          root?: boolean | null,
          createdAt: string,
          ManufacturingProcess?:  {
            __typename: "ManufacturingProcess",
            id: string,
            process: string,
            category: ProcessCategory,
          } | null,
        } | null >,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomListContractsQueryVariables = {
  filter?: ModelContractFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomListContractsQuery = {
  listContracts?:  {
    __typename: "ModelContractConnection",
    items:  Array< {
      __typename: "Contract",
      id: string,
      billingAddressID?: string | null,
      comment_customer?: string | null,
      comment_internal?: string | null,
      certificates?: Array< string | null > | null,
      currency?: string | null,
      deadline?: string | null,
      description?: string | null,
      price?: number | null,
      priority?: boolean | null,
      quantity?: number | null,
      read_only?: boolean | null,
      remarks?: string | null,
      shippingAddressID?: string | null,
      version?: number | null,
      wish_date?: string | null,
      Request?:  {
        __typename: "ModelContractRequestConnection",
        items:  Array< {
          __typename: "ContractRequest",
          id: string,
          contractID: string,
          accepted: boolean,
          date: string,
        } | null >,
      } | null,
      Status?:  {
        __typename: "ModelContractStatusConnection",
        items:  Array< {
          __typename: "ContractStatus",
          id: string,
          contractID: string,
          Status: Status,
          createdAt?: string | null,
        } | null >,
      } | null,
      Product?:  {
        __typename: "Product",
        id: string,
        name: string,
        description?: string | null,
        article_number?: string | null,
      } | null,
      productID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type customGetContractQueryVariables = {
  id: string,
};

export type customGetContractQuery = {
  getContract?:  {
    __typename: "Contract",
    id: string,
    billingAddressID?: string | null,
    certificates?: Array< string | null > | null,
    comment_customer?: string | null,
    comment_internal?: string | null,
    currency?: string | null,
    deadline?: string | null,
    description?: string | null,
    price?: number | null,
    priority?: boolean | null,
    quantity?: number | null,
    read_only?: boolean | null,
    remarks?: string | null,
    shippingAddressID?: string | null,
    version?: number | null,
    wish_date?: string | null,
    productID: string,
    Product?:  {
      __typename: "Product",
      id: string,
      name: string,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      comments?: string | null,
      extra_data?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    ShippingAddress?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      address_1: string,
      address_2?: string | null,
      zip_code: string,
      city: string,
      country?: string | null,
      type?: AddressType | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    BillingAddress?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      address_1: string,
      address_2?: string | null,
      zip_code: string,
      city: string,
      country?: string | null,
      type?: AddressType | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Status?:  {
      __typename: "ModelContractStatusConnection",
      items:  Array< {
        __typename: "ContractStatus",
        id: string,
        date: string,
        contractID: string,
        Status: Status,
        createdAt?: string | null,
      } | null >,
    } | null,
    Request?:  {
      __typename: "ModelContractRequestConnection",
      items:  Array< {
        __typename: "ContractRequest",
        id: string,
        date: string,
        contractID: string,
        accepted: boolean,
        createdAt?: string | null,
      } | null >,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type customListChannelsQueryVariables = {
  filter?: ModelChannelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type customListChannelsQuery = {
  listChannels?:  {
    __typename: "ModelChannelConnection",
    items:  Array< {
      __typename: "Channel",
      id: string,
      Messages?:  {
        __typename: "ModelMessageConnection",
        items:  Array< {
          __typename: "Message",
          id: string,
          body: string,
          author: string,
          createdAt: string,
          updatedAt: string,
          channelID?: string | null,
        } | null >,
      } | null,
      address_from: string,
      address_to: string,
      reference?: string | null,
      product?: string | null,
      visitedAt?: string | null,
      isNew?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type getComponentsFromProductQueryVariables = {
  id: string,
};

export type getComponentsFromProductQuery = {
  getProduct?:  {
    __typename: "Product",
    id: string,
    article_number?: string | null,
    barcode_content?: string | null,
    barcode_type?: string | null,
    comments?: string | null,
    description?: string | null,
    extra_data?: Array< string | null > | null,
    name: string,
    Components?:  {
      __typename: "ModelComponentConnection",
      items:  Array< {
        __typename: "Component",
        Parts?:  {
          __typename: "ModelBOMConnection",
          items:  Array< {
            __typename: "BOM",
            childID: string,
            parentID: string,
            quantity?: number | null,
          } | null >,
        } | null,
        name: string,
        id: string,
        description?: string | null,
        weight?: string | null,
        tolerances?: string | null,
        thread_count?: number | null,
        root?: boolean | null,
        productID?: string | null,
        masking?: string | null,
        manufacturingprocessID: string,
        manufacturing_data?: string | null,
        dimensions?:  {
          __typename: "Dimensions",
          bounding_box_x?: string | null,
          bounding_box_y?: string | null,
          bounding_box_z?: string | null,
          volume?: string | null,
        } | null,
        costs?: string | null,
        comment_internal?: string | null,
        comment?: string | null,
        certificates?: Array< string | null > | null,
        barcode_type?: string | null,
        barcode_content?: string | null,
        article_number?: string | null,
      } | null >,
    } | null,
  } | null,
};

export type getStatusesQuery = {
  __type?:  {
    __typename: "__Type",
    name?: string | null,
    enumValues?:  Array< {
      __typename: "__EnumValue",
      name: string,
    } > | null,
  } | null,
};

export type customOnCreateMessageSubscription = {
  onCreateMessage?:  {
    __typename: "Message",
    id: string,
    body: string,
    author: string,
    channelID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateManufacturingRequirementMutationVariables = {
  input: CreateManufacturingRequirementInput,
  condition?: ModelManufacturingRequirementConditionInput | null,
};

export type CreateManufacturingRequirementMutation = {
  createManufacturingRequirement?:  {
    __typename: "ManufacturingRequirement",
    id: string,
    type: string,
    label: string,
    value?: string | null,
    description?: string | null,
    options?: Array< string | null > | null,
    manufacturingProcessID: string,
    ManufacturingProcess?:  {
      __typename: "ManufacturingProcess",
      id: string,
      category: ProcessCategory,
      process: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateManufacturingRequirementMutationVariables = {
  input: UpdateManufacturingRequirementInput,
  condition?: ModelManufacturingRequirementConditionInput | null,
};

export type UpdateManufacturingRequirementMutation = {
  updateManufacturingRequirement?:  {
    __typename: "ManufacturingRequirement",
    id: string,
    type: string,
    label: string,
    value?: string | null,
    description?: string | null,
    options?: Array< string | null > | null,
    manufacturingProcessID: string,
    ManufacturingProcess?:  {
      __typename: "ManufacturingProcess",
      id: string,
      category: ProcessCategory,
      process: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteManufacturingRequirementMutationVariables = {
  input: DeleteManufacturingRequirementInput,
  condition?: ModelManufacturingRequirementConditionInput | null,
};

export type DeleteManufacturingRequirementMutation = {
  deleteManufacturingRequirement?:  {
    __typename: "ManufacturingRequirement",
    id: string,
    type: string,
    label: string,
    value?: string | null,
    description?: string | null,
    options?: Array< string | null > | null,
    manufacturingProcessID: string,
    ManufacturingProcess?:  {
      __typename: "ManufacturingProcess",
      id: string,
      category: ProcessCategory,
      process: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateManufacturingProcessMutationVariables = {
  input: CreateManufacturingProcessInput,
  condition?: ModelManufacturingProcessConditionInput | null,
};

export type CreateManufacturingProcessMutation = {
  createManufacturingProcess?:  {
    __typename: "ManufacturingProcess",
    id: string,
    category: ProcessCategory,
    process: string,
    Requirements?:  {
      __typename: "ModelManufacturingRequirementConnection",
      nextToken?: string | null,
    } | null,
    Components?:  {
      __typename: "ModelComponentConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateManufacturingProcessMutationVariables = {
  input: UpdateManufacturingProcessInput,
  condition?: ModelManufacturingProcessConditionInput | null,
};

export type UpdateManufacturingProcessMutation = {
  updateManufacturingProcess?:  {
    __typename: "ManufacturingProcess",
    id: string,
    category: ProcessCategory,
    process: string,
    Requirements?:  {
      __typename: "ModelManufacturingRequirementConnection",
      nextToken?: string | null,
    } | null,
    Components?:  {
      __typename: "ModelComponentConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteManufacturingProcessMutationVariables = {
  input: DeleteManufacturingProcessInput,
  condition?: ModelManufacturingProcessConditionInput | null,
};

export type DeleteManufacturingProcessMutation = {
  deleteManufacturingProcess?:  {
    __typename: "ManufacturingProcess",
    id: string,
    category: ProcessCategory,
    process: string,
    Requirements?:  {
      __typename: "ModelManufacturingRequirementConnection",
      nextToken?: string | null,
    } | null,
    Components?:  {
      __typename: "ModelComponentConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateContractStatusMutationVariables = {
  input: CreateContractStatusInput,
  condition?: ModelContractStatusConditionInput | null,
};

export type CreateContractStatusMutation = {
  createContractStatus?:  {
    __typename: "ContractStatus",
    id: string,
    date: string,
    createdAt?: string | null,
    contractID: string,
    Contract?:  {
      __typename: "Contract",
      id: string,
      billingAddressID?: string | null,
      certificates?: Array< string | null > | null,
      comment_customer?: string | null,
      comment_internal?: string | null,
      currency?: string | null,
      deadline?: string | null,
      description?: string | null,
      price?: number | null,
      priority?: boolean | null,
      quantity?: number | null,
      read_only?: boolean | null,
      remarks?: string | null,
      shippingAddressID?: string | null,
      version?: number | null,
      wish_date?: string | null,
      productID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Status: Status,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateContractStatusMutationVariables = {
  input: UpdateContractStatusInput,
  condition?: ModelContractStatusConditionInput | null,
};

export type UpdateContractStatusMutation = {
  updateContractStatus?:  {
    __typename: "ContractStatus",
    id: string,
    date: string,
    createdAt?: string | null,
    contractID: string,
    Contract?:  {
      __typename: "Contract",
      id: string,
      billingAddressID?: string | null,
      certificates?: Array< string | null > | null,
      comment_customer?: string | null,
      comment_internal?: string | null,
      currency?: string | null,
      deadline?: string | null,
      description?: string | null,
      price?: number | null,
      priority?: boolean | null,
      quantity?: number | null,
      read_only?: boolean | null,
      remarks?: string | null,
      shippingAddressID?: string | null,
      version?: number | null,
      wish_date?: string | null,
      productID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Status: Status,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteContractStatusMutationVariables = {
  input: DeleteContractStatusInput,
  condition?: ModelContractStatusConditionInput | null,
};

export type DeleteContractStatusMutation = {
  deleteContractStatus?:  {
    __typename: "ContractStatus",
    id: string,
    date: string,
    createdAt?: string | null,
    contractID: string,
    Contract?:  {
      __typename: "Contract",
      id: string,
      billingAddressID?: string | null,
      certificates?: Array< string | null > | null,
      comment_customer?: string | null,
      comment_internal?: string | null,
      currency?: string | null,
      deadline?: string | null,
      description?: string | null,
      price?: number | null,
      priority?: boolean | null,
      quantity?: number | null,
      read_only?: boolean | null,
      remarks?: string | null,
      shippingAddressID?: string | null,
      version?: number | null,
      wish_date?: string | null,
      productID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Status: Status,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateContractRequestMutationVariables = {
  input: CreateContractRequestInput,
  condition?: ModelContractRequestConditionInput | null,
};

export type CreateContractRequestMutation = {
  createContractRequest?:  {
    __typename: "ContractRequest",
    id: string,
    date: string,
    createdAt?: string | null,
    contractID: string,
    Contract?:  {
      __typename: "Contract",
      id: string,
      billingAddressID?: string | null,
      certificates?: Array< string | null > | null,
      comment_customer?: string | null,
      comment_internal?: string | null,
      currency?: string | null,
      deadline?: string | null,
      description?: string | null,
      price?: number | null,
      priority?: boolean | null,
      quantity?: number | null,
      read_only?: boolean | null,
      remarks?: string | null,
      shippingAddressID?: string | null,
      version?: number | null,
      wish_date?: string | null,
      productID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    accepted: boolean,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateContractRequestMutationVariables = {
  input: UpdateContractRequestInput,
  condition?: ModelContractRequestConditionInput | null,
};

export type UpdateContractRequestMutation = {
  updateContractRequest?:  {
    __typename: "ContractRequest",
    id: string,
    date: string,
    createdAt?: string | null,
    contractID: string,
    Contract?:  {
      __typename: "Contract",
      id: string,
      billingAddressID?: string | null,
      certificates?: Array< string | null > | null,
      comment_customer?: string | null,
      comment_internal?: string | null,
      currency?: string | null,
      deadline?: string | null,
      description?: string | null,
      price?: number | null,
      priority?: boolean | null,
      quantity?: number | null,
      read_only?: boolean | null,
      remarks?: string | null,
      shippingAddressID?: string | null,
      version?: number | null,
      wish_date?: string | null,
      productID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    accepted: boolean,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteContractRequestMutationVariables = {
  input: DeleteContractRequestInput,
  condition?: ModelContractRequestConditionInput | null,
};

export type DeleteContractRequestMutation = {
  deleteContractRequest?:  {
    __typename: "ContractRequest",
    id: string,
    date: string,
    createdAt?: string | null,
    contractID: string,
    Contract?:  {
      __typename: "Contract",
      id: string,
      billingAddressID?: string | null,
      certificates?: Array< string | null > | null,
      comment_customer?: string | null,
      comment_internal?: string | null,
      currency?: string | null,
      deadline?: string | null,
      description?: string | null,
      price?: number | null,
      priority?: boolean | null,
      quantity?: number | null,
      read_only?: boolean | null,
      remarks?: string | null,
      shippingAddressID?: string | null,
      version?: number | null,
      wish_date?: string | null,
      productID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    accepted: boolean,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateContractMutationVariables = {
  input: CreateContractInput,
  condition?: ModelContractConditionInput | null,
};

export type CreateContractMutation = {
  createContract?:  {
    __typename: "Contract",
    id: string,
    billingAddressID?: string | null,
    certificates?: Array< string | null > | null,
    comment_customer?: string | null,
    comment_internal?: string | null,
    currency?: string | null,
    deadline?: string | null,
    description?: string | null,
    price?: number | null,
    priority?: boolean | null,
    quantity?: number | null,
    read_only?: boolean | null,
    remarks?: string | null,
    shippingAddressID?: string | null,
    version?: number | null,
    wish_date?: string | null,
    productID: string,
    Product?:  {
      __typename: "Product",
      id: string,
      name: string,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      comments?: string | null,
      extra_data?: Array< string | null > | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ShippingAddress?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      address_1: string,
      address_2?: string | null,
      zip_code: string,
      city: string,
      country?: string | null,
      type?: AddressType | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    BillingAddress?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      address_1: string,
      address_2?: string | null,
      zip_code: string,
      city: string,
      country?: string | null,
      type?: AddressType | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Status?:  {
      __typename: "ModelContractStatusConnection",
      nextToken?: string | null,
    } | null,
    Request?:  {
      __typename: "ModelContractRequestConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateContractMutationVariables = {
  input: UpdateContractInput,
  condition?: ModelContractConditionInput | null,
};

export type UpdateContractMutation = {
  updateContract?:  {
    __typename: "Contract",
    id: string,
    billingAddressID?: string | null,
    certificates?: Array< string | null > | null,
    comment_customer?: string | null,
    comment_internal?: string | null,
    currency?: string | null,
    deadline?: string | null,
    description?: string | null,
    price?: number | null,
    priority?: boolean | null,
    quantity?: number | null,
    read_only?: boolean | null,
    remarks?: string | null,
    shippingAddressID?: string | null,
    version?: number | null,
    wish_date?: string | null,
    productID: string,
    Product?:  {
      __typename: "Product",
      id: string,
      name: string,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      comments?: string | null,
      extra_data?: Array< string | null > | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ShippingAddress?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      address_1: string,
      address_2?: string | null,
      zip_code: string,
      city: string,
      country?: string | null,
      type?: AddressType | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    BillingAddress?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      address_1: string,
      address_2?: string | null,
      zip_code: string,
      city: string,
      country?: string | null,
      type?: AddressType | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Status?:  {
      __typename: "ModelContractStatusConnection",
      nextToken?: string | null,
    } | null,
    Request?:  {
      __typename: "ModelContractRequestConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteContractMutationVariables = {
  input: DeleteContractInput,
  condition?: ModelContractConditionInput | null,
};

export type DeleteContractMutation = {
  deleteContract?:  {
    __typename: "Contract",
    id: string,
    billingAddressID?: string | null,
    certificates?: Array< string | null > | null,
    comment_customer?: string | null,
    comment_internal?: string | null,
    currency?: string | null,
    deadline?: string | null,
    description?: string | null,
    price?: number | null,
    priority?: boolean | null,
    quantity?: number | null,
    read_only?: boolean | null,
    remarks?: string | null,
    shippingAddressID?: string | null,
    version?: number | null,
    wish_date?: string | null,
    productID: string,
    Product?:  {
      __typename: "Product",
      id: string,
      name: string,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      comments?: string | null,
      extra_data?: Array< string | null > | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ShippingAddress?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      address_1: string,
      address_2?: string | null,
      zip_code: string,
      city: string,
      country?: string | null,
      type?: AddressType | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    BillingAddress?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      address_1: string,
      address_2?: string | null,
      zip_code: string,
      city: string,
      country?: string | null,
      type?: AddressType | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Status?:  {
      __typename: "ModelContractStatusConnection",
      nextToken?: string | null,
    } | null,
    Request?:  {
      __typename: "ModelContractRequestConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateProductMutationVariables = {
  input: CreateProductInput,
  condition?: ModelProductConditionInput | null,
};

export type CreateProductMutation = {
  createProduct?:  {
    __typename: "Product",
    id: string,
    name: string,
    description?: string | null,
    article_number?: string | null,
    barcode_type?: string | null,
    barcode_content?: string | null,
    comments?: string | null,
    extra_data?: Array< string | null > | null,
    owner?: string | null,
    Components?:  {
      __typename: "ModelComponentConnection",
      nextToken?: string | null,
    } | null,
    Contracts?:  {
      __typename: "ModelContractConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateProductMutationVariables = {
  input: UpdateProductInput,
  condition?: ModelProductConditionInput | null,
};

export type UpdateProductMutation = {
  updateProduct?:  {
    __typename: "Product",
    id: string,
    name: string,
    description?: string | null,
    article_number?: string | null,
    barcode_type?: string | null,
    barcode_content?: string | null,
    comments?: string | null,
    extra_data?: Array< string | null > | null,
    owner?: string | null,
    Components?:  {
      __typename: "ModelComponentConnection",
      nextToken?: string | null,
    } | null,
    Contracts?:  {
      __typename: "ModelContractConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteProductMutationVariables = {
  input: DeleteProductInput,
  condition?: ModelProductConditionInput | null,
};

export type DeleteProductMutation = {
  deleteProduct?:  {
    __typename: "Product",
    id: string,
    name: string,
    description?: string | null,
    article_number?: string | null,
    barcode_type?: string | null,
    barcode_content?: string | null,
    comments?: string | null,
    extra_data?: Array< string | null > | null,
    owner?: string | null,
    Components?:  {
      __typename: "ModelComponentConnection",
      nextToken?: string | null,
    } | null,
    Contracts?:  {
      __typename: "ModelContractConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateComponentMutationVariables = {
  input: CreateComponentInput,
  condition?: ModelComponentConditionInput | null,
};

export type CreateComponentMutation = {
  createComponent?:  {
    __typename: "Component",
    id: string,
    name: string,
    root?: boolean | null,
    description?: string | null,
    dimensions?:  {
      __typename: "Dimensions",
      bounding_box_x?: string | null,
      bounding_box_y?: string | null,
      bounding_box_z?: string | null,
      volume?: string | null,
    } | null,
    article_number?: string | null,
    barcode_type?: string | null,
    barcode_content?: string | null,
    tolerances?: string | null,
    weight?: string | null,
    thread_count?: number | null,
    masking?: string | null,
    certificates?: Array< string | null > | null,
    comment?: string | null,
    comment_internal?: string | null,
    costs?: string | null,
    manufacturing_data?: string | null,
    productID?: string | null,
    Product?:  {
      __typename: "Product",
      id: string,
      name: string,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      comments?: string | null,
      extra_data?: Array< string | null > | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    manufacturingprocessID: string,
    ManufacturingProcess?:  {
      __typename: "ManufacturingProcess",
      id: string,
      category: ProcessCategory,
      process: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    Parts?:  {
      __typename: "ModelBOMConnection",
      nextToken?: string | null,
    } | null,
    Materials?:  {
      __typename: "ModelComponentMaterialsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateComponentMutationVariables = {
  input: UpdateComponentInput,
  condition?: ModelComponentConditionInput | null,
};

export type UpdateComponentMutation = {
  updateComponent?:  {
    __typename: "Component",
    id: string,
    name: string,
    root?: boolean | null,
    description?: string | null,
    dimensions?:  {
      __typename: "Dimensions",
      bounding_box_x?: string | null,
      bounding_box_y?: string | null,
      bounding_box_z?: string | null,
      volume?: string | null,
    } | null,
    article_number?: string | null,
    barcode_type?: string | null,
    barcode_content?: string | null,
    tolerances?: string | null,
    weight?: string | null,
    thread_count?: number | null,
    masking?: string | null,
    certificates?: Array< string | null > | null,
    comment?: string | null,
    comment_internal?: string | null,
    costs?: string | null,
    manufacturing_data?: string | null,
    productID?: string | null,
    Product?:  {
      __typename: "Product",
      id: string,
      name: string,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      comments?: string | null,
      extra_data?: Array< string | null > | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    manufacturingprocessID: string,
    ManufacturingProcess?:  {
      __typename: "ManufacturingProcess",
      id: string,
      category: ProcessCategory,
      process: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    Parts?:  {
      __typename: "ModelBOMConnection",
      nextToken?: string | null,
    } | null,
    Materials?:  {
      __typename: "ModelComponentMaterialsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteComponentMutationVariables = {
  input: DeleteComponentInput,
  condition?: ModelComponentConditionInput | null,
};

export type DeleteComponentMutation = {
  deleteComponent?:  {
    __typename: "Component",
    id: string,
    name: string,
    root?: boolean | null,
    description?: string | null,
    dimensions?:  {
      __typename: "Dimensions",
      bounding_box_x?: string | null,
      bounding_box_y?: string | null,
      bounding_box_z?: string | null,
      volume?: string | null,
    } | null,
    article_number?: string | null,
    barcode_type?: string | null,
    barcode_content?: string | null,
    tolerances?: string | null,
    weight?: string | null,
    thread_count?: number | null,
    masking?: string | null,
    certificates?: Array< string | null > | null,
    comment?: string | null,
    comment_internal?: string | null,
    costs?: string | null,
    manufacturing_data?: string | null,
    productID?: string | null,
    Product?:  {
      __typename: "Product",
      id: string,
      name: string,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      comments?: string | null,
      extra_data?: Array< string | null > | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    manufacturingprocessID: string,
    ManufacturingProcess?:  {
      __typename: "ManufacturingProcess",
      id: string,
      category: ProcessCategory,
      process: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    Parts?:  {
      __typename: "ModelBOMConnection",
      nextToken?: string | null,
    } | null,
    Materials?:  {
      __typename: "ModelComponentMaterialsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateBOMMutationVariables = {
  input: CreateBOMInput,
  condition?: ModelBOMConditionInput | null,
};

export type CreateBOMMutation = {
  createBOM?:  {
    __typename: "BOM",
    id: string,
    quantity?: number | null,
    parentID: string,
    childID: string,
    Child?:  {
      __typename: "Component",
      id: string,
      name: string,
      root?: boolean | null,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      tolerances?: string | null,
      weight?: string | null,
      thread_count?: number | null,
      masking?: string | null,
      certificates?: Array< string | null > | null,
      comment?: string | null,
      comment_internal?: string | null,
      costs?: string | null,
      manufacturing_data?: string | null,
      productID?: string | null,
      manufacturingprocessID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateBOMMutationVariables = {
  input: UpdateBOMInput,
  condition?: ModelBOMConditionInput | null,
};

export type UpdateBOMMutation = {
  updateBOM?:  {
    __typename: "BOM",
    id: string,
    quantity?: number | null,
    parentID: string,
    childID: string,
    Child?:  {
      __typename: "Component",
      id: string,
      name: string,
      root?: boolean | null,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      tolerances?: string | null,
      weight?: string | null,
      thread_count?: number | null,
      masking?: string | null,
      certificates?: Array< string | null > | null,
      comment?: string | null,
      comment_internal?: string | null,
      costs?: string | null,
      manufacturing_data?: string | null,
      productID?: string | null,
      manufacturingprocessID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteBOMMutationVariables = {
  input: DeleteBOMInput,
  condition?: ModelBOMConditionInput | null,
};

export type DeleteBOMMutation = {
  deleteBOM?:  {
    __typename: "BOM",
    id: string,
    quantity?: number | null,
    parentID: string,
    childID: string,
    Child?:  {
      __typename: "Component",
      id: string,
      name: string,
      root?: boolean | null,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      tolerances?: string | null,
      weight?: string | null,
      thread_count?: number | null,
      masking?: string | null,
      certificates?: Array< string | null > | null,
      comment?: string | null,
      comment_internal?: string | null,
      costs?: string | null,
      manufacturing_data?: string | null,
      productID?: string | null,
      manufacturingprocessID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateMaterialMutationVariables = {
  input: CreateMaterialInput,
  condition?: ModelMaterialConditionInput | null,
};

export type CreateMaterialMutation = {
  createMaterial?:  {
    __typename: "Material",
    id: string,
    name: string,
    type?: string | null,
    available?: boolean | null,
    description?: string | null,
    chemical_name?: string | null,
    numerical_name?: string | null,
    number?: string | null,
    Components?:  {
      __typename: "ModelComponentMaterialsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMaterialMutationVariables = {
  input: UpdateMaterialInput,
  condition?: ModelMaterialConditionInput | null,
};

export type UpdateMaterialMutation = {
  updateMaterial?:  {
    __typename: "Material",
    id: string,
    name: string,
    type?: string | null,
    available?: boolean | null,
    description?: string | null,
    chemical_name?: string | null,
    numerical_name?: string | null,
    number?: string | null,
    Components?:  {
      __typename: "ModelComponentMaterialsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMaterialMutationVariables = {
  input: DeleteMaterialInput,
  condition?: ModelMaterialConditionInput | null,
};

export type DeleteMaterialMutation = {
  deleteMaterial?:  {
    __typename: "Material",
    id: string,
    name: string,
    type?: string | null,
    available?: boolean | null,
    description?: string | null,
    chemical_name?: string | null,
    numerical_name?: string | null,
    number?: string | null,
    Components?:  {
      __typename: "ModelComponentMaterialsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateProfileMutationVariables = {
  input: CreateProfileInput,
  condition?: ModelProfileConditionInput | null,
};

export type CreateProfileMutation = {
  createProfile?:  {
    __typename: "Profile",
    id: string,
    company_name?: string | null,
    contact_person?: string | null,
    mobile_number?: string | null,
    vat_number?: string | null,
    preferred_contact_way?: string | null,
    preferred_incoterms?: string | null,
    phone_number?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateProfileMutationVariables = {
  input: UpdateProfileInput,
  condition?: ModelProfileConditionInput | null,
};

export type UpdateProfileMutation = {
  updateProfile?:  {
    __typename: "Profile",
    id: string,
    company_name?: string | null,
    contact_person?: string | null,
    mobile_number?: string | null,
    vat_number?: string | null,
    preferred_contact_way?: string | null,
    preferred_incoterms?: string | null,
    phone_number?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteProfileMutationVariables = {
  input: DeleteProfileInput,
  condition?: ModelProfileConditionInput | null,
};

export type DeleteProfileMutation = {
  deleteProfile?:  {
    __typename: "Profile",
    id: string,
    company_name?: string | null,
    contact_person?: string | null,
    mobile_number?: string | null,
    vat_number?: string | null,
    preferred_contact_way?: string | null,
    preferred_incoterms?: string | null,
    phone_number?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateAddressMutationVariables = {
  input: CreateAddressInput,
  condition?: ModelAddressConditionInput | null,
};

export type CreateAddressMutation = {
  createAddress?:  {
    __typename: "Address",
    id: string,
    name?: string | null,
    address_1: string,
    address_2?: string | null,
    zip_code: string,
    city: string,
    country?: string | null,
    type?: AddressType | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateAddressMutationVariables = {
  input: UpdateAddressInput,
  condition?: ModelAddressConditionInput | null,
};

export type UpdateAddressMutation = {
  updateAddress?:  {
    __typename: "Address",
    id: string,
    name?: string | null,
    address_1: string,
    address_2?: string | null,
    zip_code: string,
    city: string,
    country?: string | null,
    type?: AddressType | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteAddressMutationVariables = {
  input: DeleteAddressInput,
  condition?: ModelAddressConditionInput | null,
};

export type DeleteAddressMutation = {
  deleteAddress?:  {
    __typename: "Address",
    id: string,
    name?: string | null,
    address_1: string,
    address_2?: string | null,
    zip_code: string,
    city: string,
    country?: string | null,
    type?: AddressType | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateChannelMutationVariables = {
  input: CreateChannelInput,
  condition?: ModelChannelConditionInput | null,
};

export type CreateChannelMutation = {
  createChannel?:  {
    __typename: "Channel",
    id: string,
    address_from: string,
    address_to: string,
    reference?: string | null,
    visitedAt?: string | null,
    isNew?: boolean | null,
    product?: string | null,
    Messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateChannelMutationVariables = {
  input: UpdateChannelInput,
  condition?: ModelChannelConditionInput | null,
};

export type UpdateChannelMutation = {
  updateChannel?:  {
    __typename: "Channel",
    id: string,
    address_from: string,
    address_to: string,
    reference?: string | null,
    visitedAt?: string | null,
    isNew?: boolean | null,
    product?: string | null,
    Messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteChannelMutationVariables = {
  input: DeleteChannelInput,
  condition?: ModelChannelConditionInput | null,
};

export type DeleteChannelMutation = {
  deleteChannel?:  {
    __typename: "Channel",
    id: string,
    address_from: string,
    address_to: string,
    reference?: string | null,
    visitedAt?: string | null,
    isNew?: boolean | null,
    product?: string | null,
    Messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMessageMutationVariables = {
  input: CreateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type CreateMessageMutation = {
  createMessage?:  {
    __typename: "Message",
    id: string,
    body: string,
    author: string,
    channelID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMessageMutationVariables = {
  input: UpdateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type UpdateMessageMutation = {
  updateMessage?:  {
    __typename: "Message",
    id: string,
    body: string,
    author: string,
    channelID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMessageMutationVariables = {
  input: DeleteMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type DeleteMessageMutation = {
  deleteMessage?:  {
    __typename: "Message",
    id: string,
    body: string,
    author: string,
    channelID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUnauthRequestMutationVariables = {
  input: CreateUnauthRequestInput,
  condition?: ModelUnauthRequestConditionInput | null,
};

export type CreateUnauthRequestMutation = {
  createUnauthRequest?:  {
    __typename: "UnauthRequest",
    id: string,
    email: string,
    name: string,
    company_name: string,
    address: string,
    zip_code: string,
    city: string,
    country: string,
    phone_number: string,
    project_description: string,
    status: UnauthRequestStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUnauthRequestMutationVariables = {
  input: UpdateUnauthRequestInput,
  condition?: ModelUnauthRequestConditionInput | null,
};

export type UpdateUnauthRequestMutation = {
  updateUnauthRequest?:  {
    __typename: "UnauthRequest",
    id: string,
    email: string,
    name: string,
    company_name: string,
    address: string,
    zip_code: string,
    city: string,
    country: string,
    phone_number: string,
    project_description: string,
    status: UnauthRequestStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUnauthRequestMutationVariables = {
  input: DeleteUnauthRequestInput,
  condition?: ModelUnauthRequestConditionInput | null,
};

export type DeleteUnauthRequestMutation = {
  deleteUnauthRequest?:  {
    __typename: "UnauthRequest",
    id: string,
    email: string,
    name: string,
    company_name: string,
    address: string,
    zip_code: string,
    city: string,
    country: string,
    phone_number: string,
    project_description: string,
    status: UnauthRequestStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateEmailResultMutationVariables = {
  input: CreateEmailResultInput,
  condition?: ModelEmailResultConditionInput | null,
};

export type CreateEmailResultMutation = {
  createEmailResult?:  {
    __typename: "EmailResult",
    statusCode?: number | null,
    body?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateEmailResultMutationVariables = {
  input: UpdateEmailResultInput,
  condition?: ModelEmailResultConditionInput | null,
};

export type UpdateEmailResultMutation = {
  updateEmailResult?:  {
    __typename: "EmailResult",
    statusCode?: number | null,
    body?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteEmailResultMutationVariables = {
  input: DeleteEmailResultInput,
  condition?: ModelEmailResultConditionInput | null,
};

export type DeleteEmailResultMutation = {
  deleteEmailResult?:  {
    __typename: "EmailResult",
    statusCode?: number | null,
    body?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateComponentMaterialsMutationVariables = {
  input: CreateComponentMaterialsInput,
  condition?: ModelComponentMaterialsConditionInput | null,
};

export type CreateComponentMaterialsMutation = {
  createComponentMaterials?:  {
    __typename: "ComponentMaterials",
    id: string,
    componentID: string,
    materialID: string,
    component:  {
      __typename: "Component",
      id: string,
      name: string,
      root?: boolean | null,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      tolerances?: string | null,
      weight?: string | null,
      thread_count?: number | null,
      masking?: string | null,
      certificates?: Array< string | null > | null,
      comment?: string | null,
      comment_internal?: string | null,
      costs?: string | null,
      manufacturing_data?: string | null,
      productID?: string | null,
      manufacturingprocessID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    material:  {
      __typename: "Material",
      id: string,
      name: string,
      type?: string | null,
      available?: boolean | null,
      description?: string | null,
      chemical_name?: string | null,
      numerical_name?: string | null,
      number?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateComponentMaterialsMutationVariables = {
  input: UpdateComponentMaterialsInput,
  condition?: ModelComponentMaterialsConditionInput | null,
};

export type UpdateComponentMaterialsMutation = {
  updateComponentMaterials?:  {
    __typename: "ComponentMaterials",
    id: string,
    componentID: string,
    materialID: string,
    component:  {
      __typename: "Component",
      id: string,
      name: string,
      root?: boolean | null,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      tolerances?: string | null,
      weight?: string | null,
      thread_count?: number | null,
      masking?: string | null,
      certificates?: Array< string | null > | null,
      comment?: string | null,
      comment_internal?: string | null,
      costs?: string | null,
      manufacturing_data?: string | null,
      productID?: string | null,
      manufacturingprocessID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    material:  {
      __typename: "Material",
      id: string,
      name: string,
      type?: string | null,
      available?: boolean | null,
      description?: string | null,
      chemical_name?: string | null,
      numerical_name?: string | null,
      number?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteComponentMaterialsMutationVariables = {
  input: DeleteComponentMaterialsInput,
  condition?: ModelComponentMaterialsConditionInput | null,
};

export type DeleteComponentMaterialsMutation = {
  deleteComponentMaterials?:  {
    __typename: "ComponentMaterials",
    id: string,
    componentID: string,
    materialID: string,
    component:  {
      __typename: "Component",
      id: string,
      name: string,
      root?: boolean | null,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      tolerances?: string | null,
      weight?: string | null,
      thread_count?: number | null,
      masking?: string | null,
      certificates?: Array< string | null > | null,
      comment?: string | null,
      comment_internal?: string | null,
      costs?: string | null,
      manufacturing_data?: string | null,
      productID?: string | null,
      manufacturingprocessID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    material:  {
      __typename: "Material",
      id: string,
      name: string,
      type?: string | null,
      available?: boolean | null,
      description?: string | null,
      chemical_name?: string | null,
      numerical_name?: string | null,
      number?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ConvertCADtoSTLMutationVariables = {
  key: string,
};

export type ConvertCADtoSTLMutation = {
  convertCADtoSTL?:  {
    __typename: "ConversionResult",
    file_key?: string | null,
    format?: string | null,
    data?: string | null,
  } | null,
};

export type CopyProductMutationVariables = {
  id: string,
};

export type CopyProductMutation = {
  copyProduct?:  {
    __typename: "Product",
    id: string,
    name: string,
    description?: string | null,
    article_number?: string | null,
    barcode_type?: string | null,
    barcode_content?: string | null,
    comments?: string | null,
    extra_data?: Array< string | null > | null,
    owner?: string | null,
    Components?:  {
      __typename: "ModelComponentConnection",
      nextToken?: string | null,
    } | null,
    Contracts?:  {
      __typename: "ModelContractConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type SendEmailMutationVariables = {
  to: string,
  subject: EmailSubject,
  body: string,
};

export type SendEmailMutation = {
  sendEmail?:  {
    __typename: "EmailResult",
    statusCode?: number | null,
    body?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type AccessS3MutationVariables = {
  key: string,
  action?: S3Action | null,
};

export type AccessS3Mutation = {
  accessS3?: string | null,
};

export type GetManufacturingRequirementQueryVariables = {
  id: string,
};

export type GetManufacturingRequirementQuery = {
  getManufacturingRequirement?:  {
    __typename: "ManufacturingRequirement",
    id: string,
    type: string,
    label: string,
    value?: string | null,
    description?: string | null,
    options?: Array< string | null > | null,
    manufacturingProcessID: string,
    ManufacturingProcess?:  {
      __typename: "ManufacturingProcess",
      id: string,
      category: ProcessCategory,
      process: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListManufacturingRequirementsQueryVariables = {
  filter?: ModelManufacturingRequirementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListManufacturingRequirementsQuery = {
  listManufacturingRequirements?:  {
    __typename: "ModelManufacturingRequirementConnection",
    items:  Array< {
      __typename: "ManufacturingRequirement",
      id: string,
      type: string,
      label: string,
      value?: string | null,
      description?: string | null,
      options?: Array< string | null > | null,
      manufacturingProcessID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetManufacturingProcessQueryVariables = {
  id: string,
};

export type GetManufacturingProcessQuery = {
  getManufacturingProcess?:  {
    __typename: "ManufacturingProcess",
    id: string,
    category: ProcessCategory,
    process: string,
    Requirements?:  {
      __typename: "ModelManufacturingRequirementConnection",
      nextToken?: string | null,
    } | null,
    Components?:  {
      __typename: "ModelComponentConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListManufacturingProcessesQueryVariables = {
  filter?: ModelManufacturingProcessFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListManufacturingProcessesQuery = {
  listManufacturingProcesses?:  {
    __typename: "ModelManufacturingProcessConnection",
    items:  Array< {
      __typename: "ManufacturingProcess",
      id: string,
      category: ProcessCategory,
      process: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetContractStatusQueryVariables = {
  id: string,
};

export type GetContractStatusQuery = {
  getContractStatus?:  {
    __typename: "ContractStatus",
    id: string,
    date: string,
    createdAt?: string | null,
    contractID: string,
    Contract?:  {
      __typename: "Contract",
      id: string,
      billingAddressID?: string | null,
      certificates?: Array< string | null > | null,
      comment_customer?: string | null,
      comment_internal?: string | null,
      currency?: string | null,
      deadline?: string | null,
      description?: string | null,
      price?: number | null,
      priority?: boolean | null,
      quantity?: number | null,
      read_only?: boolean | null,
      remarks?: string | null,
      shippingAddressID?: string | null,
      version?: number | null,
      wish_date?: string | null,
      productID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Status: Status,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type ListContractStatusesQueryVariables = {
  filter?: ModelContractStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContractStatusesQuery = {
  listContractStatuses?:  {
    __typename: "ModelContractStatusConnection",
    items:  Array< {
      __typename: "ContractStatus",
      id: string,
      date: string,
      createdAt?: string | null,
      contractID: string,
      Status: Status,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ContractStatusByContractQueryVariables = {
  contractID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContractStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContractStatusByContractQuery = {
  contractStatusByContract?:  {
    __typename: "ModelContractStatusConnection",
    items:  Array< {
      __typename: "ContractStatus",
      id: string,
      date: string,
      createdAt?: string | null,
      contractID: string,
      Status: Status,
      owner?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetContractRequestQueryVariables = {
  id: string,
};

export type GetContractRequestQuery = {
  getContractRequest?:  {
    __typename: "ContractRequest",
    id: string,
    date: string,
    createdAt?: string | null,
    contractID: string,
    Contract?:  {
      __typename: "Contract",
      id: string,
      billingAddressID?: string | null,
      certificates?: Array< string | null > | null,
      comment_customer?: string | null,
      comment_internal?: string | null,
      currency?: string | null,
      deadline?: string | null,
      description?: string | null,
      price?: number | null,
      priority?: boolean | null,
      quantity?: number | null,
      read_only?: boolean | null,
      remarks?: string | null,
      shippingAddressID?: string | null,
      version?: number | null,
      wish_date?: string | null,
      productID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    accepted: boolean,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListContractRequestsQueryVariables = {
  filter?: ModelContractRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContractRequestsQuery = {
  listContractRequests?:  {
    __typename: "ModelContractRequestConnection",
    items:  Array< {
      __typename: "ContractRequest",
      id: string,
      date: string,
      createdAt?: string | null,
      contractID: string,
      accepted: boolean,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ContractRequestByContractQueryVariables = {
  contractID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContractRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContractRequestByContractQuery = {
  contractRequestByContract?:  {
    __typename: "ModelContractRequestConnection",
    items:  Array< {
      __typename: "ContractRequest",
      id: string,
      date: string,
      createdAt?: string | null,
      contractID: string,
      accepted: boolean,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetContractQueryVariables = {
  id: string,
};

export type GetContractQuery = {
  getContract?:  {
    __typename: "Contract",
    id: string,
    billingAddressID?: string | null,
    certificates?: Array< string | null > | null,
    comment_customer?: string | null,
    comment_internal?: string | null,
    currency?: string | null,
    deadline?: string | null,
    description?: string | null,
    price?: number | null,
    priority?: boolean | null,
    quantity?: number | null,
    read_only?: boolean | null,
    remarks?: string | null,
    shippingAddressID?: string | null,
    version?: number | null,
    wish_date?: string | null,
    productID: string,
    Product?:  {
      __typename: "Product",
      id: string,
      name: string,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      comments?: string | null,
      extra_data?: Array< string | null > | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ShippingAddress?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      address_1: string,
      address_2?: string | null,
      zip_code: string,
      city: string,
      country?: string | null,
      type?: AddressType | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    BillingAddress?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      address_1: string,
      address_2?: string | null,
      zip_code: string,
      city: string,
      country?: string | null,
      type?: AddressType | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Status?:  {
      __typename: "ModelContractStatusConnection",
      nextToken?: string | null,
    } | null,
    Request?:  {
      __typename: "ModelContractRequestConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListContractsQueryVariables = {
  filter?: ModelContractFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContractsQuery = {
  listContracts?:  {
    __typename: "ModelContractConnection",
    items:  Array< {
      __typename: "Contract",
      id: string,
      billingAddressID?: string | null,
      certificates?: Array< string | null > | null,
      comment_customer?: string | null,
      comment_internal?: string | null,
      currency?: string | null,
      deadline?: string | null,
      description?: string | null,
      price?: number | null,
      priority?: boolean | null,
      quantity?: number | null,
      read_only?: boolean | null,
      remarks?: string | null,
      shippingAddressID?: string | null,
      version?: number | null,
      wish_date?: string | null,
      productID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ContractByProductQueryVariables = {
  productID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContractFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContractByProductQuery = {
  contractByProduct?:  {
    __typename: "ModelContractConnection",
    items:  Array< {
      __typename: "Contract",
      id: string,
      billingAddressID?: string | null,
      certificates?: Array< string | null > | null,
      comment_customer?: string | null,
      comment_internal?: string | null,
      currency?: string | null,
      deadline?: string | null,
      description?: string | null,
      price?: number | null,
      priority?: boolean | null,
      quantity?: number | null,
      read_only?: boolean | null,
      remarks?: string | null,
      shippingAddressID?: string | null,
      version?: number | null,
      wish_date?: string | null,
      productID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductQueryVariables = {
  id: string,
};

export type GetProductQuery = {
  getProduct?:  {
    __typename: "Product",
    id: string,
    name: string,
    description?: string | null,
    article_number?: string | null,
    barcode_type?: string | null,
    barcode_content?: string | null,
    comments?: string | null,
    extra_data?: Array< string | null > | null,
    owner?: string | null,
    Components?:  {
      __typename: "ModelComponentConnection",
      nextToken?: string | null,
    } | null,
    Contracts?:  {
      __typename: "ModelContractConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListProductsQueryVariables = {
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductsQuery = {
  listProducts?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      name: string,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      comments?: string | null,
      extra_data?: Array< string | null > | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetComponentQueryVariables = {
  id: string,
};

export type GetComponentQuery = {
  getComponent?:  {
    __typename: "Component",
    id: string,
    name: string,
    root?: boolean | null,
    description?: string | null,
    dimensions?:  {
      __typename: "Dimensions",
      bounding_box_x?: string | null,
      bounding_box_y?: string | null,
      bounding_box_z?: string | null,
      volume?: string | null,
    } | null,
    article_number?: string | null,
    barcode_type?: string | null,
    barcode_content?: string | null,
    tolerances?: string | null,
    weight?: string | null,
    thread_count?: number | null,
    masking?: string | null,
    certificates?: Array< string | null > | null,
    comment?: string | null,
    comment_internal?: string | null,
    costs?: string | null,
    manufacturing_data?: string | null,
    productID?: string | null,
    Product?:  {
      __typename: "Product",
      id: string,
      name: string,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      comments?: string | null,
      extra_data?: Array< string | null > | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    manufacturingprocessID: string,
    ManufacturingProcess?:  {
      __typename: "ManufacturingProcess",
      id: string,
      category: ProcessCategory,
      process: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    Parts?:  {
      __typename: "ModelBOMConnection",
      nextToken?: string | null,
    } | null,
    Materials?:  {
      __typename: "ModelComponentMaterialsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListComponentsQueryVariables = {
  filter?: ModelComponentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListComponentsQuery = {
  listComponents?:  {
    __typename: "ModelComponentConnection",
    items:  Array< {
      __typename: "Component",
      id: string,
      name: string,
      root?: boolean | null,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      tolerances?: string | null,
      weight?: string | null,
      thread_count?: number | null,
      masking?: string | null,
      certificates?: Array< string | null > | null,
      comment?: string | null,
      comment_internal?: string | null,
      costs?: string | null,
      manufacturing_data?: string | null,
      productID?: string | null,
      manufacturingprocessID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ComponentByProductQueryVariables = {
  productID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelComponentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ComponentByProductQuery = {
  componentByProduct?:  {
    __typename: "ModelComponentConnection",
    items:  Array< {
      __typename: "Component",
      id: string,
      name: string,
      root?: boolean | null,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      tolerances?: string | null,
      weight?: string | null,
      thread_count?: number | null,
      masking?: string | null,
      certificates?: Array< string | null > | null,
      comment?: string | null,
      comment_internal?: string | null,
      costs?: string | null,
      manufacturing_data?: string | null,
      productID?: string | null,
      manufacturingprocessID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBOMQueryVariables = {
  id: string,
};

export type GetBOMQuery = {
  getBOM?:  {
    __typename: "BOM",
    id: string,
    quantity?: number | null,
    parentID: string,
    childID: string,
    Child?:  {
      __typename: "Component",
      id: string,
      name: string,
      root?: boolean | null,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      tolerances?: string | null,
      weight?: string | null,
      thread_count?: number | null,
      masking?: string | null,
      certificates?: Array< string | null > | null,
      comment?: string | null,
      comment_internal?: string | null,
      costs?: string | null,
      manufacturing_data?: string | null,
      productID?: string | null,
      manufacturingprocessID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListBOMSQueryVariables = {
  filter?: ModelBOMFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBOMSQuery = {
  listBOMS?:  {
    __typename: "ModelBOMConnection",
    items:  Array< {
      __typename: "BOM",
      id: string,
      quantity?: number | null,
      parentID: string,
      childID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMaterialQueryVariables = {
  id: string,
};

export type GetMaterialQuery = {
  getMaterial?:  {
    __typename: "Material",
    id: string,
    name: string,
    type?: string | null,
    available?: boolean | null,
    description?: string | null,
    chemical_name?: string | null,
    numerical_name?: string | null,
    number?: string | null,
    Components?:  {
      __typename: "ModelComponentMaterialsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMaterialsQueryVariables = {
  filter?: ModelMaterialFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMaterialsQuery = {
  listMaterials?:  {
    __typename: "ModelMaterialConnection",
    items:  Array< {
      __typename: "Material",
      id: string,
      name: string,
      type?: string | null,
      available?: boolean | null,
      description?: string | null,
      chemical_name?: string | null,
      numerical_name?: string | null,
      number?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProfileQueryVariables = {
  id: string,
};

export type GetProfileQuery = {
  getProfile?:  {
    __typename: "Profile",
    id: string,
    company_name?: string | null,
    contact_person?: string | null,
    mobile_number?: string | null,
    vat_number?: string | null,
    preferred_contact_way?: string | null,
    preferred_incoterms?: string | null,
    phone_number?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListProfilesQueryVariables = {
  filter?: ModelProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProfilesQuery = {
  listProfiles?:  {
    __typename: "ModelProfileConnection",
    items:  Array< {
      __typename: "Profile",
      id: string,
      company_name?: string | null,
      contact_person?: string | null,
      mobile_number?: string | null,
      vat_number?: string | null,
      preferred_contact_way?: string | null,
      preferred_incoterms?: string | null,
      phone_number?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAddressQueryVariables = {
  id: string,
};

export type GetAddressQuery = {
  getAddress?:  {
    __typename: "Address",
    id: string,
    name?: string | null,
    address_1: string,
    address_2?: string | null,
    zip_code: string,
    city: string,
    country?: string | null,
    type?: AddressType | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListAddressesQueryVariables = {
  filter?: ModelAddressFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAddressesQuery = {
  listAddresses?:  {
    __typename: "ModelAddressConnection",
    items:  Array< {
      __typename: "Address",
      id: string,
      name?: string | null,
      address_1: string,
      address_2?: string | null,
      zip_code: string,
      city: string,
      country?: string | null,
      type?: AddressType | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetChannelQueryVariables = {
  id: string,
};

export type GetChannelQuery = {
  getChannel?:  {
    __typename: "Channel",
    id: string,
    address_from: string,
    address_to: string,
    reference?: string | null,
    visitedAt?: string | null,
    isNew?: boolean | null,
    product?: string | null,
    Messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListChannelsQueryVariables = {
  filter?: ModelChannelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChannelsQuery = {
  listChannels?:  {
    __typename: "ModelChannelConnection",
    items:  Array< {
      __typename: "Channel",
      id: string,
      address_from: string,
      address_to: string,
      reference?: string | null,
      visitedAt?: string | null,
      isNew?: boolean | null,
      product?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMessageQueryVariables = {
  id: string,
};

export type GetMessageQuery = {
  getMessage?:  {
    __typename: "Message",
    id: string,
    body: string,
    author: string,
    channelID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMessagesQueryVariables = {
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMessagesQuery = {
  listMessages?:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      body: string,
      author: string,
      channelID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MessageByChannelQueryVariables = {
  channelID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MessageByChannelQuery = {
  messageByChannel?:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      body: string,
      author: string,
      channelID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUnauthRequestQueryVariables = {
  id: string,
};

export type GetUnauthRequestQuery = {
  getUnauthRequest?:  {
    __typename: "UnauthRequest",
    id: string,
    email: string,
    name: string,
    company_name: string,
    address: string,
    zip_code: string,
    city: string,
    country: string,
    phone_number: string,
    project_description: string,
    status: UnauthRequestStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUnauthRequestsQueryVariables = {
  filter?: ModelUnauthRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUnauthRequestsQuery = {
  listUnauthRequests?:  {
    __typename: "ModelUnauthRequestConnection",
    items:  Array< {
      __typename: "UnauthRequest",
      id: string,
      email: string,
      name: string,
      company_name: string,
      address: string,
      zip_code: string,
      city: string,
      country: string,
      phone_number: string,
      project_description: string,
      status: UnauthRequestStatus,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEmailResultQueryVariables = {
  id: string,
};

export type GetEmailResultQuery = {
  getEmailResult?:  {
    __typename: "EmailResult",
    statusCode?: number | null,
    body?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListEmailResultsQueryVariables = {
  filter?: ModelEmailResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEmailResultsQuery = {
  listEmailResults?:  {
    __typename: "ModelEmailResultConnection",
    items:  Array< {
      __typename: "EmailResult",
      statusCode?: number | null,
      body?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetComponentMaterialsQueryVariables = {
  id: string,
};

export type GetComponentMaterialsQuery = {
  getComponentMaterials?:  {
    __typename: "ComponentMaterials",
    id: string,
    componentID: string,
    materialID: string,
    component:  {
      __typename: "Component",
      id: string,
      name: string,
      root?: boolean | null,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      tolerances?: string | null,
      weight?: string | null,
      thread_count?: number | null,
      masking?: string | null,
      certificates?: Array< string | null > | null,
      comment?: string | null,
      comment_internal?: string | null,
      costs?: string | null,
      manufacturing_data?: string | null,
      productID?: string | null,
      manufacturingprocessID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    material:  {
      __typename: "Material",
      id: string,
      name: string,
      type?: string | null,
      available?: boolean | null,
      description?: string | null,
      chemical_name?: string | null,
      numerical_name?: string | null,
      number?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListComponentMaterialsQueryVariables = {
  filter?: ModelComponentMaterialsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListComponentMaterialsQuery = {
  listComponentMaterials?:  {
    __typename: "ModelComponentMaterialsConnection",
    items:  Array< {
      __typename: "ComponentMaterials",
      id: string,
      componentID: string,
      materialID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateManufacturingRequirementSubscriptionVariables = {
  filter?: ModelSubscriptionManufacturingRequirementFilterInput | null,
};

export type OnCreateManufacturingRequirementSubscription = {
  onCreateManufacturingRequirement?:  {
    __typename: "ManufacturingRequirement",
    id: string,
    type: string,
    label: string,
    value?: string | null,
    description?: string | null,
    options?: Array< string | null > | null,
    manufacturingProcessID: string,
    ManufacturingProcess?:  {
      __typename: "ManufacturingProcess",
      id: string,
      category: ProcessCategory,
      process: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateManufacturingRequirementSubscriptionVariables = {
  filter?: ModelSubscriptionManufacturingRequirementFilterInput | null,
};

export type OnUpdateManufacturingRequirementSubscription = {
  onUpdateManufacturingRequirement?:  {
    __typename: "ManufacturingRequirement",
    id: string,
    type: string,
    label: string,
    value?: string | null,
    description?: string | null,
    options?: Array< string | null > | null,
    manufacturingProcessID: string,
    ManufacturingProcess?:  {
      __typename: "ManufacturingProcess",
      id: string,
      category: ProcessCategory,
      process: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteManufacturingRequirementSubscriptionVariables = {
  filter?: ModelSubscriptionManufacturingRequirementFilterInput | null,
};

export type OnDeleteManufacturingRequirementSubscription = {
  onDeleteManufacturingRequirement?:  {
    __typename: "ManufacturingRequirement",
    id: string,
    type: string,
    label: string,
    value?: string | null,
    description?: string | null,
    options?: Array< string | null > | null,
    manufacturingProcessID: string,
    ManufacturingProcess?:  {
      __typename: "ManufacturingProcess",
      id: string,
      category: ProcessCategory,
      process: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateManufacturingProcessSubscriptionVariables = {
  filter?: ModelSubscriptionManufacturingProcessFilterInput | null,
};

export type OnCreateManufacturingProcessSubscription = {
  onCreateManufacturingProcess?:  {
    __typename: "ManufacturingProcess",
    id: string,
    category: ProcessCategory,
    process: string,
    Requirements?:  {
      __typename: "ModelManufacturingRequirementConnection",
      nextToken?: string | null,
    } | null,
    Components?:  {
      __typename: "ModelComponentConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateManufacturingProcessSubscriptionVariables = {
  filter?: ModelSubscriptionManufacturingProcessFilterInput | null,
};

export type OnUpdateManufacturingProcessSubscription = {
  onUpdateManufacturingProcess?:  {
    __typename: "ManufacturingProcess",
    id: string,
    category: ProcessCategory,
    process: string,
    Requirements?:  {
      __typename: "ModelManufacturingRequirementConnection",
      nextToken?: string | null,
    } | null,
    Components?:  {
      __typename: "ModelComponentConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteManufacturingProcessSubscriptionVariables = {
  filter?: ModelSubscriptionManufacturingProcessFilterInput | null,
};

export type OnDeleteManufacturingProcessSubscription = {
  onDeleteManufacturingProcess?:  {
    __typename: "ManufacturingProcess",
    id: string,
    category: ProcessCategory,
    process: string,
    Requirements?:  {
      __typename: "ModelManufacturingRequirementConnection",
      nextToken?: string | null,
    } | null,
    Components?:  {
      __typename: "ModelComponentConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateContractStatusSubscriptionVariables = {
  filter?: ModelSubscriptionContractStatusFilterInput | null,
  owner?: string | null,
};

export type OnCreateContractStatusSubscription = {
  onCreateContractStatus?:  {
    __typename: "ContractStatus",
    id: string,
    date: string,
    createdAt?: string | null,
    contractID: string,
    Contract?:  {
      __typename: "Contract",
      id: string,
      billingAddressID?: string | null,
      certificates?: Array< string | null > | null,
      comment_customer?: string | null,
      comment_internal?: string | null,
      currency?: string | null,
      deadline?: string | null,
      description?: string | null,
      price?: number | null,
      priority?: boolean | null,
      quantity?: number | null,
      read_only?: boolean | null,
      remarks?: string | null,
      shippingAddressID?: string | null,
      version?: number | null,
      wish_date?: string | null,
      productID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Status: Status,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateContractStatusSubscriptionVariables = {
  filter?: ModelSubscriptionContractStatusFilterInput | null,
  owner?: string | null,
};

export type OnUpdateContractStatusSubscription = {
  onUpdateContractStatus?:  {
    __typename: "ContractStatus",
    id: string,
    date: string,
    createdAt?: string | null,
    contractID: string,
    Contract?:  {
      __typename: "Contract",
      id: string,
      billingAddressID?: string | null,
      certificates?: Array< string | null > | null,
      comment_customer?: string | null,
      comment_internal?: string | null,
      currency?: string | null,
      deadline?: string | null,
      description?: string | null,
      price?: number | null,
      priority?: boolean | null,
      quantity?: number | null,
      read_only?: boolean | null,
      remarks?: string | null,
      shippingAddressID?: string | null,
      version?: number | null,
      wish_date?: string | null,
      productID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Status: Status,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteContractStatusSubscriptionVariables = {
  filter?: ModelSubscriptionContractStatusFilterInput | null,
  owner?: string | null,
};

export type OnDeleteContractStatusSubscription = {
  onDeleteContractStatus?:  {
    __typename: "ContractStatus",
    id: string,
    date: string,
    createdAt?: string | null,
    contractID: string,
    Contract?:  {
      __typename: "Contract",
      id: string,
      billingAddressID?: string | null,
      certificates?: Array< string | null > | null,
      comment_customer?: string | null,
      comment_internal?: string | null,
      currency?: string | null,
      deadline?: string | null,
      description?: string | null,
      price?: number | null,
      priority?: boolean | null,
      quantity?: number | null,
      read_only?: boolean | null,
      remarks?: string | null,
      shippingAddressID?: string | null,
      version?: number | null,
      wish_date?: string | null,
      productID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Status: Status,
    owner?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateContractRequestSubscriptionVariables = {
  filter?: ModelSubscriptionContractRequestFilterInput | null,
  owner?: string | null,
};

export type OnCreateContractRequestSubscription = {
  onCreateContractRequest?:  {
    __typename: "ContractRequest",
    id: string,
    date: string,
    createdAt?: string | null,
    contractID: string,
    Contract?:  {
      __typename: "Contract",
      id: string,
      billingAddressID?: string | null,
      certificates?: Array< string | null > | null,
      comment_customer?: string | null,
      comment_internal?: string | null,
      currency?: string | null,
      deadline?: string | null,
      description?: string | null,
      price?: number | null,
      priority?: boolean | null,
      quantity?: number | null,
      read_only?: boolean | null,
      remarks?: string | null,
      shippingAddressID?: string | null,
      version?: number | null,
      wish_date?: string | null,
      productID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    accepted: boolean,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateContractRequestSubscriptionVariables = {
  filter?: ModelSubscriptionContractRequestFilterInput | null,
  owner?: string | null,
};

export type OnUpdateContractRequestSubscription = {
  onUpdateContractRequest?:  {
    __typename: "ContractRequest",
    id: string,
    date: string,
    createdAt?: string | null,
    contractID: string,
    Contract?:  {
      __typename: "Contract",
      id: string,
      billingAddressID?: string | null,
      certificates?: Array< string | null > | null,
      comment_customer?: string | null,
      comment_internal?: string | null,
      currency?: string | null,
      deadline?: string | null,
      description?: string | null,
      price?: number | null,
      priority?: boolean | null,
      quantity?: number | null,
      read_only?: boolean | null,
      remarks?: string | null,
      shippingAddressID?: string | null,
      version?: number | null,
      wish_date?: string | null,
      productID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    accepted: boolean,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteContractRequestSubscriptionVariables = {
  filter?: ModelSubscriptionContractRequestFilterInput | null,
  owner?: string | null,
};

export type OnDeleteContractRequestSubscription = {
  onDeleteContractRequest?:  {
    __typename: "ContractRequest",
    id: string,
    date: string,
    createdAt?: string | null,
    contractID: string,
    Contract?:  {
      __typename: "Contract",
      id: string,
      billingAddressID?: string | null,
      certificates?: Array< string | null > | null,
      comment_customer?: string | null,
      comment_internal?: string | null,
      currency?: string | null,
      deadline?: string | null,
      description?: string | null,
      price?: number | null,
      priority?: boolean | null,
      quantity?: number | null,
      read_only?: boolean | null,
      remarks?: string | null,
      shippingAddressID?: string | null,
      version?: number | null,
      wish_date?: string | null,
      productID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    accepted: boolean,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateContractSubscriptionVariables = {
  filter?: ModelSubscriptionContractFilterInput | null,
  owner?: string | null,
};

export type OnCreateContractSubscription = {
  onCreateContract?:  {
    __typename: "Contract",
    id: string,
    billingAddressID?: string | null,
    certificates?: Array< string | null > | null,
    comment_customer?: string | null,
    comment_internal?: string | null,
    currency?: string | null,
    deadline?: string | null,
    description?: string | null,
    price?: number | null,
    priority?: boolean | null,
    quantity?: number | null,
    read_only?: boolean | null,
    remarks?: string | null,
    shippingAddressID?: string | null,
    version?: number | null,
    wish_date?: string | null,
    productID: string,
    Product?:  {
      __typename: "Product",
      id: string,
      name: string,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      comments?: string | null,
      extra_data?: Array< string | null > | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ShippingAddress?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      address_1: string,
      address_2?: string | null,
      zip_code: string,
      city: string,
      country?: string | null,
      type?: AddressType | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    BillingAddress?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      address_1: string,
      address_2?: string | null,
      zip_code: string,
      city: string,
      country?: string | null,
      type?: AddressType | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Status?:  {
      __typename: "ModelContractStatusConnection",
      nextToken?: string | null,
    } | null,
    Request?:  {
      __typename: "ModelContractRequestConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateContractSubscriptionVariables = {
  filter?: ModelSubscriptionContractFilterInput | null,
  owner?: string | null,
};

export type OnUpdateContractSubscription = {
  onUpdateContract?:  {
    __typename: "Contract",
    id: string,
    billingAddressID?: string | null,
    certificates?: Array< string | null > | null,
    comment_customer?: string | null,
    comment_internal?: string | null,
    currency?: string | null,
    deadline?: string | null,
    description?: string | null,
    price?: number | null,
    priority?: boolean | null,
    quantity?: number | null,
    read_only?: boolean | null,
    remarks?: string | null,
    shippingAddressID?: string | null,
    version?: number | null,
    wish_date?: string | null,
    productID: string,
    Product?:  {
      __typename: "Product",
      id: string,
      name: string,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      comments?: string | null,
      extra_data?: Array< string | null > | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ShippingAddress?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      address_1: string,
      address_2?: string | null,
      zip_code: string,
      city: string,
      country?: string | null,
      type?: AddressType | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    BillingAddress?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      address_1: string,
      address_2?: string | null,
      zip_code: string,
      city: string,
      country?: string | null,
      type?: AddressType | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Status?:  {
      __typename: "ModelContractStatusConnection",
      nextToken?: string | null,
    } | null,
    Request?:  {
      __typename: "ModelContractRequestConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteContractSubscriptionVariables = {
  filter?: ModelSubscriptionContractFilterInput | null,
  owner?: string | null,
};

export type OnDeleteContractSubscription = {
  onDeleteContract?:  {
    __typename: "Contract",
    id: string,
    billingAddressID?: string | null,
    certificates?: Array< string | null > | null,
    comment_customer?: string | null,
    comment_internal?: string | null,
    currency?: string | null,
    deadline?: string | null,
    description?: string | null,
    price?: number | null,
    priority?: boolean | null,
    quantity?: number | null,
    read_only?: boolean | null,
    remarks?: string | null,
    shippingAddressID?: string | null,
    version?: number | null,
    wish_date?: string | null,
    productID: string,
    Product?:  {
      __typename: "Product",
      id: string,
      name: string,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      comments?: string | null,
      extra_data?: Array< string | null > | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ShippingAddress?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      address_1: string,
      address_2?: string | null,
      zip_code: string,
      city: string,
      country?: string | null,
      type?: AddressType | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    BillingAddress?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      address_1: string,
      address_2?: string | null,
      zip_code: string,
      city: string,
      country?: string | null,
      type?: AddressType | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Status?:  {
      __typename: "ModelContractStatusConnection",
      nextToken?: string | null,
    } | null,
    Request?:  {
      __typename: "ModelContractRequestConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateProductSubscriptionVariables = {
  filter?: ModelSubscriptionProductFilterInput | null,
  owner?: string | null,
};

export type OnCreateProductSubscription = {
  onCreateProduct?:  {
    __typename: "Product",
    id: string,
    name: string,
    description?: string | null,
    article_number?: string | null,
    barcode_type?: string | null,
    barcode_content?: string | null,
    comments?: string | null,
    extra_data?: Array< string | null > | null,
    owner?: string | null,
    Components?:  {
      __typename: "ModelComponentConnection",
      nextToken?: string | null,
    } | null,
    Contracts?:  {
      __typename: "ModelContractConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateProductSubscriptionVariables = {
  filter?: ModelSubscriptionProductFilterInput | null,
  owner?: string | null,
};

export type OnUpdateProductSubscription = {
  onUpdateProduct?:  {
    __typename: "Product",
    id: string,
    name: string,
    description?: string | null,
    article_number?: string | null,
    barcode_type?: string | null,
    barcode_content?: string | null,
    comments?: string | null,
    extra_data?: Array< string | null > | null,
    owner?: string | null,
    Components?:  {
      __typename: "ModelComponentConnection",
      nextToken?: string | null,
    } | null,
    Contracts?:  {
      __typename: "ModelContractConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteProductSubscriptionVariables = {
  filter?: ModelSubscriptionProductFilterInput | null,
  owner?: string | null,
};

export type OnDeleteProductSubscription = {
  onDeleteProduct?:  {
    __typename: "Product",
    id: string,
    name: string,
    description?: string | null,
    article_number?: string | null,
    barcode_type?: string | null,
    barcode_content?: string | null,
    comments?: string | null,
    extra_data?: Array< string | null > | null,
    owner?: string | null,
    Components?:  {
      __typename: "ModelComponentConnection",
      nextToken?: string | null,
    } | null,
    Contracts?:  {
      __typename: "ModelContractConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateComponentSubscriptionVariables = {
  filter?: ModelSubscriptionComponentFilterInput | null,
  owner?: string | null,
};

export type OnCreateComponentSubscription = {
  onCreateComponent?:  {
    __typename: "Component",
    id: string,
    name: string,
    root?: boolean | null,
    description?: string | null,
    dimensions?:  {
      __typename: "Dimensions",
      bounding_box_x?: string | null,
      bounding_box_y?: string | null,
      bounding_box_z?: string | null,
      volume?: string | null,
    } | null,
    article_number?: string | null,
    barcode_type?: string | null,
    barcode_content?: string | null,
    tolerances?: string | null,
    weight?: string | null,
    thread_count?: number | null,
    masking?: string | null,
    certificates?: Array< string | null > | null,
    comment?: string | null,
    comment_internal?: string | null,
    costs?: string | null,
    manufacturing_data?: string | null,
    productID?: string | null,
    Product?:  {
      __typename: "Product",
      id: string,
      name: string,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      comments?: string | null,
      extra_data?: Array< string | null > | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    manufacturingprocessID: string,
    ManufacturingProcess?:  {
      __typename: "ManufacturingProcess",
      id: string,
      category: ProcessCategory,
      process: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    Parts?:  {
      __typename: "ModelBOMConnection",
      nextToken?: string | null,
    } | null,
    Materials?:  {
      __typename: "ModelComponentMaterialsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateComponentSubscriptionVariables = {
  filter?: ModelSubscriptionComponentFilterInput | null,
  owner?: string | null,
};

export type OnUpdateComponentSubscription = {
  onUpdateComponent?:  {
    __typename: "Component",
    id: string,
    name: string,
    root?: boolean | null,
    description?: string | null,
    dimensions?:  {
      __typename: "Dimensions",
      bounding_box_x?: string | null,
      bounding_box_y?: string | null,
      bounding_box_z?: string | null,
      volume?: string | null,
    } | null,
    article_number?: string | null,
    barcode_type?: string | null,
    barcode_content?: string | null,
    tolerances?: string | null,
    weight?: string | null,
    thread_count?: number | null,
    masking?: string | null,
    certificates?: Array< string | null > | null,
    comment?: string | null,
    comment_internal?: string | null,
    costs?: string | null,
    manufacturing_data?: string | null,
    productID?: string | null,
    Product?:  {
      __typename: "Product",
      id: string,
      name: string,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      comments?: string | null,
      extra_data?: Array< string | null > | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    manufacturingprocessID: string,
    ManufacturingProcess?:  {
      __typename: "ManufacturingProcess",
      id: string,
      category: ProcessCategory,
      process: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    Parts?:  {
      __typename: "ModelBOMConnection",
      nextToken?: string | null,
    } | null,
    Materials?:  {
      __typename: "ModelComponentMaterialsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteComponentSubscriptionVariables = {
  filter?: ModelSubscriptionComponentFilterInput | null,
  owner?: string | null,
};

export type OnDeleteComponentSubscription = {
  onDeleteComponent?:  {
    __typename: "Component",
    id: string,
    name: string,
    root?: boolean | null,
    description?: string | null,
    dimensions?:  {
      __typename: "Dimensions",
      bounding_box_x?: string | null,
      bounding_box_y?: string | null,
      bounding_box_z?: string | null,
      volume?: string | null,
    } | null,
    article_number?: string | null,
    barcode_type?: string | null,
    barcode_content?: string | null,
    tolerances?: string | null,
    weight?: string | null,
    thread_count?: number | null,
    masking?: string | null,
    certificates?: Array< string | null > | null,
    comment?: string | null,
    comment_internal?: string | null,
    costs?: string | null,
    manufacturing_data?: string | null,
    productID?: string | null,
    Product?:  {
      __typename: "Product",
      id: string,
      name: string,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      comments?: string | null,
      extra_data?: Array< string | null > | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    manufacturingprocessID: string,
    ManufacturingProcess?:  {
      __typename: "ManufacturingProcess",
      id: string,
      category: ProcessCategory,
      process: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    Parts?:  {
      __typename: "ModelBOMConnection",
      nextToken?: string | null,
    } | null,
    Materials?:  {
      __typename: "ModelComponentMaterialsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateBOMSubscriptionVariables = {
  filter?: ModelSubscriptionBOMFilterInput | null,
  owner?: string | null,
};

export type OnCreateBOMSubscription = {
  onCreateBOM?:  {
    __typename: "BOM",
    id: string,
    quantity?: number | null,
    parentID: string,
    childID: string,
    Child?:  {
      __typename: "Component",
      id: string,
      name: string,
      root?: boolean | null,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      tolerances?: string | null,
      weight?: string | null,
      thread_count?: number | null,
      masking?: string | null,
      certificates?: Array< string | null > | null,
      comment?: string | null,
      comment_internal?: string | null,
      costs?: string | null,
      manufacturing_data?: string | null,
      productID?: string | null,
      manufacturingprocessID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateBOMSubscriptionVariables = {
  filter?: ModelSubscriptionBOMFilterInput | null,
  owner?: string | null,
};

export type OnUpdateBOMSubscription = {
  onUpdateBOM?:  {
    __typename: "BOM",
    id: string,
    quantity?: number | null,
    parentID: string,
    childID: string,
    Child?:  {
      __typename: "Component",
      id: string,
      name: string,
      root?: boolean | null,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      tolerances?: string | null,
      weight?: string | null,
      thread_count?: number | null,
      masking?: string | null,
      certificates?: Array< string | null > | null,
      comment?: string | null,
      comment_internal?: string | null,
      costs?: string | null,
      manufacturing_data?: string | null,
      productID?: string | null,
      manufacturingprocessID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteBOMSubscriptionVariables = {
  filter?: ModelSubscriptionBOMFilterInput | null,
  owner?: string | null,
};

export type OnDeleteBOMSubscription = {
  onDeleteBOM?:  {
    __typename: "BOM",
    id: string,
    quantity?: number | null,
    parentID: string,
    childID: string,
    Child?:  {
      __typename: "Component",
      id: string,
      name: string,
      root?: boolean | null,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      tolerances?: string | null,
      weight?: string | null,
      thread_count?: number | null,
      masking?: string | null,
      certificates?: Array< string | null > | null,
      comment?: string | null,
      comment_internal?: string | null,
      costs?: string | null,
      manufacturing_data?: string | null,
      productID?: string | null,
      manufacturingprocessID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateMaterialSubscriptionVariables = {
  filter?: ModelSubscriptionMaterialFilterInput | null,
};

export type OnCreateMaterialSubscription = {
  onCreateMaterial?:  {
    __typename: "Material",
    id: string,
    name: string,
    type?: string | null,
    available?: boolean | null,
    description?: string | null,
    chemical_name?: string | null,
    numerical_name?: string | null,
    number?: string | null,
    Components?:  {
      __typename: "ModelComponentMaterialsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMaterialSubscriptionVariables = {
  filter?: ModelSubscriptionMaterialFilterInput | null,
};

export type OnUpdateMaterialSubscription = {
  onUpdateMaterial?:  {
    __typename: "Material",
    id: string,
    name: string,
    type?: string | null,
    available?: boolean | null,
    description?: string | null,
    chemical_name?: string | null,
    numerical_name?: string | null,
    number?: string | null,
    Components?:  {
      __typename: "ModelComponentMaterialsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMaterialSubscriptionVariables = {
  filter?: ModelSubscriptionMaterialFilterInput | null,
};

export type OnDeleteMaterialSubscription = {
  onDeleteMaterial?:  {
    __typename: "Material",
    id: string,
    name: string,
    type?: string | null,
    available?: boolean | null,
    description?: string | null,
    chemical_name?: string | null,
    numerical_name?: string | null,
    number?: string | null,
    Components?:  {
      __typename: "ModelComponentMaterialsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateProfileSubscriptionVariables = {
  filter?: ModelSubscriptionProfileFilterInput | null,
  owner?: string | null,
};

export type OnCreateProfileSubscription = {
  onCreateProfile?:  {
    __typename: "Profile",
    id: string,
    company_name?: string | null,
    contact_person?: string | null,
    mobile_number?: string | null,
    vat_number?: string | null,
    preferred_contact_way?: string | null,
    preferred_incoterms?: string | null,
    phone_number?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateProfileSubscriptionVariables = {
  filter?: ModelSubscriptionProfileFilterInput | null,
  owner?: string | null,
};

export type OnUpdateProfileSubscription = {
  onUpdateProfile?:  {
    __typename: "Profile",
    id: string,
    company_name?: string | null,
    contact_person?: string | null,
    mobile_number?: string | null,
    vat_number?: string | null,
    preferred_contact_way?: string | null,
    preferred_incoterms?: string | null,
    phone_number?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteProfileSubscriptionVariables = {
  filter?: ModelSubscriptionProfileFilterInput | null,
  owner?: string | null,
};

export type OnDeleteProfileSubscription = {
  onDeleteProfile?:  {
    __typename: "Profile",
    id: string,
    company_name?: string | null,
    contact_person?: string | null,
    mobile_number?: string | null,
    vat_number?: string | null,
    preferred_contact_way?: string | null,
    preferred_incoterms?: string | null,
    phone_number?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateAddressSubscriptionVariables = {
  filter?: ModelSubscriptionAddressFilterInput | null,
  owner?: string | null,
};

export type OnCreateAddressSubscription = {
  onCreateAddress?:  {
    __typename: "Address",
    id: string,
    name?: string | null,
    address_1: string,
    address_2?: string | null,
    zip_code: string,
    city: string,
    country?: string | null,
    type?: AddressType | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateAddressSubscriptionVariables = {
  filter?: ModelSubscriptionAddressFilterInput | null,
  owner?: string | null,
};

export type OnUpdateAddressSubscription = {
  onUpdateAddress?:  {
    __typename: "Address",
    id: string,
    name?: string | null,
    address_1: string,
    address_2?: string | null,
    zip_code: string,
    city: string,
    country?: string | null,
    type?: AddressType | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteAddressSubscriptionVariables = {
  filter?: ModelSubscriptionAddressFilterInput | null,
  owner?: string | null,
};

export type OnDeleteAddressSubscription = {
  onDeleteAddress?:  {
    __typename: "Address",
    id: string,
    name?: string | null,
    address_1: string,
    address_2?: string | null,
    zip_code: string,
    city: string,
    country?: string | null,
    type?: AddressType | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateChannelSubscriptionVariables = {
  filter?: ModelSubscriptionChannelFilterInput | null,
};

export type OnCreateChannelSubscription = {
  onCreateChannel?:  {
    __typename: "Channel",
    id: string,
    address_from: string,
    address_to: string,
    reference?: string | null,
    visitedAt?: string | null,
    isNew?: boolean | null,
    product?: string | null,
    Messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateChannelSubscriptionVariables = {
  filter?: ModelSubscriptionChannelFilterInput | null,
};

export type OnUpdateChannelSubscription = {
  onUpdateChannel?:  {
    __typename: "Channel",
    id: string,
    address_from: string,
    address_to: string,
    reference?: string | null,
    visitedAt?: string | null,
    isNew?: boolean | null,
    product?: string | null,
    Messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteChannelSubscriptionVariables = {
  filter?: ModelSubscriptionChannelFilterInput | null,
};

export type OnDeleteChannelSubscription = {
  onDeleteChannel?:  {
    __typename: "Channel",
    id: string,
    address_from: string,
    address_to: string,
    reference?: string | null,
    visitedAt?: string | null,
    isNew?: boolean | null,
    product?: string | null,
    Messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateMessageSubscriptionVariables = {
  filter?: ModelSubscriptionMessageFilterInput | null,
};

export type OnCreateMessageSubscription = {
  onCreateMessage?:  {
    __typename: "Message",
    id: string,
    body: string,
    author: string,
    channelID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMessageSubscriptionVariables = {
  filter?: ModelSubscriptionMessageFilterInput | null,
};

export type OnUpdateMessageSubscription = {
  onUpdateMessage?:  {
    __typename: "Message",
    id: string,
    body: string,
    author: string,
    channelID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMessageSubscriptionVariables = {
  filter?: ModelSubscriptionMessageFilterInput | null,
};

export type OnDeleteMessageSubscription = {
  onDeleteMessage?:  {
    __typename: "Message",
    id: string,
    body: string,
    author: string,
    channelID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUnauthRequestSubscriptionVariables = {
  filter?: ModelSubscriptionUnauthRequestFilterInput | null,
};

export type OnCreateUnauthRequestSubscription = {
  onCreateUnauthRequest?:  {
    __typename: "UnauthRequest",
    id: string,
    email: string,
    name: string,
    company_name: string,
    address: string,
    zip_code: string,
    city: string,
    country: string,
    phone_number: string,
    project_description: string,
    status: UnauthRequestStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUnauthRequestSubscriptionVariables = {
  filter?: ModelSubscriptionUnauthRequestFilterInput | null,
};

export type OnUpdateUnauthRequestSubscription = {
  onUpdateUnauthRequest?:  {
    __typename: "UnauthRequest",
    id: string,
    email: string,
    name: string,
    company_name: string,
    address: string,
    zip_code: string,
    city: string,
    country: string,
    phone_number: string,
    project_description: string,
    status: UnauthRequestStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUnauthRequestSubscriptionVariables = {
  filter?: ModelSubscriptionUnauthRequestFilterInput | null,
};

export type OnDeleteUnauthRequestSubscription = {
  onDeleteUnauthRequest?:  {
    __typename: "UnauthRequest",
    id: string,
    email: string,
    name: string,
    company_name: string,
    address: string,
    zip_code: string,
    city: string,
    country: string,
    phone_number: string,
    project_description: string,
    status: UnauthRequestStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateEmailResultSubscriptionVariables = {
  filter?: ModelSubscriptionEmailResultFilterInput | null,
};

export type OnCreateEmailResultSubscription = {
  onCreateEmailResult?:  {
    __typename: "EmailResult",
    statusCode?: number | null,
    body?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateEmailResultSubscriptionVariables = {
  filter?: ModelSubscriptionEmailResultFilterInput | null,
};

export type OnUpdateEmailResultSubscription = {
  onUpdateEmailResult?:  {
    __typename: "EmailResult",
    statusCode?: number | null,
    body?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteEmailResultSubscriptionVariables = {
  filter?: ModelSubscriptionEmailResultFilterInput | null,
};

export type OnDeleteEmailResultSubscription = {
  onDeleteEmailResult?:  {
    __typename: "EmailResult",
    statusCode?: number | null,
    body?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateComponentMaterialsSubscriptionVariables = {
  filter?: ModelSubscriptionComponentMaterialsFilterInput | null,
  owner?: string | null,
};

export type OnCreateComponentMaterialsSubscription = {
  onCreateComponentMaterials?:  {
    __typename: "ComponentMaterials",
    id: string,
    componentID: string,
    materialID: string,
    component:  {
      __typename: "Component",
      id: string,
      name: string,
      root?: boolean | null,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      tolerances?: string | null,
      weight?: string | null,
      thread_count?: number | null,
      masking?: string | null,
      certificates?: Array< string | null > | null,
      comment?: string | null,
      comment_internal?: string | null,
      costs?: string | null,
      manufacturing_data?: string | null,
      productID?: string | null,
      manufacturingprocessID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    material:  {
      __typename: "Material",
      id: string,
      name: string,
      type?: string | null,
      available?: boolean | null,
      description?: string | null,
      chemical_name?: string | null,
      numerical_name?: string | null,
      number?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateComponentMaterialsSubscriptionVariables = {
  filter?: ModelSubscriptionComponentMaterialsFilterInput | null,
  owner?: string | null,
};

export type OnUpdateComponentMaterialsSubscription = {
  onUpdateComponentMaterials?:  {
    __typename: "ComponentMaterials",
    id: string,
    componentID: string,
    materialID: string,
    component:  {
      __typename: "Component",
      id: string,
      name: string,
      root?: boolean | null,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      tolerances?: string | null,
      weight?: string | null,
      thread_count?: number | null,
      masking?: string | null,
      certificates?: Array< string | null > | null,
      comment?: string | null,
      comment_internal?: string | null,
      costs?: string | null,
      manufacturing_data?: string | null,
      productID?: string | null,
      manufacturingprocessID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    material:  {
      __typename: "Material",
      id: string,
      name: string,
      type?: string | null,
      available?: boolean | null,
      description?: string | null,
      chemical_name?: string | null,
      numerical_name?: string | null,
      number?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteComponentMaterialsSubscriptionVariables = {
  filter?: ModelSubscriptionComponentMaterialsFilterInput | null,
  owner?: string | null,
};

export type OnDeleteComponentMaterialsSubscription = {
  onDeleteComponentMaterials?:  {
    __typename: "ComponentMaterials",
    id: string,
    componentID: string,
    materialID: string,
    component:  {
      __typename: "Component",
      id: string,
      name: string,
      root?: boolean | null,
      description?: string | null,
      article_number?: string | null,
      barcode_type?: string | null,
      barcode_content?: string | null,
      tolerances?: string | null,
      weight?: string | null,
      thread_count?: number | null,
      masking?: string | null,
      certificates?: Array< string | null > | null,
      comment?: string | null,
      comment_internal?: string | null,
      costs?: string | null,
      manufacturing_data?: string | null,
      productID?: string | null,
      manufacturingprocessID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    material:  {
      __typename: "Material",
      id: string,
      name: string,
      type?: string | null,
      available?: boolean | null,
      description?: string | null,
      chemical_name?: string | null,
      numerical_name?: string | null,
      number?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};
