import React from 'react'
import useMobileService from '../../hooks/useMobileService'
import { useTranslation } from 'react-i18next'
import VerticalMenu from './VerticalMenu'
import { Button, Chip, Grid, Toolbar, Tooltip, Typography } from '@mui/material'
import { Product } from '../../graphql/graphql'

type Props = {
  product?: Product
  title: string
  editing?: boolean
  showMenu?: boolean
  hideCancel?: boolean
  onEditCancel?: () => void
  onDelete?: () => void
  invalid?: boolean
  onSave?: () => void
  CustomActions?: React.ReactNode
  TitleButton?: React.ReactNode
  hideTitleButton?: boolean
  archived?: boolean
}

function CancelIcon() {
  return null
}

function SaveIcon() {
  return null
}

/**
 * Toolbar that shows up, when you are in editing mode on product/contract
 * Lets you save or abort your recently changes
 */
export default function DetailsToolbar(props: Props) {
  const {
    product,
    title,
    editing,
    showMenu,
    hideCancel,
    onEditCancel = () => {},
    invalid,
    onSave = () => {},
    onDelete = () => {},
    CustomActions,
    TitleButton,
    hideTitleButton,
    archived,
  } = props

  const { t } = useTranslation()

  const isMobile = useMobileService()

  const Title = () => (
    <div style={{ flexGrow: 1, display: 'flex' }}>
      <Typography variant="h6">
        {title + ' '}
        {archived && <Chip label="archiviert" variant="outlined" />}
      </Typography>
      {!hideTitleButton && TitleButton !== undefined && TitleButton}
    </div>
  )

  const CancelButton = () => (
    <Tooltip title="Bearbeitung abbrechen">
      <span>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => onEditCancel()}
          startIcon={<CancelIcon />}
          style={{ marginRight: 8 }}
          fullWidth={isMobile}
        >
          {' '}
          {t('cancel')}
        </Button>
      </span>
    </Tooltip>
  )

  const SaveButton = () => (
    <Tooltip title="Änderungen Speichern">
      <span>
        <Button
          variant="contained"
          color="secondary"
          disabled={invalid}
          onClick={() => onSave()}
          startIcon={<SaveIcon />}
          fullWidth={isMobile}
        >
          {' '}
          {t('save')}
        </Button>
      </span>
    </Tooltip>
  )

  if (isMobile) {
    return (
      <Toolbar style={{ justifyContent: 'space-between', marginBottom: 16 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Title />
          </Grid>
          {CustomActions && (
            <Grid item xs={12} sm={12}>
              {CustomActions}
            </Grid>
          )}
          {editing && (
            <React.Fragment>
              {!hideCancel && (
                <Grid item xs={5}>
                  <CancelButton />
                </Grid>
              )}
              <Grid item xs={5}>
                <SaveButton />
              </Grid>
              <Grid item xs={2}>
                {showMenu && <VerticalMenu onDelete={onDelete} product={product} />}
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </Toolbar>
    )
  }

  return (
    <Toolbar style={{ justifyContent: 'space-between' }}>
      <Title />
      {CustomActions}
      {editing && (
        <React.Fragment>
          {!hideCancel && <CancelButton />}
          <SaveButton />
          {showMenu && <VerticalMenu onDelete={onDelete} product={product} />}
        </React.Fragment>
      )}
    </Toolbar>
  )
}
