import React, { useState, useRef } from 'react'
import { Message } from '../../../../graphql/graphql'
import { FormControl, OutlinedInput, Typography } from '@mui/material'
import MessageActions from './MessageActions'
import { parseMessageDate } from '../../utils/functions'
import useMobileService from '../../../../hooks/useMobileService'

type Props = {
  user: any
  message: Message
  showMessageActions: string //used to show messageActions for one message and hide them when user clicks anywhere
  setShowMessageActions: (show: string) => void
  cornerRadius?: number
  sx?: React.CSSProperties
}

/**
 * Component for a chat message
 * Has information about:
 *  - when the message was created/updated
 *  - the message text
 * Can be:
 *  - edited
 *  - deleted
 */
export default function ChatMessage(props: Props) {
  const { user, message, showMessageActions, setShowMessageActions, cornerRadius = 4, sx } = props
  const received = message.author !== user.username
  const radius = `${cornerRadius}px`
  const messageCreateDate = parseMessageDate(message.createdAt)
  const messageUpdateDate = parseMessageDate(message.updatedAt)
  const [hover, setHover] = useState<boolean>(false)
  const touchPressTimerRef = useRef<any>() //used with type NodeJS.Timeout but clearTimeout does not like specifying the Timeout-type here
  const [edit, setEdit] = useState<boolean>(false)
  const [editValue, setEditValue] = useState<string>(message.body)
  const isMobile = useMobileService()

  const preparedStyle = received
    ? {
        backgroundColor: '#cccccc',
        borderTopRightRadius: radius,
        borderBottomRightRadius: radius,
        borderBottomLeftRadius: radius,
        borderTopLeftRadius: 0,
        justifySelf: 'flex-start',
      }
    : {
        backgroundColor: '#0096ff',
        borderTopRightRadius: radius,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: radius,
        borderTopLeftRadius: radius,
        justifySelf: 'flex-end',
      }

  const handleMessageTouchStart = () => {
    touchPressTimerRef.current = setTimeout(() => {
      setShowMessageActions(message.id)
    }, 400)
  }

  const handleMessageTouchEnd = () => {
    clearTimeout(touchPressTimerRef.current)
  }

  const editMessageInput = () => {
    return (
      <FormControl sx={{ width: '100%' }}>
        <OutlinedInput
          size="small"
          fullWidth
          multiline
          minRows={1}
          maxRows={4}
          value={editValue}
          onChange={event => setEditValue(event.currentTarget.value)}
        />
      </FormControl>
    )
  }

  return (
    <div
      aria-label="chatMessage-alignmentContainer"
      style={{ ...sx, display: 'flex', flexDirection: 'column' }}
    >
      <div
        aria-label="chatMessage-container"
        onMouseLeave={() =>
          setHover(!isMobile && edit)
        } /* when in editmode, action buttons need to stay visible */
        style={{
          alignSelf: received ? 'start' : 'end',
          maxWidth: edit ? 'none' : '85%',
          minWidth: edit ? 'none' : '5%',
          width: edit ? '85%' : 'auto',
        }}
      >
        <div aria-label="chatMessage-messageActionContainer" style={{ position: 'relative' }}>
          {(hover || message.id === showMessageActions) && user.username === message.author && (
            <MessageActions
              message={message}
              setEdit={setEdit}
              edit={edit}
              setEditValue={setEditValue}
              editValue={editValue}
              setShowMessageActions={setShowMessageActions}
              sx={{
                position: 'absolute',
                top: '-20px',
                right: '3px',
              }}
            />
          )}
        </div>
        <div
          aria-label="chatMessage-text"
          onMouseEnter={() => setHover(!isMobile && true)} //messageActions should appear as soon as the mouse hovers over the message itself, but only dissapear when the mouse leaves the entire messageContainer (not only the text)
          onTouchStart={handleMessageTouchStart}
          onTouchEnd={handleMessageTouchEnd}
          style={{ display: 'flex', justifyContent: received ? 'start' : 'end' }}
        >
          {edit ? (
            editMessageInput()
          ) : (
            <Typography
              variant="body2"
              sx={{
                ...preparedStyle,
                overflowWrap: 'break-word',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                hyphens: 'auto',
                whiteSpace: 'pre-line',
                width: 'fit-content',
                padding: '5px',
                userSelect: isMobile ? 'none' : null, //disables text seclection on long press
              }}
            >
              {message && message.body && message.body}
            </Typography>
          )}
        </div>
        <div aria-label="chatMessage-infoText">
          <Typography
            sx={{
              fontSize: '10px',
              color: 'GrayText',
              textAlign: received ? 'start' : 'end',
            }}
          >
            {`${messageCreateDate.day}.${messageCreateDate.month}.${messageCreateDate.year}  ${messageCreateDate.hour}:${messageCreateDate.minute}`}
          </Typography>
          {message.createdAt !== message.updatedAt && (
            <Typography
              sx={{ fontSize: '10px', color: 'GrayText', textAlign: received ? 'start' : 'end' }}
            >
              Last Edit:{' '}
              {`${messageUpdateDate.day}.${messageUpdateDate.month}.${messageUpdateDate.year}  ${messageUpdateDate.hour}:${messageUpdateDate.minute}`}
            </Typography>
          )}
        </div>
      </div>
    </div>
  )
  {
    /* <Grid
        container
        spacing={2}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Grid item xs={10} sx={{ marginTop: '5px' }}>
          {edit ? (
            editMessageInput()
          ) : (
            <Typography
              variant="body2"
              sx={{
                ...preparedStyle,
                overflowWrap: 'break-word',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                hyphens: 'auto',
                whiteSpace: 'pre-line',
                width: 'fit-content',
                padding: '5px',
              }}
            >
              {message && message.body && message.body}
            </Typography>
          )}
        </Grid>
        <Grid item xs={2}>
          {hover && user.username === message.author && (
            <MessageActions message={message} setEdit={setEdit} edit={edit} editValue={editValue} />
          )}
        </Grid>
      </Grid> */
  }
}
