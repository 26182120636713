import { Component, Product } from '../../../graphql/graphql'

export const validComponent = (component: Omit<Component, 'id'> | Component): boolean => {
  return !!component.name
}
export const validProduct = (product: Omit<Product, 'id'> | Product): boolean => {
  return !!product.name
}

export const stringNotEmpty = (s: string | undefined) => (s ? s !== '' : false)
