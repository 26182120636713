import { Add } from '@mui/icons-material'
import {
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Component } from '../../../../graphql/graphql'
import ComponentSubTree from './ComponentSubTree'
import ListModal from '../../../../components/widgets/ListModal'
import useMobileService from '../../../../hooks/useMobileService'

type Props = {
  updatedComponent?: Component
  rootComponents: Component[]
  setOpen: (open: boolean) => void
  setComponent: (component: Component) => void
  setNew: (isNew: boolean) => void
  onDelete: (componentList: tickedComponent[]) => void
  setActiveStep: (step: any) => void
  setParentComponent: (comp: Component | undefined) => void
  refetchChildren: boolean
  setParentBOM: (BOM: any) => void
  setQuantity: (quantity: number) => void
  modalOpen: boolean
  setModalOpen: (open: boolean) => void
}

interface tickedComponent {
  id: string
  name: string
}

/**
 * Component that loads all components of a given produkt and
 * displays them as the root of a tree structure in a table
 * Every loaded component is a root component and gets its own
 * Tree which is rendered in the ComponentSubTree component
 */
export default function ComponentTree(props: Props) {
  const {
    rootComponents,
    setOpen,
    setComponent,
    setNew,
    updatedComponent,
    onDelete,
    setActiveStep,
    setParentComponent,
    refetchChildren,
    setParentBOM,
    setQuantity,
    modalOpen,
    setModalOpen,
  } = props

  const [tickedComponents, setTickedComponents] = useState<tickedComponent[]>([])
  const [isEditMode, setEditMode] = useState<boolean>(false)
  const { t } = useTranslation()
  const isMobile = useMobileService()

  const tickMe = (id: string, name: string) =>
    setTickedComponents([...tickedComponents, { id, name }])
  const untickMe = (id: string) => {
    const update: tickedComponent[] = []
    // keep all Components ticked except for the one specified with id
    tickedComponents.forEach((c: tickedComponent) => c.id !== id && update.push(c))
    setTickedComponents(update)
  }
  const getTickedStatus = (id: string) => {
    let isTicked = false
    tickedComponents.forEach((c: tickedComponent) => (c.id === id ? (isTicked = true) : null))
    return isTicked
  }
  const handleTick = (id: string, name: string) => {
    getTickedStatus(id) ? untickMe(id) : tickMe(id, name)
  }

  const deleteTickedElements = () => {
    setModalOpen(!modalOpen)
    setEditMode(false)
  }

  return (
    <div style={{ marginBottom: '80px' }}>
      {/* ComponentTreeTable header and editmode toggle start */}
      <div
        style={{
          marginTop: 10,
          marginBottom: 1,
          minHeight: '40px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6">{t('product:overview.parts')}</Typography>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {isEditMode && (
            <Grid container spacing={1}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => setTickedComponents([])}
                >
                  {t('product:overview.deselectAll')}
                </Button>
              </Grid>
              <Grid item>
                <Tooltip title={t('product:tooltip.delete') as string}>
                  <span>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => deleteTickedElements()}
                      disabled={tickedComponents.length === 0}
                    >
                      {t('delete')}
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          )}
          <FormGroup>
            <FormControlLabel
              label={!isEditMode ? (t('product:overview.selectParts') as string) : ''}
              labelPlacement="start"
              sx={{ color: 'GrayText' }}
              control={
                <Switch
                  size="small"
                  checked={isEditMode}
                  onChange={() => setEditMode(!isEditMode)}
                />
              }
            />
          </FormGroup>
        </div>
        <ListModal
          open={modalOpen}
          setOpen={() => setModalOpen(!modalOpen)}
          title={
            tickedComponents.length > 1 ? t('dialog.deleteComponents') : t('dialog.deleteComponent')
          }
          description={
            tickedComponents.length > 1
              ? t('dialog.areYouSureDeleteComponents')
              : t('dialog.areYouSureDeleteComponent')
          }
          list={tickedComponents.map(value => value.name)}
          onClose={() => setTickedComponents([])}
          onConfirm={() => {
            onDelete(tickedComponents)
            setTickedComponents([])
          }}
        />
      </div>
      {/* ComponentTreeTable header and editmode toggle end */}
      {/* Table Header Start */}
      <div
        id="table-scroll-container"
        style={{
          width: '100%',
          overflowX: 'auto',
        }}
      >
        {' '}
        {/* Scrolable container */}
        <div style={{ width: '100%' }}>
          {' '}
          {/* Container that resizes to childs width */}
          {/* Allows growing of the table without misalignment of columns */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignContent: 'center',
              borderBottom: '2px solid gray',
            }}
          >
            <span
              style={{
                width: `calc(${
                  document.getElementById('table-scroll-container')?.offsetWidth
                }px - 180px - 120px - 50px)` /* Making sure that the table will actually fill the available space */,
                flexGrow: 1,
                flexShrink: 0 /* making sure that the first column does not shrink when the width is no longer determined by the header but by a row of the table */,
                textAlign: 'center',
              }}
            >
              {t('name')}
            </span>
            <div style={{ display: 'flex' }}>
              {!isMobile && (
                <span
                  style={{
                    width: '180px',
                    textAlign: 'center',
                    paddingRight: '5px',
                    paddingLeft: '5px',
                  }}
                >
                  {t('articleNumber')}
                </span>
              )}
              <span
                style={{ width: '120px', textAlign: 'center', marginRight: '50px' }} //margin makes up for the clearspace needed for the checkbox when in editmode
              >
                {t('process')}
              </span>
            </div>
          </div>
          {/* Table Header End */}
          {/* ComponentTree with table start */}
          <div>
            {rootComponents
              .sort((a: Component, b: Component) => {
                const dateA: any = new Date(a.createdAt)
                const dateB: any = new Date(b.createdAt)
                return dateA - dateB
              })
              .map((comp: Component, index) => (
                <ComponentSubTree
                  updatedComponent={updatedComponent}
                  rootComponent={comp}
                  key={index}
                  isEditMode={isEditMode}
                  handleItemTick={handleTick}
                  getItemTickedStatus={getTickedStatus}
                  setOpen={setOpen}
                  setNew={setNew}
                  setComponent={setComponent}
                  setActiveStep={setActiveStep}
                  setParentComponent={setParentComponent}
                  refetchChildren={refetchChildren}
                  setParentBOM={setParentBOM}
                  setQuantity={setQuantity}
                />
              ))}
          </div>
          {/* Adding a root component button start */}
          <div
            style={{
              width: '100%',
              alignSelf: 'center',
              borderBottom: '2px solid gray',
            }}
          >
            <IconButton
              onClick={() => {
                setOpen(true)
                setNew(true)
                setComponent({} as Component)
                setParentComponent(undefined)
                setActiveStep(0)
              }}
              color="primary"
              size="small"
            >
              <Add sx={{ fontSize: 'large' }} />
            </IconButton>
          </div>
          {/* Adding a root component button end */}
        </div>
      </div>
    </div>
  )
}
