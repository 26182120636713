import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import Page from '../components/layout/Page'
import { useConfirm } from '../features/Feedback'
import { Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { ArrowForwardIos, Logout } from '@mui/icons-material'
import { Auth } from 'aws-amplify'

type ListItemLinkProps = {
  href: string
  children: ReactElement[]
}

function ListItemLink({ href, children }: ListItemLinkProps) {
  return (
    <ListItem button component="a" href={href}>
      {children}
    </ListItem>
  )
}

export default function SettingsPage() {
  const { t } = useTranslation()
  const { confirmDialog } = useConfirm()

  async function signOut() {
    try {
      await Auth.signOut()
      window.location.reload()
    } catch (error) {
      console.log('error signing out: ', error)
    }
  }

  return (
    <Page title="Settings">
      <List>
        <Divider />
        <ListItemLink href="/settings/profile">
          <ListItemText primary={t('pages.profile')} />
          <ListItemIcon>
            {' '}
            <ArrowForwardIos fontSize="small" />{' '}
          </ListItemIcon>
        </ListItemLink>
        <Divider />
        <ListItemLink href="/settings/language">
          <ListItemText primary={t('pages.language')} />
          <ListItemIcon>
            {' '}
            <ArrowForwardIos fontSize="small" />{' '}
          </ListItemIcon>
        </ListItemLink>
        <Divider />
        <ListItemLink href="https://www.assemblean.com/imprint">
          <ListItemText primary={t('pages.imprint')} />
          <ListItemIcon>
            {' '}
            <ArrowForwardIos fontSize="small" />{' '}
          </ListItemIcon>
        </ListItemLink>
        <Divider />
        <ListItemLink href="https://www.assemblean.com/privacy-policy">
          <ListItemText primary={t('pages.privacy')} />
          <ListItemIcon>
            {' '}
            <ArrowForwardIos fontSize="small" />{' '}
          </ListItemIcon>
        </ListItemLink>
        {/* <Divider />
        <ListItemLink href="/settings/about">
          <ListItemText primary={t('pages.about')} />
          <ListItemIcon>
            {' '}
            <ArrowForwardIos fontSize="small" />{' '}
          </ListItemIcon>
        </ListItemLink> */}
        <Divider />
        <ListItem button onClick={() => confirmDialog('dialog.logout', signOut)}>
          <ListItemText primary={t('logout')} primaryTypographyProps={{ color: 'error' }} />
          <ListItemIcon>
            {' '}
            <Logout fontSize="medium" color="error" />{' '}
          </ListItemIcon>
        </ListItem>
        <Divider />
      </List>
      <Typography align="center" style={{ color: 'lightgrey' }}>
        v.
        {process.env.REACT_APP_VERSION}
      </Typography>
    </Page>
  )
}
