import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useMobileService from '../../hooks/useMobileService'

interface Props {
  title: string
  description?: string
  textfieldLabel?: string
  placeholder?: string
  defaultValue?: string //Todo
  fallbackValue: string
  fetchValueBy: (value: string) => void
  open: boolean
  setOpen: (open: boolean) => void
  onClose?: () => void
  onCancel?: () => void
  onConfirm?: () => void
}

/**
 * Dialog that lets you name a new folder and create it
 */
export default function NewFolderDialog(props: Props) {
  const {
    title,
    description,
    textfieldLabel = '',
    placeholder = '',
    defaultValue,
    fallbackValue,
    fetchValueBy,
    open,
    setOpen,
    onClose = () => null,
    onCancel = () => null,
    onConfirm = () => null,
  } = props
  const { t } = useTranslation()
  const isMobile = useMobileService()
  let size
  isMobile ? (size = { height: '40%', width: '90%' }) : (size = { height: '30%', width: '40%' })
  const [value, setValue] = useState<string>('')

  /* useEffect(() => {
        defaultValue && setValue(defaultValue)
    }, []) */

  const handleCancel = () => {
    setOpen(false)
    setValue('')
    onCancel()
  }

  const handleConfirm = () => {
    setOpen(false)
    if (value.length === 0) {
      fetchValueBy(fallbackValue)
    } else {
      fetchValueBy(value)
    }
    setValue('')
    onConfirm()
  }

  const handleClose = () => {
    setOpen(false)
    setValue('')
    onClose()
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Card
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          height: size.height,
          width: size.width,
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            width: '100%',
            paddingBottom: '16px',
          }}
        >
          <Typography variant="h6">{title}</Typography>
          <div aria-label="textfieldModal-body">
            <span>{description && description}</span>
            <TextField
              value={value}
              placeholder={placeholder}
              label={textfieldLabel}
              onChange={e => setValue(e.target.value)}
              autoFocus
              margin="dense"
              type="text"
              fullWidth
              variant="standard"
            />
          </div>
          <div
            aria-label="textfieldModal-actions"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Button onClick={handleCancel}>{t('cancel')}</Button>
            <Button onClick={handleConfirm}>{t('confirm')}</Button>
          </div>
        </CardContent>
      </Card>
    </Modal>
  )
}
