import React, { useState } from 'react'
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Checkbox,
  Link,
  Table,
  TableCell,
  TableRow,
  Typography,
  TypographyTypeMap,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

/** 
/ Anleitung für MobileListItem
/
/ MobileListItem ist dafür gedacht in der mobilen Ansicht Listen-/Tabelleneinträge
/ anzuzeigen. Basierend auf einer Mui Karte gibt es zwei Möglichkeiten Inhalt
/ darzustellen. Erstens kann eine Art Überschrift für den Hauptbereich des
/ MobileListItem gewählt werden. Zweitens kann das MobileListItem über einen
/ Button am Ende aufgeklappt werden. Der Bereich, der aufgeklappt wird kann
/ dann mit beliebigem Inhalt gefüllt werden.
/
/ Parameter:
/   key: Wird MobileListItem in einer Aufzählung verwendet hätte React gerne einen key
/   primaryText: Überschrift des MobileListItems
/   primaryTextVariant: Größe/Variante der Überschrift (standard: h5)
/   onClick: Aktion für das Klicken des MobileListItems
/   buttonWhenClosed: Beschriftung des Buttons am Ende des Items, wenn das MobileListItem geschlossen ist
/   buttonWhenOpened: Beschriftung, wenn geöffnet.
/       (Wird für beide Button kein Text angegeben wird der weitere Inhalt standardmäßig angezeigt. Wird nur ein Text angegeben wird dieser für beide Zustände genutzt)
/   isOpen: ermöglicht das erzwingen des geöffneten Zustands beim Laden der Seite
/   index: Wenn das Durchzählen der Items gewünscht ist, kann hier die Nummer eines Items angegeben werden.
/       (bleibt der Parameter leer, werden Einträge nicht durchgezählt)
/
/
/ Nutzungsbeispiel:
/   list.map((listItem) => { return (
/     <MobileListItem
/       key={listItem.key}
/       primaryText={listItem.title}
/       buttonWhenClosed='Klick mich'
/       isOpen={true}
/     >
/       ...
/     </MobileListItem>
/   )})
/
/   Erzeugt für jedes Element in list einen Element. Dabei bekommt jedes Element
/   eine Überschrift listItem.title und einen Button 'Klick mich', welcher sowohl
/   im offenen, als auch im geschlossenen Zustand angezeigt wird. Die Elemente
/   der Liste werden nicht durchnummeriert. Beim Laden der Seite sind alle
/   Elemente der Liste geöffnet.
*/

type Props = {
  primaryText: string | null | undefined
  //hier sollen nur Mui Typography variants erlaubt sein
  primaryTextVariant?: TypographyTypeMap['props']['variant']
  onClick?: () => any
  buttonWhenClosed?: string
  buttonWhenOpened?: string
  isOpen?: boolean
  index?: number
  enableCopyAction?: boolean
  children?: any
}

export default function MobileListItem(props: Props) {
  const {
    primaryText,
    primaryTextVariant,
    onClick,
    index,
    buttonWhenClosed,
    buttonWhenOpened,
    isOpen,
    enableCopyAction = false,
    children,
  } = props

  const [open, setOpen] = useState(isOpen ? true : false)
  const { t } = useTranslation()
  let openedButton, closedButton, buttonActivated

  if (buttonWhenClosed || buttonWhenOpened) {
    buttonActivated = true
    if (buttonWhenClosed) {
      closedButton = buttonWhenClosed
    } else {
      closedButton = buttonWhenOpened
    }
    if (buttonWhenOpened) {
      openedButton = buttonWhenOpened
    } else {
      openedButton = buttonWhenClosed
    }
  } else {
    buttonActivated = false
  }

  return (
    <React.Fragment>
      <Card sx={{ marginBottom: '4px' }}>
        <CardActionArea onClick={onClick ? onClick : () => {}}>
          <CardContent
            sx={{
              paddingTop: '8px',
              paddingBottom: '8px',
            }}
          >
            {typeof index === 'number' && (
              <Typography variant="subtitle2" color="text.secondary">
                {`${index + 1}.`}
              </Typography>
            )}
            <div style={{ display: 'flex' }}>
              <Typography
                variant={primaryTextVariant ? primaryTextVariant : 'h6'}
                sx={{
                  flexGrow: 1,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {primaryText}
              </Typography>
              {enableCopyAction && (
                <Button variant="contained" sx={{ flexShrink: 0 }}>
                  {t('copy')}
                </Button>
              )}
            </div>
            <div style={{ overflow: 'hidden' }}>
              {
                /*Details anzeigen, wenn auf
                'Details Anzeigen' gedrückt wurde*/
                buttonActivated ? open && children : children
              }
            </div>
          </CardContent>
        </CardActionArea>

        {buttonActivated && (
          <CardActions sx={{ paddingTop: '4px', paddingBottom: '4px' }}>
            <Button
              variant="text"
              sx={{ fontSize: 12 }}
              size="small"
              onClick={e => {
                e.stopPropagation()
                setOpen(!open)
              }}
            >
              {open ? openedButton : closedButton}
            </Button>
          </CardActions>
        )}
      </Card>
    </React.Fragment>
  )
}
