import React from 'react'
import { useHistory } from 'react-router-dom'
import Page from '../components/layout/Page'

export default function WrongRoutePage() {
  const history = useHistory()

  return (
    <Page>
      Nothing here: 404
      <button onClick={() => history.goBack()}>Zurück</button>
    </Page>
  )
}
