export const customGetComponent = /* GraphQL */ `
  query CustomGetComponent($id: ID!) {
    getComponent(id: $id) {
      id
      name
      root
      description
      dimensions {
        bounding_box_x
        bounding_box_y
        bounding_box_z
        volume
      }
      article_number
      barcode_type
      barcode_content
      tolerances
      weight
      thread_count
      masking
      certificates
      comment
      comment_internal
      costs
      manufacturing_data
      productID
      Product {
        id
        name
        description
        article_number
        barcode_type
        barcode_content
        comments
        extra_data
        createdAt
        updatedAt
        owner
      }
      manufacturingprocessID
      ManufacturingProcess {
        id
        category
        process
        Requirements {
          items {
            id
            label
            options
            type
            value
          }
        }
        createdAt
        updatedAt
      }
      Parts {
        items {
          id
          parentID
          quantity
          Child {
            id
            name
            Parts {
              items {
                id
                Child {
                  id
                  name
                }
              }
            }
          }
        }
        nextToken
      }
      Materials {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`

export const customComponentByProduct = /* GraphQL */ `
  query CustomComponentByProduct(
    $productID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelComponentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    componentByProduct(
      productID: $productID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        root
        Parts {
          items {
            id
            parentID
            childID
            Child {
              id
              name
              Parts {
                items {
                  id
                  Child {
                    id
                    name
                  }
                }
              }
            }
          }
        }
        description
        article_number
        barcode_type
        barcode_content
        tolerances
        weight
        thread_count
        masking
        certificates
        comment
        comment_internal
        costs
        manufacturing_data
        productID
        ManufacturingProcess {
          id
          process
          Requirements {
            items {
              id
              label
              options
              type
              value
            }
          }
        }
        manufacturingprocessID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`

export const customListProducts = /* GraphQL */ `
  query customListProducts($filter: ModelProductFilterInput, $limit: Int, $nextToken: String) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        article_number
        barcode_type
        barcode_content
        comments
        extra_data
        Components {
          items {
            id
            name
            root
            createdAt
            ManufacturingProcess {
              id
              process
              category
            }
          }
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`

export const customListContracts = /* GraphQL */ `
  query CustomListContracts($filter: ModelContractFilterInput, $limit: Int, $nextToken: String) {
    listContracts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        billingAddressID
        comment_customer
        comment_internal
        certificates
        currency
        deadline
        description
        price
        priority
        quantity
        read_only
        remarks
        shippingAddressID
        version
        wish_date
        Request {
          items {
            id
            contractID
            accepted
            date
          }
        }
        Status {
          items {
            id
            contractID
            Status
            createdAt
          }
        }
        Product {
          id
          name
          description
          article_number
        }
        productID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`

export const customGetContract = /* GraphQL */ `
  query customGetContract($id: ID!) {
    getContract(id: $id) {
      id
      billingAddressID
      certificates
      comment_customer
      comment_internal
      currency
      deadline
      description
      price
      priority
      quantity
      read_only
      remarks
      shippingAddressID
      version
      wish_date
      productID
      Product {
        id
        name
        description
        article_number
        barcode_type
        barcode_content
        comments
        extra_data
        createdAt
        updatedAt
        owner
      }
      ShippingAddress {
        id
        name
        address_1
        address_2
        zip_code
        city
        country
        type
        createdAt
        updatedAt
        owner
      }
      BillingAddress {
        id
        name
        address_1
        address_2
        zip_code
        city
        country
        type
        createdAt
        updatedAt
        owner
      }
      Status {
        items {
          id
          date
          contractID
          Status
          createdAt
        }
      }
      Request {
        items {
          id
          date
          contractID
          accepted
          createdAt
        }
      }
      createdAt
      updatedAt
      owner
    }
  }
`
export const customListChannels = /* GraphQL */ `
  query customListChannels($filter: ModelChannelFilterInput, $limit: Int, $nextToken: String) {
    listChannels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Messages {
          items {
            id
            body
            author
            createdAt
            updatedAt
            channelID
          }
        }
        address_from
        address_to
        reference
        product
        visitedAt
        isNew
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const getComponentsFromProduct = /* GraphQL */ `
  query getComponentsFromProduct($id: ID!) {
    getProduct(id: $id) {
      id
      article_number
      barcode_content
      barcode_type
      comments
      description
      extra_data
      name
      Components {
        items {
          Parts {
            items {
              childID
              parentID
              quantity
            }
          }
          name
          id
          description
          weight
          tolerances
          thread_count
          root
          productID
          masking
          manufacturingprocessID
          manufacturing_data
          dimensions {
            bounding_box_x
            bounding_box_y
            bounding_box_z
            volume
          }
          costs
          comment_internal
          comment
          certificates
          barcode_type
          barcode_content
          article_number
        }
      }
    }
  }
`
export const getStatuses = /* GraphQL */ `
  query getStatuses {
    __type(name: "Status") {
      name
      enumValues {
        name
      }
    }
  }
`
