import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Address } from '../../../graphql/graphql'
import { updateAddress } from '../../../graphql/mutations'
import { useNotifications } from '../../Feedback'
import { validAddress } from '../utils/validation'
import { AddressForm } from './AddressForm'
import { feedbackForGraphQL, handlePromise } from '../../../utils/functions'
import { API, graphqlOperation } from 'aws-amplify'

interface Props {
  address: Address
  fetchAddresses: () => void
}

/**
 * Logic to update an already existing address
 */
export default function Update(props: Props) {
  const { addNotification } = useNotifications()
  const { t } = useTranslation(['common', 'profile'])
  const [address, setAddress] = useState(props.address)
  const [open, setOpen] = useState(false)

  useEffect(() => setAddress(props.address), [props.address])

  async function onSubmit() {
    setOpen(false)
    const [res, error] = await handlePromise(
      'updateAddress',
      API.graphql(
        graphqlOperation(updateAddress, {
          input: {
            id: address.id,
            name: address.name,
            address_1: address.address_1,
            address_2: address.address_2,
            zip_code: address.zip_code,
            city: address.city,
            country: address.country,
          },
        })
      )
    )
    feedbackForGraphQL(res, addNotification, t)
    props.fetchAddresses()
  }

  const onCancel = () => {
    setOpen(false)
    setAddress(props.address)
  }

  return (
    <div>
      <Tooltip
        title={t('profile:updateAddress') as string}
        aria-label="delete"
        style={{ marginLeft: 5, marginRight: 5 }}
        leaveDelay={200}
      >
        <IconButton
          className="icon-button"
          size="small"
          color="primary"
          aria-label="delete"
          onClick={() => setOpen(true)}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{t('profile:updateAddress')}</DialogTitle>
        <DialogContent>
          <AddressForm
            address={address}
            setAddress={value => setAddress({ ...value, id: address.id })}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={onCancel}>
            {t('cancel')}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            disabled={!validAddress(address)}
            onClick={onSubmit}
          >
            {t('save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
