import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AddressSelect } from '../../Address'
import { Form, FormFieldType } from '../../Form'
import { Contract, ContractRequest, Product } from '../../../graphql/graphql'
import FormControlLabel from '@mui/material/FormControlLabel'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import S3FileUpload from '../../../components/inputs/S3FileUpload'
import CADFileUpload from '../../../components/inputs/CADFileUpload'
import { useHistory, useLocation } from 'react-router'
import { ContractStatus } from '../../../graphql/graphql'

const certificateOptions = [
  { value: 'AS 9100D', label: 'AS 9100D' },
  { value: 'DIN EN ISO 13485', label: 'DIN EN ISO 13485' },
  { value: 'DIN EN ISO 9001', label: 'DIN EN ISO 9001' },
  { value: 'NADCAP', label: 'NADCAP' },
  { value: 'ISO 14001', label: 'ISO 14001' },
  { value: 'IATF 16949', label: 'IATF 16949' },
  { value: 'ISO 27001', label: 'ISO 27001' },
  { value: 'BS7799', label: 'BS7799' },
  { value: 'ISO 50001', label: 'ISO 50001' },
  { value: 'EN ISO 17065', label: 'EN ISO 17065' },
  { value: 'SA8000', label: 'SA8000' },
  { value: 'EMAS', label: 'EMAS' },
  { value: 'DIN EN 1090', label: 'DIN EN 1090' },
  { value: 'DIN EN ISO 3834', label: 'DIN EN ISO 3834' },
  { value: 'DIN 2304', label: 'DIN 2304' },
  { value: 'DIN EN ISO 13585', label: 'DIN EN ISO 13585' },
  { value: 'DIN ISO 11745', label: 'DIN ISO 11745' },
]

type Props = {
  isNew: boolean
  product: Product
  editing: boolean
  setEditing: (editing: boolean) => void
  contract: Contract
  setContract: (contract: Contract) => void
  statusDate: string
  adminStatus: string
  setStatusDate: (date: string) => void
  createStatus: () => void
  setAdminStatus: (adminStatus: string) => void
  statuses: string[]
  groups: string[]
  contractStatuses: ContractStatus[] | undefined
  request: ContractRequest
}

const currencies: any = [
  {
    value: 'USD',
    label: '$',
  },
  {
    value: 'EUR',
    label: '€',
  },
  {
    value: 'JPY',
    label: '¥',
  },
]

/**
 * Form to fill in detailed information about a contract.
 * Lets you change contract status if you are an admin
 */
export default function ContractDetails(props: Props) {
  const {
    isNew,
    product,
    editing,
    setEditing,
    contract,
    setContract,
    statusDate,
    adminStatus,
    setStatusDate,
    createStatus,
    setAdminStatus,
    statuses,
    groups,
    contractStatuses,
    request,
  } = props
  const { t } = useTranslation()
  const [diffBillAddress, setDiffBillAddress] = useState(false)
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    location.state ? setEditing(true) : null
  }, [])

  useEffect(() => {
    // set default currency for contract e.g. currency --> EUR, so not changing currency will
    // result in EUR as default currency
    !contract?.currency ? setContract({ ...contract, currency: 'EUR' }) : null
  }, [editing])

  const contractData: FormFieldType[] = [
    {
      type: 'custom',
      key: 'link',
      xs: 12,
      sm: 6,
      CustomComponent: (
        <div>
          {contract?.Product ? (
            <Button onClick={() => history.push('/products/' + contract.productID)}>
              {t('contract:details.viewProduct')}
            </Button>
          ) : null}
        </div>
      ),
    },
    { type: 'number', key: 'quantity', label: 'quantity', xs: 12, sm: 6 },
    {
      type: 'selectMultipleWithText',
      key: 'certificates',
      label: 'contract:details.certificates',
      md: 12,
      xs: 12,
      sm: 12,
      lg: 12,
      xl: 12,
      options: certificateOptions,
    },
    {
      type: 'multiline',
      key: 'description',
      label: 'description',
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
    },
    {
      type: 'multiline',
      key: 'comment_customer',
      label: 'additionalComments',
      md: 12,
      xs: 12,
      sm: 12,
      lg: 12,
      xl: 12,
    },
  ]

  const shippingData: FormFieldType[] = [
    { type: 'date', key: 'wish_date', label: 'contract:details.wishDate', xs: 12, sm: 6 },
    { type: 'date', key: 'deadline', label: 'contract:details.deadline', xs: 12, sm: 6 },
    {
      type: 'custom',
      key: 'shippingAddress',
      xs: 12,
      sm: 6,
      CustomComponent: (
        <AddressSelect
          name={'contract:details.shippingAddress'}
          addressId={contract?.shippingAddressID ? contract.shippingAddressID : ''}
          editing={editing}
          onChange={address => {
            setContract({
              ...contract,
              shippingAddressID: address.id,
              ShippingAddress: address,
            })
          }}
        />
      ),
    },
    {
      type: 'custom',
      key: 'billingAddress',
      xs: 12,
      sm: 6,
      CustomComponent: (
        <div>
          {(diffBillAddress || contract?.BillingAddress) && (
            <AddressSelect
              name={'contract:details.billingAddress'}
              addressId={contract?.billingAddressID as string}
              editing={editing}
              onChange={address => {
                setContract({
                  ...contract,
                  billingAddressID: address.id,
                  BillingAddress: address,
                })
              }}
            />
          )}
        </div>
      ),
    },
    {
      type: 'custom',
      key: 'differentBillingAddress',
      xs: 12,
      sm: 6,
      CustomComponent: (
        <div>
          <FormControlLabel
            value="end"
            control={
              <Checkbox
                color="primary"
                checked={diffBillAddress}
                onChange={() => setDiffBillAddress(!diffBillAddress)}
                disabled={!editing}
              />
            }
            label=""
            labelPlacement="end"
          />
          <FormLabel>{t('contract:details.differentBillingAddress')}</FormLabel>
        </div>
      ),
    },
    { type: 'checkbox', key: 'priority', label: 'contract:details.priority', xs: 12, sm: 6 },
  ]

  return (
    <Grid container spacing={2}>
      <Typography variant="h6" style={{ marginLeft: '18px', marginBottom: '10px' }}>
        {isNew ? t('contract:details.placeNewRequest') : t('contract:details.requestedProduct')}
      </Typography>
      <Grid container spacing={2} sx={{ marginLeft: '3px', marginBottom: '12px' }}>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <FormControl fullWidth variant="outlined">
            <TextField
              variant="outlined"
              label={t('contract:details.selectedProduct')}
              value={contract?.Product?.name}
              disabled={true}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <FormControl fullWidth variant="outlined">
            <TextField
              type="number"
              variant="outlined"
              label={t('articleNumber')}
              value={contract?.Product?.article_number}
              disabled={true}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Form
        formObject={(contract as Contract) || (product as Product)}
        formFields={contractData}
        onChange={value => setContract(value as Contract)}
        editing={editing}
      />
      <Typography
        variant="h6"
        style={{ marginLeft: '15px', marginTop: '15px', marginBottom: '10px' }}
      >
        {t('contract:details.shipping')}
      </Typography>
      <Form
        formObject={contract as Contract}
        formFields={shippingData}
        onChange={value => setContract(value as Contract)}
        editing={editing}
      />
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="h6" sx={{ marginTop: '15px', marginBottom: '10px' }}>
          {t('documents')}
        </Typography>
        <S3FileUpload product={contract?.Product as Product} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="h6" sx={{ marginTop: '15px', marginBottom: '10px' }}>
          {t('cadModels')}
        </Typography>
        <CADFileUpload product={contract?.Product as Product} />
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('status')}</TableCell>
            <TableCell>{t('date')}</TableCell>
            {groups && groups.includes('AdminS3') && <TableCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          {contractStatuses
            ?.sort(
              (a, b) => Date.parse(b?.createdAt as string) - Date.parse(a?.createdAt as string)
            )
            .map((status, index: number) => {
              return (
                <TableRow key={index}>
                  <TableCell>{status?.Status}</TableCell>
                  <TableCell>{status?.date}</TableCell>
                  {groups && groups.includes('AdminS3') && <TableCell />}
                </TableRow>
              )
            })}
          {request && (
            <TableRow>
              <TableCell>REQUEST</TableCell>
              <TableCell>{request.date}</TableCell>
              {groups && groups.includes('AdminS3') && <TableCell />}
            </TableRow>
          )}
          {groups && groups.includes('AdminS3') && (
            <TableRow>
              <TableCell>
                <FormControl fullWidth>
                  <Select
                    value={adminStatus}
                    onChange={(event: SelectChangeEvent) =>
                      setAdminStatus(event.target.value as string)
                    }
                  >
                    {statuses.map((value: string, idx: number) => (
                      <MenuItem key={idx} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell>
                <FormControl variant="outlined">
                  <TextField
                    id="date"
                    label="Date"
                    value={statusDate || ''}
                    onChange={e => setStatusDate(e.target.value)}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  sx={{ float: 'right' }}
                  onClick={() => createStatus()}
                  disabled={statusDate === '' || adminStatus === ''}
                >
                  {t('create')}
                </Button>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {groups && groups.includes('AdminS3') ? (
        <Grid
          container
          spacing={1}
          sx={{
            marginLeft: '10px',
            marginBottom: '12px',
            marginTop: '12px',
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl fullWidth variant="outlined">
              <TextField
                id={'commentInternal'}
                variant="outlined"
                multiline
                rows={4}
                label={t('commentInternal')}
                value={contract?.comment_internal}
                onChange={e => {
                  setContract({ ...contract, comment_internal: e.target.value })
                }}
                autoComplete="text"
                disabled={!editing}
                aria-describedby="text-input"
                inputProps={{
                  'aria-label': 'weight',
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6} md={11} lg={6} xl={6}>
            <FormControl fullWidth>
              <TextField
                type="number"
                variant="outlined"
                label={t('price')}
                defaultValue="0.0"
                value={contract?.price}
                disabled={!editing}
                onChange={e => {
                  const price: number = parseFloat(e.target.value)
                  setContract({ ...contract, price: price })
                }}
                inputProps={{
                  maxLength: 13,
                  step: '0.1',
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6} md={1} lg={6} xl={6}>
            <FormControl>
              <TextField
                id="outlined-select-currency"
                select
                defaultValue="EUR"
                value={contract?.currency || 'EUR'}
                onChange={e => setContract({ ...contract, currency: e.target.value })}
                disabled={!editing}
              >
                {currencies.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
        </Grid>
      ) : (
        <div>
          {contract?.comment_internal && (
            <Box sx={{ width: '100%', maxWidth: 800 }}>
              <Typography variant="h6" sx={{ marginLeft: '18px', marginTop: '10px' }}>
                {t('commentInternal') + ': '}
              </Typography>
              <Typography variant="body1" sx={{ marginLeft: '18px', marginTop: '10px' }}>
                {contract.comment_internal}
              </Typography>
            </Box>
          )}
          {contract?.price && contract?.currency && (
            <Typography variant="h5" sx={{ marginLeft: '18px', marginTop: '10px' }}>
              {t('contract:details.price') +
                ': ' +
                contract.price +
                '  ' +
                currencies.filter((x: any) => x.value === contract.currency)[0].label}
            </Typography>
          )}
        </div>
      )}
    </Grid>
  )
}
