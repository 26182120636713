import React from 'react'
import PaperCard from '../../../../components/widgets/PaperCard'
import { useHistory, useParams } from 'react-router-dom'
import { useNotifications } from '../../../Feedback'
import { useTranslation } from 'react-i18next'
import { Product } from '../../../../graphql/graphql'
import { feedbackForGraphQL, handlePromise } from '../../../../utils/functions'
import { API, graphqlOperation } from 'aws-amplify'
import { createProduct, deleteProduct, updateProduct } from '../../../../graphql/mutations'
import { stringNotEmpty } from '../../utils/validation'

type urlParams = {
  id: string
}

type Props = {
  children: any
  product: Product
  setProduct: (product: Product) => void
  setEditing: (editing: boolean) => void
  editing: boolean
  isNew: boolean
}

/**
 * Logic needed to delete, create and update products
 */
export default function ProductLogic(props: Props) {
  const { children, product, setProduct, setEditing, editing, isNew } = props
  const { t } = useTranslation()
  const history = useHistory()
  const { addNotification } = useNotifications()
  const { id } = useParams<urlParams>()

  const onEditCancel = () => {
    setEditing(false)
    if (isNew) {
      history.push('/products')
    }
  }

  async function onSave() {
    const { createdAt, updatedAt, owner, Components, Contracts, ...productInput } = product
    const [res, error] = await handlePromise(
      'updateProduct',
      API.graphql(graphqlOperation(updateProduct, { input: productInput }))
    )
    feedbackForGraphQL(res, addNotification, t)
    onEditCancel()
  }

  async function onCreate() {
    const [res, error] = await handlePromise(
      'createProduct',
      API.graphql(graphqlOperation(createProduct, { input: product }))
    )
    feedbackForGraphQL(res, addNotification, t)
    onEditCancel()
  }

  async function handleDeleteProduct() {
    const [res, error] = await handlePromise(
      'deleteProduct',
      API.graphql(graphqlOperation(deleteProduct, { input: { id: id } }))
    )
    feedbackForGraphQL(res, addNotification, t)
    onEditCancel()
  }

  return (
    <PaperCard
      product={product}
      title={t('product:details.productData')}
      editing={editing}
      invalid={!stringNotEmpty(product?.name)}
      onEditCancel={onEditCancel}
      onSave={isNew ? onCreate : onSave}
      onDelete={handleDeleteProduct}
      showMenu={!!product}
    >
      {children}
    </PaperCard>
  )
}
