import React from 'react'
import PaperCard from '../../../components/widgets/PaperCard'
import { useHistory, useParams } from 'react-router-dom'
import { useNotifications } from '../../Feedback'
import { useTranslation } from 'react-i18next'
import { Contract, Product } from '../../../graphql/graphql'
import { feedbackForGraphQL, handlePromise } from '../../../utils/functions'
import { API, graphqlOperation } from 'aws-amplify'
import { createContract, deleteContract, updateContract } from '../../../graphql/mutations'

type urlParams = {
  id: string
}

type Props = {
  children: any
  contract: Contract
  setContract: (contract: Contract) => void
  editing: boolean
  setEditing: (editing: boolean) => void
  isNew: boolean
}

/**
 * Logic that is required to create/update/delete/request contracts
 */
export default function ContractLogic(props: Props) {
  const { children, contract, setContract, setEditing, editing, isNew } = props
  const { addNotification } = useNotifications()
  const { id } = useParams<urlParams>()
  const { t } = useTranslation()
  const history = useHistory()

  const onEditCancel = () => {
    setEditing(false)
    if (isNew) {
      history.goBack()
    }
  }

  async function onSave() {
    const {
      createdAt,
      updatedAt,
      owner,
      productID,
      Status,
      Product,
      ShippingAddress,
      BillingAddress,
      Request,
      ...contractInput
    } = contract
    const [res, error] = await handlePromise(
      'updateContract',
      API.graphql(graphqlOperation(updateContract, { input: contractInput }))
    )
    feedbackForGraphQL(res, addNotification, t)
    onEditCancel()
  }

  async function onCreate() {
    const [res, error] = await handlePromise(
      'createContract',
      API.graphql(graphqlOperation(createContract, { input: contract }))
    )
    feedbackForGraphQL(res, addNotification, t)
    onEditCancel()
  }

  async function handleDeleteContract() {
    const [res, error] = await handlePromise(
      'deleteContract',
      API.graphql(graphqlOperation(deleteContract, { input: { id: id } }))
    )
    feedbackForGraphQL(res, addNotification, t)
    onEditCancel()
  }

  return (
    <PaperCard
      title={t('contract')}
      editing={editing}
      onEditCancel={onEditCancel}
      onSave={isNew ? onCreate : onSave}
      onDelete={handleDeleteContract}
      product={contract?.Product as Product}
    >
      {children}
    </PaperCard>
  )
}
