import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ProfileButton from '../layout/ProfileButton'
import LanguageSelect from '../widgets/LanguageSelect'
import { makeStyles } from '@mui/styles'
import { AppBar, Badge, Button, IconButton, Toolbar, Typography } from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined'
import MailOutlined from '@mui/icons-material/MailOutlined'
import { DraftsOutlined, MarkEmailUnread } from '@mui/icons-material'
import ChannelLogic from '../../features/Conversation/components/Channel/ChannelLogic'

interface Props {
  title?: string
  showSettings?: boolean
  showBack?: boolean
  centerTitle?: boolean
  isDekstop?: boolean
  onBackClick?: () => void
  groups?: any
}

const useStyles = makeStyles((theme: any) => ({
  offset: theme.mixins.toolbar,
}))

export default function Header(props: Props) {
  const { title, showBack, showSettings, centerTitle, isDekstop, onBackClick, groups } = props
  const history = useHistory()
  const classes = useStyles()
  const { t } = useTranslation()
  const [badgeStatus_countMessages, setBadgeStatus_countMessages] = useState<number>(0)
  const [badgeStatus_unreadConversations, setBadgeStatus_unreadConversations] =
    useState<boolean>(false)

  if (isDekstop) {
    return (
      <React.Fragment>
        <AppBar position="fixed" color={isDekstop ? 'inherit' : 'primary'}>
          <Toolbar>
            <Typography
              variant="h5"
              sx={{ marginRight: '30px' }}
              onClick={() => history.push('/contracts')}
            >
              <b>assemb</b>lean
            </Typography>
            <IconButton
              color="primary"
              onClick={() => {
                setBadgeStatus_unreadConversations(false)
                history.push('/messages')
              }}
            >
              <Badge badgeContent={badgeStatus_countMessages} max={99} color="primary">
                {!(history.location.pathname === '/messages') ? (
                  !badgeStatus_unreadConversations ? (
                    <MailOutlined color="inherit" />
                  ) : (
                    <MarkEmailUnread />
                  )
                ) : (
                  <DraftsOutlined color="inherit" />
                )}
              </Badge>
            </IconButton>
            <ChannelLogic
              setBadgeStatus_countMessages={setBadgeStatus_countMessages}
              setBadgeStatus_unreadConversations={setBadgeStatus_unreadConversations}
            />
            <IconButton color="primary" onClick={() => history.push('/files')}>
              <FolderOpenOutlinedIcon />
            </IconButton>
            <Button style={{ fontWeight: 'bolder' }} onClick={() => history.push('/contracts')}>
              {t('contracts')}
            </Button>
            <Button style={{ fontWeight: 'bolder' }} onClick={() => history.push('/products')}>
              {t('products')}
            </Button>
            {groups && groups.includes('AdminS3') && (
              <Button style={{ fontWeight: 'bolder' }} onClick={() => history.push('/requests')}>
                {t('requests')}
              </Button>
            )}
            <div style={{ marginLeft: 'auto' }}>
              <LanguageSelect />
            </div>
            <div>
              <ProfileButton />
            </div>
          </Toolbar>
        </AppBar>
        <div className={classes.offset} />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <AppBar position="fixed" color="primary">
        <Toolbar>
          {showBack && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="back"
              onClick={onBackClick}
              size="large"
            >
              <ArrowBackIcon /> {/* <BackIcon /> */}
            </IconButton>
          )}
          <Typography
            variant="h6"
            style={{
              flexGrow: 1,
              textAlign: centerTitle ? 'center' : 'left',
              marginRight: showBack ? 32 : 0,
            }}
          >
            {title ? (
              t(title || '')
            ) : (
              <div>
                <b>assemb</b>lean
              </div>
            )}
          </Typography>
          {showSettings && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="settings"
              onClick={() => history.push('/settings')}
              size="small"
            >
              <SettingsIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <div className={classes.offset} />
    </React.Fragment>
  )
}
