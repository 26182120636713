import React, { useEffect, useState } from 'react'
import { Channel, Message } from '../../../../graphql/graphql'
import ChatMessage from './ChatMessage'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
  user: any
  newMessages: Message[]
  activeChannel: Channel
  messages: Message[]
  verticalSpacing?: number
  sx?: React.CSSProperties
}

/**
 * Component that holds the conversation of a channel
 * Sender messages on the right side (editable)
 * Receiver messages on the left side (not editable)
 */
export default function ChatConversation(props: Props) {
  const { newMessages, activeChannel, user, messages, verticalSpacing, sx = {} } = props
  const [showMessageActions, setShowMessageActions] = useState<string>('')
  const { t } = useTranslation()
  /* div sollte immer zur aktuellsten Nachricht scrollen */
  useEffect(() => {
    const conversationContainer = document.getElementById('chatConversation-container')
    conversationContainer?.scrollTop &&
      (conversationContainer.scrollTop = conversationContainer?.scrollHeight)
  }, [messages])

  return (
    <div
      aria-label="chatConversation-container"
      style={{ ...sx, display: 'flex', flexDirection: 'column' }}
    >
      {messages.map((message: Message, index: number) => {
        /*console.log(
          `Message: ${message.body} is from ${message.author} and was written at ${message.createdAt}`
        )*/
        return (
          activeChannel.id === message?.channelID && (
            <div style={{ width: '100%' }}>
              {message && newMessages[0]?.id === message.id && (
                <div
                  style={{
                    width: '100%',
                    display: ' flex',
                    flexDirection: 'row',
                    flexGrow: 1,
                    alignContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    aria-label="chatConversation-horizontalLine"
                    style={{
                      height: 0,
                      flexGrow: 1,
                      border: '1px solid #9e9e9e',
                      marginRight: '10px',
                    }}
                  />
                  <Typography
                    variant="overline"
                    sx={{
                      color: 'GrayText',
                      maxWidth: '65%',
                      overflowWrap: 'normal',
                    }}
                  >
                    {`${newMessages.filter(x => x.channelID === activeChannel.id).length} ${t(
                      'product:channel.newMessages'
                    )}, ${t('product:channel.lastVisited')}: ${activeChannel.visitedAt}`}
                  </Typography>
                  <div
                    aria-label="chatConversation-horizontalLine"
                    style={{
                      height: 0,
                      flexGrow: 1,
                      border: '1px solid #9e9e9e',
                      marginLeft: '10px',
                    }}
                  />
                </div>
              )}
              <ChatMessage
                user={user}
                key={index}
                message={message}
                showMessageActions={showMessageActions}
                setShowMessageActions={setShowMessageActions}
                cornerRadius={8}
                sx={{
                  marginTop: `${verticalSpacing}px`,
                }}
              />
            </div>
          )
        )
      })}
    </div>
  )
}
