import React from 'react'
import Page from '../components/layout/Page'
import RequestForm from '../components/widgets/RequestForm'

export default function UnauthRequestPage() {
  return (
    <Page>
      <RequestForm />
    </Page>
  )
}
