import React, { useEffect, useState } from 'react'
import { ProcessIcons } from './ProcessIcons'
import { Grid, ImageList, InputLabel, MenuItem, Select } from '@mui/material'
import {
  Component,
  ManufacturingProcess,
  ManufacturingRequirement,
  ProcessCategory,
} from '../../../../graphql/graphql'
import { API, graphqlOperation } from 'aws-amplify'
import { getManufacturingProcess, listManufacturingProcesses } from '../../../../graphql/queries'
import { handlePromise } from '../../../../utils/functions'
import ProcessInputs from './ProcessInputs'
import useMobileService from '../../../../hooks/useMobileService'
import { useTranslation } from 'react-i18next'

type Props = {
  component: Component
  setComponent: (component: Component) => void
  manufacturingData: ManufacturingRequirement[]
  setManufacturingData: (manufacturingData: ManufacturingRequirement[]) => void
}

/**
 * Lets you select a Manufacturingprocess.
 * After selection the connected requirements will be displayed
 */
export default function ProcessSelection(props: Props) {
  const { component, setComponent, manufacturingData, setManufacturingData } = props
  const [processList, setProcessList] = useState<ManufacturingProcess[]>([])
  const [process, setProcess] = useState<ManufacturingProcess>()
  // Category thats first selected
  const [originalCategory, setOriginalCategory] = useState<string>(
    component?.ManufacturingProcess?.category as string
  )
  // Process thats first selected
  const [originalProcess, setOriginalProcess] = useState<ManufacturingProcess>(
    component?.ManufacturingProcess as ManufacturingProcess
  )
  const [selectedCategory, selectCategory] = useState<string>(originalCategory)
  const [processName, setProcessName] = useState<string | null>('')
  const isMobile = useMobileService()
  const { t } = useTranslation(['product'])

  useEffect(() => {
    // set already selected category as original and load process
    setOriginalCategory(component?.ManufacturingProcess?.category as string)
    fetchProcess(component?.manufacturingprocessID)
  }, [])

  useEffect(() => {
    // if category changes the Processes also change
    fetchManufacturingProcesses()
  }, [selectedCategory])

  useEffect(() => {
    // set process name on original process or on the first one in select field
    setProcessName(
      originalCategory === selectedCategory ? originalProcess?.process : processList[0]?.process
    )
  }, [processList])

  useEffect(() => {
    // process selection happens
    setProcess(processList.filter(x => x.process === processName)[0])
  }, [processName])

  useEffect(() => {
    // save selected process in component Manufacturingprocess
    process
      ? setComponent({
          ...component,
          manufacturingprocessID: process?.id as string,
          ManufacturingProcess: process,
        })
      : null
  }, [process])

  // filter fetched process by category
  const processFilter = {
    category: {
      eq: `${selectedCategory}`,
    },
  }

  async function fetchManufacturingProcesses() {
    const [res, err] = await handlePromise(
      'listManufacturingProcesses',
      API.graphql(graphqlOperation(listManufacturingProcesses, { filter: processFilter }))
    )
    res
      ? setProcessList(res.data.listManufacturingProcesses.items)
      : console.log('Error on fetching ManufacturingProcesses: ' + err)
  }

  async function fetchProcess(id: string) {
    const [res, err] = await handlePromise(
      'getManufacturingProcess',
      API.graphql(graphqlOperation(getManufacturingProcess, { id: id }))
    )
    if (res) {
      setProcess(res.data.getManufacturingProcess)
      setProcessName(res.data.getManufacturingProcess.process)
      selectCategory(res.data.getManufacturingProcess.category)
    } else {
      console.log('Error on fetching ManufacturingProcess' + err)
    }
  }

  return (
    <div>
      {!isMobile ? (
        <div style={{ overflowX: 'auto' }}>
          <ImageList sx={{ width: '100%', height: 210 }} cols={6} rowHeight={50}>
            {Object.values(ProcessCategory).map((category: string, index: number) => (
              <div onClick={() => selectCategory(category)} key={index}>
                <ProcessIcons category={category} active={selectedCategory === category} />
              </div>
            ))}
          </ImageList>
        </div>
      ) : (
        <Grid container spacing={1} justifyContent="space-evenly">
          {Object.values(ProcessCategory).map((category: string, index: number) => (
            <Grid item onClick={() => selectCategory(category)} key={index}>
              <ProcessIcons category={category} active={selectedCategory === category} />
            </Grid>
          ))}
        </Grid>
      )}
      <InputLabel id="manufacturing_label" sx={{ marginTop: '16px' }}>
        {t('ManufacturingProcess.manufacturingProcess')}
      </InputLabel>
      <Select
        sx={
          !isMobile
            ? {
                minWidth: '500px',
                marginBottom: '5px',
              }
            : {
                width: '100%',
                marginBottom: '5px',
              }
        }
        label="ManufacturingProcess"
        labelId="manufacturing_label"
        id={'ManufacturingProcess'}
        value={processName ?? ''}
        onChange={e => setProcessName(e.target.value)}
      >
        {processList.map((value: ManufacturingProcess, index: number) => (
          <MenuItem key={index} value={value.process}>
            {t('ManufacturingProcess.' + value.process)}
          </MenuItem>
        ))}
      </Select>
      {process && component && (
        <ProcessInputs
          component={component}
          manufacturingProcess={process}
          manufacturingData={manufacturingData}
          setManufacturingData={setManufacturingData}
        />
      )}
    </div>
  )
}
