import React, { useEffect, useRef } from 'react'
import * as BABYLON from 'babylonjs'
import Engine = BABYLON.Engine
import Scene = BABYLON.Scene

export default function SceneComponent(props: any) {
  const reactCanvas = useRef(null)
  const {
    antialias,
    engineOptions,
    adaptToDeviceRatio,
    sceneOptions,
    onRender,
    onSceneReady,
    ...rest
  } = props

  useEffect(() => {
    if (reactCanvas.current) {
      const engine = new Engine(reactCanvas.current, antialias, engineOptions, adaptToDeviceRatio)
      const scene = new Scene(engine, sceneOptions)

      if (scene.isReady()) {
        props.onSceneReady(scene)
      } else {
        scene.onReadyObservable.addOnce((scene: any) => props.onSceneReady(scene))
      }

      engine.runRenderLoop(() => {
        if (typeof onRender === 'function') {
          onRender(scene)
        }
        scene.render()
      })

      const resize = () => {
        scene.getEngine().resize()
      }

      if (window) {
        window.addEventListener('resize', resize)
      }
      return () => {
        scene.getEngine().dispose()
        if (window) {
          window.removeEventListener('resize', resize)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reactCanvas])

  return <canvas ref={reactCanvas} {...rest} style={{ margin: '10px', width: '97%' }} />
}
