import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useConfirm } from '../../features/Feedback'
import { Auth } from 'aws-amplify'
import { Button } from '@mui/material'
import { AccountCircle } from '@mui/icons-material'

export default function ProfileButton() {
  const history = useHistory()
  const { confirmDialog } = useConfirm()
  const { t } = useTranslation()
  const [email, setUserEmail] = useState('')

  useEffect(() => {
    getUser()
  })

  async function getUser() {
    try {
      const user = await Auth.currentAuthenticatedUser()
      setUserEmail(user.attributes.email)
    } catch (error) {
      console.log('Error fetching user')
    }
  }

  // write a function to sign out
  async function signOut() {
    await Auth.signOut()
    window.location.reload()
  }

  return (
    <div>
      <Button
        variant="text"
        color="inherit"
        style={{ textTransform: 'lowercase' }}
        onClick={() => history.push('/settings/profile')}
      >
        <AccountCircle fontSize="large" />
        {email}
      </Button>
      <Button
        color="secondary"
        variant="contained"
        onClick={() => {
          confirmDialog('dialog.logout', signOut)
        }}
      >
        {t('logout')}
      </Button>
    </div>
  )
}
