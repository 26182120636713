import React, { SyntheticEvent, useEffect, useState } from 'react'
import { BOM, Component } from '../../../../graphql/graphql'
import { TreeView } from '@mui/lab'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { handlePromise } from '../../../../utils/functions'
import { API, graphqlOperation } from 'aws-amplify'
import { customGetComponent } from '../../../../graphql/custom-queries'
import ComponentTreeItem from './ComponentTreeItem'
import { useTranslation } from 'react-i18next'

interface Child {
  id: string
  name: string
  quantity: number
}

type Props = {
  updatedComponent?: Component
  rootComponent: Component
  isEditMode?: boolean
  handleItemTick: (id: string, name: string) => void
  getItemTickedStatus: (id: string) => boolean
  setOpen: (open: boolean) => void
  setComponent: (component: Component) => void
  setNew: (isNew: boolean) => void
  setActiveStep: (step: any) => void
  setParentComponent: (comp: Component) => void
  refetchChildren: boolean
  setParentBOM: (BOM: any) => void
  setQuantity: (quantity: number) => void
}

/**
 * Subtree of one root component
 * Data for Subtree is fetched from the Components BOM
 * On click of the expand arrow all child components will be fetched and
 * stored in the file structure
 */
export default function ComponentSubTree(props: Props) {
  const {
    rootComponent,
    isEditMode = false,
    handleItemTick,
    getItemTickedStatus,
    setOpen,
    setComponent,
    setNew,
    updatedComponent,
    setActiveStep,
    setParentComponent,
    refetchChildren,
    setParentBOM,
    setQuantity,
  } = props
  const initialData: any = {
    root: [{ id: rootComponent.id, name: rootComponent.name, quantity: 1 }],
    1: [],
  }
  const [expanded, setExpanded] = useState<string[]>([])
  const [hasChildren, setHasChildren] = useState<string[]>([rootComponent.id])
  const [treeData, setTreeData] = useState(initialData)
  const { t } = useTranslation()

  useEffect(() => {
    expanded.length > 1 ? fetchChildren(expanded[0]) : null
  }, [expanded, refetchChildren])

  /**
   * Loads Children of expanded Component
   * If child also has childs it shows another expand arrow
   * @param id Component id of the component that gets expanded
   */
  async function fetchChildren(id: string) {
    const [res, err] = await handlePromise(
      'fetchChildren',
      API.graphql(graphqlOperation(customGetComponent, { id: id }))
    )
    if (res.data.getComponent?.Parts) {
      const newTreeData: any[] = []
      res.data.getComponent.Parts.items.map((bom: BOM | null, index: number) => {
        newTreeData.push({
          id: bom?.Child?.id,
          name: bom?.Child?.name,
          quantity: bom?.quantity,
        })
        if (bom?.Child?.Parts?.items.length && bom?.Child?.Parts?.items.length > 0) {
          setHasChildren(hasChildren => [...hasChildren, bom?.Child?.id as string])
        }
      })
      setTreeData({ ...treeData, [id]: newTreeData })
    } else {
      console.log('Error on fetching Children', err)
    }
  }

  const handleChange = (event: SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds)
  }

  // recursively render child components as ComponentTreeItem component
  const renderTree = (children: any[]) => {
    return children
      .filter((value, index, self) => index === self.findIndex(t => t.id === value.id) && value.id)
      .map((child: Child, index: number) => {
        const childrenNodes =
          treeData[child.id] && treeData[child.id].length > 0
            ? renderTree(treeData[child.id])
            : [<div key={index} />]
        return (
          <ComponentTreeItem
            updatedComponent={updatedComponent}
            key={child.id}
            nodeId={child.id}
            label={child.quantity > 1 ? child.quantity + 'x ' + child.name : child.name}
            handleTick={handleItemTick}
            getTickedStatus={getItemTickedStatus}
            setOpen={setOpen}
            setNew={setNew}
            setComponent={setComponent}
            isEditMode={isEditMode}
            setActiveStep={setActiveStep}
            setParentComponent={setParentComponent}
            setParentBOM={setParentBOM}
            setQuantity={setQuantity}
            childPos={index}
          >
            {childrenNodes}
          </ComponentTreeItem>
        )
      })
  }

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpanded={['root']}
      defaultExpandIcon={<ChevronRightIcon />}
      sx={{ flexGrow: 1 }}
      onNodeToggle={handleChange}
    >
      {renderTree(treeData.root)}
    </TreeView>
  )
}
