import React, { useEffect } from 'react'
import { Channel, Message } from '../../../../graphql/graphql'
import ChatTextInput from './ChatTextInput'
import { handlePromise, sendInfoEmail } from '../../../../utils/functions'
import { API, graphqlOperation } from 'aws-amplify'
import { listMessages, messageByChannel } from '../../../../graphql/queries'
import { createMessage } from '../../../../graphql/mutations'
import { Observable } from 'zen-observable-ts'
import { customOnCreateMessage } from '../../../../graphql/custom-subscriptions'
import { Grid, IconButton, Typography } from '@mui/material'
import ChatConversation from './ChatConversation'
import NewChannel from '../Channel/NewChannel'
import { AccountCircle } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import { onDeleteMessage, onUpdateMessage } from '../../../../graphql/subscriptions'
import useMobileService from '../../../../hooks/useMobileService'
import Page from '../../../../components/layout/Page'
import { useTranslation } from 'react-i18next'

type Props = {
  messages: Message[]
  setMessages: (messages: Message[]) => void
  newMessages: Message[]
  setNewMessages: (messages: Message[]) => void
  channel: Channel
  handleMobileCloseChatView?: () => void //required if on mobile
  handleChannelClick: (channel: Channel) => void
  visitedChannel: Channel
  user: any
  userGroups: string[] | null
  sx?: React.CSSProperties
}

/**
 * Container to view the Chat shows reference, product, textinput and messages
 * Also holds subscriptions and logic for messages
 */
export default function ChatView(props: Props) {
  const {
    messages,
    setMessages,
    newMessages,
    setNewMessages,
    channel,
    handleMobileCloseChatView,
    visitedChannel,
    user,
    userGroups,
    sx = {},
    handleChannelClick,
  } = props
  const { t } = useTranslation()
  const isMobile = useMobileService()

  useEffect(() => {
    // load messages if the channel is not null
    channel && channel.id ? fetchMessages() : null
  }, [channel])

  useEffect(() => {
    // update messages when new messages are send/received
    const client = API.graphql(graphqlOperation(customOnCreateMessage)) as Observable<any>
    const subscription = client.subscribe({
      next: event => {
        console.log(event.value.data.onCreateMessage)
        setMessages([...(messages as Message[]), event.value.data.onCreateMessage])
        user.username !== event.value.data.onCreateMessage.author
          ? setNewMessages(
              getNewMessages([...(messages as Message[]), event.value.data.onCreateMessage])
            )
          : null
      },
      error: error => console.warn(error),
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [messages])

  useEffect(() => {
    // update messages when new messages are deleted
    const client = API.graphql(graphqlOperation(onDeleteMessage)) as Observable<any>
    const subscription = client.subscribe({
      next: event => fetchMessages(),
      error: error => console.warn(error),
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [messages])

  useEffect(() => {
    // update messages when new messages are edited
    const client = API.graphql(graphqlOperation(onUpdateMessage)) as Observable<any>
    const subscription = client.subscribe({
      next: event => fetchMessages(),
      error: error => console.warn(error),
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [messages])

  /**
   * Differentiate between new and old messages in a chat
   * @params messages all messages inside chat
   * @returns new messages after the last visit on channel
   */
  function getNewMessages(messages: Message[]) {
    const newMessages: Message[] = []
    if (visitedChannel) {
      const channelDate = new Date(visitedChannel?.visitedAt as string)
      messages.map((message: Message) => {
        const messageDate = new Date(message.createdAt)
        messageDate > channelDate ? newMessages.push(message) : null
      })
    } else {
      const channelDate = new Date(channel?.visitedAt as string)
      messages.map((message: Message) => {
        const messageDate = new Date(message.createdAt)
        messageDate > channelDate ? newMessages.push(message) : null
      })
    }
    return newMessages.filter(x => x.author !== user.username)
  }

  async function fetchMessages() {
    const [res, err] = await handlePromise(
      'listMessages',
      API.graphql(graphqlOperation(messageByChannel, { channelID: channel.id }))
    )
    if (res) {
      const messages: Message[] = res.data.messageByChannel.items
      // sort messages to return them in the right order (new to old)
      messages.sort(function (a: any, b: any) {
        const dateA = new Date(a.createdAt)
        const dateB = new Date(b.createdAt)
        return dateA > dateB ? 1 : -1
      })
      setMessages(messages)
      setNewMessages(getNewMessages(messages))
    } else {
      console.log('Error on fetching Messages', err)
    }
  }

  async function sendMessage(message: string) {
    const [res, err] = await handlePromise(
      'createMessage',
      API.graphql(
        graphqlOperation(createMessage, {
          input: { body: message, channelID: channel.id, author: user.username },
        })
      )
    )
    if (res) {
      console.log('Message created', res)
      sendInfoEmail(
        user.attributes.email === channel.address_to ? channel.address_from : channel.address_to,
        'NEW_MESSAGES',
        res.data.createMessage.body
      )
    }
    setNewMessages([])
  }

  if (channel) {
    return (
      <div
        aria-label="chatView-container"
        style={{ ...sx, display: 'flex', flexDirection: 'column' }}
      >
        {isMobile && (
          <div
            aria-label="chatView-mobileCloseAction"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'right',
              alignContent: 'center',
            }}
          >
            <IconButton onClick={handleMobileCloseChatView} sx={{ display: 'flex' }}>
              <CloseIcon />
            </IconButton>
          </div>
        )}
        <div
          aria-label="chatView-header"
          style={{
            background: '#0088ff',
            height: 'max-content',
            borderRadius: '8px',
            paddingLeft: '8px',
            paddingRight: '8px',
            paddingTop: '2px',
            paddingBottom: '2px',
          }}
        >
          <div aria-label="chatView-header-reference" style={{ display: 'flex' }}>
            <Typography variant="subtitle1" component="span" sx={{ marginRight: '5px' }}>
              {t('product:channel.reference') + ':'}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                overflowWrap: 'break-word',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                hyphens: 'auto',
                maxHeight: '150px',
                overflowY: 'auto',
              }}
            >
              {channel && channel.reference && channel.reference}
            </Typography>
          </div>
          <Grid container direction="row" alignItems="center" gap={1}>
            <Grid item>
              <AccountCircle />
            </Grid>
            <Grid item>
              <Typography
                variant="body2"
                style={{
                  fontWeight: 'bolder',
                  overflowWrap: 'break-word',
                  wordWrap: 'break-word',
                  wordBreak: 'break-word',
                  hyphens: 'auto',
                  maxHeight: '150px',
                  overflowY: 'auto',
                }}
              >
                {channel && channel.product}
              </Typography>
              {userGroups && userGroups.includes('AdminS3') && (
                <Typography>
                  From:{' '}
                  {user.attributes.email === channel.address_from
                    ? channel.address_to
                    : channel.address_from}
                </Typography>
              )}
            </Grid>
          </Grid>
        </div>
        <ChatConversation
          newMessages={newMessages}
          activeChannel={channel}
          user={user}
          messages={messages}
          verticalSpacing={25}
          sx={{
            overflowY: 'auto',
            overflowX: 'hidden',
            flex: '1 1 auto',
            width: '100%',
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingBottom: '4px',
          }}
        />

        <ChatTextInput
          sendMessage={sendMessage}
          sx={{ height: 'max-content', overflowY: 'auto' }}
        />
      </div>
    )
  } else {
    return !isMobile ? (
      <NewChannel user={user} userGroups={userGroups} handleChannelClick={handleChannelClick} />
    ) : (
      <Page>
        <div
          aria-label="chatView-mobileCloseAction"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'right',
            alignContent: 'center',
          }}
        >
          <IconButton onClick={handleMobileCloseChatView} sx={{ display: 'flex' }}>
            <CloseIcon />
          </IconButton>
        </div>
        <NewChannel user={user} userGroups={userGroups} handleChannelClick={handleChannelClick} />
      </Page>
    )
  }
}
