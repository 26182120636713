import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Page from '../../components/layout/Page'
import Auth from '@aws-amplify/auth'
import useMobileService from '../../hooks/useMobileService'

type Props = {
  setUser: (user: any) => void
}

type UserAttributes = {
  [key: string]: string
}

type LoginFormFieldType = {
  placeholder: string
  label: string
  type: string
  required?: boolean
  error?: boolean
  helperText?: string
  inputProps?: any
}

export default function SignUpPage(props: Props) {
  const { setUser } = props
  const [invalidPassword, setInvalidPassword] = useState(false)
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  const passwordRegex =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/
  const [data, setData] = useState<UserAttributes>({
    email: '',
    password: '',
    company_name: '',
    contact_person: '',
    mobile_number: '',
    phone_number: '',
    vat_number: '',
  })
  const history = useHistory()
  const { t } = useTranslation(['common'])
  const isMobile = useMobileService()

  const dataIsValid = (data: UserAttributes) => {
    return (
      data.email &&
      emailRegex.test(data.email) &&
      data.password &&
      passwordRegex.test(data.password)
    )
  }

  const handleSignUp = async (e: any) => {
    e.preventDefault()
    try {
      const user = await Auth.signUp({
        username: data.email,
        password: data.password,
        attributes: {
          'custom:company_name': data.company_name,
          'custom:contact_person': data.contact_person,
          'custom:mobile_number': data.mobile_number,
          'custom:phone_number': data.phone_number,
          'custom:vat_number': data.vat_number,
        },
      })
      setUser(user)
    } catch (error) {
      console.log('error signing up:', error)
    }
  }

  const loginFormfields: LoginFormFieldType[] = [
    {
      type: 'email',
      label: t('common:loginForm.email'),
      placeholder: t('common:loginForm.emailPlaceholder'),
      inputProps: { required: true, autoComplete: 'username' },
      helperText:
        data.email && !emailRegex.test(data.email) ? t('common:loginForm.emailHelperText') : '',
      error: (data.email && !emailRegex.test(data.email)) || false,
    },
    {
      type: 'password',
      label: t('common:loginForm.password'),
      placeholder: t('common:loginForm.passwordPlaceholder'),
      inputProps: { required: true, autoComplete: 'new-password' },
      helperText: t('common:loginForm.passwordHelperText'),
      error: (data.password && !passwordRegex.test(data.password) && invalidPassword) || false,
    },
    {
      type: 'custom:company_name',
      label: t('common:loginForm.companyName'),
      placeholder: t('common:loginForm.companyNamePlaceholder'),
    },
    {
      type: 'custom:contact_person',
      label: t('common:loginForm.contactPerson'),
      placeholder: t('common:loginForm.contactPersonPlaceholder'),
    },
    {
      type: 'custom:mobile_number',
      label: t('common:loginForm.mobileNumber'),
      placeholder: t('common:loginForm.mobileNumberPlaceholder'),
    },
    {
      type: 'custom:phone_number',
      label: t('common:loginForm.phoneNumber'),
      placeholder: t('common:loginForm.phoneNumberPlaceholder'),
    },
    {
      type: 'custom:vat_number',
      label: t('common:loginForm.vatNumber'),
      placeholder: t('common:loginForm.vatNumberPlaceholder'),
    },
  ]

  const handleSubmit = (event: any) => {
    event.preventDefault()
    if (dataIsValid(data)) {
      handleSignUp(event)
      history.push({ pathname: '/verify-email', state: { email: data.email, password: data.password } })
    } else {
      setInvalidPassword(true)
    }
  }

  if (isMobile) {
    return (
      <Page>
        <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
          <Grid item xs={12} sm={8} md={6} lg={4} xl={3}>
            <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
              <Typography variant="h4">
                <b>assemb</b>lean
              </Typography>
              <Typography variant="h5">{t('auth.register')}</Typography>
              <form onSubmit={handleSubmit} style={{ width: isMobile ? '100%' : '60%' }}>
                {loginFormfields.map((field: LoginFormFieldType, index: number) => {
                  return (
                    <Box mt={2} key={index}>
                      <TextField
                        id={field.type}
                        label={field.label}
                        type={field.type}
                        variant="outlined"
                        fullWidth
                        value={data[field.type]}
                        onChange={e => setData({ ...data, [field.type]: e.target.value })}
                        placeholder={field.placeholder}
                        inputProps={field.inputProps}
                        helperText={field.helperText}
                        error={field.error}
                      />
                    </Box>
                  )
                })}
                <Box mt={2}>
                  <Button type="submit" variant="contained" color="primary" fullWidth>
                    {t('auth.register')}
                  </Button>
                </Box>
                <Box mt={2}>
                  <Button
                    sx={{ marginBottom: '1rem' }}
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() => history.push('/signIn')}
                  >
                    {t('auth.backToSignIn')}
                  </Button>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Page>
    )
  }

  return (
    <Page>
      <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
        <Typography variant="h4">
          <b>assemb</b>lean
        </Typography>
        <Typography variant="h5">{t('auth.register')}</Typography>
        <form onSubmit={handleSubmit} style={{ width: isMobile ? '100%' : '60%' }}>
          {loginFormfields.map((field: LoginFormFieldType, index: number) => {
            return (
              <Box mt={2} key={index}>
                <TextField
                  id={field.type}
                  label={field.label}
                  type={field.type}
                  variant="outlined"
                  fullWidth
                  value={data[field.type]}
                  onChange={e => setData({ ...data, [field.type]: e.target.value })}
                  placeholder={field.placeholder}
                  inputProps={field.inputProps}
                  helperText={field.helperText}
                  error={field.error}
                />
              </Box>
            )
          })}
          <Box mt={2}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              {t('common:auth.register')}
            </Button>
          </Box>
          <Box mt={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={() => history.push('/signIn')}
            >
              {t('common:auth.backToSignIn')}
            </Button>
          </Box>
        </form>
      </Box>
    </Page>
  )
}
