import { FormFieldType } from '../../Form'
import { Component, ManufacturingRequirement } from '../../../graphql/graphql'

/**
 * Map requirements for Manufacturingprocesses to its corresponding input components
 * @param requirements are mapped to inputs
 * @returns list of objects to define input props
 */
export function requirementsToFields(requirements: ManufacturingRequirement[]) {
  const fields: FormFieldType[] = []
  requirements.map((req: any, index: number) => {
    switch (req.type) {
      case 'string':
        fields.push({ type: 'text', key: req.label, label: req.label, xs: 12, sm: 6 })
        break
      case 'number':
        fields.push({ type: 'number', key: req.label, label: req.label, xs: 12, sm: 6 })
        break
      case 'boolean':
        fields.push({ type: 'checkbox', key: req.label, label: req.label, xs: 12, sm: 6 })
        break
      case 'select':
        const options: any[] = []
        req.options.map((option: any) => options.push({ value: option, label: option }))
        fields.push({
          type: 'select',
          key: req.label,
          label: req.label,
          options: options,
          xs: 12,
          sm: 6,
        })
        break
      case 'color':
        fields.push({
          type: 'color',
          key: req.label,
          label: req.label,
          xs: 12,
          sm: 12,
        })
        break
      case 'selectWithText':
        if (req.options.length > 0) {
          const options: any[] = []
          req.options.map((option: any) => options.push({ value: option, label: option }))
          fields.push({
            type: 'selectWithText',
            key: req.label,
            label: req.label,
            options: options,
            xs: 12,
            sm: 6,
          })
        } else {
          fields.push({
            type: 'checked',
            key: req.label,
            label: req.label,
            xs: 12,
            sm: 6,
          })
        }
        break
    }
  })
  fields.sort((a, b) => (a.type < b.type ? 1 : -1))
  return fields
}

/**
 * Format FileUploads from requirements to List for component to render
 * @param requirements given requirements by ManufacturingProcess
 * */
export function requirementsToFileUploads(requirements: any[], component?: Component) {
  console.log(requirements)
  const fileUploads: FormFieldType[] = []
  requirements.map((req: any, index) => {
    switch (req.type) {
      case 'document_upload':
        fileUploads.push({
          type: 'file_upload',
          key: req.label,
          label: req.label,
          component: component,
          xs: 12,
          sm: 6,
          md: 12,
          lg: 12,
        })
        break
      case 'cad_upload':
        // TODO: implement cad_upload
        fileUploads.push({
          type: 'cad_upload',
          key: req.label,
          label: req.label,
          component: component,
          xs: 12,
          sm: 6,
          md: 12,
          lg: 12,
        })
        break
    }
  })
  return fileUploads
}

/**
 * Get text for Steps in DynamicForm
 * @param step string for each step
 * @param t useTranslation hook
 * */
export default function getTranslatetedStepTitle(step: string, t: any) {
  switch (step) {
    case 'Component Information':
      return t('product:dynamicForm.componentInformation')
    case 'Process Information':
      return t('product:dynamicForm.processInformation')
    case 'File Uploads':
      return t('product:dynamicForm.fileUploads')
    case 'componentQuantity':
      return t('product:dynamicForm.componentQuantity')
    default:
      return step
  }
}
