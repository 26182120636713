import React, { useEffect, useState } from 'react'
import { BrowserRouter, Redirect, Switch } from 'react-router-dom'
import { AuthLayout, EmptyLayout, MainLayout } from './components/layout/Layout'
import { AdminRoute, AuthenticatedRoute, PublicRoute } from './components/layout/Routes'
import { ConfirmDialog, Snackbar } from './features/Feedback'
import ContractOverviewPage from './pages/ContractOverviewPage'
import ProductOverviewPage from './pages/ProductOverviewPage'
import ProductPage from './pages/ProductPage'
import ProfilePage from './pages/ProfilePage'
import LanguageSelectPage from './pages/LanguageSelectPage'
import SettingsPage from './pages/SettingsPage'
import WrongRoutePage from './pages/WrongRoutePage'
import createTheme from './utils/theme'
import ContractPage from './pages/ContractPage'
import { CssBaseline, ThemeProvider, StyledEngineProvider, useMediaQuery } from '@mui/material'
import MessageOverviewPage from './pages/MessageOverviewPage'
import FilesystemPage from './pages/FilesystemPage'
import Amplify, { Auth } from 'aws-amplify'
import ContractOverviewAdminPage from './pages/ContractOverviewAdminPage'
import ProductOverviewAdminPage from './pages/ProductOverviewAdminPage'
import SignInPage from './pages/Auth/SignInPage'
import SignUpPage from './pages/Auth/SignUpPage'
import ResetPasswordPage from './pages/Auth/ResetPasswordPage'
import awsExports from './aws-exports'
import VerifyEmailPage from './pages/Auth/VerifyEmailPage'
import UnauthRequestPage from './pages/UnauthRequestPage'
import RequestAdminPage from './pages/RequestAdminPage'
import RequestOverviewAdminPage from './pages/RequestOverviewAdminPage'

Amplify.configure(awsExports)

/**
 * Routing of pages
 */
function Routes() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const theme = createTheme(prefersDarkMode)
  const [user, setUser] = useState<any>(null)
  const [groups, setGroups] = useState<any>(null)

  useEffect(() => {
    checkAuthStatus()
  }, [])

  useEffect(() => {
    if (user) {
      const userGroups: string[] | null =
        user?.signInUserSession?.accessToken?.payload['cognito:groups']
      setGroups(userGroups)
    }
  }, [user])

  const checkAuthStatus = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      setUser(user)
    } catch (error) {
      console.log('error', error)
    }
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Switch>
            <PublicRoute
              path="/"
              exact
              component={() => <Redirect to="/signIn" />}
              layout={EmptyLayout}
              title=""
            />
            { groups && groups.includes('AdminS3') ? (
                <AdminRoute
                  path="/contracts/new"
                  exact
                  component={ContractPage}
                  layout={MainLayout}
                  showBack
                  title="pages.newContractPage"
                  user={user}
                  groups={groups}
                />
            ) : (
                  <AuthenticatedRoute
                    path="/contracts/new"
                    exact
                    component={ContractPage}
                    layout={MainLayout}
                    showBack
                    title="pages.newContractPage"
                    user={user}
                  />
              )}
            {groups && groups.includes("AdminS3") ? (
                <AdminRoute
                  path="/contracts/:id"
                  exact
                  component={ContractPage}
                  layout={MainLayout}
                  showBack
                  title="pages.contractPage"
                  user={user}
                  groups={groups}
                />
            ) : (
                <AuthenticatedRoute
                    path="/contracts/:id"
                    exact
                    component={ContractPage}
                    layout={MainLayout}
                    showBack
                    title="pages.contractPage"
                    user={user}
                />
            )}
            {groups && groups.includes("AdminS3") ? (
                <AdminRoute
                  path="/products/new"
                  exact
                  component={ProductPage}
                  layout={MainLayout}
                  showBack
                  title="pages.newProductPage"
                  user={user}
                  groups={groups}
                />
            ) : (
                <AuthenticatedRoute
                  path="/products/new"
                  exact
                  component={ProductPage}
                  layout={MainLayout}
                  showBack
                  title="pages.newProductPage"
                  user={user}
                />
            )}
            {groups && groups.includes("AdminS3") ? (
                <AdminRoute
                  path="/products/:id"
                  exact
                  component={ProductPage}
                  layout={MainLayout}
                  showBack
                  title="pages.productPage"
                  user={user}
                  groups={groups}
                />
            ) : (
                <AuthenticatedRoute
                  path="/products/:id"
                  exact
                  component={ProductPage}
                  layout={MainLayout}
                  showBack
                  title="pages.productPage"
                  user={user}
                />
              )}
            {groups && groups.includes("AdminS3") ? (
                <AdminRoute
                  path="/admin/settings/profile"
                  exact
                  component={ProfilePage}
                  layout={MainLayout}
                  showBack
                  title="pages.profile"
                  user={user}
                  groups={groups}
                />
            ) : (
                <AuthenticatedRoute
                  path="/settings/profile"
                  exact
                  component={ProfilePage}
                  layout={MainLayout}
                  showBack
                  title="pages.profile"
                  user={user}
                />
              )}
            {groups && groups.includes("AdminS3") ? (
                <AdminRoute
                  path="/admin/settings/language"
                  exact
                  component={LanguageSelectPage}
                  layout={MainLayout}
                  showBack
                  title="pages.language"
                  user={user}
                  groups={groups}
                />
            ) : (
                <AuthenticatedRoute
                  path="/settings/language"
                  exact
                  component={LanguageSelectPage}
                  layout={MainLayout}
                  showBack
                  title="pages.language"
                  user={user}
                />
              )}
            {groups && groups.includes('AdminS3') ? (
              <AdminRoute
                path="/contracts"
                exact
                showSettings
                component={ContractOverviewAdminPage}
                layout={MainLayout}
                user={user}
                groups={groups}
              />
            ) : (
              <AuthenticatedRoute
                path="/contracts"
                exact
                showSettings
                component={ContractOverviewPage}
                layout={MainLayout}
                user={user}
              />
            )}
            {groups && groups.includes('AdminS3') ? (
              <AdminRoute
                path="/products"
                exact
                showSettings
                component={ProductOverviewAdminPage}
                layout={MainLayout}
                user={user}
                groups={groups}
              />
            ) : (
              <AuthenticatedRoute
                path="/products"
                exact
                showSettings
                component={ProductOverviewPage}
                layout={MainLayout}
                user={user}
              />
            )}
            {groups && groups.includes('') ? (
              <AdminRoute
                path="/settings"
                exact
                showBack
                component={SettingsPage}
                layout={MainLayout}
                title="pages.settings"
                user={user}
                groups={groups}
              />
            ) : (
              <AuthenticatedRoute
                path="/settings"
                exact
                showBack
                component={SettingsPage}
                layout={MainLayout}
                title="pages.settings"
                user={user}
              />
            )}
            {groups && groups.includes('AdminS3') ? (
              <AdminRoute
                path="/messages"
                exact
                component={MessageOverviewPage}
                layout={MainLayout}
                showBack
                title="messages"
                user={user}
                groups={groups}
              />
            ) : (
              <AuthenticatedRoute
                path="/messages"
                exact
                component={MessageOverviewPage}
                layout={MainLayout}
                showBack
                title="messages"
                user={user}
              />
            )}
            {groups && groups.includes('AdminS3') ? (
              <AdminRoute
                path="/files"
                exact
                component={FilesystemPage}
                layout={MainLayout}
                showBack
                title="files"
                user={user}
                groups={groups}
              />
            ) : (
              <AuthenticatedRoute
                path="/files"
                exact
                component={FilesystemPage}
                layout={MainLayout}
                showBack
                title="files"
                user={user}
              />
            )}
            <AdminRoute
              path="/requests"
              exact
              showSettings
              component={RequestOverviewAdminPage}
              layout={MainLayout}
              user={user}
              groups={groups}
            />
            <AdminRoute
              path="/requests/:id"
              exact
              component={RequestAdminPage}
              layout={MainLayout}
              showBack
              title="pages.requestPage"
              user={user}
              groups={groups}
            />
            <PublicRoute
              path="/404"
              exact
              component={WrongRoutePage}
              layout={EmptyLayout}
              title="404"
            />
            <PublicRoute
              path="/signIn"
              exact
              component={() => <SignInPage setUser={setUser} user={user} />}
              layout={AuthLayout}
            />
            <PublicRoute
              path="/signUp"
              exact
              component={() => <SignUpPage setUser={setUser} />}
              layout={AuthLayout}
            />
            <PublicRoute
              path="/reset-password"
              exact
              component={ResetPasswordPage}
              layout={AuthLayout}
            />
            <PublicRoute
              path="/verify-email"
              exact
              component={VerifyEmailPage}
              layout={AuthLayout}
            />
            <PublicRoute
              path="/send-request"
              exact
              component={UnauthRequestPage}
              layout={AuthLayout}
            />
            <Redirect to="/404" />
          </Switch>
        </BrowserRouter>
        <Snackbar />
        <ConfirmDialog />
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
export default Routes
