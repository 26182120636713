import React, { useEffect, useState } from 'react'
import {
  Button,
  FormControl,
  InputLabel,
  TextField,
  Select,
  SelectChangeEvent,
  MenuItem,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { handlePromise, sendInfoEmail } from '../../../../utils/functions'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import { createChannel } from '../../../../graphql/mutations'
import { Channel, Product } from '../../../../graphql/graphql'
import { listProducts } from '../../../../graphql/queries'

type Props = {
  user: any
  userGroups: string[] | null
  handleChannelClick: (channel: Channel) => void
}

/**
 * Page that lets you create a new channel
 *  - Reference
 *  - Product
 *  - To: (Receiver (only for admin))
 */
export default function NewChannel(props: Props) {
  const { user, userGroups, handleChannelClick } = props
  const { t } = useTranslation()
  const [reference, setReference] = useState<string>('')
  const [newChannel, setNewChannel] = useState<Channel | undefined>()
  const [products, setProducts] = useState<Product[]>([])
  const [product, setProduct] = useState<string>('')
  const [userEmails, setUserEmails] = useState<string[]>([])
  const [addressTo, setAddressTo] = useState<string>('')

  useEffect(() => {
    fetchProducts()
    listUserAddresses()
  }, [])

  useEffect(() => {
    newChannel ? handleChannelClick(newChannel as Channel) : null
  }, [newChannel])

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    startConversation()
  }

  async function fetchProducts() {
    const [res, err] = await handlePromise(
      'listProducts',
      API.graphql(graphqlOperation(listProducts))
    )
    res ? setProducts(res.data.listProducts.items) : console.log('Error fetching products')
  }

  async function startConversation() {
    const receiver: string =
      userGroups &&
      userGroups.includes('AdminS3') &&
      user.attributes.email === 'chat@assemblean.com'
        ? addressTo
        : 'chat@assemblean.com'
    const [res, err] = await handlePromise(
      'createChannel',
      API.graphql(
        graphqlOperation(createChannel, {
          input: {
            reference: reference,
            address_to: receiver,
            address_from: user.attributes.email,
            product: product,
          },
        })
      )
    )
    res ? setNewChannel(res.data.createChannel) : console.log('Error on creating Channel', err)
    res && user.attributes.email
      ? sendInfoEmail(receiver, 'NEW_CONVERSATION', reference as string)
      : null
  }

  async function listUserAddresses() {
    const apiName = 'AdminQueries'
    const path = '/listUsers'
    const myInit = {
      queryStringParameters: {
        UserPoolId: user.pool?.userPoolId,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    }
    const response = await API.get(apiName, path, myInit)
    const emails: string[] = response.Users.map(
      (user: any) => user.Attributes.filter((x: any) => x.Name === 'email')[0].Value
    )
    setUserEmails(emails)
  }

  return (
    <div style={{ maxWidth: 600 }}>
      <FormControl fullWidth variant="outlined" sx={{ margin: '10px', marginBottom: '5px' }}>
        <TextField
          id={t('product:channel.reference')}
          variant="outlined"
          label={t('product:channel.reference')}
          value={reference || ''}
          onChange={e => setReference(e.currentTarget.value)}
          autoComplete="text"
          aria-describedby="text-input"
          inputProps={{
            'aria-label': 'weight',
          }}
          sx={{ maxWidth: 600 }}
        />
      </FormControl>
      <FormControl fullWidth sx={{ margin: '10px', marginTop: '5px' }}>
        <InputLabel>{t('product')}</InputLabel>
        <Select
          sx={{
            maxWidth: 600,
          }}
          value={product}
          label={t('product')}
          onChange={(e: SelectChangeEvent) => setProduct(e.target.value as string)}
        >
          {products.map(product => (
            <MenuItem value={product.name} key={product.id}>
              {product.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {userGroups &&
        userGroups.includes('AdminS3') &&
        user.attributes.email === 'chat@assemblean.com' && (
          <FormControl fullWidth sx={{ margin: '10px', marginTop: '5px' }}>
            <InputLabel>{t('sendTo')}</InputLabel>
            <Select
              sx={{
                maxWidth: 600,
              }}
              value={addressTo}
              label={t('sendTo')}
              onChange={(e: SelectChangeEvent) => setAddressTo(e.target.value as string)}
            >
              {userEmails.map((email: string, index: number) => (
                <MenuItem value={email} key={index}>
                  {email}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      <Button
        onClick={e => handleSubmit(e)}
        variant="contained"
        sx={{ float: 'right', marginTop: '5px' }}
      >
        {t('product:channel.startConversation')}
      </Button>
    </div>
  )
}
