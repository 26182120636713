import { Stack, Box, Button } from '@mui/material'
import React, { useState } from 'react'
import { Message } from '../../../../graphql/graphql'
import { handlePromise } from '../../../../utils/functions'
import { deleteMessage, updateMessage } from '../../../../graphql/mutations'
import { API, graphqlOperation } from 'aws-amplify'
import DeleteModal from '../../../../components/widgets/DeleteModal'
import { useTranslation } from 'react-i18next'
import { useNotifications } from '../../../Feedback'
import { Delete } from '@mui/icons-material'
import Edit from '@mui/icons-material/Edit'
import DoneRoundedIcon from '@mui/icons-material/DoneRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import CopyAllRoundedIcon from '@mui/icons-material/CopyAllRounded'
import useMobileService from '../../../../hooks/useMobileService'

type Props = {
  message: Message
  setEdit: (edit: boolean) => void
  edit: boolean
  setEditValue: (value: string) => void
  editValue: string
  setShowMessageActions: (show: string) => void
  sx?: React.CSSProperties
}

/**
 * Actions for messages where messages can be deleted/edited
 */
export default function MessageActions(props: Props) {
  const { message, setEdit, edit, setEditValue, editValue, setShowMessageActions, sx } = props
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const { t } = useTranslation()
  const { addNotification } = useNotifications()
  const isMobile = useMobileService()

  async function onDelete() {
    const [res, err] = await handlePromise(
      'deleteMessage',
      API.graphql(graphqlOperation(deleteMessage, { input: { id: message.id } }))
    )
    res
      ? addNotification({ message: t('success.changesSavedSuccessfully'), color: 'success' })
      : addNotification({ message: t('error.errorOccurred'), color: 'error' })
  }

  async function onEdit() {
    setEditValue(message.body)
    if (edit) {
      const [res, err] = await handlePromise(
        'editMessage',
        API.graphql(graphqlOperation(updateMessage, { input: { id: message.id, body: editValue } }))
      )
      res
        ? addNotification({ message: t('success.changesSavedSuccessfully'), color: 'success' })
        : addNotification({ message: t('error.errorOccurred'), color: 'error' })
    }
    setEdit(!edit)
  }

  async function handleMessageCopy() {
    navigator.clipboard
      .writeText(message.body)
      .then(() => addNotification({ message: t('success.copied'), color: 'success' }))
      .catch(err => addNotification({ message: t('error.errorOccurred'), color: 'error' }))
  }

  return (
    <Box sx={{ ...sx, width: 'max-content', zIndex: 5 }}>
      <Stack spacing={1} direction="row" alignItems="center" sx={{ marginBottom: '8px' }}>
        {isMobile && !edit && (
          <Button
            variant="contained"
            size="small"
            onClick={() => setShowMessageActions('')}
            sx={{
              borderRadius: '2em',
              height: '1.7em',
              textTransform: 'none',
              minWidth: 'max-content',
            }}
          >
            <CloseRoundedIcon fontSize="small" />
          </Button>
        )}
        {isMobile && !edit && (
          <Button
            variant="contained"
            size="small"
            onClick={() => handleMessageCopy()}
            sx={{ borderRadius: '2em', height: '1.7em', textTransform: 'none' }}
          >
            <CopyAllRoundedIcon fontSize="small" />
          </Button>
        )}
        <Button
          variant="contained"
          size="small"
          disabled={
            edit && (editValue.length === 0 || editValue.trim().length === 0)
          } /* check if string is empty or only contains spaces*/
          onClick={() => onEdit()}
          sx={{ borderRadius: '2em', height: '1.7em', textTransform: 'none' }}
        >
          {edit ? <DoneRoundedIcon fontSize="small" /> : <Edit fontSize="small" />}
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={
            edit
              ? () => {
                  setEdit(false)
                  setEditValue(message.body)
                }
              : () => setOpenDeleteModal(!openDeleteModal)
          }
          sx={{ borderRadius: '2em', height: '1.7em', textTransform: 'none' }}
        >
          {edit ? <CloseRoundedIcon fontSize="small" /> : <Delete fontSize="small" />}
        </Button>
      </Stack>
      <DeleteModal
        name={t('theMessage')}
        open={openDeleteModal}
        setOpen={() => setOpenDeleteModal(!openDeleteModal)}
        onDelete={() => {
          onDelete()
          setOpenDeleteModal(!openDeleteModal)
        }}
      />
    </Box>
  )
}
