import {
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Modal,
  Typography,
} from '@mui/material'
import React from 'react'
import useMobileService from '../../hooks/useMobileService'
import { useTranslation } from 'react-i18next'

interface Props {
  open: boolean
  setOpen: (open: boolean) => void
  title: string
  description?: string
  list: string[]
  onClose?: () => void
  onCancel?: () => void
  onConfirm?: () => void
}

export default function ListModal(props: Props) {
  const {
    open,
    setOpen,
    title,
    description,
    list,
    onClose = () => null,
    onCancel = () => null,
    onConfirm = () => null,
  } = props
  const { t } = useTranslation()
  const isMobile = useMobileService()
  let size
  isMobile ? (size = { height: '40%', width: '95%' }) : (size = { height: '50%', width: '40%' })

  const handleCancel = () => {
    setOpen(false)
    onCancel()
  }

  const handleConfirm = () => {
    setOpen(false)
    onConfirm()
  }

  const handleClose = () => {
    setOpen(false)
    onClose()
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Card
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          height: size.height,
          width: size.width,
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            width: '100%',
            paddingBottom: '16px',
          }}
        >
          <div>
            <Typography variant="h6" sx={{ marginBottom: '16px' }}>
              {title}
            </Typography>
            <div>
              <span>{description && description}</span>
            </div>
          </div>
          <div aria-label="accessFileModal-ListOfContent" style={{ overflow: 'auto' }}>
            <List>
              {list.map((item, index) => {
                return (
                  <ListItem key={index}>
                    <ListItemText primary={'- ' + item} />
                  </ListItem>
                )
              })}
            </List>
          </div>
          <div
            aria-label="textfieldModal-actions"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Button onClick={handleCancel}>{t('cancel')}</Button>
            <Button onClick={handleConfirm}>{t('confirm')}</Button>
          </div>
        </CardContent>
      </Card>
    </Modal>
  )
}
