import React, { useEffect, useState } from 'react'
import Page from '../components/layout/Page'
import { handlePromise, sendInfoEmail } from '../utils/functions'
import { API, graphqlOperation } from 'aws-amplify'
import { listUnauthRequests } from '../graphql/queries'
import { UnauthRequest, UnauthRequestStatus } from '../graphql/graphql'
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LoadingSkeleton from '../components/widgets/LoadingSkeleton'
import useMobileService from '../hooks/useMobileService'
import MobileListItem from '../components/widgets/MobileListItem'
import { Check, Close, Delete } from '@mui/icons-material'
import DeleteModal from '../components/widgets/DeleteModal'
import { deleteUnauthRequest, updateUnauthRequest } from '../graphql/mutations'
import { useNotifications } from '../features/Feedback'
import AreYouSureModal from '../components/widgets/AreYouSureModal'

enum ModalType {
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
}

export default function RequestOverviewAdminPage() {
  const [unauthRequests, setUnauthRequests] = useState<UnauthRequest[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [requestToDelete, setRequestToDelete] = useState<UnauthRequest>()
  const [selectedRequest, setSelectedRequest] = useState<UnauthRequest>()
  const [openAreYouSureModal, setOpenAreYouSureModal] = useState<boolean>(false)
  const [modalType, setModalType] = useState<ModalType>(ModalType.ACCEPT)
  const history = useHistory()
  const { t } = useTranslation()
  const isMobile = useMobileService()
  const { addNotification } = useNotifications()

  useEffect(() => {
    fetchUnauthRequests()
  }, [])

  async function fetchUnauthRequests(nextToken: string | null = null) {
    const variables = {
      limit: 100,
      nextToken: nextToken,
    }
    const [res, err] = await handlePromise(
      'listUnauthRequests',
      API.graphql(graphqlOperation(listUnauthRequests, variables))
    )
    if (!err) {
      const { items, nextToken } = res.data?.listUnauthRequests
      variables.nextToken = nextToken
      setUnauthRequests(prevRequests => [...prevRequests, ...items])
      if(nextToken) {
        fetchUnauthRequests(res.data.listUnauthRequests.nextToken)    
      } else {
        setLoading(false)
      } 
      setLoading(false)     
    } else {
      setLoading(false)
      setError(true)
    }
  }

  async function deleteRequest() {
    setOpenDeleteModal(false)
    const [res, err] = await handlePromise(
      'deleteUnauthRequest',
      API.graphql(
        graphqlOperation(deleteUnauthRequest, {
          input: {
            id: requestToDelete?.id,
          },
        })
      )
    )
    if (res) {
      addNotification({ message: t('requestDeleted'), color: 'success' })
      fetchUnauthRequests()
    } else {
      addNotification({ message: t('requestNotDeleted'), color: 'error' })
    }
  }

  async function acceptRequest() {
    setOpenAreYouSureModal(false)
    const [res, err] = await handlePromise(
      'updateUnauthRequest',
      API.graphql(
        graphqlOperation(updateUnauthRequest, {
          input: {
            id: selectedRequest?.id,
            status: UnauthRequestStatus.ACCEPTED,
          },
        })
      )
    )
    if (res) {
      addNotification({ message: t('requestAccepted'), color: 'success' })
      selectedRequest && selectedRequest.email
        ? sendInfoEmail(selectedRequest.email, 'UNAUTH_REQUEST_ACCEPTED', '')
        : null
      fetchUnauthRequests()
    } else {
      addNotification({ message: t('requestNotAccepted'), color: 'error' })
    }
  }

  async function rejectRequest() {
    setOpenAreYouSureModal(false)
    const [res, err] = await handlePromise(
      'updateUnauthRequest',
      API.graphql(
        graphqlOperation(updateUnauthRequest, {
          input: {
            id: selectedRequest?.id,
            status: UnauthRequestStatus.REJECTED,
          },
        })
      )
    )
    if (res) {
      addNotification({ message: t('requestRejected'), color: 'success' })
      selectedRequest && selectedRequest.email
        ? sendInfoEmail(selectedRequest.email, 'UNAUTH_REQUEST_REJECTED', '')
        : null
      fetchUnauthRequests()
    } else {
      addNotification({ message: t('requestNotRejected'), color: 'error' })
    }
  }

  if (loading) return <LoadingSkeleton />

  if (error)
    return (
      <Page>
        <h1>Error</h1>
      </Page>
    )

  const UnauthorizedRequests = (status: UnauthRequestStatus) =>
    unauthRequests
      .filter(request => request.status === status)
      .map((unauthRequest, index) => {
        return (
          <TableRow key={index} hover onClick={() => history.push('/requests/' + unauthRequest.id)}>
            <TableCell>{index + 1}</TableCell>
            <TableCell>{unauthRequest.email}</TableCell>
            <TableCell>{unauthRequest.name}</TableCell>
            <TableCell>{unauthRequest.company_name}</TableCell>
            <TableCell>{unauthRequest.phone_number}</TableCell>
            <TableCell>{new Date(unauthRequest.updatedAt).toLocaleDateString()}</TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <IconButton
                onClick={() => {
                  setOpenDeleteModal(true)
                  setRequestToDelete(unauthRequest)
                }}
              >
                <Delete />
              </IconButton>
            </TableCell>
            {status === UnauthRequestStatus.PENDING && (
              <TableCell onClick={e => e.stopPropagation()} align="center">
                <IconButton
                  onClick={() => {
                    setModalType(ModalType.REJECT)
                    setSelectedRequest(unauthRequest)
                    setOpenAreYouSureModal(true)
                  }}
                >
                  <Close />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setModalType(ModalType.ACCEPT)
                    setSelectedRequest(unauthRequest)
                    setOpenAreYouSureModal(true)
                  }}
                >
                  <Check />
                </IconButton>
              </TableCell>
            )}
          </TableRow>
        )
      })

  const mobileUnauthorizedRequests = (status: UnauthRequestStatus) =>
    unauthRequests
      .filter(request => request.status === status)
      .map((unauthRequest, index) => {
        return (
          <MobileListItem
            key={index}
            index={index}
            primaryText={unauthRequest.company_name}
            buttonWhenClosed={t('mobile.showDetails')}
            buttonWhenOpened={t('mobile.hideDetails')}
            onClick={() => history.push('/requests/' + unauthRequest.id)}
          >
            {`${t('name')}: ${unauthRequest.name}`}
            <br />
            {`${t('email')}: ${unauthRequest.email}`}
            <br />
            {`${t('phoneNumber')}: ${unauthRequest.phone_number}`}
            <br />
            {`${t('projectDescription')}: ${unauthRequest.project_description}`}
            <br />
            <Grid sx={{ marginTop: '10px' }} container justifyContent="space-between">
              <Grid item xs={8}>
                <IconButton
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    setOpenDeleteModal(true)
                    setRequestToDelete(unauthRequest)
                  }}
                >
                  <Delete />
                </IconButton>
              </Grid>
              {status === UnauthRequestStatus.PENDING && (
                <>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={e => {
                        e.stopPropagation()
                        e.preventDefault()
                        setModalType(ModalType.REJECT)
                        setSelectedRequest(unauthRequest)
                        setOpenAreYouSureModal(true)
                      }}
                    >
                      <Close />
                    </IconButton>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={e => {
                        e.stopPropagation()
                        e.preventDefault()
                        setModalType(ModalType.ACCEPT)
                        setSelectedRequest(unauthRequest)
                        setOpenAreYouSureModal(true)
                      }}
                    >
                      <Check />
                    </IconButton>
                  </Grid>
                </>
              )}
            </Grid>
          </MobileListItem>
        )
      })

  if (isMobile) {
    return (
      <Page marginBottom="200px">
        <div>
          <h2>{t('requests')}</h2>
          {mobileUnauthorizedRequests}
        </div>
        <AreYouSureModal
          name={selectedRequest?.company_name as string}
          type={modalType}
          open={openAreYouSureModal}
          setOpen={setOpenAreYouSureModal}
          onConfirm={() => {
            modalType === ModalType.ACCEPT ? acceptRequest() : rejectRequest()
          }}
        />
        <DeleteModal
          name={requestToDelete?.company_name as string}
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          onDelete={() => deleteRequest()}
        />
      </Page>
    )
  }

  return (
    <Page marginBottom="200px">
      <h2>{t('requests')}</h2>
      <Card sx={{width: "1100px", margin: "auto"}}>
        <CardContent>
          {unauthRequests.length === 0 && <Typography>{t('noRequests')}</Typography>}
          {unauthRequests.filter(request => request.status === UnauthRequestStatus.PENDING).length >
            0 && (
            <>
              <Typography variant="h6">{t('pendingRequests')}</Typography>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>{t('email')}</TableCell>
                    <TableCell>{t('name')}</TableCell>
                    <TableCell>{t('company')}</TableCell>
                    <TableCell>{t('phoneNumber')}</TableCell>
                    <TableCell>{t('createdAt')}</TableCell>
                    <TableCell>{t('delete')}</TableCell>
                    <TableCell>{`${t('reject')} / ${t('accept')}`}</TableCell>
                  </TableRow>
                </TableHead>
                {UnauthorizedRequests(UnauthRequestStatus.PENDING)}
              </Table>
            </>
          )}
          {unauthRequests.filter(request => request.status === UnauthRequestStatus.ACCEPTED)
            .length > 0 && (
            <>
              <Typography variant="h6">{t('acceptedRequests')}</Typography>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>{t('email')}</TableCell>
                    <TableCell>{t('name')}</TableCell>
                    <TableCell>{t('company')}</TableCell>
                    <TableCell>{t('phoneNumber')}</TableCell>
                    <TableCell>{t('createdAt')}</TableCell>
                    <TableCell>{t('delete')}</TableCell>
                  </TableRow>
                </TableHead>
                {UnauthorizedRequests(UnauthRequestStatus.ACCEPTED)}
              </Table>
            </>
          )}
          {unauthRequests.filter(request => request.status === UnauthRequestStatus.REJECTED)
            .length > 0 && (
            <>
              <Typography variant="h6">{t('rejectedRequests')}</Typography>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>{t('email')}</TableCell>
                    <TableCell>{t('name')}</TableCell>
                    <TableCell>{t('company')}</TableCell>
                    <TableCell>{t('phoneNumber')}</TableCell>
                    <TableCell>{t('createdAt')}</TableCell>
                    <TableCell>{t('delete')}</TableCell>
                  </TableRow>
                </TableHead>
                {UnauthorizedRequests(UnauthRequestStatus.REJECTED)}
              </Table>
            </>
          )}
        </CardContent>
      </Card>
      <AreYouSureModal
        name={selectedRequest?.company_name as string}
        type={modalType}
        open={openAreYouSureModal}
        setOpen={setOpenAreYouSureModal}
        onConfirm={() => {
          modalType === ModalType.ACCEPT ? acceptRequest() : rejectRequest()
        }}
      />
      <DeleteModal
        name={requestToDelete?.company_name as string}
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        onDelete={() => deleteRequest()}
      />
    </Page>
  )
}
