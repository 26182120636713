import React, { useEffect, useState } from 'react'
import { BOM, Component } from '../../../../graphql/graphql'
import { feedbackForGraphQL, handlePromise } from '../../../../utils/functions'
import { API, graphqlOperation } from 'aws-amplify'
import { componentByProduct, listBOMS } from '../../../../graphql/queries'
import DynamicForm from './DynamicForm'
import {
  createBOM,
  createComponent,
  deleteBOM,
  deleteComponent,
  updateBOM,
} from '../../../../graphql/mutations'
import { customUpdateComponent } from '../../../../graphql/custom-mutations'
import { useNotifications } from '../../../Feedback'
import { useTranslation } from 'react-i18next'
import { customComponentByProduct } from '../../../../graphql/custom-queries'
import ComponentTree from './ComponentTree'

interface updatedBOM {
  id: string
  quantity: number
}

type Props = {
  productIdInOverview?: string
}

/**
 * Logic needed to handle create, delete and update on a component or components
 */
export default function ComponentLogic(props: Props) {
  const productID = props.productIdInOverview
    ? props.productIdInOverview
    : location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
  // ComponentStates
  const [components, setComponents] = useState<Component[]>([])
  const [component, setComponent] = useState<Component>()
  const [updatedComponent, setUpdatedComponent] = useState<Component>()
  // parent component corresponding to the positon in component tree
  const [parentComponent, setParentComponent] = useState<Component | undefined>()
  // child component corresponding to the positon in component tree
  const [childComponent, setChildComponent] = useState<Component | undefined>()
  // BOM that defines children with quantity of parent component
  const [parentBOM, setParentBOM] = useState<updatedBOM>()
  // quantity of how often a child component is needed to create its parent component
  const [quantity, setQuantity] = useState<number>(1)
  // additional comment at the end of creating a component
  const [comment, setComment] = useState<string>('')
  // Other states
  const [open, setOpen] = useState<boolean>(false)
  const [isNew, setNew] = useState<boolean>(true)
  const [refetchChildren, setRefetchChildren] = useState<boolean>(false)
  const [activeStep, setActiveStep] = useState(0)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  // hooks
  const { addNotification } = useNotifications()
  const { t } = useTranslation()

  useEffect(() => {
    fetchComponents()
  }, [])

  useEffect(() => {
    component?.comment ? setComment(component.comment) : null
  }, [component])

  useEffect(() => {
    childComponent && parentComponent
      ? createComponentBOM(childComponent.id, parentComponent.id, quantity)
      : null
    setRefetchChildren(!refetchChildren)
  }, [childComponent])

  useEffect(() => {
    if (updatedComponent && parentBOM) {
      updateComponentBOM(parentBOM.id, quantity)
      setRefetchChildren(!refetchChildren)
    }
  }, [updatedComponent])

  const filter = {
    root: {
      eq: `${true}`,
    },
  }

  // ComponentLogic including Queries and Mutations
  async function fetchComponents() {
    const [data, error] = await handlePromise(
      'componentByProduct',
      API.graphql(
        graphqlOperation(customComponentByProduct, { productID: productID, filter: filter })
      )
    )
    error
      ? console.log('Error on fetching components')
      : setComponents(data.data.componentByProduct.items)
  }

  const onEditCancel = () => {
    setOpen(false)
  }

  async function onSave() {
    const {
      createdAt,
      owner,
      updatedAt,
      Product,
      Materials,
      ManufacturingProcess,
      Parts,
      ...componentInput
    } = component as Component
    const [res, error] = await handlePromise(
      'updateComponent',
      API.graphql(
        graphqlOperation(customUpdateComponent, {
          input: { ...componentInput, productID: productID, comment: comment },
        })
      )
    )
    fetchComponents()
    setUpdatedComponent(res.data.updateComponent)
    feedbackForGraphQL(res, addNotification, t)
    onEditCancel()
  }

  async function onCreate() {
    const { Parts, createdAt, updatedAt, ManufacturingProcess, ...componentInput } =
      component as Component
    const [res, error] = await handlePromise(
      'createComponent',
      API.graphql(
        graphqlOperation(createComponent, {
          input: {
            ...componentInput,
            root: !parentComponent,
            productID: productID,
            comment: comment,
          },
        })
      )
    )
    if (res) {
      res.data.createComponent.root === true
        ? setComponents([...components, res.data.createComponent])
        : null
      parentComponent ? setChildComponent(res.data.createComponent) : null
    } else {
      console.log('Error on creating Component')
    }

    feedbackForGraphQL(res, addNotification, t)
    onEditCancel()
  }

  async function createComponentBOM(childID: string, parentID: string, quantity: number) {
    const [res, err] = await handlePromise(
      'createBOM',
      API.graphql(
        graphqlOperation(createBOM, {
          input: { childID: childID, parentID: parentID, quantity: quantity },
        })
      )
    )
    res ? console.log(res.data.createBOM) : console.log('Error on creating BOM ', err)
  }

  async function updateComponentBOM(id: string, quantity: number) {
    const [res, err] = await handlePromise(
      'updateBOM',
      API.graphql(graphqlOperation(updateBOM, { input: { id: id, quantity: quantity } }))
    )
    res
      ? console.log('Updated BOM: ', res.data.updateBOM)
      : console.log('Error on updating BOM ', err)
  }

  async function deleteComponentBOM(id: string) {
    const [res, err] = await handlePromise(
      'deleteBOM',
      API.graphql(graphqlOperation(deleteBOM, { input: { id: id } }))
    )
    return !err
  }

  async function deleteComponentBOMs(componentId: string) {
    const childBOMs = await API.graphql(
      graphqlOperation(listBOMS, { filter: { parentID: { eq: componentId } } })
    )
    const parentBOMs = await API.graphql(
      graphqlOperation(listBOMS, { filter: { childID: { eq: componentId } } })
    )
    const BOMs = childBOMs.data.listBOMS.items.concat(parentBOMs.data.listBOMS.items)
    Promise.all(
      BOMs.map((value: BOM) => {
        deleteComponentBOM(value.id)
        deleteOneComponent(value.childID)
      })
    )
      .then(value => {
        value.includes(false)
          ? addNotification({ message: t('error.errorOccurred'), color: 'error' })
          : addNotification({ message: t('success.changesSavedSuccessfully'), color: 'success' })
      })
      .catch(err => {
        addNotification({ message: t('error.errorOccurred'), color: 'error' })
        console.log('Error on deleting components', err)
      })
  }

  async function deleteOneComponent(id: string) {
    deleteComponentBOMs(id)
    const [res, err] = await handlePromise(
      'deleteComponent',
      API.graphql(graphqlOperation(deleteComponent, { input: { id: id } }))
    )
    return !err
  }

  async function onDelete(componentList: any[]) {
    Promise.all(componentList.map(comp => deleteOneComponent(comp.id)))
      .then(value => {
        value.includes(false)
          ? addNotification({ message: t('error.errorOccurred'), color: 'error' })
          : addNotification({ message: t('success.changesSavedSuccessfully'), color: 'success' })
        setTimeout(fetchComponents, 100)
        setRefetchChildren(!refetchChildren)
      })
      .catch(err => {
        addNotification({ message: t('error.errorOccurred'), color: 'error' })
        console.log('Error on deleting components', err)
      })
  }

  return (
    <div>
      <ComponentTree
        updatedComponent={updatedComponent}
        rootComponents={components}
        setOpen={setOpen}
        setComponent={setComponent}
        setNew={setNew}
        onDelete={onDelete}
        setActiveStep={setActiveStep}
        setParentComponent={setParentComponent}
        refetchChildren={refetchChildren}
        setParentBOM={setParentBOM}
        setQuantity={setQuantity}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
      <DynamicForm
        component={component as Component}
        setComponent={setComponent}
        open={open}
        setOpen={setOpen}
        isNew={isNew}
        onCreate={onCreate}
        onSave={onSave}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        isChildComponent={!!parentComponent || component?.root === false}
        quantity={quantity}
        setQuantity={setQuantity}
        comment={comment}
        setComment={setComment}
      />
    </div>
  )
}
