import { Alert, Box, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material'
import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import React, {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { createUnauthRequest, sendEmail } from '../../graphql/mutations'
import { useHistory } from 'react-router-dom'
import UnauthS3FileUpload from '../inputs/UnauthS3FileUpload'
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3'
import useMobileService from '../../hooks/useMobileService'

type File = {
  name: string
  size: number
  path: string
  type: string
  lastModified: number
  webkitRelativePath: string
}

const s3 = new S3Client({
  region: process.env.REACT_APP_AWS_S3_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID as string,
    secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY as string,
  },
})

export default function RequestForm() {
  const { t } = useTranslation(['common'])
  const history = useHistory()
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  const isMobile = useMobileService()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<boolean>(false)
  const [fileList, setFileList] = useState<File[]>([])
  const [success, setSuccess] = useState<boolean>(false)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company_name: '',
    address: '',
    zip_code: '',
    city: '',
    country: '',
    phone_number: '',
    project_description: '',
  })

  useEffect(() => {
    success ? setLoading(false) : null
  }, [success])

  const handleChange = (event: any) => {
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }

  const isDataValid = () => {
    return (
      formData.name !== '' &&
      formData.email !== '' &&
      emailRegex.test(formData.email) &&
      formData.company_name !== '' &&
      formData.address !== '' &&
      formData.zip_code !== '' &&
      formData.city !== '' &&
      formData.country !== '' &&
      formData.phone_number !== '' &&
      formData.project_description !== ''
    )
  }

  async function createRequest() {
    const createdUnauthRequest = await API.graphql({
      query: createUnauthRequest,
      variables: {
        input: {
          name: formData.name,
          email: formData.email,
          company_name: formData.company_name,
          address: formData.address,
          zip_code: formData.zip_code,
          city: formData.city,
          country: formData.country,
          phone_number: formData.phone_number,
          project_description: formData.project_description,
          status: 'PENDING',
        },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    })
    if (createdUnauthRequest.data.createUnauthRequest) {
      setError(false)
    } else {
      setError(true)
    }
  }

  /**
   * Handles uploading one or multiple files
   * @params files list of files to upload
   */
  function uploadFiles(files: File[]) {
    const results: boolean[] = []
    Promise.all(
      files.map(async (file: File, index) => {
        const success: boolean = await handleUpload(file)
        results.push(success)
      })
    )
    return !results.includes(false)
  }

  // upload file to s3 bucket
  async function handleUpload(file: any) {
    const command = new PutObjectCommand({
      Bucket: process.env.REACT_APP_AWS_S3_PUBLIC_BUCKET_NAME as string,
      Key: formData.email + '/' + file.name,
      Body: file,
    })
    try {
      const data = await s3.send(command)
      setError(false)
      return true
    } catch (err) {
      console.log('Error', err)
      setError(true)
      return false
    }
  }

  async function sendRequestEmail() {
    try {
      const result = await API.graphql({
        query: sendEmail,
        variables: {
            to: 'Admin',
            subject: 'NEW_UNAUTH_REQUEST',
            body: formData.company_name,
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      })
      if (result.data.sendEmail) {
          console.log('Email sent')
      } else {
          console.log('Email not sent')
      }
    } catch (err) {
      console.log('Error', err)
    }
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    setLoading(true)
    if (isDataValid()) {
      try {
        await createRequest()
        await sendRequestEmail()
        if (fileList.length > 0) {
          !error ? uploadFiles(fileList) : setError(true)
        }
        !error ? setSuccess(true) : setError(true)
      } catch (err) {
        console.log('Error', err)
        setError(true)
      }
    }
    setLoading(false)
  }

  console.log("Error: ", error)
  console.log("Loading: ", loading)
  console.log("Success: ", success)

  const requestForm = () => {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
        <Grid item xs={12} sm={8} md={8} lg={8} xl={6}>
          <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
            <Typography variant="h4" align="center">
              <b>assemb</b>lean
            </Typography>
            <Typography variant="h5" align="center">
              {t('auth.startYourRequestHere')} <br />
              {t('auth.weAreLookingForwardToYourProject')}
            </Typography>
            <div style={{margin: "20px"}} />            
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label={t('name')}
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label={t('email')}
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={t('company')}
                    name="company_name"
                    value={formData.company_name}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={t('address')}
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    type="number"
                    label={t('zipCode')}
                    name="zip_code"
                    value={formData.zip_code}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label={t('city')}
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={t('country')}
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={t('phoneNumber')}
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={t('projectDescription')}
                    name="project_description"
                    multiline
                    rows={4}
                    value={formData.project_description}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                {/*<Grid item xs={12}>
                          <UnauthCADFileUpload />
                         </Grid>*/}
                <Grid item xs={12}>
                  <UnauthS3FileUpload fileList={fileList} setFileList={setFileList} />
                </Grid>
                {error && (
                  <Grid item xs={12}>
                    <Alert severity="error">{t('error.errorOccurred')}</Alert>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Button fullWidth variant="contained" color="primary" type="submit">
                    {t('auth.sendRequest')}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    sx={{ marginBottom: '1rem' }}
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={() => history.push('/signIn')}
                  >
                    {t('auth.goToSignIn')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Grid>
      </Grid>
    )
  }

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <div>
      {!error && success ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <Grid maxWidth={900} container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" align="center">
                <b>assemb</b>lean
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" align="center">
                {t('auth.requestSent')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={() => history.push('/signIn')}
              >
                {t('auth.goToSignIn')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      ) : (
        requestForm()
      )}
    </div>
  )
}
