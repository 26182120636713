import React from 'react'
import { useState } from 'react'
import { Auth } from 'aws-amplify'
import Page from '../../components/layout/Page'
import { Box } from '@mui/system'
import { Button, Grid, TextField, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default function PasswordResetPage() {
  const [email, setEmail] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [newCodeSent, setNewCodeSent] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [resetSuccess, setResetSuccess] = useState(false)
  const history = useHistory()
  const { t } = useTranslation(['common'])

  async function handlePasswordReset(event: any) {
    event.preventDefault()
    try {
      await Auth.forgotPasswordSubmit(email, verificationCode, newPassword)
      setResetSuccess(true)
    } catch (error: any) {
      console.log(error.message)
      setResetSuccess(false)
    }
  }

  const isValidEmail = (email: string) => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    return email && emailRegex.test(email)
  }

  async function sendCode(event: any) {
    event.preventDefault()
    try {
      await Auth.forgotPassword(email)
      setNewCodeSent(true)
    } catch (error: any) {
      setNewCodeSent(true)
    }
  }

  return (
    <Page>
      {resetSuccess ? (
        <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
          <Typography variant="h6">{t('auth.passwordResetSuccessful')}</Typography>
          <Typography variant="body1">{t('auth.signInWithNewPassword')}</Typography>
          <Button
            variant="contained"
            onKeyDown={e => e.key === 'Enter' && resetSuccess && history.push('/signIn')}
            onClick={() => history.push('/signIn')}
          >
            {t('auth.signIn')}
          </Button>
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          alignContent="center"
          justifyContent="center"
          mt={4}
          height="100vh"
        >
          <Grid
            container
            maxWidth={600}
            display="flex"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} display="flex" justifyContent="center">
              <Typography display="block" variant="h4">
                <b>assemb</b>lean
              </Typography>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Typography display="block" variant="h5">
                {t('auth.resetPassword')}
              </Typography>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Typography display="block" variant="body1">
                {t('auth.enterEmailAndWeWillSendYouAVerificationCode')}
              </Typography>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <TextField
                id="email"
                label="Email address"
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                margin="normal"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            {!newCodeSent && (
              <Grid item xs={12}>
                <Button
                  onKeyDown={e => e.key === 'Enter' && sendCode}
                  onClick={sendCode}
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={!isValidEmail(email)}
                >
                  {t('auth.sendVerificationCode')}
                </Button>
              </Grid>
            )}
            {!newCodeSent && (
              <Grid item xs={12}>
                <Button
                  onClick={() => history.push('/signIn')}
                  variant="contained"
                  color="secondary"
                  fullWidth
                >
                  {t('auth.backToSignIn')}
                </Button>
              </Grid>
            )}
            {newCodeSent && (
              <Grid item xs={12} display="flex" justifyContent="center">
                <TextField
                  id="verificationCode"
                  label="Verification code"
                  type="text"
                  value={verificationCode}
                  onChange={e => setVerificationCode(e.target.value)}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            )}
            {newCodeSent && (
              <Grid item xs={12} display="flex" justifyContent="center">
                <TextField
                  id="newPassword"
                  label="New password"
                  type="password"
                  value={newPassword}
                  onChange={e => setNewPassword(e.target.value)}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            )}
            {newCodeSent && (
              <Grid item xs={12} display="flex" justifyContent="center">
                <Button
                  onKeyDown={e => e.key === 'Enter' && handlePasswordReset}
                  onClick={handlePasswordReset}
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  {t('auth.resetPassword')}
                </Button>
              </Grid>
            )}
            {newCodeSent && (
              <Grid item xs={12} display="flex" justifyContent="center">
                <Button
                  sx={{ mt: 1 }}
                  onClick={sendCode}
                  variant="contained"
                  color="secondary"
                  fullWidth
                >
                  {t('auth.resendVerificationCode')}
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </Page>
  )
}
