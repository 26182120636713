import { Button, Card, CardContent, Modal, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useMobileService from '../../../../hooks/useMobileService'

type Props = {
  open: boolean
  onClose: () => void
  onCreate: () => void
  onCopy: () => void
}

export default function ProductOptionsModal(props: Props) {
  const { open, onClose, onCreate, onCopy } = props
  const { t } = useTranslation()
  const isMobile = useMobileService()

  const handleCreateProduct = () => {
    onClose()
    onCreate()
  }

  const handleCopyProduct = () => {
    onClose()
    onCopy()
  }

  const handleCancel = () => {
    onClose()
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Card
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 'max-content',
          height: 'max-content',
          maxWidth: isMobile ? '95%' : '50%',
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            paddingBottom: '16px',
          }}
        >
          <Typography variant="h6" sx={{ float: 'left', marginBottom: '1em' }}>
            {t('product:overview.productOptions')}
          </Typography>
          <div style={{ marginTop: '1em', display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="outlined" onClick={handleCancel} sx={{ float: 'left' }}>
              {t('cancel')}
            </Button>
            <div style={{ marginLeft: '40px' }}>
              <Button variant="contained" onClick={handleCopyProduct} sx={{ float: 'left' }}>
                {t('copy')}
              </Button>
              <Button
                variant="contained"
                onClick={handleCreateProduct}
                sx={{ float: 'right', marginLeft: '10px' }}
              >
                {t('create')}
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </Modal>
  )
}
