import React from 'react'
import Page from '../layout/Page'
import { Card, CardContent } from '@mui/material'
import { Skeleton } from '@mui/material'

export default function LoadingSkeleton() {
  return (
    <Page>
      <h1>
        <Skeleton />
      </h1>
      <Card>
        <CardContent>
          <Skeleton height={500} width={900} />
        </CardContent>
      </Card>
    </Page>
  )
}
