/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateManufacturingRequirement = /* GraphQL */ `
  subscription OnCreateManufacturingRequirement(
    $filter: ModelSubscriptionManufacturingRequirementFilterInput
  ) {
    onCreateManufacturingRequirement(filter: $filter) {
      id
      type
      label
      value
      description
      options
      manufacturingProcessID
      ManufacturingProcess {
        id
        category
        process
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateManufacturingRequirement = /* GraphQL */ `
  subscription OnUpdateManufacturingRequirement(
    $filter: ModelSubscriptionManufacturingRequirementFilterInput
  ) {
    onUpdateManufacturingRequirement(filter: $filter) {
      id
      type
      label
      value
      description
      options
      manufacturingProcessID
      ManufacturingProcess {
        id
        category
        process
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteManufacturingRequirement = /* GraphQL */ `
  subscription OnDeleteManufacturingRequirement(
    $filter: ModelSubscriptionManufacturingRequirementFilterInput
  ) {
    onDeleteManufacturingRequirement(filter: $filter) {
      id
      type
      label
      value
      description
      options
      manufacturingProcessID
      ManufacturingProcess {
        id
        category
        process
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateManufacturingProcess = /* GraphQL */ `
  subscription OnCreateManufacturingProcess(
    $filter: ModelSubscriptionManufacturingProcessFilterInput
  ) {
    onCreateManufacturingProcess(filter: $filter) {
      id
      category
      process
      Requirements {
        nextToken
      }
      Components {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateManufacturingProcess = /* GraphQL */ `
  subscription OnUpdateManufacturingProcess(
    $filter: ModelSubscriptionManufacturingProcessFilterInput
  ) {
    onUpdateManufacturingProcess(filter: $filter) {
      id
      category
      process
      Requirements {
        nextToken
      }
      Components {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteManufacturingProcess = /* GraphQL */ `
  subscription OnDeleteManufacturingProcess(
    $filter: ModelSubscriptionManufacturingProcessFilterInput
  ) {
    onDeleteManufacturingProcess(filter: $filter) {
      id
      category
      process
      Requirements {
        nextToken
      }
      Components {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateContractStatus = /* GraphQL */ `
  subscription OnCreateContractStatus(
    $filter: ModelSubscriptionContractStatusFilterInput
    $owner: String
  ) {
    onCreateContractStatus(filter: $filter, owner: $owner) {
      id
      date
      createdAt
      contractID
      Contract {
        id
        billingAddressID
        certificates
        comment_customer
        comment_internal
        currency
        deadline
        description
        price
        priority
        quantity
        read_only
        remarks
        shippingAddressID
        version
        wish_date
        productID
        createdAt
        updatedAt
        owner
      }
      Status
      owner
      updatedAt
    }
  }
`;
export const onUpdateContractStatus = /* GraphQL */ `
  subscription OnUpdateContractStatus(
    $filter: ModelSubscriptionContractStatusFilterInput
    $owner: String
  ) {
    onUpdateContractStatus(filter: $filter, owner: $owner) {
      id
      date
      createdAt
      contractID
      Contract {
        id
        billingAddressID
        certificates
        comment_customer
        comment_internal
        currency
        deadline
        description
        price
        priority
        quantity
        read_only
        remarks
        shippingAddressID
        version
        wish_date
        productID
        createdAt
        updatedAt
        owner
      }
      Status
      owner
      updatedAt
    }
  }
`;
export const onDeleteContractStatus = /* GraphQL */ `
  subscription OnDeleteContractStatus(
    $filter: ModelSubscriptionContractStatusFilterInput
    $owner: String
  ) {
    onDeleteContractStatus(filter: $filter, owner: $owner) {
      id
      date
      createdAt
      contractID
      Contract {
        id
        billingAddressID
        certificates
        comment_customer
        comment_internal
        currency
        deadline
        description
        price
        priority
        quantity
        read_only
        remarks
        shippingAddressID
        version
        wish_date
        productID
        createdAt
        updatedAt
        owner
      }
      Status
      owner
      updatedAt
    }
  }
`;
export const onCreateContractRequest = /* GraphQL */ `
  subscription OnCreateContractRequest(
    $filter: ModelSubscriptionContractRequestFilterInput
    $owner: String
  ) {
    onCreateContractRequest(filter: $filter, owner: $owner) {
      id
      date
      createdAt
      contractID
      Contract {
        id
        billingAddressID
        certificates
        comment_customer
        comment_internal
        currency
        deadline
        description
        price
        priority
        quantity
        read_only
        remarks
        shippingAddressID
        version
        wish_date
        productID
        createdAt
        updatedAt
        owner
      }
      accepted
      updatedAt
      owner
    }
  }
`;
export const onUpdateContractRequest = /* GraphQL */ `
  subscription OnUpdateContractRequest(
    $filter: ModelSubscriptionContractRequestFilterInput
    $owner: String
  ) {
    onUpdateContractRequest(filter: $filter, owner: $owner) {
      id
      date
      createdAt
      contractID
      Contract {
        id
        billingAddressID
        certificates
        comment_customer
        comment_internal
        currency
        deadline
        description
        price
        priority
        quantity
        read_only
        remarks
        shippingAddressID
        version
        wish_date
        productID
        createdAt
        updatedAt
        owner
      }
      accepted
      updatedAt
      owner
    }
  }
`;
export const onDeleteContractRequest = /* GraphQL */ `
  subscription OnDeleteContractRequest(
    $filter: ModelSubscriptionContractRequestFilterInput
    $owner: String
  ) {
    onDeleteContractRequest(filter: $filter, owner: $owner) {
      id
      date
      createdAt
      contractID
      Contract {
        id
        billingAddressID
        certificates
        comment_customer
        comment_internal
        currency
        deadline
        description
        price
        priority
        quantity
        read_only
        remarks
        shippingAddressID
        version
        wish_date
        productID
        createdAt
        updatedAt
        owner
      }
      accepted
      updatedAt
      owner
    }
  }
`;
export const onCreateContract = /* GraphQL */ `
  subscription OnCreateContract(
    $filter: ModelSubscriptionContractFilterInput
    $owner: String
  ) {
    onCreateContract(filter: $filter, owner: $owner) {
      id
      billingAddressID
      certificates
      comment_customer
      comment_internal
      currency
      deadline
      description
      price
      priority
      quantity
      read_only
      remarks
      shippingAddressID
      version
      wish_date
      productID
      Product {
        id
        name
        description
        article_number
        barcode_type
        barcode_content
        comments
        extra_data
        owner
        createdAt
        updatedAt
      }
      ShippingAddress {
        id
        name
        address_1
        address_2
        zip_code
        city
        country
        type
        createdAt
        updatedAt
        owner
      }
      BillingAddress {
        id
        name
        address_1
        address_2
        zip_code
        city
        country
        type
        createdAt
        updatedAt
        owner
      }
      Status {
        nextToken
      }
      Request {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateContract = /* GraphQL */ `
  subscription OnUpdateContract(
    $filter: ModelSubscriptionContractFilterInput
    $owner: String
  ) {
    onUpdateContract(filter: $filter, owner: $owner) {
      id
      billingAddressID
      certificates
      comment_customer
      comment_internal
      currency
      deadline
      description
      price
      priority
      quantity
      read_only
      remarks
      shippingAddressID
      version
      wish_date
      productID
      Product {
        id
        name
        description
        article_number
        barcode_type
        barcode_content
        comments
        extra_data
        owner
        createdAt
        updatedAt
      }
      ShippingAddress {
        id
        name
        address_1
        address_2
        zip_code
        city
        country
        type
        createdAt
        updatedAt
        owner
      }
      BillingAddress {
        id
        name
        address_1
        address_2
        zip_code
        city
        country
        type
        createdAt
        updatedAt
        owner
      }
      Status {
        nextToken
      }
      Request {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteContract = /* GraphQL */ `
  subscription OnDeleteContract(
    $filter: ModelSubscriptionContractFilterInput
    $owner: String
  ) {
    onDeleteContract(filter: $filter, owner: $owner) {
      id
      billingAddressID
      certificates
      comment_customer
      comment_internal
      currency
      deadline
      description
      price
      priority
      quantity
      read_only
      remarks
      shippingAddressID
      version
      wish_date
      productID
      Product {
        id
        name
        description
        article_number
        barcode_type
        barcode_content
        comments
        extra_data
        owner
        createdAt
        updatedAt
      }
      ShippingAddress {
        id
        name
        address_1
        address_2
        zip_code
        city
        country
        type
        createdAt
        updatedAt
        owner
      }
      BillingAddress {
        id
        name
        address_1
        address_2
        zip_code
        city
        country
        type
        createdAt
        updatedAt
        owner
      }
      Status {
        nextToken
      }
      Request {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateProduct = /* GraphQL */ `
  subscription OnCreateProduct(
    $filter: ModelSubscriptionProductFilterInput
    $owner: String
  ) {
    onCreateProduct(filter: $filter, owner: $owner) {
      id
      name
      description
      article_number
      barcode_type
      barcode_content
      comments
      extra_data
      owner
      Components {
        nextToken
      }
      Contracts {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProduct = /* GraphQL */ `
  subscription OnUpdateProduct(
    $filter: ModelSubscriptionProductFilterInput
    $owner: String
  ) {
    onUpdateProduct(filter: $filter, owner: $owner) {
      id
      name
      description
      article_number
      barcode_type
      barcode_content
      comments
      extra_data
      owner
      Components {
        nextToken
      }
      Contracts {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProduct = /* GraphQL */ `
  subscription OnDeleteProduct(
    $filter: ModelSubscriptionProductFilterInput
    $owner: String
  ) {
    onDeleteProduct(filter: $filter, owner: $owner) {
      id
      name
      description
      article_number
      barcode_type
      barcode_content
      comments
      extra_data
      owner
      Components {
        nextToken
      }
      Contracts {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateComponent = /* GraphQL */ `
  subscription OnCreateComponent(
    $filter: ModelSubscriptionComponentFilterInput
    $owner: String
  ) {
    onCreateComponent(filter: $filter, owner: $owner) {
      id
      name
      root
      description
      dimensions {
        bounding_box_x
        bounding_box_y
        bounding_box_z
        volume
      }
      article_number
      barcode_type
      barcode_content
      tolerances
      weight
      thread_count
      masking
      certificates
      comment
      comment_internal
      costs
      manufacturing_data
      productID
      Product {
        id
        name
        description
        article_number
        barcode_type
        barcode_content
        comments
        extra_data
        owner
        createdAt
        updatedAt
      }
      manufacturingprocessID
      ManufacturingProcess {
        id
        category
        process
        createdAt
        updatedAt
      }
      Parts {
        nextToken
      }
      Materials {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateComponent = /* GraphQL */ `
  subscription OnUpdateComponent(
    $filter: ModelSubscriptionComponentFilterInput
    $owner: String
  ) {
    onUpdateComponent(filter: $filter, owner: $owner) {
      id
      name
      root
      description
      dimensions {
        bounding_box_x
        bounding_box_y
        bounding_box_z
        volume
      }
      article_number
      barcode_type
      barcode_content
      tolerances
      weight
      thread_count
      masking
      certificates
      comment
      comment_internal
      costs
      manufacturing_data
      productID
      Product {
        id
        name
        description
        article_number
        barcode_type
        barcode_content
        comments
        extra_data
        owner
        createdAt
        updatedAt
      }
      manufacturingprocessID
      ManufacturingProcess {
        id
        category
        process
        createdAt
        updatedAt
      }
      Parts {
        nextToken
      }
      Materials {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteComponent = /* GraphQL */ `
  subscription OnDeleteComponent(
    $filter: ModelSubscriptionComponentFilterInput
    $owner: String
  ) {
    onDeleteComponent(filter: $filter, owner: $owner) {
      id
      name
      root
      description
      dimensions {
        bounding_box_x
        bounding_box_y
        bounding_box_z
        volume
      }
      article_number
      barcode_type
      barcode_content
      tolerances
      weight
      thread_count
      masking
      certificates
      comment
      comment_internal
      costs
      manufacturing_data
      productID
      Product {
        id
        name
        description
        article_number
        barcode_type
        barcode_content
        comments
        extra_data
        owner
        createdAt
        updatedAt
      }
      manufacturingprocessID
      ManufacturingProcess {
        id
        category
        process
        createdAt
        updatedAt
      }
      Parts {
        nextToken
      }
      Materials {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateBOM = /* GraphQL */ `
  subscription OnCreateBOM(
    $filter: ModelSubscriptionBOMFilterInput
    $owner: String
  ) {
    onCreateBOM(filter: $filter, owner: $owner) {
      id
      quantity
      parentID
      childID
      Child {
        id
        name
        root
        description
        article_number
        barcode_type
        barcode_content
        tolerances
        weight
        thread_count
        masking
        certificates
        comment
        comment_internal
        costs
        manufacturing_data
        productID
        manufacturingprocessID
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateBOM = /* GraphQL */ `
  subscription OnUpdateBOM(
    $filter: ModelSubscriptionBOMFilterInput
    $owner: String
  ) {
    onUpdateBOM(filter: $filter, owner: $owner) {
      id
      quantity
      parentID
      childID
      Child {
        id
        name
        root
        description
        article_number
        barcode_type
        barcode_content
        tolerances
        weight
        thread_count
        masking
        certificates
        comment
        comment_internal
        costs
        manufacturing_data
        productID
        manufacturingprocessID
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteBOM = /* GraphQL */ `
  subscription OnDeleteBOM(
    $filter: ModelSubscriptionBOMFilterInput
    $owner: String
  ) {
    onDeleteBOM(filter: $filter, owner: $owner) {
      id
      quantity
      parentID
      childID
      Child {
        id
        name
        root
        description
        article_number
        barcode_type
        barcode_content
        tolerances
        weight
        thread_count
        masking
        certificates
        comment
        comment_internal
        costs
        manufacturing_data
        productID
        manufacturingprocessID
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateMaterial = /* GraphQL */ `
  subscription OnCreateMaterial($filter: ModelSubscriptionMaterialFilterInput) {
    onCreateMaterial(filter: $filter) {
      id
      name
      type
      available
      description
      chemical_name
      numerical_name
      number
      Components {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMaterial = /* GraphQL */ `
  subscription OnUpdateMaterial($filter: ModelSubscriptionMaterialFilterInput) {
    onUpdateMaterial(filter: $filter) {
      id
      name
      type
      available
      description
      chemical_name
      numerical_name
      number
      Components {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMaterial = /* GraphQL */ `
  subscription OnDeleteMaterial($filter: ModelSubscriptionMaterialFilterInput) {
    onDeleteMaterial(filter: $filter) {
      id
      name
      type
      available
      description
      chemical_name
      numerical_name
      number
      Components {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProfile = /* GraphQL */ `
  subscription OnCreateProfile(
    $filter: ModelSubscriptionProfileFilterInput
    $owner: String
  ) {
    onCreateProfile(filter: $filter, owner: $owner) {
      id
      company_name
      contact_person
      mobile_number
      vat_number
      preferred_contact_way
      preferred_incoterms
      phone_number
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateProfile = /* GraphQL */ `
  subscription OnUpdateProfile(
    $filter: ModelSubscriptionProfileFilterInput
    $owner: String
  ) {
    onUpdateProfile(filter: $filter, owner: $owner) {
      id
      company_name
      contact_person
      mobile_number
      vat_number
      preferred_contact_way
      preferred_incoterms
      phone_number
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteProfile = /* GraphQL */ `
  subscription OnDeleteProfile(
    $filter: ModelSubscriptionProfileFilterInput
    $owner: String
  ) {
    onDeleteProfile(filter: $filter, owner: $owner) {
      id
      company_name
      contact_person
      mobile_number
      vat_number
      preferred_contact_way
      preferred_incoterms
      phone_number
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateAddress = /* GraphQL */ `
  subscription OnCreateAddress(
    $filter: ModelSubscriptionAddressFilterInput
    $owner: String
  ) {
    onCreateAddress(filter: $filter, owner: $owner) {
      id
      name
      address_1
      address_2
      zip_code
      city
      country
      type
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateAddress = /* GraphQL */ `
  subscription OnUpdateAddress(
    $filter: ModelSubscriptionAddressFilterInput
    $owner: String
  ) {
    onUpdateAddress(filter: $filter, owner: $owner) {
      id
      name
      address_1
      address_2
      zip_code
      city
      country
      type
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteAddress = /* GraphQL */ `
  subscription OnDeleteAddress(
    $filter: ModelSubscriptionAddressFilterInput
    $owner: String
  ) {
    onDeleteAddress(filter: $filter, owner: $owner) {
      id
      name
      address_1
      address_2
      zip_code
      city
      country
      type
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateChannel = /* GraphQL */ `
  subscription OnCreateChannel($filter: ModelSubscriptionChannelFilterInput) {
    onCreateChannel(filter: $filter) {
      id
      address_from
      address_to
      reference
      visitedAt
      isNew
      product
      Messages {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChannel = /* GraphQL */ `
  subscription OnUpdateChannel($filter: ModelSubscriptionChannelFilterInput) {
    onUpdateChannel(filter: $filter) {
      id
      address_from
      address_to
      reference
      visitedAt
      isNew
      product
      Messages {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChannel = /* GraphQL */ `
  subscription OnDeleteChannel($filter: ModelSubscriptionChannelFilterInput) {
    onDeleteChannel(filter: $filter) {
      id
      address_from
      address_to
      reference
      visitedAt
      isNew
      product
      Messages {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage($filter: ModelSubscriptionMessageFilterInput) {
    onCreateMessage(filter: $filter) {
      id
      body
      author
      channelID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage($filter: ModelSubscriptionMessageFilterInput) {
    onUpdateMessage(filter: $filter) {
      id
      body
      author
      channelID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage($filter: ModelSubscriptionMessageFilterInput) {
    onDeleteMessage(filter: $filter) {
      id
      body
      author
      channelID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUnauthRequest = /* GraphQL */ `
  subscription OnCreateUnauthRequest(
    $filter: ModelSubscriptionUnauthRequestFilterInput
  ) {
    onCreateUnauthRequest(filter: $filter) {
      id
      email
      name
      company_name
      address
      zip_code
      city
      country
      phone_number
      project_description
      status
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUnauthRequest = /* GraphQL */ `
  subscription OnUpdateUnauthRequest(
    $filter: ModelSubscriptionUnauthRequestFilterInput
  ) {
    onUpdateUnauthRequest(filter: $filter) {
      id
      email
      name
      company_name
      address
      zip_code
      city
      country
      phone_number
      project_description
      status
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUnauthRequest = /* GraphQL */ `
  subscription OnDeleteUnauthRequest(
    $filter: ModelSubscriptionUnauthRequestFilterInput
  ) {
    onDeleteUnauthRequest(filter: $filter) {
      id
      email
      name
      company_name
      address
      zip_code
      city
      country
      phone_number
      project_description
      status
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEmailResult = /* GraphQL */ `
  subscription OnCreateEmailResult(
    $filter: ModelSubscriptionEmailResultFilterInput
  ) {
    onCreateEmailResult(filter: $filter) {
      statusCode
      body
      id
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEmailResult = /* GraphQL */ `
  subscription OnUpdateEmailResult(
    $filter: ModelSubscriptionEmailResultFilterInput
  ) {
    onUpdateEmailResult(filter: $filter) {
      statusCode
      body
      id
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEmailResult = /* GraphQL */ `
  subscription OnDeleteEmailResult(
    $filter: ModelSubscriptionEmailResultFilterInput
  ) {
    onDeleteEmailResult(filter: $filter) {
      statusCode
      body
      id
      createdAt
      updatedAt
    }
  }
`;
export const onCreateComponentMaterials = /* GraphQL */ `
  subscription OnCreateComponentMaterials(
    $filter: ModelSubscriptionComponentMaterialsFilterInput
    $owner: String
  ) {
    onCreateComponentMaterials(filter: $filter, owner: $owner) {
      id
      componentID
      materialID
      component {
        id
        name
        root
        description
        article_number
        barcode_type
        barcode_content
        tolerances
        weight
        thread_count
        masking
        certificates
        comment
        comment_internal
        costs
        manufacturing_data
        productID
        manufacturingprocessID
        createdAt
        updatedAt
        owner
      }
      material {
        id
        name
        type
        available
        description
        chemical_name
        numerical_name
        number
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateComponentMaterials = /* GraphQL */ `
  subscription OnUpdateComponentMaterials(
    $filter: ModelSubscriptionComponentMaterialsFilterInput
    $owner: String
  ) {
    onUpdateComponentMaterials(filter: $filter, owner: $owner) {
      id
      componentID
      materialID
      component {
        id
        name
        root
        description
        article_number
        barcode_type
        barcode_content
        tolerances
        weight
        thread_count
        masking
        certificates
        comment
        comment_internal
        costs
        manufacturing_data
        productID
        manufacturingprocessID
        createdAt
        updatedAt
        owner
      }
      material {
        id
        name
        type
        available
        description
        chemical_name
        numerical_name
        number
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteComponentMaterials = /* GraphQL */ `
  subscription OnDeleteComponentMaterials(
    $filter: ModelSubscriptionComponentMaterialsFilterInput
    $owner: String
  ) {
    onDeleteComponentMaterials(filter: $filter, owner: $owner) {
      id
      componentID
      materialID
      component {
        id
        name
        root
        description
        article_number
        barcode_type
        barcode_content
        tolerances
        weight
        thread_count
        masking
        certificates
        comment
        comment_internal
        costs
        manufacturing_data
        productID
        manufacturingprocessID
        createdAt
        updatedAt
        owner
      }
      material {
        id
        name
        type
        available
        description
        chemical_name
        numerical_name
        number
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
