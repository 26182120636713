import React from 'react'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import Page from '../components/layout/Page'
import { List, ListItem, ListItemButton } from '@mui/material'

const languages: { value: string; label: string }[] = [
  {
    value: 'de',
    label: 'Deutsch',
  },
  {
    value: 'en',
    label: 'English',
  },
]

export default function LanguageSelectPage() {
  const { t } = useTranslation()
  return (
    <Page title={t('pages.language')}>
      <List>
        {languages.map(({ value, label }) => (
          <ListItem key={value}>
            <ListItemButton
              selected={i18next.language === value}
              onClick={() => i18next.changeLanguage(value)}
            >
              {label}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Page>
  )
}
