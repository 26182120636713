import React, { useEffect, useState } from 'react'
import Page from '../components/layout/Page'
import { useLocation, useParams } from 'react-router-dom'
import { CardContent } from '@mui/material'
import ProductDetails from '../features/Product/components/Product/ProductDetails'
import FAB from '../components/widgets/FAB'
import ProductLogic from '../features/Product/components/Product/ProductLogic'
import { API, graphqlOperation } from 'aws-amplify'
import { getProduct } from '../graphql/queries'
import { Product } from '../graphql/graphql'
import { useTranslation } from 'react-i18next'
import { handlePromise } from '../utils/functions'

type urlParams = {
  id: string
}

type Props = {
  productIdInOverview?: string
}

/**
 * Product page consisting of product details and logic
 */
export default function ProductPage(props: Props) {
  const { t } = useTranslation(['common', 'product'])
  const { id } = useParams<urlParams>()
  const productID: string = props.productIdInOverview ? props.productIdInOverview : id
  const location = useLocation()
  const [product, setProduct] = useState<Product>()
  const isNew = location.pathname === '/products/new'
  const [editing, setEditing] = useState(isNew)

  useEffect(() => {
    !isNew ? fetchProduct() : null
  }, [])

  async function fetchProduct() {
    const [res, err] = await handlePromise(
      'getProduct',
      API.graphql(graphqlOperation(getProduct, { id: productID }))
    )
    res ? setProduct(res.data.getProduct) : console.log('Error fetching product', err)
  }

  return (
    <Page>
      {!props.productIdInOverview ? (
        <ProductLogic
          setProduct={setProduct}
          product={product as Product}
          editing={editing}
          setEditing={setEditing}
          isNew={isNew}
        >
          <CardContent sx={{ width: '100%' }}>
            <ProductDetails
              product={product as Product}
              editing={editing}
              setProduct={setProduct}
              isNew={isNew}
            />
            {product && <FAB type={'EDIT'} onClick={() => setEditing(!editing)} />}
          </CardContent>
        </ProductLogic>
      ) : (
        <ProductDetails
          product={product as Product}
          editing={editing}
          setProduct={setProduct}
          isNew={isNew}
          productIdInOverview={props.productIdInOverview}
        />
      )}
    </Page>
  )
}
