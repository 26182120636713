/* eslint-disable no-restricted-syntax */

import MailOutlinedIcon from '@mui/icons-material/MailOutlined'
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined'
import AssignmentIcon from '@mui/icons-material/Assignment'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { isNavActive } from '../../utils/functions'
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import RequestPageIcon from '@mui/icons-material/RequestPage'

const navItems: { to: string; name: string; exact: boolean; Icon: React.ComponentType }[] = [
  {
    to: '/messages',
    name: 'messages',
    exact: false,
    Icon: MailOutlinedIcon,
  },
  {
    to: '/files',
    name: 'files',
    exact: false,
    Icon: FolderOpenOutlinedIcon,
  },
  {
    to: '/contracts',
    name: 'contracts',
    exact: true,
    Icon: ShoppingCartIcon,
  },
  {
    to: '/products',
    name: 'products',
    exact: false,
    Icon: AssignmentIcon,
  },
  /* {
    to: '/settings',
    name: 'pages.settings',
    exact: false,
    Icon: SettingsIcon,
  }, */
]

type Props = {
  groups?: any
}

export default function TabBar(props: Props) {
  const { groups } = props
  const history = useHistory()
  const location = useLocation()
  const [activeValue, setActiveValue] = React.useState('')
  const { t } = useTranslation()

  // update the active value when the url changes
  useEffect(() => {
    if (location.pathname !== activeValue) {
      for (const item of navItems) {
        if (isNavActive(item.to, item.exact, location.pathname)) {
          setActiveValue(item.to)
          break
        }
      }
      // setActiveValue(location.pathname)
    }
  }, [location.pathname, activeValue])

  console.log(groups && groups.includes('AdminS3'))
  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation
        value={activeValue}
        onChange={(event, value) => {
          setActiveValue(value)
          history.push(value)
        }}
        showLabels
        sx={{ width: '100%' }}
      >
        {navItems.map(({ to, name, Icon }) => (
          <BottomNavigationAction
            sx={{ margin: groups && groups.includes('AdminS3') ? '-10px' : 'auto' }}
            key={name}
            label={t(name)}
            value={to}
            icon={<Icon />}
          />
        ))}
        {groups && groups.includes('AdminS3') && (
          <BottomNavigationAction
            sx={{ margin: '-10px' }}
            key="requests"
            label={t('pages.requests')}
            value="/requests"
            icon={<RequestPageIcon />}
          />
        )}
      </BottomNavigation>
    </Paper>
  )
}
