import React, { useState } from 'react'
import { ErrorBoundary as Boundary } from 'react-error-boundary'
import { Button, Card, Container } from '@mui/material'
import { Alert, AlertTitle } from '@mui/lab'

interface Props {
  children: React.ReactNode
}

interface ErrorState {
  error: Error | null
  componentStack: string
}

export default function ErrorBoundary({ children }: Props) {
  const [errorState, setErrorState] = useState<ErrorState>({ error: null, componentStack: '' })

  const handleError = (error: Error, componentStack: string) => {
    setErrorState({ error, componentStack })
    console.error(error, componentStack)
  }

  return (
    <Boundary
      fallbackRender={() => (
        <Container maxWidth="sm">
          <Card>
            <Alert severity="error">
              <AlertTitle>Oops.. Something went wrong</AlertTitle>
              There must have been an error here. Please try again later or contact us with the
              following info:
              <pre>{errorState.error && errorState.error.message}</pre>
              <Button
                size="small"
                color="inherit"
                variant="outlined"
                onClick={() => alert('TODO: Implement')}
              >
                Fehler übermitteln
              </Button>
            </Alert>
          </Card>
        </Container>
      )}
      onError={(error, { componentStack }) => handleError(error, componentStack)}
    >
      {children}
    </Boundary>
  )
}
