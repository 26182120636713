import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

export const languages = ['en', 'de']

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ['common', 'product', 'contract', 'profile'],
    defaultNS: 'common',
    fallbackLng: 'en',
    detection: {
      order: ['querystring', 'cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    debug: process.env.NODE_ENV === 'development',
    whitelist: languages,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
