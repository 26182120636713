import React, { useEffect, useState } from 'react'
import ProcessSelection from '../ManufacturingProcess/ProcessSelection'
import { Component, ManufacturingRequirement } from '../../../../graphql/graphql'
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  Grid,
  IconButton,
  MobileStepper,
  Modal,
  Paper,
  Slide,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import ComponentDetails from './ComponentDetails'
import { useTranslation } from 'react-i18next'
import getTranslatetedStepTitle from '../../utils/functions'
import { Close, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import FileUploads from '../ManufacturingProcess/FileUploads'
import useMobileService from '../../../../hooks/useMobileService'
import { TransitionProps } from '@mui/material/transitions'

type Props = {
  component: Component
  setComponent: (component: Component) => void
  open: boolean
  setOpen: (open: boolean) => void
  onCreate: () => void
  onSave: () => void
  isNew: boolean
  activeStep: any
  setActiveStep: (step: any) => void
  isChildComponent: boolean
  quantity: number
  setQuantity: (quantity: number) => void
  comment: string
  setComment: (comment: string) => void
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const normalSteps = ['Component Information', 'Process Information', 'File Uploads']
const childSteps = [
  'Component Information',
  'Process Information',
  'File Uploads',
  'componentQuantity',
]

/**
 * Form to create a component with
 * Steps:
 *  1. Component information
 *      - common information every component has
 *  2. Process information
 *      - manufacturing process specific information (cnc, 3d print, ...)
 *  3. File uploads
 *      - Normal file upload and CAD file upload
 *  4. Number of Components and comment (only as child component)
 */
export default function DynamicForm(props: Props) {
  const {
    component,
    setComponent,
    open,
    setOpen,
    onCreate,
    onSave,
    activeStep,
    setActiveStep,
    isChildComponent,
    quantity,
    setQuantity,
    comment,
    setComment,
  } = props
  const theme = useTheme()
  const { t } = useTranslation()
  const [steps, setSteps] = useState<string[]>(normalSteps)
  const [manufacturingData, setManufacturingData] = useState<ManufacturingRequirement[]>([])
  const [skipped, setSkipped] = useState(new Set<number>())
  const isMobile = useMobileService()

  useEffect(() => {
    isChildComponent ? setSteps(childSteps) : setSteps(normalSteps)
  })

  const isStepSkipped = (step: number) => {
    return skipped.has(step)
  }

  const handleNext = () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }
    activeStep === 1
      ? setComponent({ ...component, manufacturing_data: JSON.stringify(manufacturingData) })
      : null
    setActiveStep((prevActiveStep: any) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep: any) => prevActiveStep - 1)
  }

  const handleFinish = () => {
    setActiveStep(0)
    props.isNew ? onCreate() : onSave()
  }

  const nextStepDisabled = (activeStep: number, component: Component) => {
    if (component && component?.name === undefined && activeStep === 0) {
      return true
    } else return component && component?.manufacturingprocessID === undefined && activeStep === 1
  }

  if (isMobile) {
    const maxSteps: number = steps.length
    return (
      <Dialog
        open={open}
        fullScreen
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
      >
        <Box sx={{ flexGrow: 1, marginBottom: '50px' }}>
          <Paper
            square
            elevation={0}
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: 50,
              pl: 2,
              bgcolor: 'background.default',
            }}
          >
            <Typography>{steps[activeStep]}</Typography>
            <IconButton
              onClick={() => setOpen(false)}
              aria-label="close"
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </Paper>
          <Box
            sx={{
              width: '100%',
              p: 2,
            }}
          >
            {activeStep === steps.length - 1 ? (
              <React.Fragment>
                {isChildComponent ? (
                  <Box>
                    <Typography sx={{ margin: '5px' }}>
                      {t('product:dynamicForm.howManyComponents')}
                    </Typography>
                    <TextField
                      id="outlined-number"
                      label={t('quantity')}
                      type="number"
                      value={quantity}
                      onChange={event => setQuantity(parseInt(event.currentTarget.value))}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ inputProps: { min: 1 } }}
                    />
                    <TextField
                      sx={{ width: '100%', marginTop: '10px' }}
                      id="outlined-multiline-static"
                      label={t('comment')}
                      multiline
                      rows={4}
                      value={comment}
                      onChange={event => setComment(event.currentTarget.value)}
                    />
                  </Box>
                ) : (
                  <FileUploads component={component} />
                )}
                {/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    {t('back')}
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={handleFinish}>{t('save')}</Button>
                </Box> */}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {activeStep === 0 && (
                  <ComponentDetails component={component} setComponent={setComponent} />
                )}
                {activeStep === 1 && (
                  <ProcessSelection
                    component={component}
                    setComponent={setComponent}
                    manufacturingData={manufacturingData}
                    setManufacturingData={setManufacturingData}
                  />
                )}
                {activeStep === 2 && <FileUploads component={component} />}
                {/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    {t('back')}
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleNext} disabled={nextStepDisabled(activeStep, component)}>
                      {activeStep === steps.length - 1 ? t('save') : t('continue')}
                    </Button>
                  </Box> */}
              </React.Fragment>
            )}
          </Box>
          <MobileStepper
            variant="text"
            steps={maxSteps}
            position="bottom"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={activeStep === maxSteps - 1 ? handleFinish : handleNext}
                disabled={nextStepDisabled(activeStep, component)}
              >
                {activeStep === maxSteps - 1 ? t('save') : t('continue')}
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                {t('back')}
              </Button>
            }
          />
        </Box>
      </Dialog>
    )
  }

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') {
          return null
        } else {
          setOpen(false)
        }
      }}
    >
      <Card
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxHeight: '95%',
          maxWidth: '95%',
          overflowY: 'auto',
          minWidth: '50%',
        }}
      >
        <CardContent>
          <div>
            <Typography sx={{ float: 'left' }} variant="h6">
              {component && Object.values(component).length > 0
                ? t('product:overview.editComponent')
                : t('product:overview.newComponent')}
            </Typography>
            <IconButton sx={{ float: 'right' }} onClick={() => setOpen(false)}>
              <Close />
            </IconButton>
          </div>
          <Stepper activeStep={activeStep} sx={{ marginTop: '40px', marginBottom: '20px' }}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {}
              const labelProps: {
                optional?: React.ReactNode
              } = {}
              if (isStepSkipped(index)) {
                stepProps.completed = false
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{getTranslatetedStepTitle(label, t)}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
          {activeStep === steps.length - 1 ? (
            <React.Fragment>
              {isChildComponent ? (
                <Box>
                  <Typography sx={{ margin: '5px' }}>
                    {t('product:dynamicForm.howManyComponents')}
                  </Typography>
                  <TextField
                    id="outlined-number"
                    label={t('quantity')}
                    type="number"
                    value={quantity}
                    onChange={event => setQuantity(parseInt(event.currentTarget.value))}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ inputProps: { min: 1 } }}
                  />
                  <TextField
                    sx={{ width: '100%', marginTop: '10px' }}
                    id="outlined-multiline-static"
                    label={t('comment')}
                    multiline
                    rows={4}
                    value={comment}
                    onChange={event => setComment(event.currentTarget.value)}
                  />
                </Box>
              ) : (
                <FileUploads component={component} />
              )}
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  {t('back')}
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleFinish}>{t('save')}</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {activeStep === 0 && (
                <ComponentDetails component={component} setComponent={setComponent} />
              )}
              {activeStep === 1 && (
                <ProcessSelection
                  component={component}
                  setComponent={setComponent}
                  manufacturingData={manufacturingData}
                  setManufacturingData={setManufacturingData}
                />
              )}
              {activeStep === 2 && <FileUploads component={component} />}
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  {t('back')}
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleNext} disabled={nextStepDisabled(activeStep, component)}>
                  {activeStep === steps.length - 1 ? t('save') : t('continue')}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </CardContent>
      </Card>
    </Modal>
  )
}
