import React, { useEffect, useState } from 'react'
import { TextField, Button, Link, Grid, Typography, Box } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import Page from '../../components/layout/Page'
import { useTranslation } from 'react-i18next'
import useMobileService from '../../hooks/useMobileService'

type Props = {
  setUser: (user: any) => void
  user: any
}

export default function SignInPage(props: Props) {
  const { setUser, user } = props
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState<boolean>(false)
  const { t } = useTranslation(['common'])
  const history = useHistory()
  const isMobile = useMobileService()

  useEffect(() => {
    setError(false)
  }, [password, email])

  const emailIsValid = (email: string) => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    return email && emailRegex.test(email)
  }

  const handleSignIn = async (e: any) => {
    e.preventDefault()
    if (emailIsValid(email)) {
      try {
        const user = await Auth.signIn(email, password)
        setUser(user)
        history.push('/contracts')
        console.log('user', user)
      } catch (error) {
        console.log('error signing in', error)
        setError(true)
      }
    } else {
      setError(true)
    }
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      handleSignIn(e)
    }
  }

  if (user) {
    history.push('/contracts')
  }

  return (
    <Page>
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Grid
          container
          spacing={2}
          width={isMobile ? '100%' : '60%'}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} md={12} display="flex" justifyContent="center">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4" align="center">
                  <b>assemb</b>lean
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" align="center">
                  {t('auth.signIn')}
                </Typography>
              </Grid>
              {error && (
                <Grid item xs={12}>
                  <Typography color="error" align="center">
                    {error}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  id="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="password"
                  label="Password"
                  variant="outlined"
                  fullWidth
                  type="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
              </Grid>
              {error && (
                <Grid item xs={12}>
                  <Typography color="error" align="center">
                    {t('auth.signInError')}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleSignIn}
                  onKeyDown={handleKeyDown}
                >
                  {t('auth.signIn')}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => history.push('/send-request')}
                >
                  {t('auth.goToRequest')}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Link href="/reset-password">{t('auth.forgotPassword')}</Link>
                  <Link href="/signUp">{t('auth.dontHaveAnAccount')}</Link>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Page>
  )
}
