import React, { useState } from 'react'
import Page from '../components/layout/Page'
import ChannelLogic from '../features/Conversation/components/Channel/ChannelLogic'

/**
 * Page as entry point for message system
 */
export default function MessageOverviewPage() {
  return (
    <Page>
      <ChannelLogic />
    </Page>
  )
}
