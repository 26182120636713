import React from 'react'
import useMobileService from '../../hooks/useMobileService'
import { Checkbox, FormControl, InputLabel, MenuItem, Select } from '@mui/material'

type Props<T> = {
  name: string
  value: T[]
  options: { value: T; label?: string; disabled?: boolean }[]
  disabled?: boolean
  labelWidth?: number
  onChange: (value: T[]) => void
}

export default function MultiSelectInput<T extends string | number>(props: Props<T>) {
  const { name, value, options, disabled, labelWidth = 100, onChange } = props
  const isMobile = useMobileService()

  const handleChangeMultiple = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange([...(event.target.value as T[])])
  }
  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel htmlFor={name}>{name}</InputLabel>
      <Select
        native={isMobile}
        label={name}
        id={name}
        multiple
        value={value || ''}
        disabled={disabled}
        onChange={(e: any) => handleChangeMultiple(e)}
        renderValue={selected =>
          (selected as string[])
            .map(val => options.find(option => option.value === val))
            .map(option => option?.label || option?.value || '')
            .join(', ')
        }
      >
        {options.map(option =>
          isMobile ? (
            <option
              value={option.value as string}
              disabled={option.disabled}
              key={option.value as string}
            >
              {option.label ? option.label : option.value}
            </option>
          ) : (
            <MenuItem
              value={option.value as string}
              disabled={option.disabled}
              key={option.value as string}
            >
              <Checkbox checked={value.indexOf(option.value) > -1} disabled={option.disabled} />
              {option.label ? option.label : option.value}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  )
}
