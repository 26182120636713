import React from 'react'
import { Checkbox, FormControl, FormControlLabel } from '@mui/material'

type Props = {
  value: boolean
  label: string
  labelWidth?: number
  onChange: (value: boolean) => void
  validation?: (value: boolean) => boolean
  errorMessage?: string
  disabled?: boolean
}

export default function CheckboxInput(props: Props) {
  const { label, value, onChange, labelWidth = 100, disabled = false } = props

  return (
    <div>
      <FormControl component="fieldset">
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={value}
              onChange={() => onChange(!value)}
              name={label}
              disabled={disabled}
            />
          }
          label={label}
        />
      </FormControl>
    </div>
  )
}
