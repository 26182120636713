import React from 'react'
import { Divider, Grid, Link } from '@mui/material'

const Footer = () => (
  <Grid container>
    <Grid item xs={12}>
      <Divider />
    </Grid>
    <Grid item xs={12} style={{ textAlign: 'center', color: 'grey', padding: 8 }}>
      Copyright © {new Date().getFullYear()}
      {' - '}
      <span style={{ fontWeight: 'bold' }}>assemblean plattform</span>
      {` v.${process.env.REACT_APP_VERSION}, more on `}
      <Link href="https://assemblean.com/" target="_blank" rel="noreferrer" color="secondary">
        assemblean
      </Link>
      {' - '}
      <Link
        href="https://www.assemblean.com/privacy-policy"
        target="_blank"
        rel="noreferrer"
        color="secondary"
      >
        privacy policy
      </Link>
    </Grid>
  </Grid>
)

export default Footer
