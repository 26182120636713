import {
  Checkbox,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import FolderRoundedIcon from '@mui/icons-material/FolderRounded'
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { useTranslation } from 'react-i18next'
import { formatBytes } from '../../../utils/functions'
import { RenderTree } from '../utils/types'
import 'moment/locale/de'
import { formatDateString, groupedFilesAndFolders } from '../utils/functions'
import useMobileService from '../../../hooks/useMobileService'

type Props = {
  files: RenderTree[]
  tickedFiles: RenderTree[]
  onFileClick: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, file: any) => void
  updateTickedStatus: (file: any) => void
  getTickedStatus: (file: any) => number
  onTickAllFilesClick: () => void
  onUntickAllFilesClick: () => void
  sx?: React.CSSProperties
}

const columnWidths = {
  name: {
    width: 250,
    maxWidth: 250,
  },
}

/**
 * Lists all files in a table
 */
export function FilesystemFileview(props: Props) {
  const {
    files,
    tickedFiles,
    onFileClick,
    updateTickedStatus,
    getTickedStatus,
    onTickAllFilesClick,
    onUntickAllFilesClick,
    sx,
  } = props
  const { t } = useTranslation()
  const [noFiles, setNoFiles] = useState<boolean>(true)
  const [allFilesTicked, setAllFilesTicked] = useState<boolean>(false)
  const { i18n } = useTranslation()
  const isMobile = useMobileService()

  useEffect(() => {
    files.filter(x => x.size > 0 || x.name !== '').length === 0
      ? setNoFiles(true)
      : setNoFiles(false)
    if (noFiles) {
      //if there are no files the checkbox should not be ticked
      setAllFilesTicked(false)
    }
  }, [files])

  useEffect(() => {
    tickedFiles.length > 0 && tickedFiles.length === files.length
      ? setAllFilesTicked(!allFilesTicked)
      : setAllFilesTicked(false)
  }, [tickedFiles])

  const handleCheckboxClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    file: RenderTree
  ) => {
    event.stopPropagation()
    updateTickedStatus(file)
  }

  if (isMobile) {
    return (
      <List>
        {groupedFilesAndFolders(files.filter(x => x.size > 0 || x.name !== '')).map(
          (file: any, index: number) => {
            const labelId = `checkbox-list-secondary-label-${file}`
            return (
              <ListItem
                key={index}
                secondaryAction={
                  <Checkbox
                    edge="end"
                    onChange={(event: any) => handleCheckboxClick(event, file)}
                    checked={getTickedStatus(file) !== -1}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                }
              >
                <ListItemIcon onClick={(event: any) => onFileClick(event, file)}>
                  {file.children.length > 0 ? (
                    <FolderRoundedIcon />
                  ) : (
                    <InsertDriveFileRoundedIcon />
                  )}
                </ListItemIcon>
                <ListItemText
                  onClick={(event: any) => onFileClick(event, file)}
                  primary={file.name}
                  secondary={formatDateString(file.lastModified)}
                />
              </ListItem>
            )
          }
        )}
      </List>
    )
  }

  return (
    <div style={sx}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell /> {/* empty tablecell as placeholder for folder/file icons */}
              <TableCell>{t('name')}</TableCell>
              <TableCell align="right">{t('createdAt')}</TableCell>
              <TableCell align="right">{t('size')}</TableCell>
              <TableCell align="right">
                <IconButton onClick={allFilesTicked ? onUntickAllFilesClick : onTickAllFilesClick}>
                  {allFilesTicked ? <CheckBoxIcon color="primary" /> : <CheckBoxOutlineBlankIcon />}
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {noFiles ? (
              <TableRow>
                <TableCell colSpan={5} align="center" sx={{ textalign: 'center' }}>
                  <Typography variant="body2" sx={{ color: 'gray' }}>
                    {t('product:filesystemError.emptyFolder')}
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              groupedFilesAndFolders(files.filter(x => x.size > 0 || x.name !== '')).map(
                (file: any, index: number) => {
                  return (
                    <TableRow
                      key={index}
                      onClick={event => onFileClick(event, file)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell width="40px">
                        <Icon>
                          {file.children.length > 0 ? (
                            <FolderRoundedIcon />
                          ) : (
                            <InsertDriveFileRoundedIcon />
                          )}
                        </Icon>
                      </TableCell>
                      <TableCell
                        sx={{
                          width: columnWidths.name.width,
                          maxWidth: columnWidths.name.maxWidth,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {file.name}
                      </TableCell>
                      <TableCell align="right">{formatDateString(file.lastModified)}</TableCell>
                      <TableCell align="right">
                        {file.children.length > 0 ? '' : formatBytes(file.size)}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton onClick={event => handleCheckboxClick(event, file)}>
                          {getTickedStatus(file) === -1 ? (
                            <CheckBoxOutlineBlankIcon />
                          ) : (
                            <CheckBoxIcon color="primary" />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                }
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
