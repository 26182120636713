import React, { useState } from 'react'
import { Select, MenuItem, FormControl } from '@mui/material'
import i18next from 'i18next'

/*
const languages: {countryCode: string, language: string}[] =[
    {
        countryCode: 'de',
        language: 'DE'
    },
    {
        countryCode: 'en',
        language: 'EN'
    }
]
*/

export default function LanguageSelect() {
  const [currentLanguage, setCurrentLanguage] = useState(i18next.language) //i18next.language == current language

  return (
    <FormControl size="small">
      <Select
        value={currentLanguage}
        displayEmpty
        onChange={langSelect => setCurrentLanguage(langSelect.target.value)}
      >
        {/*Todo: iterieren reparieren, um in Zukunft mehr als zwei Sprachen ohne großen Aufwand zu unterstützen
          
          languages.map(({ countryCode, language }) => (
              <MenuItem value={countryCode} onClick={() => i18next.changeLanguage(countryCode)}>
                  Test
              </MenuItem>           
          ))*/}
        <MenuItem value={'de'} onClick={() => i18next.changeLanguage('de')}>
          DE
        </MenuItem>
        <MenuItem value={'en'} onClick={() => i18next.changeLanguage('en')}>
          EN
        </MenuItem>
      </Select>
    </FormControl>
  )
}
