import React, { useState } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import Page from '../../components/layout/Page'
import Auth from '@aws-amplify/auth'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default function VerifyEmailPage() {
  const [code, setCode] = useState('')
  const location: any = useLocation()
  const history = useHistory()
  const email = location.state.email
  const password = location.state.password
  const { t } = useTranslation(['common'])

  const handleCodeChange = (event: any) => {
    setCode(event.target.value)
  }
  const handleSubmit = (event: any) => {
    event.preventDefault()
    verifyEmail()
    signIn()
  }

  const signIn = async () => {
    try {
      const result = await Auth.signIn(email, password)
      console.log('Sign in: ', result)
      history.push('/')
    } catch (error) {
      console.log('Error signing in: ', error)
    }
  }
  const verifyEmail = async () => {
    try {
      const result = await Auth.confirmSignUp(email, code)
      console.log('Verify email: ', result)
    } catch (error) {
      console.log('Error verifying email: ', error)
    }
  }

  const resendCode = async () => {
    try {
      const result = await Auth.resendSignUp(email)
      console.log('Resend code: ', result)
    } catch (error) {
      console.log('Error resending code: ', error)
    }
  }

  return (
    <Page>
      <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
        <Typography variant="h5">{t('auth.verifyEmail')}</Typography>
        <form onSubmit={handleSubmit} style={{ width: '60%' }}>
          <Box mt={2}>
            <TextField
              id="code"
              label="Verification Code"
              variant="outlined"
              fullWidth
              value={code}
              onChange={handleCodeChange}
            />
          </Box>
          <Box mt={2}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              {t('common:auth.verifyEmail')}
            </Button>
          </Box>
          <Box mt={2}>
            <Button onClick={() => resendCode()} variant="contained" color="secondary" fullWidth>
              {t('common:auth.resendCode')}
            </Button>
          </Box>
        </form>
      </Box>
    </Page>
  )
}
