import React, { useState } from 'react'
import { FormControl, FormHelperText, TextField } from '@mui/material'

type Props = {
  value: string
  label: string
  labelWidth?: number
  onChange: (value: number) => void
  validation?: boolean
  errorMessage?: string
  disabled?: boolean
}

export default function NumberInput(props: Props) {
  const {
    label,
    value,
    onChange,
    labelWidth = 100,
    disabled = false,
    errorMessage = '',
    validation,
  } = props

  const [error, setError] = useState<boolean>(validation !== undefined && value === '')

  return (
    <FormControl fullWidth variant="outlined">
      <TextField
        type="number"
        variant="outlined"
        label={label}
        id={label}
        value={value || ''}
        onChange={e => {
          onChange(parseInt(e.currentTarget.value))
          e.currentTarget.value !== '' ? setError(false) : setError(true)
        }}
        autoComplete="number"
        disabled={disabled}
        error={error}
        aria-describedby="text-input"
        inputProps={{
          'aria-label': 'weight',
          defaultValue: '1',
          min: '1',
          step: '1',
        }}
      />
      {error && (
        <FormHelperText error variant="outlined">
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  )
}
