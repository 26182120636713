export const customOnCreateMessage = /* GraphQL */ `
  subscription customOnCreateMessage {
    onCreateMessage {
      id
      body
      author
      channelID
      createdAt
      updatedAt
    }
  }
`
