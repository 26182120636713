import React from 'react'
import {
  getManProcessData,
  getPropertyByString,
  setManProcessData,
  setPropertyByString,
} from '../utils/functions'
import { FieldObject, FormFieldType, FormObject, ValueOf } from '../utils/types'
import { FormField } from './FormField'
import { Grid } from '@mui/material'

interface Props {
  editing?: boolean
  formObject: FormObject
  formFields: FormFieldType[]
  onChange: (object: FormObject) => void
}

export function Form(props: Props) {
  const { editing, formObject, formFields } = props
  const onChange = (key: keyof FormObject) => (value: ValueOf<FormObject>) => {
    if (key.toString().includes('manufacturing_process_data')) {
      props.onChange(setManProcessData(formObject, key as string, value))
    } else {
      props.onChange(setPropertyByString(formObject, key as string, value))
    }
  }

  const value = (key: keyof FormObject) => {
    if (key.toString().includes('manufacturing_process_data')) {
      return getManProcessData(formObject, key as string) as ValueOf<FormObject>
    } else {
      return getPropertyByString(formObject, key as string) as ValueOf<FormObject>
    }
  }

  return (
    <Grid item xs={12}>
      <form>
        <Grid container spacing={2}>
          {formFields.map(({ xs = 12, sm = 6, md = 6, lg = 6, xl = 6, ...field }) => (
            <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} key={field.key}>
              <FormField
                field={field as FieldObject}
                value={value(field.key)}
                onChange={onChange(field.key)}
                disabled={!editing}
              />
            </Grid>
          ))}
        </Grid>
      </form>
    </Grid>
  )
}
