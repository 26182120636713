import {
  Button,
  Chip,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import { Channel, Message } from '../../../../graphql/graphql'
import { Add, Delete } from '@mui/icons-material'
import Checkbox from '@mui/material/Checkbox'
import { useTranslation } from 'react-i18next'
import ListModal from '../../../../components/widgets/ListModal'

type Props = {
  user: any
  userGroups: string[] | null
  newMessages: Message[]
  channels: Channel[]
  handleCreateChannelClick: () => void
  handleChannelClick: (channel: Channel | undefined) => void
  handleChannelDelete: (channels: any[]) => void
  sx?: React.CSSProperties
}

/**
 * List all Channels
 *  - Admin has information about the sender
 *  - User always sends to chat@assemblean.com
 */
export default function ChannelList(props: Props) {
  const {
    user,
    userGroups,
    newMessages,
    channels,
    handleCreateChannelClick,
    handleChannelClick,
    handleChannelDelete,
    sx = {},
  } = props
  const noChannels = channels.length === 0
  // holds channelId checked information for that channel
  const [checked, setChecked] = useState<any>({})
  // list of checked channels for deletion
  const [checkedChannels, setCheckedChannels] = useState<Channel[]>([])
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const { t } = useTranslation()

  useEffect(() => {
    // create list of checked channels with object of form { channelID: boolean }
    channels.map((channel: Channel) => {
      checked[channel.id] === true && !checkedChannels.includes(channel)
        ? setCheckedChannels([...checkedChannels, channel])
        : null
      checked[channel.id] === false && checkedChannels.includes(channel)
        ? setCheckedChannels(checkedChannels.filter((x: Channel) => x.id !== channel.id))
        : null
    })
  }, [checked])

  return (
    <div style={sx}>
      <List
        disablePadding
        sx={{
          width: '100%',
          height: 'inherit',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Tooltip title={t('product:tooltip.newChannel') as string}>
          <Button variant="contained" sx={{ margin: '10px' }} onClick={handleCreateChannelClick}>
            <Add />
          </Button>
        </Tooltip>
        <Tooltip title={t('product:tooltip.delete') as string}>
          <Button
            disabled={checkedChannels.length === 0}
            variant="contained"
            sx={{ marign: '10px' }}
            onClick={() => {
              setOpenDeleteModal(!openDeleteModal)
            }}
          >
            <Delete />
          </Button>
        </Tooltip>
        {noChannels ? (
          <Typography color="text.secondary" sx={{ fontSize: 13 }}>
            {t('product:channelError.noChannelsFound')}
          </Typography>
        ) : (
          channels.map((channel: Channel, index: number) => {
            return (
              <ListItem
                key={index}
                disablePadding
                divider={channels.length !== index + 1}
                sx={{
                  width: 'inherit',
                }}
                secondaryAction={
                  newMessages.filter(x => x.channelID === channel.id).length > 0 && (
                    <Chip
                      sx={{ marginRight: '5px', float: 'right' }}
                      label={newMessages.filter(x => x.channelID === channel.id).length}
                    />
                  )
                }
              >
                <Checkbox
                  size="small"
                  checked={checked[channel.id] ? checked[channel.id] : false}
                  onChange={e => {
                    setChecked({ ...checked, [channel.id]: e.target.checked })
                  }}
                />
                <ListItemButton
                  onClick={() => handleChannelClick(channel)}
                  sx={{
                    maxHeight: '60px',
                    width: 'calc(100% - 38px)', //fill remaining space after checkbox
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography
                        variant="subtitle1"
                        color={'primary'}
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {`Ref: ${channel.reference}`}
                      </Typography>
                    }
                    secondary={
                      <div>
                        <Typography
                          variant="body2"
                          sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {t('product') + ': ' + channel.product}
                        </Typography>
                        {userGroups && userGroups.includes('AdminS3') && (
                          <Typography
                            variant="body2"
                            sx={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            From:{' '}
                            {user.attributes.email === channel.address_from
                              ? channel.address_to
                              : channel.address_from}
                          </Typography>
                        )}
                      </div>
                    }
                    sx={{
                      whiteSpace: 'nowrap',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            )
          })
        )}
      </List>
      <ListModal
        open={openDeleteModal}
        setOpen={() => setOpenDeleteModal(!openDeleteModal)}
        title={checkedChannels.length > 1 ? t('dialog.deleteChannels') : t('dialog.deleteChannel')}
        description={
          checkedChannels.length > 1
            ? t('dialog.areYouSureDeleteChannels')
            : t('dialog.areYouSureDeleteChannel')
        }
        list={checkedChannels.map(value => value.reference as string)}
        onConfirm={() => {
          handleChannelDelete(checkedChannels)
          setCheckedChannels([])
          setChecked({})
          setOpenDeleteModal(!openDeleteModal)
        }}
      />
    </div>
  )
}
