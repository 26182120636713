import React, { useState } from 'react'
import DeleteModal from './DeleteModal'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Alert, IconButton, Menu, MenuItem, Popover } from '@mui/material'
import { Delete, MoreVert, ShoppingCart } from '@mui/icons-material'
import { Contract, Product } from '../../graphql/graphql'
import { handlePromise } from '../../utils/functions'
import { API, graphqlOperation } from 'aws-amplify'
import { createContract } from '../../graphql/mutations'
import { customListContracts } from '../../graphql/custom-queries'

type Props = {
  onDelete: () => void
  product?: Product
}

/**
 * Vertical menu that lets you request or delete a product
 */
export default function VerticalMenu(props: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [deleteModal, openDeleteModal] = useState(false)
  const history = useHistory()
  const { t } = useTranslation()
  const [status, setStatus] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)

  const handleClose = () => {
    setAnchorEl(null)
  }

  async function orderProduct(product: Product) {
    const [res, err] = await handlePromise(
      'createContract',
      API.graphql(graphqlOperation(createContract, { input: { productID: product?.id } }))
    )
    res ? console.log(res.data.createContract) : console.log('Error on creating Contract', err)

    const id = res.data.createContract.id

    history.push('/contracts/' + id)
  }

  async function checkContractStatus(product: Product) {
    const [contractRes, contractErr] = await handlePromise(
      'listContracts',
      API.graphql(
        graphqlOperation(customListContracts, { filter: { productID: { eq: product?.id } } })
      )
    )
    if (contractRes) {
      if (contractRes.data.listContracts.items.length > 0) {
        contractRes.data.listContracts.items.map((contract: Contract) => {
          console.log(contract)
          if (contract.Status?.items.length && contract.Status?.items.length > 0) {
            setStatus(true)
            setOpen(true)
          }
        })
      } else {
        setStatus(false)
        openDeleteModal(true)
      }
    } else {
      console.log('Error checking contract status', contractErr)
    }
  }

  return (
    <div>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={e => setAnchorEl(e.currentTarget)}
        size="large"
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="verticalMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => checkContractStatus(props.product as Product)}>
          <Delete />
          {t('delete')}
        </MenuItem>
        <MenuItem onClick={() => orderProduct(props.product as Product)}>
          <ShoppingCart />
          {t('request')}
        </MenuItem>
      </Menu>
      <DeleteModal
        name={props.product ? props.product.name : ''}
        open={deleteModal}
        setOpen={openDeleteModal}
        onDelete={() => {
          props.onDelete()
          openDeleteModal(false)
          history.push({ pathname: '/products', state: true })
        }}
      />
      <Popover
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        open={open}
        onClose={() => setOpen(false)}
        anchorReference={'none'}
      >
        <Alert severity="warning" sx={{ fontSize: '20px', textAlign: 'center' }}>
          {' '}
          {t('product:error.canNotDelete')}
        </Alert>
      </Popover>
    </div>
  )
}
