import React from 'react'
import { Address } from '../../../graphql/graphql'
import { Form, FormFieldType } from '../../Form'
import { stringNotEmpty } from '../utils/validation'
import { Grid } from '@mui/material'

interface Props {
  address: Address
  setAddress: (address: Address) => void
  editing?: boolean
}

/**
 * Form that lets you fill in address information
 */
export function AddressForm(props: Props) {
  const { address, setAddress, editing = true } = props

  const formFields: FormFieldType[] = [
    {
      type: 'text',
      key: 'address_1',
      label: 'profile:addressForm.address1',
      validation: stringNotEmpty(address?.address_1),
      errorMessage: 'profile:addressForm.errAddress1',
    },
    {
      type: 'text',
      key: 'city',
      label: 'profile:addressForm.city',
      validation: stringNotEmpty(address?.city),
      errorMessage: 'profile:addressForm.errCity',
    },
    {
      type: 'text',
      key: 'zip_code',
      label: 'profile:addressForm.zipCode',
      validation: stringNotEmpty(address?.zip_code),
      errorMessage: 'profile:addressForm.errZipCode',
    },
    {
      type: 'text',
      key: 'country',
      label: 'profile:addressForm.country',
      validation: stringNotEmpty(address?.country as string),
      errorMessage: 'profile:addressForm.errCountry',
    },
    {
      type: 'text',
      key: 'name',
      label: 'profile:addressForm.contactPerson',
    },
    { type: 'text', key: 'address_2', label: 'profile:addressForm.address2' },
  ]

  return (
    <Grid container sx={{ marginTop: '10px' }}>
      <Form
        editing={editing}
        formObject={address}
        onChange={value => setAddress(value as Address)}
        formFields={formFields}
      />
    </Grid>
  )
}
