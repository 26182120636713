import React, { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AssemblyIcon,
  CNCMachineIcon,
  FuegenIcon,
  InjectionMouldingIcon,
  PostprocessingIcon,
  Printing3DIcon,
  QCIcon,
  SheetMetalIcon,
  SourcedPartIcon,
  customProcessIcon,
  rawMaterialIcon,
  standardPartIcon,
  purchasedPartIcon,
} from '../../../../utils/images'
import { Card, ImageListItem, ImageListItemBar } from '@mui/material'
import './Icons.css'
import useMobileService from '../../../../hooks/useMobileService'

type Props = {
  category: string
  active?: boolean
  style?: CSSProperties
}

const imageListItemStyles = {
  borderRadius: '3px',
  height: 100,
  '&:hover': {
    background: 'lightblue',
    borderRadius: '3px',
    height: 100,
  },
}

const itemSelected = {
  background: 'lightblue',
  borderRadius: '3px',
  height: 100,
}

/**
 * Icons needed for the different Manufacturingprocesses
 */
export function ProcessIcons(props: Props) {
  const { t } = useTranslation(['product'])
  const { category, active, style } = props
  const isMobile = useMobileService()

  const Icon = (source: string, label: string) => (
    <Card sx={active ? itemSelected : imageListItemStyles}>
      {!isMobile ? (
        <ImageListItem sx={{ height: 100 }}>
          <img
            style={{ width: 70, height: 70, display: 'flex', margin: 'auto' }}
            src={source}
            alt={label}
          />
          <ImageListItemBar
            sx={{ marginLeft: '5px', marginRight: '5px', textAlign: 'center' }}
            subtitle={t('category.' + label)}
            position="below"
          />
        </ImageListItem>
      ) : (
        <ImageListItem sx={{ width: 100, height: 100 }}>
          <img
            style={{ width: 70, height: 70, display: 'flex', margin: 'auto' }}
            src={source}
            alt={label}
          />
          <ImageListItemBar
            sx={{ marginLeft: '5px', marginRight: '5px', textAlign: 'center' }}
            subtitle={t('category.' + label)}
            position="below"
          />
        </ImageListItem>
      )}
    </Card>
  )

  const ManufacturingImage = (category: string) => {
    switch (category) {
      case 'CASTING':
        return InjectionMouldingIcon
      case 'ASSEMBLY':
        return AssemblyIcon
      case 'ADDITIVEMANUFACTURING':
        return Printing3DIcon
      case 'CNCMACHINING':
        return CNCMachineIcon
      case 'RAWMATERIAL':
        return rawMaterialIcon
      case 'CUSTOMPROCESS':
        return customProcessIcon
      case 'STANDARDCOMPONENTS':
        return standardPartIcon
      case 'JOINING':
        return FuegenIcon
      case 'PURCHASEDPART':
        return purchasedPartIcon
      case 'POSTPROCESSING':
        return PostprocessingIcon
      case 'QUALITYCONTROL':
        return QCIcon
      case 'SHEETMETAL':
        return SheetMetalIcon
      case 'SOURCEDPARTS':
        return SourcedPartIcon
      case 'PRINTING3D':
        return Printing3DIcon
      case 'INJECTIONMOULDING':
        return InjectionMouldingIcon
      default:
        return ''
    }
  }

  return Icon(ManufacturingImage(props.category), props.category)
}
