import React, { useEffect, useMemo, useState } from 'react'
import { Auth, Storage } from 'aws-amplify'
import { useDropzone } from 'react-dropzone'
import { Card, CardContent, Typography } from '@mui/material'
import { handlePromise } from '../../utils/functions'
import FileList from '../widgets/FileList'
import { useNotifications } from '../../features/Feedback'
import { useTranslation } from 'react-i18next'
import { Component, Product } from '../../graphql/graphql'
import LinearProgressWithLabel from '../widgets/LinearProgressWithLabel'
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column' as const,
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const focusedStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

type Props = {
  reloadFiles?: (reloaded: boolean) => void
  reloaded?: boolean
  uploadPath?: string
  component?: Component
  product?: Product
  isAdmin?: boolean
  credentials?: any
  identityIds?: any
}

export default function S3FileUpload(props: Props) {
  const [fileKeys, setFileKeys] = useState([]) // keys for selected files
  const [loading, setLoading] = useState(false) // loading status for uploading files
  const [user, setUser] = useState<any>() // aws cognito user
  const [filePath, setFilePath] = useState<string>() // path where to upload files
  const [progress, setProgress] = useState<number>(0) // progress of fileUpload
  const { addNotification } = useNotifications()
  const { t } = useTranslation()
  // hook for upload that uses drag and drop
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, acceptedFiles } =
    useDropzone({ onDrop: acceptedFiles => uploadFiles(acceptedFiles) })

  const s3Client = new S3Client({ region: 'eu-central-1', credentials: props.credentials })

  useEffect(() => {
    fetchUser()
  }, [])

  useEffect(() => {
    props.reloadFiles ? props.reloadFiles(!props.reloaded) : null
  }, [fileKeys])

  useEffect(() => {
    // upload files by location (e. g. uploaded in contracts uploads to /contracts folder)
    if (user && user?.attributes && user?.attributes.email) {
      if (props.product) {
        setFilePath(user.attributes.email + '/contracts/' + props.product.name + '/')
      } else if (props.component) {
        setFilePath(user.attributes.email + '/products/' + props.component.name + '/')
      } else if (props.uploadPath) {
        setFilePath(props.uploadPath.slice(-1) === '/' ? props.uploadPath : props.uploadPath + '/')
      } else {
        setFilePath(user.attributes.email + '/')
      }
    }
  }, [user, props.uploadPath])

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  )

  async function fetchUser() {
    const [res, error] = await handlePromise(
      'getCurrentAuthenticatedUser',
      Auth.currentAuthenticatedUser()
    )
    res ? setUser(res) : console.log('Error on fetching current authenticated user')
  }

  /**
   * Handles uploading one ore multiple files
   * @params files list of files to upload
   */
  function uploadFiles(files: any[]) {
    files.map((file: any, index) => {
      props.isAdmin && filePath && filePath.split('/').length > 2
        ? handleUploadAdmin(file)
        : handleUpload(file, index)
    })
  }

  /**
   * Handles uploading single file by file path and sets progress of upload
   * @params file fileobject with key
   */
  async function handleUpload(file: any, index: number) {
    setLoading(true)
    setProgress(0)
    const [data, error] = await handlePromise(
      'uploadFile',
      Storage.put(filePath + file.name, file, {
        level: 'private',
        progressCallback(progress) {
          setProgress(Math.round(progress.loaded / progress.total) * 100)
        },
      })
    )
    data
      ? addNotification({ message: t('common:success.uploadedFileSuccessfully'), color: 'success' })
      : addNotification({ message: t('common:error.errorOccurred'), color: 'error' })
    setFileKeys(data)
    setLoading(false)
  }

  /**
   * Handles uploading file as an admin using lambda layer assembleanplatformgraphQL
   * @params file fileobject with key
   */
  async function handleUploadAdmin(file: any) {
    if (filePath) {
      setLoading(true)
      try {
        const result = await s3Client.send(
          new PutObjectCommand({
            Bucket: process.env.REACT_APP_BUCKET_NAME,
            Key: 'private' + '/' + props.identityIds[filePath.split('/')[1]] + filePath + file.name, // uploads need to be on private folders
            Body: file,
          })
        )
        console.log('Success: ', result)
        props.reloadFiles ? props.reloadFiles(!props.reloaded) : null
        setLoading(false)
      } catch (err) {
        console.log('Error: ', err)
        setLoading(false)
      }
    }
  }

  return (
    <Card>
      <CardContent>
        <Typography>{t('uploadAdditionalFiles')}</Typography>
      </CardContent>
      <Card>
        <div className="container" style={{ margin: '5px' }}>
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <p>Drag &apos;n&apos; drop some files here, or click to select files</p>
          </div>
          {loading && <LinearProgressWithLabel value={progress} />}
          <FileList type={'DOCUMENT'} fileKeys={fileKeys} filePath={filePath as string} />
        </div>
      </Card>
    </Card>
  )
}
