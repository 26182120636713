import React, { useEffect, useState } from 'react'
import { Form, FormFieldType } from '../../../Form'
import { requirementsToFileUploads } from '../../utils/functions'
import { Component, ManufacturingRequirement } from '../../../../graphql/graphql'
import { handlePromise } from '../../../../utils/functions'
import { API, graphqlOperation } from 'aws-amplify'
import { listManufacturingRequirements } from '../../../../graphql/queries'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
  component: Component
}

/**
 * Fileuploads inside of the DynamicForm
 * Every file you upload here lands in the Filesystem in the
 * "/products" folder and creates a new folder with the components name
 * as a label
 */
export default function FileUploads(props: Props) {
  const { component } = props
  const [requirements, setRequirements] = useState<ManufacturingRequirement[]>([])
  const [fileUploadObject, setFileObject] = useState({})
  const uploadFieldData: FormFieldType[] = requirementsToFileUploads(
    requirements.filter(
      (value, index, self) => index === self.findIndex(t => t.type === value.type)
    ),
    component
  )
  const { t } = useTranslation()

  useEffect(() => {
    fetchRequirements()
  }, [])

  useEffect(() => {
    setFileObject(createFileObject(uploadFieldData))
  }, [requirements])

  async function fetchRequirements() {
    if (component.manufacturingprocessID) {
      const [res, err] = await handlePromise(
        'listManufacturingRequirements',
        API.graphql(
          graphqlOperation(listManufacturingRequirements, {
            filter: {
              manufacturingProcessID: { eq: component.manufacturingprocessID },
            },
            limit: 200,
          })
        )
      )
      res
        ? setRequirements(res.data.listManufacturingRequirements.items)
        : console.log('Error fetching ManufacturingRequirements', err)
    }
  }

  function createFileObject(requirementsData: FormFieldType[]) {
    const fileObj: any = {}
    requirementsData.map((file: any, index: number) => (fileObj[`${file.label}`] = ''))
    return fileObj
  }

  return (
    <div>
      {uploadFieldData.length === 0 ? (
        <Typography>{t('product:dynamicForm.noFileUploadsNeeded')}</Typography>
      ) : (
        <Form
          formObject={fileUploadObject}
          formFields={uploadFieldData}
          onChange={value => setFileObject(value)}
          editing={true}
        />
      )}
    </div>
  )
}
