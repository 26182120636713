import { API, graphqlOperation } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import useMobileService from '../hooks/useMobileService'
import Page from '../components/layout/Page'
import { Observable } from 'zen-observable-ts'
import { Contract, ContractRequest, Product } from '../graphql/graphql'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import FAB from '../components/widgets/FAB'
import MobileListItem from '../components/widgets/MobileListItem'
import { customListContracts } from '../graphql/custom-queries'
import LoadingSkeleton from '../components/widgets/LoadingSkeleton'
import useQuery from '../hooks/useAPI'
import ContractOptionsModal from '../features/Contract/components/ContractOptionsModal'
import { handlePromise } from '../utils/functions'
import { createContract } from '../graphql/mutations'
import { onCreateContractStatus } from '../graphql/subscriptions'
import { ContractStatus } from '../graphql/graphql'
import { listContractRequests, listContractStatuses } from '../graphql/queries'

interface Date {
  year: string
  month: string
  day: string
  hour: string
  minute: string
  second: string
  millisecond: string
}

/**
 * Shows all contracts in a table
 */
function ContractOverviewPage() {
  const { t } = useTranslation(['common', 'contract'])
  const [contractList, setContracts] = useState<Contract[]>([])
  const [openOptionsModal, setOpenOptionsModal] = useState<boolean>(false)
  const [contractStatuses, setContractStatuses] = useState<ContractStatus[]>([])
  const [contractRequests, setContractRequests] = useState<ContractRequest[]>([])
  const { data, error, loading } = useQuery(
    'customListContracts',
    API.graphql(graphqlOperation(customListContracts))
  )
  const history = useHistory()
  const isMobile = useMobileService()
  
  useEffect(() => {
    data ? setContracts(data.data?.listContracts.items) : null
    fetchContractRequests()
    fetchContractStatuses()
  }, [data])

  useEffect(() => {
    // update contractStatuses when new statuses are received
    const client = API.graphql(
      graphqlOperation(onCreateContractStatus, {
        input: { owner: contractList[0]?.owner },
      })
    ) as Observable<any>
    const subscription = client.subscribe({
      next: event => {
        console.log(event.value.data.onCreateContractStatus)
        setContractStatuses([
          ...(contractStatuses as ContractStatus[]),
          event.value.data.onCreateContractStatus,
        ])
      },
      error: error => console.warn(error),
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [contractStatuses])

  console.log('ContractStatuses: ', contractStatuses)

  async function fetchContractStatuses() {
    const [res, err] = await handlePromise(
      'listContractStatuses',
      API.graphql(graphqlOperation(listContractStatuses))
    )
    res
      ? setContractStatuses(res.data.listContractStatuses.items)
      : console.log('Error on fetching contractStatuses', err)
  }

  async function fetchContractRequests() {
    const [res, err] = await handlePromise(
      'listContractRequests',
      API.graphql(graphqlOperation(listContractRequests))
    )
    res
      ? setContractRequests(res.data.listContractRequests.items)
      : console.log('Error on fetching contractRequests', err)
  }

  async function orderProduct(product: Product) {
    const [res, err] = await handlePromise(
      'createContract',
      API.graphql(graphqlOperation(createContract, { input: { productID: product?.id } }))
    )
    res ? console.log(res.data.createContract) : console.log('Error on creating Contract', err)

    const id = res.data.createContract.id

    history.push('/contracts/' + id)
  }

  function parseAWSDateString(dateString: string): Date {
    const date = dateString.split(/-|T|:|\.|Z/)
    return {
      year: date[0],
      month: date[1],
      day: date[2],
      hour: date[3],
      minute: date[4],
      second: date[5],
      millisecond: date[6],
    }
  }

  function getDDMMYYYY(date: Date): string {
    return `${date.day}.${date.month}.${date.year}`
  }

  if (error) {
    return <div>{t('contract:error.fetchingContracts')}</div>
  }

  if (loading) {
    return <LoadingSkeleton />
  }

  const columnWidths = {
    name: {
      width: 200,
      maxWidth: 200,
    },
    description: {
      width: 120,
      maxWidth: 120,
    },
    articleNumber: {
      width: 100,
      maxWidth: 100,
    },
  }

  return (
    <Page marginBottom="200px">
      {isMobile ? (
        <div>
          <h2>{t('contract:overview.yourContracts')}</h2>
          {contractList.length === 0 ? (
            //Show Empty-Page-Info when there are no contracts yet
            <Typography align="center" color="text.secondary" sx={{ fontSize: 13 }}>
              {t('contract:error.noContractsFound')}
            </Typography>
          ) : (
            //Show Contracts
            contractList.map((contract: Contract, index) => {
              return (
                <MobileListItem
                  key={index}
                  primaryText={contract.Product?.name}
                  onClick={() => history.push('/contracts/' + contract?.id)}
                  buttonWhenClosed={t('mobile.showDetails')}
                  buttonWhenOpened={t('mobile.hideDetails')}
                  index={index}
                >
                  <br />
                  {`${t('contract:details.wishDate')}: ${
                    contract?.wish_date
                      ? getDDMMYYYY(parseAWSDateString(contract.wish_date))
                      : t('notSetYet')
                  }`}
                  <br />
                  {`${t('contract:details.deadline')}: ${
                    contract?.deadline
                      ? getDDMMYYYY(parseAWSDateString(contract.deadline))
                      : t('notSetYet')
                  }`}
                  <br />
                  {`${t('quantity')}: ${contract?.quantity ? contract.quantity : t('notSetYet')}`}
                  <br />
                  {`${t('price')}: ${contract?.price ? contract.price : t('notSetYet')}`}
                  <br />
                </MobileListItem>
              )
            })
          )}
          {/* <FAB type={'ADD'} onClick={() => history.push('/contracts/new')} /> */}
        </div>
      ) : (
        <Card>
          <CardContent>
            <Typography variant="h5">{t('contract:overview.contractList')}</Typography>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">#</TableCell>
                    <TableCell align="center">{t('name')}</TableCell>
                    <TableCell align="center">{t('articleNumber')}</TableCell>
                    <TableCell align="center">{t('description')}</TableCell>
                    <TableCell align="center">{t('quantity')}</TableCell>
                    <TableCell align="center">{t('price')}</TableCell>
                    <TableCell align="center">{t('status')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contractList.map((contract: Contract, index) => {
                    return (
                      <TableRow
                        key={index}
                        hover={true}
                        onClick={() => history.push('/contracts/' + contract?.id)}
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            width: columnWidths.name.width,
                            maxWidth: columnWidths.name.maxWidth,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {contract?.Product?.name}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: columnWidths.articleNumber.width,
                            maxWidth: columnWidths.articleNumber.maxWidth,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {contract?.Product?.article_number}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: columnWidths.description.width,
                            maxWidth: columnWidths.description.maxWidth,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {contract?.description}
                        </TableCell>
                        <TableCell align="center">{contract?.quantity}</TableCell>
                        <TableCell align="center">{contract?.price}</TableCell>
                        {contractRequests.filter(x => x.contractID === contract.id).length > 0 &&
                        contractStatuses.filter(x => x.contractID === contract.id).length === 0 ? (
                          <TableCell align="center">REQUEST</TableCell>
                        ) : (
                          <TableCell align="center">
                            {
                              contractStatuses
                                .filter(x => x.contractID === contract.id)
                                .sort(
                                  (a, b) =>
                                    Date.parse(b?.createdAt as string) -
                                    Date.parse(a?.createdAt as string)
                                )[0]?.Status
                            }
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      )}
      <FAB type="ADD" onClick={() => setOpenOptionsModal(!openOptionsModal)} />
      <ContractOptionsModal
        open={openOptionsModal}
        onClose={() => setOpenOptionsModal(!openOptionsModal)}
        onConfirm={(product: Product) => orderProduct(product)}
      />
    </Page>
  )
}
export default ContractOverviewPage
