import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Card, CardActions, CardContent, CardHeader, Grid, Modal } from '@mui/material'

type Props = {
  name: string
  open: boolean
  setOpen: (open: boolean) => void
  onDelete: () => void
}

export default function DeleteModal(props: Props) {
  // getModalStyle is not a pure function, we roll the style only on the first render
  const { t } = useTranslation()

  const body = (
    <Grid
      container
      spacing={0}
      alignItems={'center'}
      justifyContent={'center'}
      style={{ height: '100%', padding: '10px' }}
    >
      <Grid item>
        <Card style={{ padding: '20px' }}>
          <CardHeader title={t('areYouSure')} />
          <CardContent>{t('deleteText', { name: props.name })}</CardContent>
          <CardActions>
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs={6}>
                <Button
                  style={{ float: 'left' }}
                  color="primary"
                  variant="outlined"
                  onClick={() => props.setOpen(false)}
                >
                  {t('cancel')}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  style={{ float: 'right' }}
                  color="primary"
                  variant="contained"
                  onClick={props.onDelete}
                >
                  {t('delete')}
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  )

  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => props.setOpen(false)}
        aria-labelledby="delete-modal"
        aria-describedby="modal-for-final-delete"
      >
        {body}
      </Modal>
    </div>
  )
}
