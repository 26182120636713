import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Autocomplete, TextField } from '@mui/material'

type Props<T> = {
  name: string
  value: T
  options: { value: T; label?: string; disabled?: boolean }[]
  disabled?: boolean
  labelWidth?: number
  onChange: (value: T) => void
}

export default function SelectWithTextInput<T extends string | number | boolean>(props: Props<T>) {
  const { t } = useTranslation(['common', 'profile'])
  const { name, value, options, disabled, labelWidth = 100, onChange } = props
  const [inputValue, setInputValue] = useState<string>('')
  const translatedOptions = () =>
    options.map((value: any) => {
      return t(value.label)
    })
  const [newOptions, setNewOptions] = useState<any[]>(translatedOptions())

  return (
    <Autocomplete
      options={newOptions}
      noOptionsText={t('EnterForNewOption')}
      isOptionEqualToValue={(option: T, value: T) => option == value} // from strict equal(default) to non-strict equal
      onInputChange={(e, newValue) => {
        onChange(newValue as T)
        setInputValue(newValue)
      }}
      disabled={disabled}
      defaultValue={value}
      renderInput={params => (
        <TextField
          {...params}
          label={name}
          variant="outlined"
          onKeyDown={e => {
            if (
              e.key === 'Enter' &&
              newOptions.findIndex((o: any) => o.value === inputValue) === -1
            ) {
              setNewOptions([...newOptions, { value: inputValue as T, label: inputValue }])
              onChange(inputValue as T)
            }
          }}
        />
      )}
    />
  )
}
