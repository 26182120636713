/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Redirect, Route } from 'react-router-dom'

interface Props {
  component?: any
  layout: any
  title?: string
  showBack?: boolean
  showSettings?: boolean
  onBackClick?: () => void
  [x: string]: any
}

interface AuthProps {
  component: any
  layout: any
  title?: string
  showBack?: boolean
  showSettings?: boolean
  onBackClick?: () => void
  [x: string]: any
  user: any
}

interface AdminProps {
  component: any
  layout: any
  title?: string
  showBack?: boolean
  showSettings?: boolean
  onBackClick?: () => void
  [x: string]: any
  user: any
  groups: any
}

export const PublicRoute = ({
  component: Component,
  layout: Layout,
  title,
  showBack,
  showSettings,
  onBackClick,
  ...rest
}: Props) => (
  <Route
    {...rest}
    render={(props: { [x: string]: any }) => (
      <Layout
        title={title}
        showBack={showBack}
        showSettings={showSettings}
        onBackClick={onBackClick}
      >
        <Component {...props} />
      </Layout>
    )}
  />
)

export function AuthenticatedRoute(props: AuthProps) {
  const {
    user,
    layout: Layout,
    showSettings,
    showBack,
    onBackClick,
    title,
    component: Component,
    ...rest
  } = props
  return (
    <Route
      {...rest}
      render={props => {
        console.log('user', user)
        if (user) {
          return (
            <Layout
              title={title}
              showBack={showBack}
              showSettings={showSettings}
              onBackClick={onBackClick}
            >
              <Component {...props} />
            </Layout>
          )
        }
        return <Redirect to={{ pathname: '/signIn', state: { from: props.location } }} />
      }}
    />
  )
}

export function AdminRoute(props: AdminProps) {
  const {
    user,
    groups,
    layout: Layout,
    showSettings,
    showBack,
    onBackClick,
    title,
    component: Component,
    ...rest
  } = props
  return (
    <Route
      {...rest}
      render={props => {
        console.log("Admin user: ", user)
        if (user && groups && groups.includes('AdminS3')) {
          return (
            <Layout
              groups={groups}
              title={title}
              showBack={showBack}
              showSettings={showSettings}
              onBackClick={onBackClick}
            >
              <Component {...props} />
            </Layout>
          )
        }
        return <Redirect to={{ pathname: '/signIn', state: { from: props.location } }} />
      }}
    />
  )
}
