import { Button, ButtonGroup, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

type Props = {
  history: any
  currentDepth: number
  currentPath: string
  onHistoryForwardClick: () => void
  onHistoryBackClick: () => void
  sx?: React.CSSProperties
}

/**
 * Navigation for Filesystem using history, depth and path
 * @param props
 * @returns
 */
export function FilesystemNavigation(props: Props) {
  const { history, currentPath, currentDepth, onHistoryForwardClick, onHistoryBackClick, sx } =
    props
  const [forwardDisabled, setForwardDisabled] = useState<boolean>(true)

  useEffect(() => {
    const breadcrumbContainer = document.getElementById('breadcrumb-container')
    breadcrumbContainer ? (breadcrumbContainer.scrollLeft = breadcrumbContainer?.scrollWidth) : null
  })

  useEffect(() => {
    (currentPath &&
      history.path &&
      history.depth &&
      currentPath.split('/').length === history.path.split('/').length) ||
    history.path === undefined ||
    currentDepth === history.depth
      ? setForwardDisabled(true)
      : setForwardDisabled(false)
  }, [currentPath])

  return (
    <div style={{ ...sx, display: 'flex' }}>
      <ButtonGroup size="small" variant="outlined">
        <Button onClick={onHistoryBackClick} disabled={currentPath.split('/').length === 1}>
          <ArrowBackIcon />
        </Button>
        <Button onClick={onHistoryForwardClick} disabled={forwardDisabled}>
          <ArrowForwardIcon />
        </Button>
      </ButtonGroup>
      <div
        id="breadcrumb-container"
        style={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'start',
          marginLeft: '8px',
          flexGrow: 1,
          flexShrink: 0,
          width: 'calc(100% - 95px)',
          whiteSpace: 'nowrap',
          overflowX: 'auto',
          border: '1px solid gray',
          borderRadius: '4px',
        }}
      >
        {currentPath.split('/').map((folder, index) => {
          return (
            <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ marginLeft: '5px' }}>{folder !== '' && folder + '/'}</Typography>
            </div>
          )
        })}
      </div>
    </div>
  )
}
