import IconButton from '@mui/material/IconButton'
import MUISnackbar from '@mui/material/Snackbar'
import { createStyles, makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import MuiAlert from '@mui/lab/Alert'
import React from 'react'
import { useNotifications } from '../hooks/useNotifications'
import { Theme } from '@mui/material'

// Snackbar should appear above tabBar (on mobile).
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    snackbar: {
      [theme.breakpoints.down(1)]: {
        bottom: 65,
      },
    },
  })
)

export function Snackbar() {
  const classes = useStyles()
  const { notifications, removeNotification } = useNotifications()

  if (!notifications || notifications.length === 0) {
    return null
  }

  const { color, message, id } = notifications[0]

  const closeSnackbar = () => {
    removeNotification(id)
  }

  if (color === 'default') {
    return (
      <MUISnackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        className={classes.snackbar}
        open
        autoHideDuration={5000}
        onClose={() => closeSnackbar()}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{message}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={() => closeSnackbar()}
            size="large"
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    )
  }
  return (
    <MUISnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      className={classes.snackbar}
      open
      autoHideDuration={5000}
      onClose={() => closeSnackbar()}
    >
      <MuiAlert elevation={6} variant="filled" onClose={() => closeSnackbar()} severity={color}>
        {message}
      </MuiAlert>
    </MUISnackbar>
  )
}
