import React from 'react'
import { Component } from '../../../../graphql/graphql'
import { Form, FormFieldType } from '../../../Form'
import { stringNotEmpty } from '../../utils/validation'

type Props = {
  component: Component
  setComponent: (component: Component) => void
}

/**
 * Form that holds general information(details) of component
 */
export default function ComponentDetails(props: Props) {
  const { component, setComponent } = props

  const componentData: FormFieldType[] = [
    {
      type: 'text',
      key: 'name',
      label: 'name',
      xs: 12,
      sm: 6,
      validation: stringNotEmpty(component.name),
    },
    { type: 'text', key: 'tolerances', label: 'product:overview.tolerances', xs: 12, sm: 6 },
    { type: 'text', key: 'weight', label: 'weight', xs: 12, sm: 6 },
    { type: 'text', key: 'article_number', label: 'articleNumber', xs: 12, sm: 6 },
    {
      type: 'select',
      key: 'barcode_type',
      label: 'barcodeType',
      xs: 12,
      sm: 6,
      options: [
        { value: 'EAN', label: 'EAN' },
        { value: 'GTIN', label: 'GTIN' },
        { value: 'UPC', label: 'UPC' },
        { value: 'custom', label: 'custom' },
      ],
    },
    { type: 'text', key: 'barcode_content', label: 'barcodeContent', xs: 12, sm: 6 },
  ]

  return (
    <div>
      <Form
        formObject={component as Component}
        formFields={componentData}
        onChange={value => setComponent(value as Component)}
        editing={true}
      />
    </div>
  )
}
